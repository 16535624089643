import { useEffect, useState } from 'react';

import { explorerStore } from 'stores/media';
import { FileResponse, FileType } from 'utils/api/api';

import ImagePreview from 'assets/images/image-preview.svg';
import AudioPreview from 'assets/images/audio-preview.svg';
import VideoPreview from 'assets/images/video-preview.svg';

export const defaultPreview = (fileType: FileResponse['type']) => {
	switch (fileType) {
		case FileType.Image:
			return ImagePreview;
		case FileType.Audio:
			return AudioPreview;
		case FileType.Video:
			return VideoPreview;
	}
};

export function useFilePreview(file: FileResponse) {
	const [previewLink, setPreviewLink] = useState<string>();

	useEffect(() => {
		if (![FileType.Image, FileType.Video].includes(file.type)) return;

		explorerStore
			.getFilePreview(file.id)
			.then((blob) => {
				if (blob) {
					setPreviewLink(window.URL.createObjectURL(blob));
				}
			})
			.catch(console.error);
	}, [file.id, file.type]);

	return previewLink;
}
