import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout } from 'components/common';
import './styles.scss';
import { RouteLink } from '../../modules/routing-module';

export const Error: FC<{
	title: ReactNode;
	message?: ReactNode;
	renderButton?: ReactNode;
}> = (props) => {
	const { title, renderButton } = props;

	return (
		<Layout flex className="error__wrapper">
			<Layout flex row>
				<Layout flex className="error__content">
					<h1 className="error__heading">
						<span>{title}</span>
					</h1>
					<p className="error__message">
						<FormattedMessage
							id="Oops! Something went wrong."
							defaultMessage="Ой! Что-то пошло не так."
						/>
					</p>
					<p className="error__message">
						<FormattedMessage
							id="Please try again"
							defaultMessage="Пожалуйста, попробуйте еще раз"
						/>
					</p>
				</Layout>
				<div className="error__icon" />
			</Layout>
			{renderButton || (
				<RouteLink
					className="button button-secondary error__reload"
					routeKey="Home"
				>
					<FormattedMessage id="Try Again" defaultMessage="Try Again" />
				</RouteLink>
			)}
		</Layout>
	);
};
