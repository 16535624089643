import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { bidsStore } from 'stores/bids';
import { authStore } from 'stores/auth';

import { IBidsStore } from '_types/stores';

import { explorerStore } from 'stores/media';

import { formatCurrency, formatSeconds } from 'utils';
import { BidApprove, BidResponse, UserRole } from 'utils/api/api';

import { IVideoEngine, IVideoProps, Video } from 'components/video';

export interface IBidsWindowProps {
	bid: BidResponse;
	onClose?: () => void;
}

export const BidsWindow: FC<IBidsWindowProps> = observer(({ bid, onClose }) => {
	const intl = useIntl();

	const isAdvertiser = useMemo(
		() => authStore.userRole === UserRole.Advertiser,
		[authStore.userRole],
	);

	const playButtonRef = useRef<HTMLButtonElement | null>(null);
	const [sources, setSources] = useState<IVideoProps['sources']>([]);

	const createClickCallback = useCallback(
		(approved: Parameters<IBidsStore['decide']>[1]) => {
			return () => {
				if (!isAdvertiser && bid) {
					void bidsStore.decide(bid, approved);
				}
			};
		},
		[bid, isAdvertiser],
	);

	const createPlayToggle = useCallback(
		(engine: IVideoEngine) => {
			return () => {
				if (!sources.length) return;
				if (engine.isPlayed) {
					engine.pause();
				} else {
					engine.play();
				}
			};
		},
		[sources],
	);

	useEffect(() => {
		if (bid.playlist) {
			Promise.all(
				bid.playlist.files.map(async (file) => {
					const blob = await explorerStore.getFileS3(file.id, file.name);
					if (blob) {
						const video = document.createElement('VIDEO') as HTMLVideoElement;

						video.src = window.URL.createObjectURL(blob);

						return new Promise((resolve) => {
							video.onloadedmetadata = () => {
								resolve(video);
							};
						});
					}
					return undefined;
				}),
			).then((videos) =>
				setSources(videos.filter((v): v is HTMLVideoElement => Boolean(v))),
			);
		}
	}, [bid.playlist]);

	useEffect(() => {
		if (sources.length) {
			playButtonRef.current?.click(); // autoPlay
		}
	}, [sources]);

	return (
		<div className="bids__window">
			<div className="bids__player-layout">
				<Video className="bids__player" sources={sources}>
					{(engine) => {
						return (
							<div className="bids__controls-layout">
								<div className="bids__info">
									<span className="bids__info-name">{bid?.monitor.name}</span>
									<div className="bids__info-container">
										<div className="bids__info-time">
											{formatSeconds(engine.totalDuration)}
										</div>
										<div className="bids__info-price">
											{bid?.monitor.price1s
												? formatCurrency(bid.monitor.price1s)
												: intl.formatMessage({ id: 'PriceFree' })}
										</div>
									</div>
								</div>
								<div className="bids__controls">
									<div className="bids__decor" />
									<button
										className="bids__controls-button bids__rewind"
										onClick={() =>
											engine.moveTimelinePointer({ shiftSeconds: -15 })
										}
										title={intl.formatMessage({
											id: 'app.button.rewind15',
											defaultMessage: 'Назад на 15 секунд',
										})}
									/>
									<button
										ref={playButtonRef}
										className={cx('bids__controls-button bids__play', {
											'bids__play--on': engine.isPlayed,
										})}
										disabled={!sources.length}
										onClick={createPlayToggle(engine)}
									/>
									<button
										className="bids__controls-button bids__forward"
										onClick={() =>
											engine.moveTimelinePointer({ shiftSeconds: +15 })
										}
										title={intl.formatMessage({
											id: 'app.button.forward15',
											defaultMessage: 'Вперед на 15 секунд',
										})}
									/>
									<button
										className="bids__controls-button bids__speed"
										onClick={() =>
											engine.setPlayback(engine.playbackRate === 2 ? 1 : 2)
										}
									>
										x{engine.playbackRate}
									</button>
								</div>
								<div className="bids__buttons">
									{[BidApprove.Allowed, BidApprove.Denied].map((status) =>
										isAdvertiser ? (
											<div
												key={status}
												className={cx({
													'bids__buttons-approve':
														status === BidApprove.Allowed,
													'bids__buttons-deny': status === BidApprove.Denied,
												})}
											>
												<FormattedMessage id={status} defaultMessage={status} />
											</div>
										) : (
											<button
												key={status}
												className={cx({
													'bids__buttons-approve':
														status === BidApprove.Allowed,
													'bids__buttons-deny': status === BidApprove.Denied,
												})}
												onClick={
													isAdvertiser ? undefined : createClickCallback(status)
												}
												disabled={bid.approved !== BidApprove.NotProcessed}
											>
												<FormattedMessage id={status} defaultMessage={status} />
											</button>
										),
									)}
								</div>
							</div>
						);
					}}
				</Video>
				<button className="bids__close" onClick={onClose} />
			</div>
		</div>
	);
});
