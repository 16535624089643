import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import { Calendar, ICalendarProps } from '../calendar';

export type TDateRangeValue = ICalendarProps['value'][] | null;

export interface IDatePickerProps {
	isOpen: boolean;
	onSearch: (value: TDateRangeValue) => void;
}

const currentDate: TDateRangeValue = [];

export const DatePicker: FC<IDatePickerProps> = (props) => {
	const { isOpen, onSearch } = props;

	const [draftValue, setDraftValue] = useState(currentDate);

	const handleReset = useCallback(() => {
		setDraftValue(currentDate);
		onSearch(null);
	}, [onSearch]);

	const handleSearch = useCallback(() => {
		onSearch(draftValue);
	}, [onSearch, draftValue]);

	if (!isOpen) return null;

	return (
		<div className="filters__date-layout">
			<Calendar
				onRangeChange={(dateRange) => setDraftValue(dateRange)}
				value={null}
				rangeValue={draftValue}
			/>
			<div className="filters__date-actions">
				<button className="filters__search-button" onClick={handleSearch}>
					<FormattedMessage id="Search" defaultMessage="Search" />
				</button>
				<button className="filters__reset-button" onClick={handleReset}>
					<FormattedMessage id="Reset" defaultMessage="Reset" />
				</button>
			</div>
		</div>
	);
};
