import React, { FC, HTMLAttributes, ReactNode, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { IUser } from '_types/stores';
import { IListOrder } from 'utils/api/components';
import { TableHead } from './table-head';
import { TableBody } from './table-body';

import './styles.scss';
import cx from 'classnames';

export interface ITableRow {
	id: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

export interface ITableColumn {
	accessor: string;
	messageId?: string;
	label?: string;
	withSort?: boolean;
	renderFilter?: () => ReactNode;
	restrictedFrom?: IUser['role'][];
}

export type RenderColumn = (props: {
	columns: ITableColumn[];
	row: ITableRow;
	column: ITableColumn;
}) => ReactNode;

export type RenderRow = (props: {
	columns: ITableColumn[];
	row: ITableRow;
	renderColumn: RenderColumn;
}) => ReactNode;

export interface ITableProps extends HTMLAttributes<HTMLDivElement> {
	columns: Array<ITableColumn | ITableColumn['accessor']>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: Array<ITableRow>;
	onSortClick?: (
		accessor: ITableColumn['accessor'],
		e: React.MouseEvent<HTMLDivElement>,
	) => void;
	order?: IListOrder['order'];
	renderRow?: RenderRow;
	renderColumn?: RenderColumn;
	showHead?: boolean;
}

const TableComponent: FC<ITableProps> = ({
	className,
	columns,
	data,
	onSortClick,
	order,
	renderRow,
	renderColumn,
	showHead = true,
}) => {
	const normalizedColumns = useMemo(() => {
		const { userRole } = authStore;

		const normalizedColumns = columns.map((c) =>
			typeof c === 'object' ? c : { accessor: c },
		);

		return userRole
			? normalizedColumns.filter(
					(c) => !c.restrictedFrom || !c.restrictedFrom.includes(userRole),
				)
			: normalizedColumns;
	}, [columns, authStore.userRole]);

	return (
		<div
			className={cx('table', className, {
				'no-head': !showHead,
			})}
		>
			<table className="table__table">
				{showHead && (
					<TableHead
						columns={normalizedColumns}
						onSortClick={onSortClick}
						order={order}
					/>
				)}
				<TableBody
					columns={normalizedColumns}
					data={data}
					renderRow={renderRow}
					renderColumn={renderColumn}
				/>
			</table>
		</div>
	);
};

export const Table = observer(TableComponent);
