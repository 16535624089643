import { FC, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Button, Layout } from 'components/common';
import { InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import SendIcon from 'assets/icons/send-email.svg';
import './styles.scss';

export const SendRegisterEmail: FC = () => {
	const [searchParams] = useSearchParams();
	const domain = searchParams.get('domain');
	const domainUrl = useMemo(() => {
		if (domain) {
			return new URL(`https://${domain}`);
		}
		return null;
	}, [domain]);

	return (
		<AuthWrapper
			title="Email Delivered"
			subtitle="Check your email, you will now receive a confirmation email"
		>
			<Layout className="form" flex>
				<InputField>
					<img src={SendIcon} className="page-image  auth__icon" alt="Sent" />
				</InputField>
				<InputField>
					{domainUrl ? (
						<Button
							tag="a"
							href={domainUrl.toString()}
							target="_blank"
							primary
							className="auth__button auth__button--margin"
						>
							<FormattedMessage id="Go to email" defaultMessage="Go to email" />
						</Button>
					) : (
						<Link
							to="/login"
							className="button button-primary auth__button auth__button--margin"
						>
							<FormattedMessage id="Sign In" defaultMessage="Sign in" />
						</Link>
					)}
				</InputField>
			</Layout>
		</AuthWrapper>
	);
};
