import { appStore } from '../../stores/app';
import { FormatNumberOptions } from '@formatjs/intl/src/types';

export interface IFormatCurrencyOptions extends FormatNumberOptions {}

export const CURRENCY_SEPARATOR = '.';
export const DEFAULT_MINIMUM_FRACTION_DIGITS = 2;

export function formatCurrency(
	value: string | number | undefined,
	intl = appStore.intl,
	options?: IFormatCurrencyOptions,
) {
	const number = Number(value) || 0;
	let { minimumFractionDigits = DEFAULT_MINIMUM_FRACTION_DIGITS } =
		options || {};
	if (typeof value === 'string') {
		const [, fractionDigits] = value.split(CURRENCY_SEPARATOR);
		minimumFractionDigits = fractionDigits.length;
	}

	return intl.formatNumber(number, {
		style: 'currency',
		currency: 'RUB',
		minimumFractionDigits,
	});
}
