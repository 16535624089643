import 'components/page-plate/styles.scss';
import { icons } from 'assets/icons';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Button } from 'components/common';
import { PagePlate } from 'components/page-plate/page-plate';
import { DateInput, Form } from 'components/forms';
import { useForm } from '../../hooks';
import {
	MonitorStatus,
	ReportViewsRequest,
	SpecificFormat,
} from '../../utils/api/api';
import { monitorsStore } from '../../stores/monitors/monitor-list';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import * as MonitorListComponents from '../monitors/list-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '../../components/icon';
import { downloadFile, handleError, swaggerApi, toast } from '../../utils';

import './statistics.scss';
import { runInAction } from 'mobx';
import { appStore } from '../../stores/app';

export const Statistics: FC = observer(() => {
	const today = useMemo(() => new Date(), []);
	const intl = useIntl();

	const [{ values, canSubmit, formProps }, { setField }] = useForm<
		Partial<Omit<ReportViewsRequest, 'format'>>
	>(
		{},
		{
			parsers: {
				monitorIds: (value, { monitorIds = [] }) => ({
					monitorIds: monitorIds.includes(value as string)
						? monitorIds.filter((mId) => mId !== value)
						: monitorIds.concat(value as string),
				}),
				dateRange: (value) => {
					if (typeof value === 'string') {
						const [dateFrom, dateTo] = JSON.parse(value) as string[];
						return {
							dateFrom,
							dateTo,
						};
					}
				},
			},
		},
	);

	const monitorList = useMemo(
		() =>
			monitorsStore.listNoSubordinate.map((m) => {
				const checked = values.monitorIds?.includes(m.id);

				return (
					<label
						key={m.id}
						className={cx('page-plate__list-item', { checked })}
					>
						<input
							type="checkbox"
							name="monitorIds"
							defaultValue={m.id}
							checked={checked}
							onChange={() => null}
							className="page-plate__list-checkbox"
						/>
						<MonitorListComponents.NameWithIcon
							name={m.name}
							enabled={m.status === MonitorStatus.Online}
							type={m.multiple}
						/>
					</label>
				);
			}),
		[monitorsStore.listNoSubordinate, values.monitorIds],
	);

	const reportByViews = useCallback(
		async (format: SpecificFormat) => {
			if (values.dateFrom && values.dateTo) {
				try {
					runInAction(() => {
						appStore.isLoading = true;
					});
					const response = await swaggerApi.api.reportViews(
						{
							monitorIds: values.monitorIds,
							dateFrom: values.dateFrom,
							dateTo: values.dateTo,
							format,
						},
						{
							format: 'blob',
							timeout: 0,
						},
					);
					downloadFile(
						window.URL.createObjectURL(response.data as unknown as Blob),
						`${intl.formatMessage({ id: 'ReportByViews' })} (${values.dateFrom}-${values.dateTo}).${format}`,
					);
				} catch (e) {
					toast.error(handleError(e));
				} finally {
					runInAction(() => {
						appStore.isLoading = false;
					});
				}
			}
		},
		[values],
	);

	const reportByStatus = useCallback(
		async (format: SpecificFormat) => {
			if (values.dateFrom && values.dateTo) {
				try {
					runInAction(() => {
						appStore.isLoading = true;
					});
					const response = await swaggerApi.api.deviceStatus(
						{
							monitorIds: values.monitorIds,
							dateFrom: values.dateFrom,
							dateTo: values.dateTo,
							format,
						},
						{
							format: 'blob',
							timeout: 0,
						},
					);
					downloadFile(
						window.URL.createObjectURL(response.data as unknown as Blob),
						`${intl.formatMessage({ id: 'ReportByStatus' })} (${values.dateFrom}-${values.dateTo}).${format}`,
					);
				} catch (e) {
					toast.error(handleError(e));
				} finally {
					runInAction(() => {
						appStore.isLoading = false;
					});
				}
			}
		},
		[values],
	);

	useEffect(() => {
		void monitorsStore.getList();
	}, []);

	return (
		<div className="statistics">
			<h1 className="main__title">
				<FormattedMessage
					id="app.route.Statistics"
					defaultMessage="Статистика"
				/>
			</h1>
			<PagePlate
				iconClassName={icons.Statistics}
				name={
					<h2>
						<FormattedMessage id="Reports" defaultMessage="Отчеты" />
					</h2>
				}
			>
				<Form {...formProps} className="page-plate__form">
					<fieldset className="page-plate__fieldset">
						<legend className="page-plate__fieldset-legend">
							Выбрать период:
						</legend>
						<label
							className="page-plate__row statistics__period-row"
							htmlFor="dateRange"
						>
							<DateInput
								max={today}
								name="dateRange"
								value={[values.dateFrom, values.dateTo].map((str) =>
									str ? new Date(str) : undefined,
								)}
							/>
						</label>
					</fieldset>
					<fieldset className="page-plate__fieldset">
						<legend className="page-plate__fieldset-legend">
							{values.monitorIds?.length ? (
								<>
									<FormattedMessage
										id="Selected Monitors"
										defaultMessage="Выбрано устройств ({count})"
										values={{
											count: values.monitorIds.length,
										}}
									/>
									:
									<Icon
										className={cx(
											'page-plate__button-icon',
											icons.DeleteBinGray,
										)}
										onClick={() => setField('monitorIds', undefined)}
										title={intl.formatMessage({
											id: 'app.button.reset',
											defaultMessage: 'Очистить',
										})}
									/>
								</>
							) : (
								<>
									<FormattedMessage
										id="Select Monitors"
										defaultMessage="Выбрать устройства"
									/>
									:
								</>
							)}
						</legend>
						<div className="list page-plate__list">{monitorList}</div>
					</fieldset>
					<fieldset className="page-plate__fieldset">
						{values.monitorIds?.length && (
							<p className="statistics__info">
								{monitorsStore.list
									.filter((m) => values.monitorIds?.includes(m.id))
									.map((m) => m.name)
									.join(', ')}
							</p>
						)}
						{[reportByViews, reportByStatus].map((submit, idx) => {
							const messageId = idx === 0 ? 'ReportByViews' : 'ReportByStatus';

							return (
								<div key={messageId} className="page-plate__row">
									<div className="page-plate__row-item">
										<span>
											{idx === 0 ? (
												<FormattedMessage
													id={messageId}
													defaultMessage="Отчет по показам"
												/>
											) : (
												<FormattedMessage
													id={messageId}
													defaultMessage="Отчет по статусу устройств"
												/>
											)}
										</span>
									</div>
									<Button
										disabled={!canSubmit}
										type="button"
										className="page-plate__button"
										secondary
										rounded
										onClick={() => submit(SpecificFormat.Pdf)}
									>
										<FormattedMessage
											id="FileExt"
											defaultMessage=".{ext}"
											values={{
												ext: SpecificFormat.Pdf,
											}}
										/>
									</Button>
									<Button
										disabled={!canSubmit}
										type="button"
										className="page-plate__button"
										secondary
										rounded
										onClick={() => submit(SpecificFormat.Xlsx)}
									>
										<FormattedMessage
											id="FileExt"
											defaultMessage=".{ext}"
											values={{
												ext: SpecificFormat.Xlsx,
											}}
										/>
									</Button>
								</div>
							);
						})}
					</fieldset>
				</Form>
			</PagePlate>
		</div>
	);
});
