import { ITableColumn } from 'components/table';
import { UserRole } from 'utils/api/api';

export const constants = {
	columns: [
		{ accessor: 'seqNo', label: '№', withSort: true },
		'status',
		{ accessor: 'createdAt', withSort: true },
		'period',
		{ accessor: 'monitor', withSort: true },
		{ accessor: 'sum', withSort: true },
		'playlist',
		{
			accessor: 'playlistChange',
			restrictedFrom: [UserRole.Advertiser],
			messageId: 'BidQueue',
			withSort: true,
		},
		'address',
		{ accessor: 'actions', restrictedFrom: [UserRole.Advertiser] },
	] as ITableColumn[],
};
