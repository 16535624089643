import equal from 'deep-equal';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import { MONITOR_TABLE_COLUMNS } from './constants';
import { monitorsStore } from 'stores/monitors/monitor-list';

import { formatCurrency, throttle } from 'utils';
import { MonitorResponse, MonitorStatus, UserRole } from 'utils/api/api';
import { formatAddress } from 'utils/formatters/format-address';
import { ITableProps, Table, TableCheckbox } from 'components/table';
import {
	Actions,
	CategoryOption,
	Coordinate,
	FavoriteIcon,
	NameWithIcon,
	StatusIcon,
} from './list-components';
import { IFilterState, IMonitorsMapProps, MonitorsMap } from './monitors-map';
import { appStore } from 'stores/app';
import { authStore } from 'stores/auth';
import { MonitorsPrecalcForm } from './monitors-precalc-form';

import './styles.scss';

export interface IMonitorsTableFilterData {
	dateWhenApp?: string[];
}

const MonitorsList: FC = () => {
	const intl = useIntl();

	const filterState = useState<IFilterState>({
		isExpand: authStore.userRole === UserRole.Advertiser,
		filterStep: null,
	});
	const [{ isExpand, filterStep }, setFilterState] = filterState;
	const [monitorIds, setMonitorIds] = useState<MonitorResponse['id'][]>([]);
	const [mapMonitorIds, setMapMonitorIds] =
		useState<Parameters<IMonitorsMapProps['onFilter']>[0]>(null);
	const [selectedMonitorId, setSelectedMonitorId] = useState('');

	const handleMapFilter = useCallback<IMonitorsMapProps['onFilter']>(
		(monitorIds) =>
			setMapMonitorIds((state) =>
				equal(state, monitorIds, { strict: true }) ? state : monitorIds,
			),
		[setMapMonitorIds],
	);

	const handleMonitorSelect = (id: string) => {
		setSelectedMonitorId(id);
	};

	const monitorList = useMemo(
		() =>
			mapMonitorIds
				? monitorsStore.listNoSubordinate.filter((m) =>
						mapMonitorIds.includes(m.id),
					)
				: monitorsStore.listNoSubordinate,
		[monitorsStore.listNoSubordinate, mapMonitorIds],
	);

	const selectMonitor = useCallback(
		(checked: boolean, mId: string) => {
			setMonitorIds((state) => {
				const newState = state.filter((id) => mId !== id);
				if (checked) {
					return newState.concat(mId);
				}
				return newState;
			});
		},
		[setMonitorIds],
	);

	const monitorTableData = useMemo(
		() =>
			monitorList.map((m) => ({
				...m,
				checkbox: (
					<TableCheckbox
						colorModifier={m.status === MonitorStatus.Online ? 'green' : 'dark'}
						itemId={m.id}
						itemIds={monitorIds}
						onChange={selectMonitor}
					/>
				),
				favorite: <FavoriteIcon monitor={m} />,
				price1s: m.price1s
					? formatCurrency(m.price1s)
					: intl.formatMessage({ id: 'PriceFree' }),
				minWarranty: m.minWarranty || intl.formatMessage({ id: 'NoLimit' }),
				maxDuration: m.maxDuration || intl.formatMessage({ id: 'NoLimit' }),
				name: (
					<NameWithIcon
						name={m.name}
						enabled={m.status === MonitorStatus.Online}
						type={m.multiple}
					/>
				),
				category: <CategoryOption id={m.id} category={m.category} />,
				address: formatAddress(m.address),
				coordinate: <Coordinate id={m.id} location={m.location} />,
				status: (
					<StatusIcon id={m.id} enabled={m.status === MonitorStatus.Online} />
				),
				actions: <Actions id={m.id} multiple={m.multiple} />,
			})),
		[monitorList, mapMonitorIds, monitorIds, intl],
	);

	useEffect(() => {
		void monitorsStore.getList();
	}, [monitorsStore.order]);

	const renderRow: ITableProps['renderRow'] = (props) => {
		const { columns, row, renderColumn } = props;

		return (
			<tr
				key={`table_row_${row.id}`}
				className={cx({ selected: row.id === selectedMonitorId })}
			>
				{columns.map((column) => {
					return renderColumn({
						columns,
						row,
						column,
					});
				})}
			</tr>
		);
	};

	useEffect(() => {
		let scrollY = window.scrollY;
		const scroll = throttle(() => {
			if (window.scrollY) {
				if (filterStep) {
					window.scrollTo(window.scrollX, 0);
				} else {
					if (isExpand && scrollY <= 0) {
						setFilterState({
							filterStep: null,
							isExpand: false,
						});
					}
				}
			}
			scrollY = window.scrollY;
		}, 80);
		window.addEventListener('scroll', scroll);

		return () => {
			window.removeEventListener('scroll', scroll);
		};
	}, [isExpand, filterStep]);

	return (
		<div
			className={cx('monitor', {
				'map-expand': filterState[0].isExpand,
			})}
		>
			<MonitorsMap
				onMonitorSelect={handleMonitorSelect}
				onFilter={handleMapFilter}
				filterState={filterState}
			/>
			{appStore.isCreatedFor('promo') && (
				<MonitorsPrecalcForm monitorIds={monitorIds} />
			)}
			<Table
				className="monitor__table scroll"
				columns={
					appStore.isCreatedFor('promo')
						? [{ accessor: 'checkbox', label: ' ' }, ...MONITOR_TABLE_COLUMNS]
						: MONITOR_TABLE_COLUMNS
				}
				data={monitorTableData}
				onSortClick={monitorsStore.setOrder}
				order={monitorsStore.order}
				renderRow={renderRow}
			/>
		</div>
	);
};

export default observer(MonitorsList);
