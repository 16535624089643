import { IAppRouteProps, TRouteKey } from './types';

export const DEFAULT_ROUTE: Partial<IAppRouteProps> = {
	isPublic: false,
};

export const PUBLIC_ROUTE_KEYS: TRouteKey[] = [
	'Login',
	'Register',
	'Logout',
	'ResetPassword',
	'UpdateResetPassword',
	'SendRegisterEmail',
	'VerifyRegisterEmail',
	'SendResetPassword',
	'Privacy',
];

export const BASE_ROUTE_KEYS: TRouteKey[] = ['NotFound'];
