import cx from 'classnames';
import { FC, ReactElement, SelectHTMLAttributes, useState } from 'react';

import { InputField } from './field';

import { random } from 'utils';

import './styles.scss';
import { checkInputError } from '../../hocs';

export interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	fieldClass?: string;
	fieldElement?: ReactElement;
}

export const Select: FC<ISelectProps> = checkInputError<ISelectProps>(
	({
		isError,
		id,
		className,
		children,
		fieldClass,
		fieldElement,
		...props
	}) => {
		const [inputId] = useState(() => id || random());

		return (
			<InputField className={fieldClass}>
				{fieldElement}
				<select
					onChange={() => null}
					{...props}
					id={inputId}
					className={cx(
						'form__input',
						'form__input__select',
						{
							error: isError,
						},
						className,
					)}
				>
					{children}
				</select>
			</InputField>
		);
	},
);
