import cx from 'classnames';
import { Button, Layout } from 'components/common';
import Container from 'components/container';
import { observer } from 'mobx-react-lite';
import { FC, HTMLAttributes, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import { RouteLink } from '../../modules/routing-module';
import { TRouteKey } from '../../modules/routing-module/routes';
import { useParams } from 'react-router-dom';

export interface IAddEditWrapperProps extends HTMLAttributes<HTMLDivElement> {
	icon: string | null;
	cancelRouteKey: TRouteKey;
	title?: string;
	action?: ReactElement;
	className?: string;
	disabled?: boolean;

	submit?(): void;
}

const AddEditWrapper: FC<IAddEditWrapperProps> = ({
	icon,
	title,
	action,
	children,
	className,
	submit,
	cancelRouteKey,
	disabled,
}) => {
	const { id } = useParams<{ id?: string }>();

	return (
		<Container className={cx('add__edit__wrapper', className)}>
			<div className="add__edit__inner-wrapper">
				<Layout flex row className="add__edit__header">
					{icon && <img className="add__edit__icon" src={icon} alt="Icon" />}
					{title && !action && (
						<h2 className="add__edit__title">
							<FormattedMessage id={title} defaultMessage={title} />
						</h2>
					)}
					{!title && action}
				</Layout>
				{children}
			</div>
			<Layout flex row className="add__edit__form__actions">
				<RouteLink
					routeKey={cancelRouteKey}
					className="button button-secondary"
				>
					<FormattedMessage id="app.button.back" defaultMessage="Назад" />
				</RouteLink>
				<Button type="submit" primary onClick={submit} disabled={disabled}>
					<FormattedMessage
						id={id ? 'app.button.update' : 'app.button.confirm'}
						defaultMessage={id ? 'Обновить' : 'Сохранить'}
					/>
				</Button>
			</Layout>
		</Container>
	);
};

export default observer(AddEditWrapper);
