import { FC, HTMLAttributes, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import OutsideClickHandler, {
	Props as OutsideClickHandlerProps,
} from 'react-outside-click-handler';

import './styles.scss';

export interface IPopupFullscreenProps
	extends HTMLAttributes<HTMLDivElement>,
		Pick<OutsideClickHandlerProps, 'onOutsideClick'> {
	contentNode?: Element | null;
	withCloseButton?: boolean;
}

export const PopupFullscreen: FC<IPopupFullscreenProps> = ({
	children,
	contentNode,
	onOutsideClick,
	className,
	withCloseButton = true,
}) => {
	if (!contentNode) {
		contentNode = document.querySelector('.main__content') || document.body;
	}

	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			switch (e.key) {
				case 'Escape': {
					if (onOutsideClick) {
						onOutsideClick(e as unknown as MouseEvent);
					}
				}
			}
		};
		window.addEventListener('keydown', listener);

		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, [onOutsideClick]);

	return (
		contentNode &&
		ReactDOM.createPortal(
			<div className={cx('popup-fullscreen__shadow', className)}>
				<div className="popup-fullscreen__container">
					<OutsideClickHandler onOutsideClick={onOutsideClick}>
						{children}
					</OutsideClickHandler>
				</div>
				{withCloseButton && (
					<button aria-label="X" className="popup-fullscreen__close-button" />
				)}
			</div>,
			contentNode,
		)
	);
};
