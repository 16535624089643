import { ComponentProps, FC, ReactNode } from 'react';
import { Input } from './input';
import './styles.scss';

export const Radio: FC<
	Omit<ComponentProps<typeof Input>, 'type' | 'label' | 'placeholder'> & {
		label: ReactNode;
	}
> = ({ label, ...props }) => (
	<Input {...props} type="radio" placeholder={label} />
);
