import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/common';

import { Icon } from '../icon';
import { icons } from 'assets/icons';
import cx from 'classnames';
import './styles.scss';

export interface ILinksPlaylistMonitorProps
	extends HTMLAttributes<HTMLButtonElement> {
	disabled: boolean;
}

const LinkPlaylistMonitor: FC<ILinksPlaylistMonitorProps> = ({
	disabled,
	...restProps
}) => {
	return (
		<div className="link-monitor-playlist-button">
			<Button
				type="submit"
				primary
				className="playlists__link-button"
				disabled={disabled}
				{...restProps}
			>
				<Icon className={cx(icons.SearchMonitor, 'playlists__search-icon')} />
				<span className="text">
					<FormattedMessage
						id="Send selected to device"
						defaultMessage="Send selected to device"
					/>
				</span>
			</Button>
		</div>
	);
};

export default LinkPlaylistMonitor;
