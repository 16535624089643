import { FC, HTMLAttributes, useCallback, useMemo, useRef } from 'react';
import cx from 'classnames';
import { simulateChange } from 'utils/helpers/simulate-change';

import './styles.scss';
import { Calendar, ICalendarProps } from '../calendar';

export interface IDateInputProps extends HTMLAttributes<HTMLDivElement> {
	name: string;
	value: ICalendarProps['value'][] | ICalendarProps['value'];
	min?: Date;
	max?: Date;
}

export const DateInput: FC<IDateInputProps> = ({
	className,
	name,
	value,
	min,
	max,
	...restProps
}) => {
	const ref = useRef<HTMLInputElement | null>(null);

	const isRange = useMemo(() => Array.isArray(value), [value]);
	const [vMin, vMax] = useMemo(() => {
		if (Array.isArray(value)) {
			return value;
		}

		if (value instanceof Date) {
			if (min && min >= value) {
				return [null, min];
			} else if (max && max > value) {
				return [null, value];
			}
		}
		return [null, max];
	}, [value, min, max]);

	const handleChange = useCallback(
		(value: ICalendarProps['value'][] | ICalendarProps['value']) => {
			if (isRange) {
				if (!Array.isArray(value)) {
					throw new TypeError(`Invalid DateInput value type: ${value}`);
				}
				simulateChange(
					ref,
					value.map((v) => (v ? v.toISOString() : '')),
				);
			} else if (value === null) {
				simulateChange(ref, undefined);
			} else {
				simulateChange(ref, value);
			}
		},
		[value, isRange],
	);

	return (
		<div {...restProps} className={cx('date-input', className)}>
			<input type="hidden" name={name} ref={ref} />
			<div className="date-input__date-layout">
				{vMin !== null && (
					<Calendar
						min={min}
						max={max}
						value={null}
						rangeValue={[vMin, vMax]}
						onRangeChange={handleChange}
					/>
				)}
				<Calendar
					min={min}
					max={max}
					value={isRange ? null : vMax}
					rangeValue={isRange ? [vMin, vMax] : undefined}
					onChange={isRange ? undefined : handleChange}
					onRangeChange={isRange ? handleChange : undefined}
				/>
			</div>
		</div>
	);
};
