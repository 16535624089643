import ChartistGraph from 'react-chartist';
import { FC, ReactNode, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';

import { authStore } from 'stores/auth';
import { Checkbox } from 'components/forms';

import './styles.scss';
import { PagePlate } from 'components/page-plate/page-plate';
import { icons } from 'assets/icons';
import { IUser } from '_types/stores';
import { UserRoleMessageId } from '_types/constants';
import { formatBytes, formatUserName } from 'utils';
import { upFirstLetter } from 'utils/formatters';
import { UserMetrics, UserRole } from 'utils/api/api';
import { RouteLink } from '../../modules/routing-module';
import { metricsStore } from '../../stores/metrics';
import cx from 'classnames';

const UserInfoField: FC<{ name: keyof IUser | string }> = observer(
	({ name }) => {
		const intl = useIntl();

		const { messageId, value } = useMemo(() => {
			let messageId = `app.field.${name}`,
				value = (authStore.user &&
					authStore.user[name as keyof IUser]) as ReactNode;

			switch (name) {
				case 'lastEntry':
					{
						messageId = 'Last entry';
						value = intl.formatDate(
							authStore.user?.lastEntry?.at || authStore.user?.updatedAt,
							{
								dateStyle: 'medium',
								timeStyle: 'full',
							},
						);
					}
					break;

				case 'role':
					{
						messageId = 'Role';
						const valueMessageId = UserRoleMessageId[value as UserRole];
						if (valueMessageId) {
							value = <FormattedMessage id={valueMessageId} />;
						}
					}
					break;

				case 'fullName': {
					messageId = 'Name';
					value = formatUserName(authStore.user, 'FULL', intl);
				}
			}

			return {
				messageId,
				value,
			};
		}, [authStore.user, name, intl]);

		return (
			<>
				<div className="current-user__info-field">
					<FormattedMessage id={messageId} defaultMessage={name} />
				</div>
				<div className="current-user__info-value">{value}</div>
			</>
		);
	},
);

const UserInfoPlate: FC = () => {
	return (
		<PagePlate
			iconClassName={icons.ProfileGray}
			name={
				<h2>
					<FormattedMessage
						id="About the user"
						defaultMessage="О пользователе"
					/>
				</h2>
			}
		>
			<div className="current-user__info">
				{(['email', 'fullName', 'role', 'lastEntry'] as Array<keyof IUser>).map(
					(key) => (
						<UserInfoField key={key} name={key} />
					),
				)}
			</div>
		</PagePlate>
	);
};

const diskOptionsList = ['isUsed', 'isNotUsed', 'busy', 'free'] as const;

const CurrentUser: FC = () => {
	const [diskOptionsOpened, setDiskOptionsOpened] = useState(false);
	const [diskOptions, setDiskOptions] = useState(() =>
		Object.fromEntries(diskOptionsList.map((k) => [k, true])),
	);

	const renderDiskOptionsMenu = () => {
		if (!diskOptionsOpened) return null;

		return (
			<div className="current-user__disk-options-list">
				{diskOptionsList.map((option) => {
					const handleChange = () => {
						setDiskOptions((state) => ({
							...state,
							[option]: !state[option],
						}));
					};

					return (
						<div className="current-user__disk-option" key={option}>
							<Checkbox
								label=""
								name="disk-options"
								colorModifier="gray"
								className="current-user__disk-option-checkbox"
								checked={diskOptions[option]}
								onChange={handleChange}
							/>
							<span>
								<FormattedMessage
									id={option}
									defaultMessage={upFirstLetter(option)}
								/>
							</span>
						</div>
					);
				})}
			</div>
		);
	};

	const renderDiskOptions = () => {
		return (
			metricsStore.userMetrics &&
			diskOptionsList.map((option) => {
				const { storage, total } = metricsStore.userMetrics!.storageSpace;
				let value;
				switch (option) {
					case 'busy':
					case 'isUsed':
						{
							value = storage;
						}
						break;

					case 'isNotUsed':
					case 'free':
						{
							value = total - storage;
						}
						break;
				}

				return (
					<div
						className={`current-user__space-field current-user__space-field--${option}`}
						key={option}
					>
						<div
							className={`current-user__space-label current-user__space-label--${option}`}
						/>
						<FormattedMessage
							id={option}
							defaultMessage={upFirstLetter(option)}
						/>{' '}
						-
						<span
							className={`current-user__space-value current-user__space-value--${option}`}
						>
							{formatBytes(value)}
						</span>
					</div>
				);
			})
		);
	};

	const renderPieChart = () => {
		if (!metricsStore.userMetrics) {
			return <FormattedMessage id="Loading" defaultMessage="Загружаем..." />;
		}
		const { storage, total } = metricsStore.userMetrics!.storageSpace;

		const playing = storage / total,
			notPlaying = (total - storage) / total,
			busy = storage / total - playing - notPlaying,
			free = 1 - playing - notPlaying - busy;

		const series = [
			{
				value: free,
				className: 'current-user__pie--free',
			},
		];

		if (diskOptions.isUsed) {
			series.push({
				value: playing,
				className: 'current-user__pie--playing',
			});
		}

		if (diskOptions.isNotUsed) {
			series.push({
				value: notPlaying,
				className: 'current-user__pie--not-playing',
			});
		}

		if (diskOptions.busy) {
			series.push({
				value: busy,
				className: 'current-user__pie--used',
			});
		}

		return (
			<ChartistGraph
				data={{
					series,
				}}
				options={
					{
						width: 252,
						height: 252,
						donut: true,
						donutWidth: 24,
						showLabel: false,
						total: 1,
					} as Chartist.IPieChartOptions
				}
				type="Pie"
			/>
		);
	};

	return (
		<div className="profile" id="metrics">
			<PagePlate
				className="counters"
				iconClassName={icons.Devices}
				name={
					<h2>
						<FormattedMessage id="Devices" defaultMessage="Devices" />
					</h2>
				}
				buttons={
					<div className="current-user__title-info">
						<span>
							<FormattedMessage
								id={
									authStore.userRole === UserRole.Advertiser
										? 'Devices purchased'
										: 'Registered devices'
								}
							/>
						</span>
						<div className="current-user__title-info-value">
							<span>{metricsStore.userMetrics?.monitors.user}</span>
						</div>
					</div>
				}
			>
				<div className="current-user__section--devices">
					<div className="current-user__devices">
						{metricsStore.userMetrics ? (
							(
								['online', 'offline', 'empty'] as Array<
									keyof UserMetrics['monitors']
								>
							).map((key) => {
								let className = null,
									label: ReactNode = null;

								switch (key) {
									case 'online':
										{
											className = 'current-user__devices-value--online';
											label = (
												<FormattedMessage id="Online" defaultMessage="Online" />
											);
										}
										break;

									case 'offline':
										{
											className = 'current-user__devices-value--offline';
											label = (
												<FormattedMessage
													id="Offline"
													defaultMessage="Offline"
												/>
											);
										}
										break;
									case 'empty': {
										className = 'current-user__devices-value--drafts';
										label = (
											<FormattedMessage
												id="Without bids"
												defaultMessage="Без заявок"
											/>
										);
									}
								}

								return (
									<div key={key} className="current-user__devices-option">
										<div className="current-user__devices-label">{label}</div>
										<div className="current-user__devices-line" />
										<div
											className={cx('current-user__devices-value', className)}
										>
											{metricsStore.userMetrics?.monitors[key]}
										</div>
									</div>
								);
							})
						) : (
							<div>
								<FormattedMessage id="Loading" defaultMessage="Загружаем..." />
							</div>
						)}
					</div>
					<RouteLink
						routeKey="MonitorsAdd"
						className="button button-primary current-user__add-create-button"
					>
						<FormattedMessage id="Add" defaultMessage="Add" />
					</RouteLink>
				</div>
			</PagePlate>
			<UserInfoPlate />
			<PagePlate
				iconClassName={icons.PlaylistsGray}
				name={
					<h2>
						<FormattedMessage id="Playlists" defaultMessage="Playlists" />
					</h2>
				}
				buttons={
					<div className="current-user__title-info">
						<span>
							<FormattedMessage
								id={
									authStore.userRole === UserRole.Advertiser
										? 'Created playlists'
										: 'Created/Paid playlists'
								}
							/>
						</span>
						<div className="current-user__title-info-value">
							<span>{metricsStore.userMetrics?.playlists.added}</span>
						</div>
					</div>
				}
			>
				<div className="current-user__playlists">
					{metricsStore.userMetrics ? (
						['played', 'noPlayed'].map((key) => {
							let label = null,
								className = null,
								value = null;

							switch (key) {
								case 'played':
									{
										value = metricsStore.userMetrics?.playlists.played;
										className = 'current-user__playlists-value--on';
										label = (
											<FormattedMessage
												id="On the broadcast"
												defaultMessage="On the broadcast"
											/>
										);
									}
									break;

								case 'noPlayed':
									{
										const { played, added } =
											metricsStore.userMetrics?.playlists || {};
										value = added && played ? added - played : 0;
										className = 'current-user__playlists-value--off';
										label = (
											<FormattedMessage
												id="No broadcast"
												defaultMessage="No broadcast"
											/>
										);
									}
									break;
							}

							return (
								<div key={key} className="current-user__playlists-option">
									<div className="current-user__playlists-label">{label}</div>
									<div className="current-user__playlists-line" />
									<div
										className={cx('current-user__playlists-value', className)}
									>
										{value}
									</div>
								</div>
							);
						})
					) : (
						<div>
							<FormattedMessage id="Loading" defaultMessage="Загружаем..." />
						</div>
					)}
				</div>
				<RouteLink
					routeKey="PlaylistsAdd"
					className="button button-primary current-user__add-create-button"
				>
					<FormattedMessage id="Create" defaultMessage="Создать" />
				</RouteLink>
			</PagePlate>
			<PagePlate
				className="current-user__section current-user__section--disk"
				name={
					<h2>
						<FormattedMessage id="Disk space" defaultMessage="Disk space" />
					</h2>
				}
				buttons={
					<div className="current-user__section-top">
						<OutsideClickHandler
							onOutsideClick={() => setDiskOptionsOpened(false)}
						>
							<div className="current-user__disk-options">
								<button
									className={
										diskOptionsOpened
											? 'current-user__disk-options-button current-user__disk-options-button--opened'
											: 'current-user__disk-options-button'
									}
									onClick={() => setDiskOptionsOpened(!diskOptionsOpened)}
								>
									<FormattedMessage
										id="Display info"
										defaultMessage="Display info"
									/>
								</button>
								{renderDiskOptionsMenu()}
							</div>
						</OutsideClickHandler>
					</div>
				}
			>
				<div className="current-user__disk">
					<div className="current-user__pie">
						{renderPieChart()}
						<div className="current-user__free-space">
							<span>
								<FormattedMessage id="Free" defaultMessage="Free" />
							</span>
							<div className="current-user__free-space-value">
								{metricsStore.userMetrics &&
									formatBytes(
										metricsStore.userMetrics.storageSpace.total -
											metricsStore.userMetrics.storageSpace.storage,
									)}
							</div>
						</div>
					</div>
					<div>
						<div className="current-user__space-field current-user__space-field--total">
							<div className="current-user__space-label current-user__space-label--total" />
							<FormattedMessage id="Total" defaultMessage="Total" /> -
							<span className="current-user__space-value current-user__space-value--total">
								{formatBytes(metricsStore.userMetrics?.storageSpace.total)}
							</span>
						</div>
						{renderDiskOptions()}
					</div>
				</div>
			</PagePlate>
		</div>
	);
};

export default observer(CurrentUser);
