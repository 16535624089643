import { FC, useEffect } from 'react';

import { authStore } from 'stores/auth';

import './styles.scss';

export const Logout: FC = () => {
	useEffect(() => {
		authStore.logout();
	}, []);

	return null;
};
