import { FC, useCallback } from 'react';

import { useFileLink } from 'hooks/use-file-link';

import { Layout } from 'components/common';

import { IEditFilesListProps } from './add-edit-files';

import IconMiniDelete from 'assets/icons/mini-delete.svg';
import './styles.scss';
import { FileResponse } from '../../utils/api/api';

const DocumentItem: FC<{
	file: FileResponse;
	onDelete: IEditFilesListProps['onDelete'];
}> = ({ file, onDelete }) => {
	const fileLink = useFileLink(file.id, file.name);

	const handleDelete = useCallback(
		() => onDelete(file.id),
		[file.id, onDelete],
	);

	return (
		<Layout flex row className="add__edit__documents__item">
			<span className="add__edit__documents__item-ext">{file.extension}</span>
			<a
				className="add__edit__documents__item-link"
				href={fileLink}
				target="_blank"
			>
				{file.name}
			</a>
			<button
				type="button"
				className="add__edit__documents__item-remove"
				onClick={handleDelete}
			>
				<img src={IconMiniDelete} alt="Remove icon" />
			</button>
		</Layout>
	);
};

export const DocumentsList: FC<IEditFilesListProps> = ({ files, onDelete }) => (
	<Layout flex column className="add__edit__documents__wrapper">
		{files.map((f) => (
			<DocumentItem key={f.id} file={f} onDelete={onDelete} />
		))}
	</Layout>
);
