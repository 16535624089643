import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import routes from 'modules/routing-module/routes';
import { monitorsStore } from 'stores/monitors/monitor-list';
import { Confirmation } from 'components/common';

const MonitorDelete: FC = () => {
	const intl = useIntl();

	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const monitor = monitorsStore.list.find((m) => m.id === id);

	const message = intl.formatMessage(
		{
			id: 'Deleting Confirm',
		},
		{ it: monitor ? monitor.name : id },
	);

	const handleConfirm = useCallback(() => {
		if (id) {
			void monitorsStore.deleteMonitor(id);

			navigate(routes.MonitorsList.path, { replace: true });
		}
	}, [id, navigate, routes.MonitorsList.path]);

	const handleCancel = useCallback(() => {
		navigate(routes.MonitorsList.path, { replace: true });
	}, [navigate, routes.MonitorsList.path]);

	return (
		<Confirmation
			message={message}
			onConfirm={handleConfirm}
			onCancel={handleCancel}
		/>
	);
};

export default observer(MonitorDelete);
