import { MutableRefObject } from 'react';
import ReactTestUtils from 'react-dom/test-utils';

export function simulateChange<T extends HTMLInputElement>(
	inputRef: MutableRefObject<T | null>,
	value: unknown,
) {
	const { current: input } = inputRef;

	if (input) {
		if (value === null || value === undefined) {
			input.value = '';
		} else {
			input.value = typeof value === 'string' ? value : JSON.stringify(value);
		}

		ReactTestUtils.Simulate.change(input);
	}
}
