import { IUser } from '_types/stores';
import { appStore } from 'stores/app';

export function formatUserName(
	user: IUser | null | undefined,
	format: 'SHORT' | 'MEDIUM' | 'FULL',
	intl = appStore.intl,
) {
	if (user) {
		switch (format) {
			case 'SHORT': {
				if (user.surname) {
					return `${user?.name} ${user.surname[0]}.`;
				}
				return user.name;
			}

			case 'MEDIUM': {
				let result = user.name;
				if (user.surname) {
					result = `${user.surname} ${result}`;
				}
				if (user.middleName) {
					result = `${result} ${user.middleName[0]}.`;
				}
				return result;
			}

			case 'FULL': {
				return [user.surname, user.name, user.middleName]
					.filter((item) => item)
					.join(' ');
			}
		}
	}
	return intl.formatMessage({
		id: 'Unknown User',
		defaultMessage: 'Неизвестный пользователь',
	});
}
