import { Button, Layout } from 'components/common';
import { Table, TableCheckbox } from 'components/table';
import {
	DEFAULT_MONITOR_CATEGORY,
	DEFAULT_MONITOR_MULTIPLE,
	MONITOR_GROUP_TYPES,
	MONITOR_TABLE_COLUMNS,
	WALL_SCALING_SCHEMAS,
} from './constants';
import { monitorsStore } from 'stores/monitors/monitor-list';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input } from 'components/forms';
import {
	MonitorCategory,
	MonitorCreateRequest,
	MonitorGroup,
	MonitorMultiple,
	MonitorStatus,
} from 'utils/api/api';
import * as MonitorListComponents from '../monitors/list-components';
import { formatAddress } from 'utils/formatters/format-address';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import {
	formatCurrency,
	handleError,
	IObjectWithOrder,
	orderObjects,
	toast,
} from 'utils';
import { IMonitorItem } from '_types/stores';
import deepEqual from 'deep-equal';

import './styles.scss';
import { PriceFields } from './add-edit-fields';
import { useForm, useSlider } from 'hooks';
import { orderWall, Wall } from './monitors-wall';
import { useParams } from 'react-router-dom';
import { RequestError } from '../../utils/api/check-request-data';
import { icons } from '../../assets/icons';
import { Icon } from '../../components/icon';
import { errorStore } from '../../stores/error-store';

export interface IMonitorGroupItem extends MonitorGroup, IObjectWithOrder {}

export interface IMonitorGroupValues
	extends Omit<MonitorCreateRequest, 'groupIds'> {
	groupIds: IMonitorGroupItem[];
}

export interface IMonitorGroupItemProps
	extends Pick<IMonitorGroupItem, 'order'> {
	monitor: IMonitorItem;
	blocked: boolean;

	onRemove(): void;
}

const MonitorGroupItem: FC<IMonitorGroupItemProps> = ({
	monitor,
	order,
	blocked,
	onRemove,
}) => {
	const [canRemove, setCanRemove] = useState(false);

	return (
		<tr
			key={monitor.id}
			className={cx({ blocked })}
			onMouseOver={() => setCanRemove(true)}
			onMouseOut={() => setCanRemove(false)}
		>
			<td>
				{canRemove ? (
					<Icon className={icons.DeleteBinGray} onClick={onRemove} />
				) : (
					<b>{order}</b>
				)}
				<MonitorListComponents.NameWithIcon
					name={monitor.name}
					enabled={monitor.status === MonitorStatus.Online}
					type={monitor.multiple}
				/>
			</td>
			<td>
				<MonitorListComponents.CategoryOption
					id={monitor.id}
					category={monitor.category}
				/>
			</td>
			<td>{formatAddress(monitor.address)}</td>
		</tr>
	);
};

function MonitorsGroupComponent() {
	const intl = useIntl();
	const params = useParams<{ id?: string }>();

	const [monitorIdFetching, setMonitorIdFetching] = useState<
		IMonitorItem['id'] | null | undefined
	>(null);
	const wallSlider = useSlider<[number, number]>(WALL_SCALING_SCHEMAS);
	const [
		{ currentSlide: wallSchema, currentIdx: wallIdx, slides: wallSlides },
		{ setSlide: setWallSchema },
	] = wallSlider;

	const calcMaxWallLength = useCallback(
		(multiple?: MonitorMultiple) => {
			if (multiple === MonitorMultiple.SCALING) {
				return wallSchema ? wallSchema[0] * wallSchema[1] : 0;
			}
			return Infinity;
		},
		[wallSchema],
	);

	const [{ values, formProps }, { setFields, setValues, submit, reset }] =
		useForm<IMonitorGroupValues>(
			{
				category: DEFAULT_MONITOR_CATEGORY,
				multiple: DEFAULT_MONITOR_MULTIPLE,
				groupIds: [],
				name: '',
				sound: false,
			},
			{
				parsers: {
					groupIds: (value) => {
						try {
							return {
								groupIds: JSON.parse(value as string),
							};
						} catch (e) {
							toast.error(handleError(e));
						}
					},
				},
				onSubmit: async (values) => {
					try {
						const { name, groupIds } = values;

						const maxWallLength = calcMaxWallLength(values.multiple);

						if (
							values.multiple === MonitorMultiple.SCALING &&
							groupIds.length !== maxWallLength
						) {
							return toast.error(
								`Число выбранных мониторов ${groupIds.length} не совпадает с доступными ячейками на стене ${maxWallLength}`,
							);
						}

						const requestData = values.groupIds.reduce<MonitorCreateRequest>(
							(data, item, idx) => {
								const m = monitorsStore.list.find(
									(m) => item.monitorId === m.id,
								) as IMonitorItem;

								const expected: MonitorCreateRequest = {
									...data,
									name,
									category: m.category,
								};

								if (idx > 0 && !deepEqual(data, expected, { strict: true })) {
									throw new Error('Категория мониторов не совпадает');
								}

								(
									['address', 'location'] as Array<
										keyof Pick<IMonitorItem, 'address' | 'location'>
									>
								).forEach((k) => {
									if (!expected[k]) {
										const value = m[k];
										if (value) {
											expected[k] = value as never;
										}
									}
								});

								return expected;
							},
							{
								...values,
								name,
								category: MonitorCategory.GAS_STATION,
								sound: false,
								groupIds: groupIds
									.filter(({ order }) => maxWallLength >= order)
									.map(({ order, ...m }) => m),
							},
						);

						if (params.id) {
							await monitorsStore.updateMonitor(params.id, requestData);
						} else {
							await monitorsStore.createMonitor(requestData);
						}
						errorStore.reset();
					} catch (error) {
						if (error instanceof RequestError) {
							error.notify();
							errorStore.setErrors(error.fieldNames);
						}
						return;
					}
				},
				onReset: (values, initial) => {
					return {
						...initial,
						multiple: values.multiple,
					};
				},
			},
		);

	const currentSchema = useCallback(
		(itemsLength = values.groupIds.length) => {
			const schemaIdx = wallSlides.findIndex(
				([colCnt, rowCnt]) => colCnt * rowCnt >= itemsLength,
			);
			const schema = wallSlides[schemaIdx];

			if (!schema) {
				throw new Error('Непредвиденная ошибка при выборе видеостены.');
			}

			if (wallIdx !== schemaIdx) {
				setWallSchema(schemaIdx);
			}

			return schema;
		},
		[wallIdx, wallSlides, values.groupIds.length],
	);

	const maxWallLength = useMemo(
		() => calcMaxWallLength(values.multiple),
		[calcMaxWallLength, values.multiple],
	);

	const openTab = useCallback(
		(multiple: MonitorMultiple) => {
			setValues((state) => {
				const newState = {
					...state,
					multiple,
				};

				if (multiple === MonitorMultiple.SCALING) {
					try {
						newState.groupIds = orderWall(
							state.groupIds,
							...currentSchema(state.groupIds.length),
							{ order: true },
						);
					} catch (e) {
						toast.error(handleError(e));
					}
				}

				return newState;
			});
		},
		[setValues, currentSchema],
	);

	const selectMonitor = useCallback(
		(checked: boolean, mId: IMonitorItem['id']) => {
			setValues((state) => {
				let { groupIds } = state;

				if (checked) {
					if (groupIds.some(({ monitorId }) => monitorId === mId)) {
						return state;
					}

					groupIds = groupIds.concat({
						monitorId: mId,
						row: 0,
						col: 0,
						order: 0,
					});
				} else {
					groupIds = groupIds.filter(({ monitorId }) => monitorId !== mId);
				}

				if (state.multiple === MonitorMultiple.SCALING) {
					if (!wallSchema) {
						toast.error('Непредвиденная ошибка при сохранении шаблона.');
						return state;
					}

					return {
						...state,
						groupIds: orderWall(groupIds, ...wallSchema, { order: true }),
					};
				}

				return {
					...state,
					groupIds: orderObjects(groupIds),
				};
			});
		},
		[wallSchema, setValues],
	);

	const monitorTableData = useMemo(
		() =>
			monitorsStore.list
				.filter(
					(m) =>
						!m.multiple ||
						[MonitorMultiple.SINGLE, MonitorMultiple.SUBORDINATE].includes(
							m.multiple,
						),
				)
				.map((m) => ({
					...m,
					checkbox: (
						<TableCheckbox
							name="monitor__checkbox"
							itemId={m.id}
							itemIds={values.groupIds.map(({ monitorId }) => monitorId)}
							onChange={selectMonitor}
							colorModifier={
								m.status === MonitorStatus.Online ? 'green' : 'dark'
							}
						/>
					),
					favorite: <MonitorListComponents.FavoriteIcon monitor={m} />,
					name: (
						<MonitorListComponents.NameWithIcon
							name={m.name}
							enabled={m.status === MonitorStatus.Online}
							type={m.multiple}
						/>
					),
					category: (
						<MonitorListComponents.CategoryOption
							id={m.id}
							category={m.category}
						/>
					),
					price1s: m.price1s
						? formatCurrency(m.price1s)
						: intl.formatMessage({ id: 'PriceFree' }),
					minWarranty: m.minWarranty || intl.formatMessage({ id: 'NoLimit' }),
					maxDuration: m.maxDuration || intl.formatMessage({ id: 'NoLimit' }),
					status: (
						<MonitorListComponents.StatusIcon
							id={m.id}
							enabled={m.status === MonitorStatus.Online}
						/>
					),
					coordinate: (
						<MonitorListComponents.Coordinate id={m.id} location={m.location} />
					),
					address: formatAddress(m.address),
					actions: (
						<MonitorListComponents.Actions id={m.id} multiple={m.multiple} />
					),
				})),
		[monitorsStore.list, values.groupIds, selectMonitor, intl],
	);

	useEffect(() => {
		void monitorsStore.getList();
	}, [monitorsStore.order]);

	useEffect(() => {
		if (params.id && params.id !== monitorIdFetching) {
			setMonitorIdFetching(params.id);

			monitorsStore
				.loadMonitor(params.id)
				.then((m) => {
					if (m) {
						if (m.groupIds) {
							let groupIds = m.groupIds
								// TEMPORARY: .reduce used to wait server's corrections.
								.reduce((acc, item) => {
									if (
										acc.some(({ monitorId }) => monitorId === item.monitorId)
									) {
										return acc;
									}
									return acc.concat(item);
								}, [] as MonitorGroup[]);

							if (m.multiple === MonitorMultiple.SCALING) {
								groupIds = orderWall(
									groupIds,
									...currentSchema(groupIds.length),
								);
							}

							setFields({
								...m,
								groupIds: orderObjects(groupIds),
							});
						}
					}
				})
				.catch((e) => {
					reset();
					toast.error(handleError(e));
					console.error(e);
				})
				.finally(() => {
					setMonitorIdFetching(null);
				});
		} else if (!monitorIdFetching) {
			reset();
		}
	}, [params.id]);

	return (
		<Layout className="monitor-group">
			<ul className="monitor-group__tab">
				{MONITOR_GROUP_TYPES.map((t) => {
					const isActive = values.multiple === t;
					return (
						<li
							key={t}
							className={cx('monitor-group__tab-item', {
								active: isActive,
							})}
							onClick={() => openTab(t)}
						>
							<div
								className={cx('monitor-group__tab-icon', t.toLowerCase(), {
									active: isActive,
								})}
							/>
							<FormattedMessage id={t} defaultMessage={t} />
						</li>
					);
				})}
			</ul>
			<Table
				className="monitor-group__table scroll"
				columns={[
					{ accessor: 'checkbox', label: ' ' },
					...MONITOR_TABLE_COLUMNS,
				]}
				data={monitorTableData}
				onSortClick={monitorsStore.setOrder}
				order={monitorsStore.order}
			/>
			<Form {...formProps} className="monitor-group__form">
				{values.multiple === MonitorMultiple.SCALING && (
					<fieldset name="wall">
						<legend>
							<FormattedMessage
								id="Choice wall"
								defaultMessage="Выбор шаблона стены"
							/>
							:
						</legend>
						<Wall groupIds={values.groupIds} slider={wallSlider} />
						<p className="monitor-group__notice">
							*
							<FormattedMessage
								id="Move wall item"
								defaultMessage="Перемещайте положение экранов мышкой"
							/>
						</p>
					</fieldset>
				)}
				<fieldset name="checkedMonitors">
					<legend>
						<FormattedMessage
							id="Monitor selected"
							defaultMessage="Выбрано устройств"
							values={{
								count: values.groupIds.length,
							}}
						/>
						:
					</legend>
					<div
						className={cx('monitor-group__checklist', {
							empty: !values.groupIds.length,
						})}
					>
						<table>
							<tbody>
								{values.groupIds
									.sort((a, b) => (a.order > b.order ? 1 : -1))
									.map(({ monitorId, order }) => {
										const monitor = monitorsStore.list.find(
											(m) => m.id === monitorId,
										);

										return (
											monitor && (
												<MonitorGroupItem
													key={monitorId}
													monitor={monitor}
													blocked={order > maxWallLength}
													order={order}
													onRemove={() => selectMonitor(false, monitorId)}
												/>
											)
										);
									})}
							</tbody>
						</table>
					</div>
				</fieldset>
				<fieldset name="conditions">
					<legend>
						<FormattedMessage
							id="Monitor condition"
							defaultMessage="Условия показа"
						/>
						:
					</legend>
					<div className="monitor-group__conditions">
						<PriceFields monitor={values} />
					</div>
				</fieldset>
				<fieldset name="groupName">
					<label htmlFor="name">
						<FormattedMessage
							id="app.field.groupName"
							defaultMessage="Введите имя группы"
						/>
					</label>
					<Input
						name="name"
						id="name"
						value={values.name}
						rawPlaceholder={intl.formatMessage({
							id: 'app.placeholder.name',
							defaultMessage: 'Имя',
						})}
					/>
					{values.groupIds.length ? (
						<div className="flex row">
							<Button type="reset" secondary onClick={reset}>
								<FormattedMessage
									id="app.button.reset"
									defaultMessage="Очистить"
								/>
							</Button>
							<Button type="submit" primary onClick={submit}>
								<FormattedMessage
									id="app.button.confirm"
									defaultMessage="Подтвердить"
								/>
							</Button>
						</div>
					) : null}
				</fieldset>
			</Form>
		</Layout>
	);
}

export const MonitorsGroup = observer(MonitorsGroupComponent);
