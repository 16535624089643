export enum IntlLocale {
	Ru = 'ru-RU',
	En = 'en-US',
}

export const MONTH_IN_YEAR_NUMBER = 12;
export const DAY_IN_WEEK_NUMBER = 7;
export const DECADE_NUMBER = 10;

export interface ArrayWithLocale extends Array<string> {
	locale: IntlLocale;
}

export function normalizeLocale(locale: string) {
	if (locale.toLowerCase().includes('en')) {
		return IntlLocale.En;
	}

	return IntlLocale.Ru;
}

export function monthsForLocale(
	_locale: string,
	monthFormat: Intl.DateTimeFormatOptions['month'] = 'long',
): ArrayWithLocale {
	const locale = normalizeLocale(_locale);
	const monthIndexes = Array.from({ length: MONTH_IN_YEAR_NUMBER });
	const { format } = new Intl.DateTimeFormat(locale, {
		month: monthFormat,
		timeZone: 'UTC',
	});

	const months = monthIndexes.map((...[, m]) =>
		format(new Date(Date.UTC(0, m))),
	);

	Object.defineProperty(months, 'locale', { value: locale });

	return months as ArrayWithLocale;
}

export function daysForLocale(
	_locale: string,
	monthFormat: Intl.DateTimeFormatOptions['weekday'] = 'long',
): ArrayWithLocale {
	const locale = normalizeLocale(_locale);
	let dateIndexes = Array.from(new Array(DAY_IN_WEEK_NUMBER).keys());
	if (locale === 'ru-RU') {
		dateIndexes = [...dateIndexes.slice(1, dateIndexes.length), dateIndexes[0]];
	}

	const { format } = new Intl.DateTimeFormat(locale, {
		weekday: monthFormat,
		timeZone: 'UTC',
	});

	const days = dateIndexes.map((d) => format(new Date(Date.UTC(0, 0, d))));

	Object.defineProperty(days, 'locale', { value: locale, writable: false });

	return days as ArrayWithLocale;
}
