import { action, makeObservable, observable, runInAction } from 'mobx';

import { appStore } from 'stores/app';
import { IPlaylistsStore } from '_types/stores';
import { filterObject, handleError, swaggerApi, toast } from 'utils';
import { ListOrder } from 'utils/api/components';
import {
	PlaylistCreateRequest,
	PlaylistRequest,
	PlaylistUpdateRequest,
} from 'utils/api/api';
import {
	checkRequestData,
	TRequestCondition,
} from '../../utils/api/check-request-data';

export const PLAYLIST_REQUEST_KEYS: Array<keyof PlaylistCreateRequest> = [
	'name',
	'files',
	'description',
];

export const REQUIRED_PLAYLIST_REQUEST_CONDITIONS: Array<
	TRequestCondition<PlaylistCreateRequest | PlaylistUpdateRequest>
> = ['name', 'files'];

class PlaylistsStore extends ListOrder implements IPlaylistsStore {
	@observable list: IPlaylistsStore['list'] = [];

	columns: IPlaylistsStore['columns'] = [
		{
			accessor: 'name',
			label: 'The name of the playlist',
			withSort: true,
		},
		{ accessor: 'createdAt', withSort: true },
		{ accessor: 'updatedAt', withSort: true },
		'status',
		'actions',
	];

	constructor() {
		super();
		makeObservable(this);
	}

	@action getList: IPlaylistsStore['getList'] = async (limit, page, where) => {
		appStore.isLoading = true;

		try {
			const { data: playlistData } = await this.getSortedList(
				swaggerApi.api.playlistsGet,
				{
					where:
						where &&
						(Object.fromEntries(
							Object.entries(where).map(([key, value]) => {
								if (key === 'name') {
									return [key, `%${value}%`];
								}

								return [key, value];
							}),
						) as PlaylistRequest),
					scope: {
						limit,
						page,
					},
				},
			);

			this.list = playlistData.data;

			return this.list;
		} catch (error) {
			toast.error(handleError(error));
			return this.list;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action loadPlaylist: IPlaylistsStore['loadPlaylist'] = async (
		id: string,
	) => {
		appStore.isLoading = true;

		try {
			const { data: playlistsData } = await swaggerApi.api.playlistGet(id);

			return playlistsData.data;
		} catch (error) {
			toast.error(handleError(error));

			return null;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action createPlaylist: IPlaylistsStore['createPlaylist'] = async (
		draftPlaylist,
	) => {
		appStore.isLoading = true;

		try {
			const { data: playlistData } = await swaggerApi.api.playlistCreate(
				checkRequestData(
					filterObject(draftPlaylist, { includedKeys: PLAYLIST_REQUEST_KEYS }),
					REQUIRED_PLAYLIST_REQUEST_CONDITIONS,
				),
			);
			const playlist = playlistData.data;
			this.list = [playlist, ...this.list.filter((p) => p.id !== playlist.id)];

			return playlist;
		} catch (error) {
			toast.error(handleError(error));

			return null;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action updatePlaylist: IPlaylistsStore['updatePlaylist'] = async (
		id,
		playlistData,
	) => {
		if (!id) {
			throw new TypeError(`Argument (1) "id" is required: "${id}"`);
		}
		appStore.isLoading = true;

		try {
			const { data: playlistsData } = await swaggerApi.api.playlistUpdate(
				id,
				checkRequestData(playlistData, REQUIRED_PLAYLIST_REQUEST_CONDITIONS),
			);
			const playlist = playlistsData.data;

			runInAction(() => {
				this.list = this.list.map((p) => (p.id === id ? playlist : p));
			});

			return playlist;
		} catch (error) {
			toast.error(handleError(error));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action deletePlaylist: IPlaylistsStore['deletePlaylist'] = async (
		id: string,
	) => {
		appStore.isLoading = true;

		try {
			await swaggerApi.api.playlistDelete(id);

			await this.getList();
		} catch (error) {
			toast.error(handleError(error));
		} finally {
			appStore.isLoading = false;
		}
	};

	findPlaylist: IPlaylistsStore['findPlaylist'] = async (where, scope = {}) => {
		appStore.isLoading = true;

		try {
			const { data: playlistData } = await swaggerApi.api.playlistsGet({
				where,
				scope,
			});

			return playlistData.data;
		} catch (e) {
			toast.error(handleError(e));
			return [];
		} finally {
			appStore.isLoading = false;
		}
	};
}

export const playlistsStore = new PlaylistsStore();
