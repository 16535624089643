import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { Duration } from 'components/duration';
import { videoEditorStore } from 'modules/video-editor-module';
import { FC, HTMLAttributes, useCallback, useMemo } from 'react';

interface IControls extends HTMLAttributes<HTMLDivElement> {}

const TimelineControlsComponent: FC<IControls> = ({
	className,
	...restProps
}) => {
	const handleCutClick = useCallback(() => {
		void videoEditorStore.cut();
	}, []);

	const handleDeleteClick = useCallback(() => {
		void videoEditorStore.delete();
	}, []);

	const { handleUndoClick, handleRedoClick } = useMemo(() => {
		const lastItemIdx = videoEditorStore.history.items.findIndex(
			(item) => item.id === videoEditorStore.history.lastItemId,
		);
		let handleUndoClick, handleRedoClick;
		if (videoEditorStore.history.lastItemId) {
			handleUndoClick = () => videoEditorStore.undo();
		}
		if (lastItemIdx + 1 in videoEditorStore.history.items) {
			handleRedoClick = () => videoEditorStore.redo();
		}

		return {
			handleUndoClick,
			handleRedoClick,
		};
	}, [videoEditorStore.history, videoEditorStore.undo, videoEditorStore.redo]);

	return (
		<div {...restProps} className={cx('timeline-controls__layout', className)}>
			<div className="timeline-controls__buttons">
				<button
					onClick={videoEditorStore.zoomOut}
					className="timeline-controls__zoom timeline-controls__zoom--out timeline-controls__button"
				/>
				<button
					onClick={videoEditorStore.zoomIn}
					className="timeline-controls__zoom timeline-controls__zoom--in timeline-controls__button"
				/>
				{/* <button className="timeline-controls__cursor timeline-controls__button" />*/}
				<button
					disabled={videoEditorStore.isReadOnly}
					onClick={handleUndoClick}
					className="timeline-controls__backward timeline-controls__button"
					style={{
						opacity: handleUndoClick ? 1 : 0.68,
					}}
				/>
				<button
					disabled={videoEditorStore.isReadOnly}
					onClick={handleRedoClick}
					className="timeline-controls__forward timeline-controls__button"
					style={{
						opacity: handleRedoClick ? 1 : 0.68,
					}}
				/>
			</div>
			<div className="timeline-controls__buttons">
				<button
					disabled={videoEditorStore.isReadOnly}
					onClick={handleCutClick}
					className="timeline-controls__cut timeline-controls__button"
				/>
				<button
					disabled={videoEditorStore.isReadOnly}
					onClick={handleDeleteClick}
					className="timeline-controls__delete timeline-controls__button"
				/>
				{videoEditorStore.view === 'window' && (
					<button
						onClick={videoEditorStore.toggleView}
						className="timeline-controls__select timeline-controls__button"
					/>
				)}

				{/* TOFIX markup */}
				{/* Hint: Duration is just a valid 'time' (text) tag */}
				{/* you can pass className as a param there */}
				<div className="timeline-controls__time-display">
					<Duration
						seconds={videoEditorStore.totalDuration}
						className="timeline-controls__time"
					/>
					<button
						onClick={() => console.info('somethin classy might happen')}
						className="timeline-controls__time-button"
					/>
				</div>
				{/*  */}
			</div>
		</div>
	);
};

export const TimelineControls = observer(TimelineControlsComponent);
