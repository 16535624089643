import { action, makeObservable, observable } from 'mobx';

import { IBidsStore } from '_types/stores';
import { handleError, swaggerApi, toast } from 'utils';
import { ListOrder } from 'utils/api/components';
import { BidApprove, UserRole } from 'utils/api/api';

import { appStore } from '../app';
import { authStore } from '../auth';

class BidsStore extends ListOrder implements IBidsStore {
	@observable list: IBidsStore['list'] = [];
	@observable error: string | null = null;

	constructor() {
		super();
		makeObservable(this);
	}

	@action getList: IBidsStore['getList'] = async (data) => {
		appStore.isLoading = true;

		try {
			const { data: editorData } = await this.getSortedList(
				swaggerApi.api.bidsGet,
				{
					...data,
				},
			);
			this.list = editorData.data;

			return this.list;
		} catch (error) {
			toast.error(handleError(error));

			return this.list;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action decide: IBidsStore['decide'] = async (bid, approved) => {
		if (
			authStore.userRole === UserRole.Advertiser ||
			bid.approved !== BidApprove.NotProcessed
		) {
			return this.list;
		}
		await swaggerApi.api.bidUpdate(bid.id, {
			approved,
		});
		return this.getList();
	};
}

export const bidsStore = new BidsStore();
