import { ComponentProps, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Input } from './input';

import './styles.scss';

export const FileInput: FC<
	Omit<ComponentProps<typeof Input>, 'type' | 'label'>
> = ({ placeholder, ...props }) => (
	<Input
		{...props}
		type="file"
		placeholder={
			placeholder || (
				<span className="form__input__file-icon">
					<FormattedMessage id="Add" defaultMessage="Add" />
				</span>
			)
		}
	/>
);
