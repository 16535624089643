import { toast, ToastOptions } from 'react-toastify';
import { ReactNode } from 'react';
import { random } from '../helpers';
import { ToastTask } from '../../components/common';

export interface IToastTaskOptions {
	toastId: string;
	name?: string;
	percent?: number;
	failed?: boolean;
	done?: boolean;
}

export function toastTask(
	message: ReactNode,
	options?: Partial<IToastTaskOptions>,
) {
	const { percent, failed } = options || {};
	let { toastId, done } = options || {};
	if (!toastId) {
		toastId = random();
	}
	if (percent && !done) {
		done = percent ? percent === 100 : false;
	}
	const render = (
		<ToastTask
			message={message}
			toastId={toastId}
			percent={percent}
			failed={failed}
			done={done}
		/>
	);
	let type: ToastOptions['type'] = 'info';
	if (done) {
		type = 'success';
	}
	if (failed) {
		type = 'error';
	}

	const commonOptions: Partial<ToastOptions> = {
		closeButton: done || failed,
		type,
	};

	if (percent && !failed) {
		commonOptions.progress = percent / 100;
	}

	if (toast.isActive(toastId)) {
		toast.update(toastId, {
			...commonOptions,
			render,
		});
	} else {
		toast(render, {
			...commonOptions,
			autoClose: false,
			closeOnClick: false,
			draggable: false,
			position: 'bottom-right',
			hideProgressBar: false,
			toastId,
		});
	}

	return toastId;
}
