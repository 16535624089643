import {
	Login,
	Logout,
	Register,
	ResetPassword,
	SendRegisterEmail,
	SendResetPassword,
	UpdateResetPassword,
	VerifyRegisterEmail,
} from 'pages/auth';
import { Desktop } from 'pages/desktop';
import { Statistics } from 'pages/report';
import { Home } from 'pages/home';
import NotFound from 'pages/not-found/not-found';
import { Library } from 'pages/library';
import {
	MonitorAddEdit,
	MonitorDelete,
	Monitors,
	MonitorsGroup,
	MonitorsList,
} from 'pages/monitors';
import { UserAddEdit, Users, UsersList } from 'pages/users';
import {
	MapPlaylistMonitorList,
	PlaylistAddEdit,
	PlaylistDelete,
	Playlists,
	PlaylistsList,
} from 'pages/playlists';
import { Bids } from 'pages/bids';
import { CurrentUser, Profile } from 'pages/current-user';
import { Privacy } from 'pages/docs/privacy';
import { EditorDraft, EditorProject, EditorProjects } from 'pages/video-editor';
import { Accountant, AccountantInvoices } from 'pages/accountant';
import { Wallet, WalletHistory } from 'pages/wallet';

import { normalizeRoutes } from './engine';
import { UserRole } from 'utils/api/api';
import { filterObject } from 'utils';
import { BASE_ROUTE_KEYS, PUBLIC_ROUTE_KEYS } from './constants';

const draftRoutes = {
	Accountant: { path: '/accountant', Component: Accountant },
	AccountantInvoices: {
		path: '/accountant/invoices',
		Component: AccountantInvoices,
	},
	AccountantInvoicesPage: {
		path: '/accountant/invoices/:currentPage',
		Component: AccountantInvoices,
	},
	Bids: {
		path: '/bids',
		Component: Bids,
		restrictedFrom: [UserRole.Accountant],
	},
	BidsPreview: {
		path: '/bids/:bidId',
		Component: Bids,
		restrictedFrom: [UserRole.Accountant],
	},
	Home: { path: '/', Component: Home },
	Library: {
		path: '/library',
		Component: Library,
		restrictedFrom: [UserRole.Accountant],
	},
	Desktop: {
		path: '/desktop',
		Component: Desktop,
		restrictedFrom: [UserRole.Accountant],
	},
	Statistics: {
		path: '/statistics',
		Component: Statistics,
		restrictedFrom: [UserRole.Accountant],
	},
	NotFound: { path: '/*', Component: NotFound, exact: false, isPublic: true },
	Profile: {
		path: '/profile',
		Component: Profile,
		restrictedFrom: [UserRole.Accountant],
	},
	CurrentUser: {
		path: '/current-user',
		Component: CurrentUser,
		restrictedFrom: [UserRole.Accountant],
	},
	Wallet: {
		path: '/wallet',
		Component: Wallet,
		restrictedFrom: [UserRole.Accountant],
		outletFor: ['WalletHistory', 'WalletPay'],
	},
	WalletHistory: {
		path: 'history',
		Component: WalletHistory,
		restrictedFrom: [UserRole.Accountant],
	},
	// AUTH
	Login: {
		path: '/login',
		Component: Login,
		isPublic: true,
	},
	Register: {
		path: '/register',
		Component: Register,
		isPublic: true,
	},
	Logout: {
		path: '/logout',
		Component: Logout,
	},
	ResetPassword: {
		path: '/reset-password',
		Component: ResetPassword,
		isPublic: true,
	},
	UpdateResetPassword: {
		path: '/reset-password-verify',
		Component: UpdateResetPassword,
		isPublic: true,
	},
	SendRegisterEmail: {
		path: '/send-register-email',
		Component: SendRegisterEmail,
		isPublic: true,
	},
	VerifyRegisterEmail: {
		path: '/verify-email',
		Component: VerifyRegisterEmail,
		isPublic: true,
	},
	SendResetPassword: {
		path: '/send-reset-password',
		Component: SendResetPassword,
		isPublic: true,
	},
	// MONITORS
	Monitors: {
		path: '/monitors',
		Component: Monitors,
		restrictedFrom: [UserRole.Accountant],
	},
	MonitorsList: {
		path: '/monitors/list',
		Component: MonitorsList,
		restrictedFrom: [UserRole.Accountant],
	},
	MonitorsListPage: {
		path: '/monitors/list/:currentPage',
		Component: MonitorsList,
		restrictedFrom: [UserRole.Accountant],
	},
	MonitorsAdd: {
		path: '/monitors/add',
		Component: MonitorAddEdit,
		restrictedFrom: [UserRole.Accountant, UserRole.Advertiser],
	},
	MonitorsAddByCode: {
		path: '/monitors/add/:code',
		Component: MonitorAddEdit,
		restrictedFrom: [UserRole.Accountant, UserRole.Advertiser],
	},
	MonitorsEdit: {
		path: '/monitors/edit/:id',
		Component: MonitorAddEdit,
		restrictedFrom: [UserRole.Accountant, UserRole.Advertiser],
	},
	MonitorsDelete: {
		path: '/monitors/delete/:id',
		Component: MonitorDelete,
		restrictedFrom: [UserRole.Accountant, UserRole.Advertiser],
	},
	MonitorsGroup: {
		path: '/monitors/group',
		Component: MonitorsGroup,
		restrictedFrom: [UserRole.Accountant, UserRole.Advertiser],
	},
	MonitorsGroupEdit: {
		path: '/monitors/group/:id',
		Component: MonitorsGroup,
		restrictedFrom: [UserRole.Accountant, UserRole.Advertiser],
	},
	// PLAYLISTS
	Playlists: {
		path: '/playlists',
		Component: Playlists,
		restrictedFrom: [UserRole.Accountant],
	},
	PlaylistsList: {
		path: '/playlists/list',
		Component: PlaylistsList,
		restrictedFrom: [UserRole.Accountant],
	},
	PlaylistsMapMonitorList: {
		path: '/playlists/map',
		Component: MapPlaylistMonitorList,
		restrictedFrom: [UserRole.Accountant],
	},
	PlaylistsPage: {
		path: '/playlists/list/:currentPage',
		Component: PlaylistsList,
		restrictedFrom: [UserRole.Accountant],
	},
	PlaylistsAdd: {
		path: '/playlists/add',
		Component: PlaylistAddEdit,
		restrictedFrom: [UserRole.Accountant],
	},
	PlaylistsAddByName: {
		path: '/playlists/add/:name',
		Component: PlaylistAddEdit,
		restrictedFrom: [UserRole.Accountant],
	},
	PlaylistsEdit: {
		path: '/playlists/edit/:id',
		Component: PlaylistAddEdit,
		restrictedFrom: [UserRole.Accountant],
	},
	PlaylistsDelete: {
		path: '/playlists/delete/:id',
		Component: PlaylistDelete,
		restrictedFrom: [UserRole.Accountant],
	},
	// USERS
	Users: {
		path: '/users',
		Component: Users,
		restrictedFrom: [
			UserRole.Accountant,
			UserRole.Advertiser,
			UserRole.MonitorOwner,
		],
	},
	UsersList: {
		path: '/users/list',
		Component: UsersList,
		restrictedFrom: [
			UserRole.Accountant,
			UserRole.Advertiser,
			UserRole.MonitorOwner,
		],
	},
	UsersListPage: {
		path: '/users/list/:currentPage',
		Component: UsersList,
		restrictedFrom: [
			UserRole.Accountant,
			UserRole.Advertiser,
			UserRole.MonitorOwner,
		],
	},
	UsersEdit: {
		path: '/users/edit/:id',
		Component: UserAddEdit,
		restrictedFrom: [
			UserRole.Accountant,
			UserRole.Advertiser,
			UserRole.MonitorOwner,
		],
	},
	UsersDelete: {
		path: '/users/delete/:id',
		Component: UserAddEdit,
		restrictedFrom: [
			UserRole.Accountant,
			UserRole.Advertiser,
			UserRole.MonitorOwner,
		],
	},
	// EDITOR
	Editor: {
		path: '/editor',
		Component: EditorDraft,
		restrictedFrom: [UserRole.Accountant],
	},
	EditorCreate: {
		path: '/editor/project',
		Component: EditorProject,
		restrictedFrom: [UserRole.Accountant],
	},
	EditorProject: {
		path: '/editor/project/:id',
		Component: EditorProject,
		restrictedFrom: [UserRole.Accountant],
	},
	EditorProjects: {
		path: '/editor/projects',
		Component: EditorProjects,
		restrictedFrom: [UserRole.Accountant],
	},
	// PUBLIC
	Privacy: { path: '/privacy', Component: Privacy, isPublic: true },
};

export type TRouteKey = keyof typeof draftRoutes;

let targetRoutes;

switch (process.env.REACT_APP_TARGET) {
	case 'promo':
		{
			targetRoutes = filterObject(draftRoutes, {
				includedKeys: [
					...BASE_ROUTE_KEYS,
					...PUBLIC_ROUTE_KEYS,
					'Accountant',
					'AccountantInvoices',
					'AccountantInvoicesPage',
					'Monitors',
					'MonitorsList',
					'MonitorsListPage',
					'Profile',
				],
			});
			targetRoutes.Home = {
				path: '/',
				Component: Monitors,
			};
		}
		break;

	default: {
		targetRoutes = draftRoutes;
	}
}

switch (process.env.REACT_APP_ENVIRONMENT) {
	case 'production': {
		targetRoutes = filterObject(draftRoutes, {
			excludedKeys: ['MonitorsGroup', 'MonitorsGroupEdit'],
		});
	}
}

export default normalizeRoutes(targetRoutes);
