import { FC, HTMLAttributes, useMemo } from 'react';
import cx from 'classnames';
import { icons } from 'assets/icons';

import './icon.scss';

export interface IIconsProps extends HTMLAttributes<HTMLDivElement> {
	size?: number;
}

export const Icon: FC<IIconsProps> = ({
	className,
	children,
	size,
	...restProps
}) => {
	const style = useMemo(() => {
		const style = {
			...(restProps.style || {}),
		};
		if (typeof size === 'number') {
			// @ts-expect-error: Custom CSS property
			style['--size'] = `${size}px`;
		}

		return style;
	}, [restProps.style, size]);

	return (
		<div
			{...restProps}
			className={cx('icon', icons.icon, className)}
			style={style}
		>
			{children}
		</div>
	);
};
