import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Radio } from 'components/forms';

import './styles.scss';

type RadioFilterProps = {
	isOpen: boolean;
	onSearch: (value: null | string) => void;
	options: {
		label: string;
		value: string;
	}[];
};

export const RadioFilter: FC<RadioFilterProps> = (props) => {
	const { options, isOpen, onSearch } = props;

	const [draftValue, setDraftValue] = useState<null | string>(null);

	const handleSearch = useCallback(() => {
		onSearch(draftValue);
	}, [draftValue, onSearch]);

	const values = useMemo(() => {
		return options.map((option) => {
			const { label, value } = option;

			const handleClick = () => {
				if (draftValue === value) {
					setDraftValue(null);
					return;
				}

				setDraftValue(value);
			};

			return (
				<div key={value} className="filters__radio-input-layout">
					<Radio
						name="playlist_filter"
						value={value}
						checked={value === draftValue}
						onClick={handleClick}
						label={<div className="form__radio-mask form__radio-mask--gray" />}
					/>
					<div className="filters__radio-label">
						<FormattedMessage id={label} defaultMessage={label} />
					</div>
				</div>
			);
		});
	}, [options, draftValue, setDraftValue]);

	if (!isOpen) return null;

	return (
		<div className="filters__radio-layout">
			{values}
			<button
				className="filters__search-button filters__radio-search"
				onClick={handleSearch}
			>
				<FormattedMessage id="Search" defaultMessage="Search" />
			</button>
		</div>
	);
};
