import { DragEvent, FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { explorerStore, IFolderItem } from 'stores/media';
import { Confirmation } from 'components/common';

import './styles.scss';
import { UploadButton } from '../../components/upload-button/upload-button';
import { Icon, IIconsProps } from '../../components/icon';
import { icons } from 'assets/icons';
import { preventMe } from '../../utils';
import { Explorer } from '../../components/explorer';

const LibraryComponent: FC = () => {
	const intl = useIntl();

	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

	const renderConfirmation = () => {
		if (!confirmationIsOpen) {
			return null;
		}

		const allEmpty = explorerStore.selected.folderIds
			.map((folderId) => {
				return explorerStore.foldersList.find((folder) => {
					return folder.id === folderId;
				});
			})
			.filter((folder): folder is IFolderItem => {
				return Boolean(folder);
			})
			.every((folder) => folder.empty);

		const message = allEmpty
			? 'Are you sure to delete the selected one?'
			: 'Among the deleted folders there are folders with nested elements that will be lost when deleted. Continue deleting?';

		const handleConfirm = async () => {
			await explorerStore.deleteSelected();

			setConfirmationIsOpen(false);
		};

		const handleCancel = () => {
			setConfirmationIsOpen(false);
		};

		return (
			<Confirmation
				message={message}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
		);
	};

	const handleUpload = useCallback((files: File[]) => {
		if (files) {
			void explorerStore.uploadFiles([...files]);
		}
	}, []);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			handleUpload([...e.dataTransfer.files]);
		},
		[handleUpload],
	);

	const controls = useMemo(
		() =>
			['Rename', 'Create directory', 'Copy', 'Cut', 'Paste', 'Delete'].map(
				(messageId) => {
					const iconProps: IIconsProps = {};

					switch (messageId) {
						case 'Rename':
							{
								iconProps.className = icons.Rename;
								iconProps.onClick = () => explorerStore.rename();
							}
							break;
						case 'Create directory':
							{
								iconProps.className = icons.CreateFolder;
								iconProps.onClick = explorerStore.turnFolderCreationOn;
							}
							break;

						case 'Copy':
							{
								iconProps.className = icons.CopyMedia;
								iconProps.onClick = explorerStore.copyToClipboard;
							}
							break;

						case 'Cut':
							{
								iconProps.className = icons.CutMedia;
								iconProps.onClick = explorerStore.cutToClipboard;
							}
							break;

						case 'Paste':
							{
								iconProps.className = icons.PasteMedia;
								iconProps.onClick = explorerStore.pasteFromClipboard;
							}
							break;

						case 'Delete':
							{
								iconProps.className = icons.DeleteBinGray;
								iconProps.onClick = () => setConfirmationIsOpen(true);
							}
							break;
					}

					return (
						<Icon
							key={messageId}
							title={
								intl.formatMessage({ id: messageId, defaultMessage: '' }) ||
								undefined
							}
							data-action={messageId}
							{...iconProps}
						/>
					);
				},
			),
		[],
	);

	return (
		<div
			className="library"
			onDragEnter={preventMe}
			onDragOver={preventMe}
			onDragLeave={preventMe}
			onDrop={handleDrop}
		>
			{renderConfirmation()}
			<h1 className="main__title">
				<FormattedMessage id="Library" defaultMessage="Библиотека" />
			</h1>
			<div className="flex row library__head">
				<UploadButton
					className="library__upload-button"
					multiple
					onUpload={handleUpload}
				>
					<FormattedMessage
						id="app.button.upload"
						defaultMessage="Загрузить файл"
					/>
				</UploadButton>
				{controls}
			</div>
			<Explorer className="library__explorer" />
		</div>
	);
};

export const Library = observer(LibraryComponent);
