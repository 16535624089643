import { ChangeEvent, FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

type TextFilterProps = {
	isOpen: boolean;
	placeholder: string;
	onSearch: (value: null | string) => void;
};

export const TextFilter: FC<TextFilterProps> = (props) => {
	const { isOpen, placeholder, onSearch } = props;

	const [draftValue, setDraftValue] = useState('');

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setDraftValue(event.target.value);
	}, []);

	const handleReset = useCallback(() => {
		setDraftValue('');
	}, []);

	const handleSearch = useCallback(() => {
		if (draftValue === '') {
			onSearch(null);
			return;
		}

		onSearch(draftValue);
	}, [onSearch, draftValue]);

	if (!isOpen) return null;

	return (
		<div className="filters__text-layout">
			<div className="filters__text-input-layout">
				<input
					className="filters__text-input"
					value={draftValue}
					onChange={handleChange}
					placeholder={placeholder}
					autoFocus
				/>
				<button className="filters__text-reset" onClick={handleReset} />
			</div>
			<button className="filters__search-button" onClick={handleSearch}>
				<FormattedMessage id="Search" defaultMessage="Search" />
			</button>
		</div>
	);
};
