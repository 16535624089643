import { action, makeObservable, observable } from 'mobx';
import cloneDeep from 'clone-deep';

import { IUser } from '_types/stores';
import { filterObject, handleError, swaggerApi, toast } from 'utils';
import { appStore } from '../app';
import { authStore } from '../auth';
import { UsersGetRequest } from 'utils/api/api';
import { ListOrder } from 'utils/api/components';

export interface IUserStore {
	user: IUser | null;
	list: IUser[];

	getUser(id: IUser['id']): Promise<IUserStore['user']>;

	getList(data?: UsersGetRequest): Promise<void>;

	setField(field: string, value: string): void;

	updateUser(): Promise<IUserStore['user']>;
}

export const DEFAULT_USER_ORDER_ACCESSOR = 'name';

class UserStore extends ListOrder implements IUserStore {
	@observable user: IUserStore['user'] = null;
	@observable list: IUserStore['list'] = [];

	constructor() {
		super();
		makeObservable(this);
		this.setOrder(DEFAULT_USER_ORDER_ACCESSOR);
	}

	@action getList: IUserStore['getList'] = async (data = {}) => {
		appStore.isLoading = true;
		try {
			const { data: usersData } = await this.getSortedList(
				swaggerApi.api.usersGet,
				data,
			);
			this.list = usersData.data;
		} catch (e) {
			toast.error(handleError(e));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action getUser: IUserStore['getUser'] = async (id) => {
		if (!authStore.user) {
			return this.user;
		}
		appStore.isLoading = true;

		try {
			if (id === authStore.user.id) {
				this.user = cloneDeep(authStore.user);
			} else {
				const { data: userData } = await swaggerApi.api.userGet(id);

				this.user = userData.data;
			}

			return this.user;
		} catch (error) {
			toast.error(handleError(error));
			return null;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action.bound setField: IUserStore['setField'] = (field, value) => {
		if (!this.user) return;

		this.user = {
			...this.user,
			[field]: value,
		};
	};

	@action updateUser: IUserStore['updateUser'] = async () => {
		if (!(this.user && authStore.user)) {
			return this.user;
		}
		appStore.isLoading = true;

		try {
			if (authStore.user.id === this.user.id) {
				const updatedUser = await authStore.updateUser(this.user);

				this.user = updatedUser && cloneDeep(updatedUser);
			} else {
				const { data: userData } = await swaggerApi.api.userUpdate(
					this.user.id,
					filterObject(this.user, {
						includedKeys: ['surname', 'name', 'middleName', 'email', 'role'],
					}),
				);

				this.user = userData.data;
			}

			return this.user;
		} catch (error) {
			toast.error(handleError(error));
			return this.user;
		} finally {
			appStore.isLoading = false;
		}
	};
}

const userStore = new UserStore();

export { userStore };
