import cx from 'classnames';
import { forwardRef, memo, PropsWithChildren } from 'react';
import './styles.scss';

const Container = forwardRef<
	HTMLElement,
	PropsWithChildren<{
		className?: string;
		boxed?: boolean;
		rounded?: boolean;
		flat?: boolean;
		style?: Record<string, string | number>;
	}>
>(({ className, boxed, rounded, flat, children, style }, ref) => (
	<main
		className={cx(
			'container',
			className,
			boxed && 'container__boxed',
			rounded && 'container__boxed-rounded',
			flat && 'container__boxed-flat',
		)}
		style={style}
		ref={ref}
	>
		{children}
	</main>
));

export default memo(Container);
