import {
	FC,
	HTMLAttributes,
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer, useLocalObservable } from 'mobx-react-lite';
import cx from 'classnames';

import { monitorsStore } from 'stores/monitors/monitor-list';
import {
	DRAFT_PROJECT_ID,
	videoEditorStore,
} from 'modules/video-editor-module';
import { IMonitorItem } from '_types/stores';

import { BidCreateMonitorRequest, MonitorStatus } from 'utils/api/api';

import {
	CategoryOption,
	FavoriteIcon,
	NameWithIcon,
} from 'pages/monitors/list-components';
import { Table, TableCheckbox } from 'components/table';
import { ChangeNameForm } from '../change-name-form';
import { BidSendForm } from 'pages/bids/bid-send';
import { MONITOR_TABLE_COLUMNS } from 'pages/monitors/constants';
import { useForm } from '../../../../hooks';
import { Form, Input } from '../../../../components/forms';

export interface IPlayerTopLineProps extends HTMLAttributes<HTMLDivElement> {}

const monitorColumns = [
	{
		accessor: 'checkbox',
		label: ' ',
	},
	...MONITOR_TABLE_COLUMNS.filter((c) =>
		typeof c === 'string' ? c !== 'actions' : c.accessor !== 'actions',
	),
];

export const PlayerTopLine: FC<IPlayerTopLineProps> = observer(
	({ className, ...restProps }) => {
		const [searchIsOpen, setSearchIsOpen] = useState(false);
		const [bidIsOpen, setBidIsOpen] = useState(false);
		const [renameFormIsOpen, setRenameFormIsOpen] = useState(false);
		const [duplicateFormIsOpen, setDuplicateFormIsOpen] = useState(false);

		const intl = useIntl();

		const localStore = useLocalObservable(() => ({
			selectedDevices: [] as IMonitorItem[],
			selectDevice(device: IMonitorItem) {
				this.selectedDevices = this.selectedDevices
					.filter((d) => d.id !== device.id)
					.concat(device);
			},
			isSelectedDevice(deviceId: IMonitorItem['id']) {
				return this.selectedDevices.some((d) => d.id === deviceId);
			},
			unSelectDevice(deviceId: IMonitorItem['id']) {
				this.selectedDevices = this.selectedDevices.filter(
					(d) => d.id !== deviceId,
				);
			},
			unSelectAll() {
				this.selectedDevices = [];
			},
		}));

		const handleSaveAsClick = useCallback(() => {
			if (!videoEditorStore.project) {
				return;
			}

			if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
				return;
			}

			setDuplicateFormIsOpen(true);
		}, [videoEditorStore.project?.id]);

		const handleSendSubmit = useCallback(
			(bid: BidCreateMonitorRequest) => {
				void videoEditorStore.exportToDevices(localStore.selectedDevices, bid);
				setBidIsOpen(false);
				setSearchIsOpen(false);
			},
			[localStore.selectedDevices],
		);

		const handleSendClick = useCallback(
			(e: MouseEvent) => {
				if (searchIsOpen) {
					e.stopPropagation();
					if (!bidIsOpen) {
						setBidIsOpen(true);
					}
				} else {
					setSearchIsOpen(true);
				}
			},
			[searchIsOpen, bidIsOpen],
		);

		const handleRenameClick = useCallback(() => {
			if (!videoEditorStore.project) {
				return;
			}

			if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
				return;
			}

			setRenameFormIsOpen(true);
		}, [videoEditorStore.project?.id]);

		const data = useMemo(
			() =>
				!monitorsStore.listNoSubordinate ||
				monitorsStore.listNoSubordinate.length === 0
					? []
					: monitorsStore.listNoSubordinate.map((m) => {
							return {
								...m,
								checkbox: (
									<TableCheckbox
										colorModifier={
											m.status === MonitorStatus.Online ? 'green' : 'dark'
										}
										itemId={m.id}
										itemIds={localStore.selectedDevices.map((d) => d.id)}
										onChange={(checked) =>
											checked
												? localStore.selectDevice(m)
												: localStore.unSelectDevice(m.id)
										}
									/>
								),
								favorite: <FavoriteIcon monitor={m} />,
								name: (
									<NameWithIcon
										name={m.name}
										enabled={m.status === MonitorStatus.Online}
										type={m.multiple}
									/>
								),
								category: <CategoryOption id={m.id} category={m.category} />,
								address:
									m.address &&
									m.address.city +
										(m.address.street ? `, ${m.address.street} ` : ' ') +
										m.address.house,
							};
						}),
			[
				localStore.selectedDevices,
				localStore.selectDevice,
				localStore.unSelectDevice,
				monitorsStore.listNoSubordinate,
			],
		);

		const handleRenameFormClose = useCallback(() => {
			setRenameFormIsOpen(false);
		}, [setRenameFormIsOpen]);

		const handleRenameFormSave = useCallback(
			(name: string) => {
				videoEditorStore.renameProject(name).finally(() => {
					setRenameFormIsOpen(false);
				});
			},
			[setRenameFormIsOpen],
		);

		const renderRenameForm = () => {
			if (!videoEditorStore.project) {
				return null;
			}

			return (
				<ChangeNameForm
					open={renameFormIsOpen}
					status="edit"
					value={videoEditorStore.project.name}
					onClose={handleRenameFormClose}
					onSave={handleRenameFormSave}
				/>
			);
		};

		const handleDuplicateFormClose = useCallback(() => {
			setDuplicateFormIsOpen(false);
		}, [setDuplicateFormIsOpen]);

		const handleDuplicateFormSave = useCallback(
			(name: string) => {
				if (!videoEditorStore.project) {
					return;
				}

				void videoEditorStore.duplicateProject(
					name,
					videoEditorStore.project.id,
				);
				setDuplicateFormIsOpen(false);
			},
			[videoEditorStore.project?.id, setDuplicateFormIsOpen],
		);

		const renderDuplicateForm = () => {
			if (!videoEditorStore.project) {
				return null;
			}

			return (
				<ChangeNameForm
					open={duplicateFormIsOpen}
					status="create"
					value={`${videoEditorStore.project.name} Копия`}
					onClose={handleDuplicateFormClose}
					onSave={handleDuplicateFormSave}
				/>
			);
		};

		const handleProjectCancel = useCallback(async () => {
			if (!videoEditorStore.project) {
				return;
			}

			if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
				return;
			}

			await videoEditorStore.cancelProjectCreation();
		}, [videoEditorStore.project?.id]);

		const handleRenderClick = useCallback(() => {
			void videoEditorStore.compileProject();
		}, []);

		const [{ formProps, values }] = useForm({
			search: '',
		});

		useEffect(() => {
			void monitorsStore.search(values.search);
		}, [values.search]);

		useEffect(() => {
			return () => {
				void monitorsStore.search('');
			};
		}, []);

		return (
			<div {...restProps} className={cx('player__bar', className)}>
				{renderRenameForm()}
				{renderDuplicateForm()}
				{bidIsOpen && (
					<BidSendForm
						monitorIds={localStore.selectedDevices.map((d) => d.id)}
						onOutsideClick={() => setBidIsOpen(false)}
						onSubmit={handleSendSubmit}
					/>
				)}
				<div className="player__bar-left">
					<div className="player__rename-project">
						<span className="player__rename-input">
							{videoEditorStore.project?.name}
						</span>
						<button
							disabled={!videoEditorStore.project}
							className="player__rename-button"
							onClick={handleRenameClick}
						>
							<FormattedMessage id="Rename" defaultMessage="Rename" />
						</button>
						<button
							onClick={handleSaveAsClick}
							className="player__button player__save-as-button"
							title={intl.formatMessage({
								id: 'Save as',
								defaultMessage: 'Save as',
							})}
						/>
						<button
							className="player__button player__cancel-project"
							onClick={handleProjectCancel}
							title={intl.formatMessage({
								id: 'Clear the project',
								defaultMessage: 'Clear the project',
							})}
						/>
					</div>
				</div>
				<div className="player__bar-right">
					<OutsideClickHandler onOutsideClick={() => setSearchIsOpen(false)}>
						<div className="player__file-name">
							<button
								className="player__send"
								onClick={handleSendClick}
								disabled={videoEditorStore.isReadOnly}
							>
								<FormattedMessage id="Send" defaultMessage="Send" />
							</button>
						</div>
						{searchIsOpen && (
							<div className="player__devices">
								<Table
									className="scroll"
									columns={monitorColumns}
									data={data}
									onSortClick={monitorsStore.setOrder}
									order={monitorsStore.order}
								/>
								<div className="player__devices-info">
									<div className="player__devices-left">
										<button className="player__devices-button" />
										<Form {...formProps} className="player__devices-find">
											<FormattedMessage id="Find" defaultMessage="Find" />:{' '}
											<Input
												className="player__devices-input"
												name="search"
												value={values.search}
											/>
										</Form>
										<button className="player__devices-find-button" />
									</div>
									<div className="player__devices-right">
										<div className="player__devices-total">
											<FormattedMessage id="Total" description="Total" /> :{' '}
											{data.length}
										</div>
										<div className="player__devices-selected">
											<FormattedMessage
												id="Highlighted"
												defaultMessage="Highlighted"
											/>
											: 1
										</div>
									</div>
								</div>
							</div>
						)}
					</OutsideClickHandler>
					<button
						className="player__button-rendering"
						onClick={handleRenderClick}
						disabled={videoEditorStore.isReadOnly}
					>
						<FormattedMessage
							id="Video rendering"
							defaultMessage="Video rendering"
						/>
					</button>
				</div>
			</div>
		);
	},
);
