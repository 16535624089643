import React, {
	ChangeEvent,
	FC,
	HTMLAttributes,
	ReactChildren,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import cx from 'classnames';
import { observer, useLocalObservable } from 'mobx-react-lite';

import { DEFAULT_SEC_TO_PX, ONE_SECOND_MS } from 'modules/video-editor-module';

import './styles.scss';
import { SliderInput } from '../slider-input';
import { formatSeconds } from '../../utils';

export interface IVideoEngine {
	_timelineAnimationFrame: number;
	_currentFrame: number;
	readonly currentSeconds: number;
	isPlayed: boolean;
	playbackRate: 1 | 2;
	totalDuration: number;

	_animate(): ((frame: number) => void | null) | null;

	play(e?: React.MouseEvent<HTMLButtonElement>): void;

	pause(e?: React.MouseEvent<HTMLButtonElement>): void;

	moveTimelinePointer(
		initObj: Partial<{
			shiftSeconds: number;
			seconds: number;
		}>,
	): boolean;

	setPlayback(rate: IVideoEngine['playbackRate']): void;

	setTotalDuration(duration: IVideoEngine['totalDuration']): void;
}

export interface IVideoProps
	extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
	sources: HTMLVideoElement[];
	children?: (engine: IVideoEngine) => ReactNode | ReactChildren;
}

const VideoComponent: FC<IVideoProps> = ({ sources, className, children }) => {
	const { timeline, totalDuration } = useMemo(() => {
		if (!sources.length) {
			return { timeline: [], totalDuration: 0 };
		}
		const timeline = sources.reduce(
			(acc, video, idx) => {
				const start = acc[idx - 1]?.end || 0;

				return acc.concat({
					start,
					end: start + video.duration,
				});
			},
			[] as Array<{ start: number; end: number }>,
		);

		return {
			timeline,
			totalDuration: timeline[timeline.length - 1].end,
		};
	}, [sources]);

	const engine = useLocalObservable<IVideoEngine>(() => ({
		_timelineAnimationFrame: 0,
		_currentFrame: 0,
		isPlayed: false,
		playbackRate: 1,
		totalDuration: totalDuration || 0,
		get currentSeconds() {
			return this._currentFrame / ONE_SECOND_MS;
		},
		setPlayback(rate) {
			this.playbackRate = rate;
			this.play();
		},
		setTotalDuration(duration) {
			this.totalDuration = duration;
		},
		moveTimelinePointer({
			shiftSeconds,
			seconds,
		}: Partial<{ shiftSeconds: number; seconds: number }>) {
			if (!Number.isNaN(shiftSeconds) && typeof shiftSeconds === 'number') {
				const frameShift = shiftSeconds * ONE_SECOND_MS;
				const currentFrame = this._currentFrame + frameShift;
				if (currentFrame >= 0) {
					const totalFrame = this.totalDuration * ONE_SECOND_MS;
					if (currentFrame <= totalFrame) {
						this._currentFrame = currentFrame;
					} else {
						this._currentFrame = totalFrame;
					}
					return true;
				} else if (this._currentFrame !== currentFrame) {
					this._currentFrame = 0;
					return true;
				}
			} else if (!Number.isNaN(seconds) && typeof seconds === 'number') {
				const currentFrame = seconds * ONE_SECOND_MS;
				if (currentFrame >= 0) {
					this._currentFrame = currentFrame;
					if (this.isPlayed) {
						// replay with current frame
						this.play();
					}
					return true;
				} else if (this._currentFrame !== currentFrame) {
					this._currentFrame = 0;
					return true;
				}
			}
			return false;
		},
		play() {
			if (this._timelineAnimationFrame) {
				this.pause();
			}
			const animate = this._animate();
			if (animate) {
				this.isPlayed = true;
				this._timelineAnimationFrame = requestAnimationFrame(animate);
			}
		},
		pause() {
			this.isPlayed = false;
			cancelAnimationFrame(this._timelineAnimationFrame);
			this._timelineAnimationFrame = 0;
		},
		_animate() {
			const fpsInterval = ONE_SECOND_MS / 60;
			const startTime =
				window.performance.now() - this._currentFrame / this.playbackRate;
			let then = startTime;

			const animate = (frame: number) => {
				this._timelineAnimationFrame = requestAnimationFrame(animate);

				const elapsed = frame - then;

				if (elapsed > fpsInterval) {
					then = frame - (elapsed % fpsInterval);
					this._currentFrame = (frame - startTime) * this.playbackRate;

					const end = this.currentSeconds >= this.totalDuration;
					if (end) {
						this.pause();
					}
				}
			};
			return animate;
		},
	}));

	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const sliderRef = useRef<HTMLDivElement | null>(null);
	const trackRef = useRef<HTMLDivElement | null>(null);

	const secondsToPixelFactor = useCallback(() => {
		const { current: trackDiv } = trackRef;
		if (trackDiv && totalDuration > 0) {
			return trackDiv.clientWidth / totalDuration;
		}
		return DEFAULT_SEC_TO_PX;
	}, [totalDuration]);

	const sliderShiftPx = useMemo(() => {
		return engine.currentSeconds * secondsToPixelFactor();
	}, [engine.currentSeconds, secondsToPixelFactor]);

	const curIdx = useMemo(() => {
		return timeline.findIndex(({ start, end }) => {
			return engine.currentSeconds >= start && engine.currentSeconds <= end;
		});
	}, [timeline, engine.currentSeconds]);

	useEffect(() => {
		if (engine.totalDuration !== totalDuration) {
			engine.setTotalDuration(totalDuration);
		}
	}, [totalDuration, engine]);

	useEffect(() => {
		const { current: canvas } = canvasRef;
		if (canvas && curIdx !== -1) {
			const { start } = timeline[curIdx];
			const source = sources[curIdx];
			const ctx = canvas.getContext('2d');

			if (engine.playbackRate !== source.playbackRate) {
				source.playbackRate = engine.playbackRate;
			}
			if (!engine.isPlayed || engine.isPlayed !== !source.paused) {
				source.currentTime = engine.currentSeconds - start;
			}
			if (engine.isPlayed && source.paused) {
				source
					.play()
					.then(() => {
						sources
							.filter((v, idx) => curIdx !== idx)
							.forEach((v) => {
								v.pause();
							});
					})
					.catch(console.error);
			} else if (!engine.isPlayed && !source.paused) {
				source.pause();
			}
			canvas.width = source.videoWidth;
			canvas.height = source.videoHeight;

			ctx?.drawImage(source, 0, 0, source.videoWidth, source.videoHeight);
		}
	}, [
		curIdx,
		sources,
		timeline,
		engine.currentSeconds,
		engine.isPlayed,
		engine.playbackRate,
	]);

	useEffect(() => {
		const { current: slider } = sliderRef;
		const { current: track } = trackRef;

		if (slider?.parentElement && track) {
			slider.parentElement.style.transform = `translateX(${
				sliderShiftPx -
				(slider.clientWidth || 0) * (sliderShiftPx / (track.clientWidth || 1))
			}px)`;
		}
	}, [sliderShiftPx]);

	const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		engine.moveTimelinePointer({
			seconds: +value,
		});
	};

	return (
		<>
			<div className={cx('video', className)}>
				<canvas ref={canvasRef} className="video__output" />
				<SliderInput
					name="currentSeconds"
					value={engine.currentSeconds}
					max={totalDuration}
					onChange={handleSliderChange}
					type="float"
					className="video__slider"
					format={formatSeconds}
				/>
			</div>
			{typeof children === 'function' ? children(engine) : children}
		</>
	);
};

export const Video = observer(VideoComponent);
