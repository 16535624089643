export function splitArray<T>(
	arr: T[],
	options: { sectionCount?: number; itemsLength: number },
) {
	const { sectionCount, itemsLength } = options;

	return arr.reduce(
		(acc, item) => {
			if (acc.every((array) => array.length === itemsLength)) {
				if (sectionCount && acc.length === sectionCount) {
					return acc;
				}

				return [...acc, [item]];
			}
			const section = acc[acc.length - 1];

			acc[acc.length - 1] = section.concat(item);

			return acc;
		},
		[[]] as Array<T[]>,
	);
}
