import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { ITableColumn, ITableProps } from './table';

import './styles.scss';
import { Icon } from '../icon';
import { icons } from '../../assets/icons';

export interface ITableHead extends Pick<ITableProps, 'onSortClick' | 'order'> {
	columns: ITableColumn[];
}

export interface ITableHeadCell
	extends ITableColumn,
		Omit<ITableHead, 'columns'> {}

const TableHeadCell: FC<ITableHeadCell> = ({
	accessor,
	messageId,
	label,
	withSort,
	renderFilter,
	onSortClick,
	order,
}) => {
	const [isOver, setIsOver] = useState(false);

	const handleSortClick = useCallback<MouseEventHandler<HTMLDivElement>>(
		(e) => {
			if (!withSort) return;
			if (onSortClick) {
				onSortClick(accessor, e);
			}
		},
		[accessor, withSort, onSortClick],
	);

	const messageIdForRender = useMemo(
		() => messageId || `app.table.${accessor}`,
		[messageId, accessor],
	);

	const sortDirection = useMemo(
		() => order && order[accessor],
		[accessor, order],
	);

	const noSorting = useMemo(
		() => !onSortClick || !withSort,
		[onSortClick, withSort],
	);

	return (
		<th
			className={cx({
				'no-arrow': noSorting,
			})}
			data-accessor={accessor}
			onClick={handleSortClick}
			onMouseOver={() => setIsOver(true)}
			onMouseOut={() => setIsOver(false)}
		>
			<span className="table__head-title">
				<FormattedMessage id={messageIdForRender} defaultMessage={label} />
			</span>
			<div
				className="table__head-filters"
				onClick={(e) => e.stopPropagation()}
				onMouseOver={(e) => e.stopPropagation()}
				onMouseOut={(e) => e.stopPropagation()}
			>
				{renderFilter && renderFilter()}
			</div>
			{!noSorting && (
				<Icon
					className={cx(
						'table__head-sort',
						sortDirection
							? {
									[icons.SortAscending]: sortDirection === 'ASC',
									[icons.SortDescending]: sortDirection === 'DESC',
								}
							: isOver
								? icons.SortHover
								: icons.Sort,
					)}
				/>
			)}
		</th>
	);
};

const TableHeadComponent: FC<ITableHead> = ({ columns, ...restProps }) => (
	<thead className="table__head">
		<tr>
			{columns.map((column) => (
				<TableHeadCell
					key={`tablet_head${column.accessor}`}
					{...restProps}
					{...column}
				/>
			))}
		</tr>
	</thead>
);

export const TableHead = observer(TableHeadComponent);
