import jwtDecode from 'jwt-decode';

import {
	AUTH_REMEMBER_ME,
	AUTH_STORAGE,
	RESPONSE_CODE,
} from '_constants/index';

import { Api, AuthenticationPayload } from './api';
import { crossTabSessionStorage } from '../storage/cross-tab-session-storage';
import { appStore } from '../../stores/app';
import { toast } from '../toast';
import { handleError } from './handle-error';

export interface IJwtToken {
	exp: number;
	iat: number;
	sub: string;
}

/**
 * @param token JSON Web Token
 *
 * @returns IJwtToken
 * {
 *   exp - expired millis
 *   eat - 'monitor'
 *   sub - monitorId
 * }
 */
export function decodeToken(token: string): IJwtToken {
	return jwtDecode(token);
}

export function getAuthStorage(): AuthenticationPayload | null {
	const authStorage =
		sessionStorage.getItem(AUTH_STORAGE) || localStorage.getItem(AUTH_STORAGE);

	try {
		return authStorage
			? (JSON.parse(authStorage) as AuthenticationPayload)
			: null;
	} catch {
		sessionStorage.removeItem(AUTH_STORAGE);
		localStorage.removeItem(AUTH_STORAGE);

		return null;
	}
}

/**
 * @param token JSON Web Token
 *
 * @returns boolean true if expired token
 */
export function isExpiredToken(token: string) {
	try {
		const user = decodeToken(token);

		return Date.now() > user.exp * 1000;
	} catch (e) {
		// Possible error: Invalid token.
		return true; // Invalid token => expired token.
	}
}

/**
 * @returns { setToken: Function; cancelToken: Function; } used to set/cancel current session and logout.
 */
export function withJwt(swaggerApi: Api<unknown>) {
	let authStorage = getAuthStorage();

	const setToken = (payload: AuthenticationPayload) => {
		authStorage = payload;
		const Authorization = `${authStorage.type} ${authStorage.token}`;

		if (
			swaggerApi.instance.defaults.headers.common.Authorization !==
			Authorization
		) {
			swaggerApi.instance.defaults.headers.common.Authorization = Authorization;
		}

		crossTabSessionStorage.setItem(AUTH_STORAGE, JSON.stringify(authStorage));
		if (localStorage.getItem(AUTH_REMEMBER_ME)) {
			localStorage.setItem(AUTH_STORAGE, JSON.stringify(authStorage));
		}
	};

	const cancelToken = () => {
		authStorage = null;

		if (swaggerApi.instance.defaults.headers.common.Authorization) {
			delete swaggerApi.instance.defaults.headers.common.Authorization;
		}

		crossTabSessionStorage.removeItem(AUTH_STORAGE);
		localStorage.removeItem(AUTH_STORAGE);
	};

	const refreshToken = async () => {
		if (!authStorage) return;
		const { refreshToken } = authStorage;
		cancelToken();

		if (!isExpiredToken(refreshToken)) {
			const { data: authData } = await swaggerApi.api.refresh({
				refreshToken,
			});

			setToken(authData.payload);
		}
	};

	if (authStorage) {
		setToken(authStorage);
	}

	swaggerApi.instance.interceptors.request.use(async (config) => {
		if (!authStorage) {
			authStorage = getAuthStorage();

			if (!authStorage) {
				return config;
			}
		}

		if (isExpiredToken(authStorage.token)) {
			await refreshToken();
		}

		if (authStorage) {
			const Authorization = `${authStorage.type} ${authStorage.token}`;

			config.headers.set('Authorization', Authorization);
		}

		return config;
	});

	swaggerApi.instance.interceptors.response.use(
		undefined,
		async (error) => {
			if (error.response) {
				switch (error.response.status) {
					case 401:
						{
							if (
								[RESPONSE_CODE.unauthorized].includes(error.response.data?.code)
							) {
								appStore.navigate('/login');
								return error;
							}
						}
						break;

					case 403: {
						toast.error(handleError(error));
						return error;
					}
				}
			}

			return Promise.reject(error);
		},
		{},
	);

	return {
		setToken,
		cancelToken,
		refreshToken,
	};
}
