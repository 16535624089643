import {
	ChangeEvent,
	FC,
	FormEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

import { Layout } from 'components/common';
import { Form, Radio } from 'components/forms';
import { LinkPlaylistMonitor } from 'components/register-forms';
import {
	ITableCheckboxProps,
	ITableColumn,
	Table,
	TableCheckbox,
} from 'components/table';
import { DatePicker, RadioFilter, TextFilter } from 'components/filters';
import * as PlaylistListComponents from './list-components';
import * as MonitorListComponents from 'pages/monitors/list-components';

import { monitorsStore } from 'stores/monitors/monitor-list';
import { playlistsStore } from 'stores/playlists';
import { MONITOR_TABLE_COLUMNS } from '../monitors/constants';
import { MonitorStatus, PlaylistStatus } from 'utils/api/api';
import { formatAddress } from 'utils/formatters/format-address';
import { BidSendForm } from '../bids/bid-send';

import './styles.scss';
import { formatCurrency } from '../../utils';
import { IMonitorItem } from '../../_types/stores';
import { Icon } from '../../components/icon';
import { icons } from '../../assets/icons';

type Columns = 'name' | 'createdAt' | 'updatedAt' | 'status';

interface IFilterValues {
	name?: string;
	createdAt?: [Date, Date];
	updatedAt?: [Date, Date];
	status?: PlaylistStatus;
}

const MapMonitorsList: FC<{
	selectedMonitorIds: string[];
	handleCheckbox: NonNullable<ITableCheckboxProps['onChange']>;
}> = observer(({ selectedMonitorIds, handleCheckbox }) => {
	const intl = useIntl();

	useEffect(() => {
		void monitorsStore.getList();
	}, [monitorsStore.order]);

	const tableData = useMemo(
		() =>
			monitorsStore.listNoSubordinate.map((m) => ({
				...m,
				checkbox: (
					<TableCheckbox
						name="monitor__checkbox"
						itemId={m.id}
						itemIds={selectedMonitorIds}
						onChange={handleCheckbox}
						colorModifier={m.status === MonitorStatus.Online ? 'green' : 'dark'}
					/>
				),
				favorite: <MonitorListComponents.FavoriteIcon monitor={m} />,
				name: (
					<MonitorListComponents.NameWithIcon
						name={m.name}
						enabled={m.status === MonitorStatus.Online}
						type={m.multiple}
					/>
				),
				category: (
					<MonitorListComponents.CategoryOption
						id={m.id}
						category={m.category}
					/>
				),
				price1s: m.price1s
					? formatCurrency(m.price1s)
					: intl.formatMessage({ id: 'PriceFree' }),
				minWarranty: m.minWarranty || intl.formatMessage({ id: 'NoLimit' }),
				maxDuration: m.maxDuration || intl.formatMessage({ id: 'NoLimit' }),
				status: (
					<MonitorListComponents.StatusIcon
						id={m.id}
						enabled={m.status === MonitorStatus.Online}
					/>
				),
				coordinate: (
					<MonitorListComponents.Coordinate id={m.id} location={m.location} />
				),
				address: formatAddress(m.address),
				actions: (
					<MonitorListComponents.Actions id={m.id} multiple={m.multiple} />
				),
			})),
		[monitorsStore.listNoSubordinate, intl, selectedMonitorIds, handleCheckbox],
	);

	return (
		<Table
			className="monitor__table scroll map"
			columns={[{ accessor: 'checkbox', label: ' ' }, ...MONITOR_TABLE_COLUMNS]}
			data={tableData}
			onSortClick={monitorsStore.setOrder}
			order={monitorsStore.order}
		/>
	);
});

const MapPlaylistList: FC<{
	playlistId: string | null;
	handleRadio: (event: ChangeEvent<HTMLInputElement>) => void;
}> = observer(({ playlistId, handleRadio }) => {
	const [filterColumn, setFilterColumn] = useState<Columns | null>(null);
	const [filterValues, setFilterValues] = useState<IFilterValues>({});

	const intl = useIntl();

	useEffect(() => {
		void playlistsStore.getList(undefined, undefined, filterValues);
	}, [playlistsStore.order, filterValues]);

	const createToggleFilter = useCallback(
		(filterName: Columns) => {
			return () => {
				if (filterColumn === filterName) {
					setFilterColumn(null);

					return;
				}

				setFilterColumn(filterName);
			};
		},
		[filterColumn, setFilterColumn],
	);

	const createChangeHandler = useCallback(
		(key: Columns) => {
			return (value: unknown) => {
				setFilterColumn(null);

				if (value === null) {
					setFilterValues((values) => {
						const { [key]: deletedValue, ...otherValues } = values;

						return otherValues;
					});

					return;
				}

				setFilterValues((values) => ({
					...values,
					[key]: value,
				}));
			};
		},
		[setFilterValues],
	);

	const createCloseHandler = useCallback(
		(filterName: Columns) => {
			return () => {
				if (filterColumn === filterName) {
					setFilterColumn(null);
				}
			};
		},
		[filterColumn, setFilterColumn],
	);

	const calcToFilter = useMemo<
		Record<string, ITableColumn['renderFilter']>
	>(() => {
		return {
			name: () => (
				<OutsideClickHandler onOutsideClick={createCloseHandler('name')}>
					<Icon
						className={
							filterColumn === 'name'
								? icons.FilterNameActive
								: icons.FilterName
						}
						onClick={createToggleFilter('name')}
					/>
					<TextFilter
						isOpen={filterColumn === 'name'}
						placeholder={intl.formatMessage({
							id: 'Search name',
							defaultMessage: 'Search name',
						})}
						onSearch={createChangeHandler('name')}
					/>
				</OutsideClickHandler>
			),
			createdAt: () => (
				<OutsideClickHandler onOutsideClick={createCloseHandler('createdAt')}>
					<Icon
						className={
							filterColumn === 'createdAt'
								? icons.FilterDateActive
								: icons.FilterDate
						}
						onClick={createToggleFilter('createdAt')}
					/>
					<DatePicker
						isOpen={filterColumn === 'createdAt'}
						onSearch={createChangeHandler('createdAt')}
					/>
				</OutsideClickHandler>
			),
			updatedAt: () => (
				<OutsideClickHandler onOutsideClick={createCloseHandler('updatedAt')}>
					<Icon
						className={
							filterColumn === 'updatedAt'
								? icons.FilterDateActive
								: icons.FilterDate
						}
						onClick={createToggleFilter('updatedAt')}
					/>
					<DatePicker
						isOpen={filterColumn === 'updatedAt'}
						onSearch={createChangeHandler('updatedAt')}
					/>
				</OutsideClickHandler>
			),
			status: () => (
				<OutsideClickHandler onOutsideClick={createCloseHandler('status')}>
					<Icon
						className={
							filterColumn === 'status'
								? icons.FilterStatusActive
								: icons.FilterStatus
						}
						onClick={createToggleFilter('status')}
					/>
					<RadioFilter
						options={[
							{
								label: 'Is not used',
								value: PlaylistStatus.Offline,
							},
							{
								label: 'On the broadcast',
								value: PlaylistStatus.Broadcast,
							},
							{
								label: 'No broadcast',
								value: PlaylistStatus.NoBroadcast,
							},
						]}
						isOpen={filterColumn === 'status'}
						onSearch={createChangeHandler('status')}
					/>
				</OutsideClickHandler>
			),
		};
	}, [
		createCloseHandler,
		createToggleFilter,
		createChangeHandler,
		filterColumn,
		intl,
	]);

	const playlistsColumns = useMemo(() => {
		return playlistsStore.columns.map((c) =>
			typeof c === 'object'
				? {
						...c,
						renderFilter: calcToFilter[c.accessor],
					}
				: { accessor: c, renderFilter: calcToFilter[c] },
		);
	}, [calcToFilter]);

	const data = useMemo(() => {
		return playlistsStore.list?.length
			? playlistsStore.list.map((playlist) => ({
					...playlist,
					radio: (
						<Radio
							name="playlist_radio"
							data-id={playlist.id}
							checked={playlistId === playlist.id}
							onChange={handleRadio}
							label={<div className="form__radio-mask" />}
						/>
					),
					createdAt: intl.formatDate(playlist.createdAt),
					updatedAt: intl.formatDate(playlist.updatedAt),
					name: <PlaylistListComponents.NameWithIcon playlist={playlist} />,
					actions: <PlaylistListComponents.Actions playlist={playlist} />,
					status: <PlaylistListComponents.Status playlist={playlist} />,
				}))
			: [];
	}, [playlistsStore.list, playlistId, handleRadio, intl]);

	return (
		<Table
			className="playlists__table scroll map"
			columns={[{ accessor: 'radio', label: ' ' }, ...playlistsColumns]}
			data={data}
			onSortClick={playlistsStore.setOrder}
			order={playlistsStore.order}
		/>
	);
});

const MapPlaylistMonitorList: FC = () => {
	const [open, setOpen] = useState(false);
	const [monitorIds, setMonitorIds] = useState<Array<IMonitorItem['id']>>([]);
	const [playlistId, setPlaylistId] = useState<string | null>(null);

	const disabled = useMemo(
		() => !(playlistId && monitorIds),
		[playlistId, monitorIds],
	);

	const handleMonitorCheckbox = useCallback<ITableCheckboxProps['onChange']>(
		(checked, mId) =>
			setMonitorIds((state) => {
				const filtered = state.filter((id) => id !== mId);
				if (checked) {
					return filtered.concat(mId);
				}
				return filtered;
			}),
		[setMonitorIds],
	);
	const handlePlaylistRadio = useCallback(
		(event: ChangeEvent<HTMLInputElement>) =>
			runInAction(() => {
				setPlaylistId(event.target.dataset.id as string);
			}),
		[],
	);
	const handleSubmit = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			event.stopPropagation();

			if (!disabled) {
				setOpen(true);
			}
		},
		[setOpen, disabled],
	);

	return (
		<Layout>
			<MapMonitorsList
				selectedMonitorIds={monitorIds}
				handleCheckbox={handleMonitorCheckbox}
			/>
			<Form onSubmit={handleSubmit} noPadding>
				<LinkPlaylistMonitor disabled={!(monitorIds.length && playlistId)} />
			</Form>
			<MapPlaylistList
				playlistId={playlistId}
				handleRadio={handlePlaylistRadio}
			/>
			{open && (
				<BidSendForm
					playlistId={playlistId}
					monitorIds={monitorIds}
					onOutsideClick={() => setOpen(false)}
				/>
			)}
		</Layout>
	);
};

export default observer(MapPlaylistMonitorList);
