import React, {
	ChangeEvent,
	Dispatch,
	FocusEventHandler,
	FormEvent,
	FormHTMLAttributes,
	KeyboardEvent,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import deepEqual from 'deep-equal';
import { errorStore } from '../stores/error-store';
import { PARAMS_SEPARATOR } from '../_constants';

export type TFormStatus =
	| 'submitProcess'
	| 'resetProcess'
	| 'wasSubmit'
	| 'initial';
export type TCallbackReturnType<T = boolean | void> = Promise<T> | T;
export type TSetFields<T> = (
	partial: Partial<T>,
	e?: ChangeEvent<HTMLFormElement>,
) => void;
export type TSetField<T> = <K extends keyof T>(
	name: K,
	value: T[K],
	e?: ChangeEvent<HTMLFormElement>,
) => void;
export type TFormChangeHandler<T> = (
	newState: T,
	state: T,
	names: Array<keyof T>,
	e?: ChangeEvent<HTMLFormElement>,
) => Partial<T> | void;
export type TFormBlurHandler<T> = (
	state: T,
	names: Array<keyof T | string>,
	e?: React.FocusEvent<HTMLFormElement>,
) => Partial<T> | void;

export interface IRequiredItem<T, N extends keyof T = keyof T> {
	name: N;

	check(value: T[N]): boolean;
}

export interface IUseFormSetter<T> {
	setField: TSetField<T>;
	setFields: TSetFields<T>;
	setStatus: Dispatch<SetStateAction<TFormStatus>>;
	setValues: Dispatch<SetStateAction<T>>;
}

export interface IUseFormOptions<T> {
	onSubmit: (values: T, setter: IUseFormSetter<T>) => TCallbackReturnType;
	onBlur: TFormBlurHandler<T>;
	onChange: TFormChangeHandler<T>;
	onReset: (values: T, initial: T) => TCallbackReturnType<T | void>;
	required: Array<keyof T | IRequiredItem<T>>;
	disableDeepEqual: boolean;
	disableCast: boolean | Array<keyof T>;
	parsers: Partial<
		Record<
			keyof T | string,
			(
				value: unknown,
				values: T,
				e: ChangeEvent<HTMLFormElement>,
			) => Partial<T> | void
		>
	>;
}

export interface IUseFormReturnTypeGetter<T> {
	values: T;
	formStatus: TFormStatus;
	formProps: FormHTMLAttributes<HTMLFormElement>;
	canSubmit: boolean;
}

export interface IUseFormReturnTypeSetter<T> extends IUseFormSetter<T> {
	submit(): void;

	reset(): void;
}

export function useForm<T>(
	initial: T,
	options: Partial<IUseFormOptions<T>> = {},
): [IUseFormReturnTypeGetter<T>, IUseFormReturnTypeSetter<T>] {
	const [values, setValues] = useState(initial);
	const [status, setStatus] = useState<TFormStatus>('initial');

	const noEqual = useMemo(
		() =>
			options.disableDeepEqual || !deepEqual(initial, values, { strict: true }),
		[initial, values, options.disableDeepEqual],
	);

	const setFields = useCallback<TSetFields<T>>(
		(partialState, e) => {
			const names = Object.keys(partialState);
			setValues((state) => {
				let newState: T = {
					...state,
					...partialState,
				};

				names
					.filter((name) => newState[name] === undefined)
					.forEach((name) => {
						delete newState[name];
					});

				if (options.onChange) {
					const partialState = options.onChange(newState, state, names, e);
					if (partialState) {
						newState = {
							...newState,
							...partialState,
						};
					}
				}

				return newState;
			});
			errorStore.takeOff(names);
		},
		[options.onChange, setValues],
	);

	const setField = useCallback<TSetField<T>>(
		(name, value, e) => {
			const partial: Partial<T> = {};
			partial[name] = value;
			setFields(partial, e);
		},
		[setFields],
	);

	const canSubmit = useMemo(() => {
		if (!['wasSubmit', 'initial'].includes(status)) {
			return false;
		}

		if (options.required) {
			return (
				noEqual &&
				options.required.every((item) => {
					if (typeof item === 'object') {
						return item.check(values[item.name]);
					}
					const value = values[item];

					switch (typeof value) {
						case 'string':
							return Boolean(value);

						case 'number':
							return !isNaN(value);

						default:
							return true;
					}
				})
			);
		}

		return noEqual;
	}, [noEqual, options.required, values]);

	const submit = useCallback(async () => {
		if (!canSubmit) return;
		setStatus('submitProcess');
		let preventStatusChanges: TCallbackReturnType = false;
		if (options.onSubmit) {
			preventStatusChanges = await options.onSubmit(
				{ ...values },
				{ setField, setFields, setStatus, setValues },
			);
		}
		if (!preventStatusChanges) {
			setStatus('wasSubmit');
		}
	}, [values, canSubmit, options]);

	const reset = useCallback<IUseFormReturnTypeSetter<T>['reset']>(async () => {
		setStatus('resetProcess');
		if (options.onReset) {
			const newState = await options.onReset(values, initial);
			setValues(newState || initial);
		} else {
			setValues(initial);
		}
		setStatus('initial');
		errorStore.reset();
	}, [initial, values, errorStore.reset, options.onReset]);

	const castValue = useCallback(
		<K extends keyof T>(name: K, value: unknown) => {
			if (
				Array.isArray(options.disableCast)
					? options.disableCast.some((n) => n === name)
					: options.disableCast
			) {
				return value;
			}
			if (value === 'true') {
				return true;
			} else if (value === 'false') {
				return false;
			} else if (value === 'undefined') {
				return undefined;
			}
			const validValue = values[name];
			const targetType = Array.isArray(validValue)
				? 'array'
				: typeof values[name];
			const valueType = typeof value;

			if (valueType !== targetType) {
				const parseJson = () => {
					try {
						const parsedValue = JSON.parse(value as string);
						if (parsedValue) {
							value = parsedValue;
						}
						return true;
					} catch {
						return false;
					}
				};

				const parseNumber = () => {
					const number = Number(value);
					const isNumber = !isNaN(number);
					if (isNumber) {
						value = number;
					}
					return isNumber;
				};

				const parseDate = () => {
					if (valueType === 'string' || valueType === 'number') {
						const date = new Date(value as string | number);
						const isDate = !isNaN(date.valueOf());
						if (isDate) {
							value = date;
						}
						return isDate;
					}
				};

				if (validValue instanceof Date) {
					parseDate();
				} else {
					if (valueType === 'string') {
						switch (targetType) {
							case 'array':
								{
									const isJson = parseJson();
									if (!isJson) {
										value = (value as string).split(PARAMS_SEPARATOR);
									}
								}
								break;
							case 'object':
								{
									parseJson();
								}
								break;
							default:
								parseJson() || parseNumber() || parseDate();
						}
					} else {
						switch (targetType) {
							case 'number':
								parseNumber();
								break;

							case 'boolean':
								value = Boolean(value);
								break;
						}
					}
				}
			}

			return value as T[K];
		},
		[values, options.disableCast],
	);

	const onChange = useCallback(
		(e: ChangeEvent<HTMLFormElement>) => {
			e.stopPropagation();
			const { name, value, checked, type } = e.target;
			const parse = options.parsers && options.parsers[name];

			if (parse) {
				const partialState = parse(value, values, e);

				if (partialState) {
					if (errorStore.isErrorName(name)) {
						errorStore.takeOff(name);
					}
					setFields(partialState, e);
				}
			} else {
				setField(
					name as keyof T,
					type === 'checkbox' ? checked : castValue(name as keyof T, value),
					e,
				);
			}
		},
		[
			castValue,
			setField,
			setFields,
			options.parsers,
			values,
			errorStore.errors,
		],
	);

	const onBlur = useCallback<FocusEventHandler<HTMLFormElement>>(
		(e) => {
			const names = [];
			const { name } = e.target;

			if (name) {
				names.push(name);
			}

			if (options.onBlur) {
				const partialState = options.onBlur(values, names, e);

				if (partialState) {
					setFields(partialState);
				}
			}
		},
		[values],
	);

	const onSubmit = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			if (options.onSubmit) {
				e.stopPropagation();
			}
		},
		[options.onSubmit],
	);

	const onReset = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			if (options.onReset) {
				e.stopPropagation();
			}
		},
		[options.onReset],
	);

	const onKeyDown = useCallback((e: KeyboardEvent<HTMLFormElement>) => {
		switch (e.code) {
			case 'Enter': {
				e.preventDefault();
				void submit();
			}
		}
	}, []);

	useEffect(() => {
		errorStore.reset();
		return () => {
			errorStore.reset();
		};
	}, []);

	return [
		{
			values,
			formProps: {
				onBlur,
				onSubmit,
				onChange,
				onReset,
				onKeyDown,
			},
			canSubmit,
			formStatus: status,
		},
		{ setField, setFields, setValues, setStatus, submit, reset },
	];
}

export function parseValueAsNumber(
	value: unknown,
	rule: Partial<{ min: number; max: number; step: number }> = {},
	options: Partial<{ noZero: boolean }> = {},
) {
	if (typeof value !== 'string' && typeof value !== 'number') {
		return value;
	}
	const { noZero = true } = options;
	const { min = -Infinity, max = Infinity, step } = rule;
	let number = +value;

	if (isNaN(number)) return;

	if (step && !isNaN(number) && number / step) {
		number = Math.floor(number - (number % step));
	}

	switch (true) {
		case noZero && number === 0:
			return undefined;
		case number < min:
			return min;
		case number > max:
			return max;
		default:
			return number;
	}
}
