import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { authStore } from 'stores/auth';
import { runInAction } from 'mobx';
import { RouteLink } from '../../modules/routing-module';

import './not-found.scss';
import { Icon } from '../../components/icon';
import { icons } from '../../assets/icons';
import cx from 'classnames';

const NotFound: FC = () => {
	useEffect(() => {
		runInAction(() => {
			authStore.isNotFound = true;
		});

		return () => {
			runInAction(() => {
				authStore.isNotFound = false;
			});
		};
	}, []);

	return (
		<div className="not-found">
			<Icon className={cx('not-found__icon', icons.ErrorPage)} size={100} />
			<h1 className="main__title">
				404{' '}
				<FormattedMessage
					id="PAGE NOT FOUND"
					defaultMessage="Страница не найдена"
				/>
			</h1>
			<RouteLink className="button button-secondary" routeKey="Home">
				<FormattedMessage id="Home page" defaultMessage="Главная страница" />
			</RouteLink>
		</div>
	);
};

export default observer(NotFound);
