import { action, makeObservable, observable } from 'mobx';
import { IEditorProjectsStore } from '_types/stores';
import { appStore } from 'stores/app';
import { handleError, swaggerApi, toast } from 'utils';
import { ListOrder } from 'utils/api/components';

class EditorProjects extends ListOrder implements IEditorProjectsStore {
	@observable list: IEditorProjectsStore['list'] = [];
	@observable error: string | null = null;

	constructor() {
		super();
		makeObservable(this);
	}

	@action getList: IEditorProjectsStore['getList'] = async () => {
		appStore.isLoading = true;

		try {
			const { data: editorData } = await this.getSortedList(
				swaggerApi.api.editorsGet,
				{},
			);
			this.list = editorData.data;

			return this.list;
		} catch (error) {
			toast.error(handleError(error));

			return this.list;
		} finally {
			appStore.isLoading = false;
		}
	};
}

export const editorProjectsStore = new EditorProjects();
