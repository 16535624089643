import { ListOrder } from 'utils/api/components';
import { IInvoicesStore } from '_types/stores/invoices';
import { downloadFile, handleError, swaggerApi, toast } from 'utils';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { appStore } from '../app';
import { authStore, REQUIRED_DETAILS_NAMES, REQUIRED_NAMES } from '../auth';
import {
	InvoiceResponse,
	InvoiceStatus,
	SpecificFormat,
	UserRole,
} from 'utils/api/api';
import {
	checkRequestData,
	RequestError,
} from '../../utils/api/check-request-data';
import { errorStore } from '../error-store';
import { AxiosRequestConfig } from 'axios';
import { explorerStore } from '../media';
import { taskStore } from '../../modules/task-manager';

export class InvoicesStore extends ListOrder implements IInvoicesStore {
	@observable invoiceFormat: IInvoicesStore['invoiceFormat'] = {};
	@observable list: IInvoicesStore['list'] = [];
	@observable error: string | null = null;

	constructor() {
		super();
		makeObservable(this);
	}

	static readableNameFromInvoice(invoice: InvoiceResponse) {
		const dateString = invoice.updatedAt || invoice.createdAt;
		const date = dateString ? new Date(dateString) : new Date();

		return `MyScreen - Счет на оплату №${invoice.seqNo} от ${Intl.DateTimeFormat(
			'ru-RU',
			{
				dateStyle: 'long',
			},
		).format(date)} на сумму ${Intl.NumberFormat('ru-RU', {
			style: 'currency',
			currency: 'RUB',
		}).format(invoice.sum)}`;
	}

	@action setInvoiceFormat(
		invoiceId: InvoiceResponse['id'],
		format: SpecificFormat,
	) {
		this.invoiceFormat[invoiceId] = format;
	}

	@action getList: IInvoicesStore['getList'] = async (data) => {
		try {
			appStore.isLoading = true;

			const { data: invoicesData } = await this.getSortedList(
				swaggerApi.api.invoicesGet,
				{
					...data,
				},
			);
			this.list = invoicesData.data;

			return this.list;
		} catch (error) {
			toast.error(handleError(error));

			return this.list;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action create: IInvoicesStore['create'] = async (sum) => {
		try {
			appStore.isLoading = true;

			if (
				authStore.user &&
				checkRequestData(authStore.user, [
					...REQUIRED_NAMES,
					...REQUIRED_DETAILS_NAMES,
				])
			) {
				await swaggerApi.api.invoiceCreate({
					sum,
					description: String(authStore.user.company),
				});
			}
		} catch (e) {
			if (e instanceof RequestError) {
				errorStore.setErrors(e.fieldNames);
			} else {
				toast.error(handleError(e));
			}
		} finally {
			appStore.isLoading = false;
		}
	};

	@action.bound confirm: IInvoicesStore['confirm'] = async ({ id, status }) => {
		if (
			authStore.restrictFrom([UserRole.MonitorOwner, UserRole.Advertiser]) &&
			status === InvoiceStatus.AWAITING_CONFIRMATION
		) {
			try {
				const { data: invoiceData } = await swaggerApi.api.invoiceConfirmed(id);
				const invoice = invoiceData.data;

				this.list = this.list.map((item) => {
					if (item.id === invoice.id) {
						return invoice;
					}
					return item;
				});
			} catch (e) {
				toast.error(handleError(e));
			}
		}
	};

	@action.bound confirmPayment: IInvoicesStore['confirmPayment'] = async (
		invoice,
	) => {
		if (authStore.restrictFrom([UserRole.MonitorOwner, UserRole.Advertiser])) {
			try {
				await swaggerApi.api.invoicePayed(invoice.id);

				this.list.forEach((item) => {
					if (item.id === invoice.id) {
						runInAction(() => {
							item.status = InvoiceStatus.PAID;
						});
					}
				});
			} catch (e) {
				toast.error(handleError(e));
			}
		}
	};

	async downloadOrigin(invoice: InvoiceResponse, format = SpecificFormat.Xlsx) {
		const fileName = `${InvoicesStore.readableNameFromInvoice(invoice)}.${format}`;
		const task = taskStore.createTask({
			id: invoice.id,
			showProgress: true,
			message: appStore.intl.formatMessage(
				{
					id: 'Download file',
					defaultMessage: 'Скачиваем "{name}"',
				},
				{ name: fileName },
			),
		});

		try {
			const response = await swaggerApi.api.invoiceDownload(
				invoice.id,
				format,
				{
					format: 'blob',
					timeout: 0,
					onDownloadProgress: ({ total, loaded }) => {
						if (total) {
							task.update({ percent: Math.floor((loaded * 100) / total) });
						}
					},
				},
			);

			task.success(
				appStore.intl.formatMessage(
					{
						id: 'InvoiceOriginDownloadSuccess',
						defaultMessage: 'Скачан сгенерированный файл "{name}".',
					},
					{ name: fileName },
				),
			);

			return downloadFile(
				window.URL.createObjectURL(response.data as unknown as Blob),
				fileName,
			);
		} catch (e) {
			task.fail(
				appStore.intl.formatMessage(
					{
						id: 'Download Error',
						defaultMessage: 'Ошибка при скачивании файла "{name}"',
					},
					{ name: fileName },
				),
			);
			toast.error(handleError(e));
		}
	}

	@action
	async upload(
		id: InvoiceResponse['id'],
		file: File,
		axiosProps?: AxiosRequestConfig,
	) {
		const uploadResponse = await explorerStore.uploadFiles(
			[file],
			{
				invoiceId: id,
			},
			axiosProps,
		);

		if (uploadResponse) {
			const invoice = uploadResponse.data as InvoiceResponse;

			if (invoice.file?.name === file.name) {
				this.list = this.list.map((inv) => {
					if (inv.id === invoice.id) {
						return invoice;
					}
					return inv;
				});
			} else {
				toast.error(
					appStore.intl.formatMessage(
						{
							id: 'UploadInvoiceFileNameError',
							defaultMessage:
								'Имя файла "{fileName}" было обработано некорректно "{errorName}". Пожалуйста, обратитесь к администратору.',
						},
						{ fileName: file.name, errorName: invoice.file?.name },
					),
				);
			}
		}

		return this.list;
	}
}

export const invoicesStore = new InvoicesStore();
