import { FC, HTMLAttributes } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { authStore } from 'stores/auth';
import { UserPlan } from 'utils/api/api';
import { DemoBar } from '../demo-bar';
import { Sidebar } from '../sidebar';
import { Header } from '../header';
import { appStore } from 'stores/app';

import './main.scss';

export const Main: FC<HTMLAttributes<HTMLDivElement>> = observer(
	({ children, className, ...restProps }) => {
		return (
			<div
				{...restProps}
				className={cx('main', className, {
					'sidebar-open': appStore.layout.sidebarOpen,
					'main--with-demobar': authStore.user?.plan === UserPlan.DEMO,
				})}
			>
				<DemoBar className="main__demobar" />
				<Header className="main__header" />
				{appStore.isCreatedFor('default') && (
					<Sidebar className="main__sidebar" />
				)}
				<div className="main__content">{children}</div>
			</div>
		);
	},
);
