import cx from 'classnames';

import { toast } from 'utils';

import './styles.scss';
import {
	DetailedHTMLProps,
	FC,
	FormEvent,
	FormEventHandler,
	FormHTMLAttributes,
} from 'react';

export interface IFormProps
	extends DetailedHTMLProps<
		FormHTMLAttributes<HTMLFormElement>,
		HTMLFormElement
	> {
	noPadding?: boolean;
	validate?: () => { 'success': boolean; 'errorText'?: string };
	onSubmitFailure?: FormEventHandler<HTMLFormElement>;
}

export const Form: FC<IFormProps> = ({
	onSubmit,
	onSubmitFailure,
	className,
	noPadding,
	children,
	validate,
	...restProps
}) => {
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const target = e.target as HTMLFormElement;

		for (const i of target.elements) {
			const item = i as HTMLInputElement;

			if (item.willValidate && !item.checkValidity()) {
				if (onSubmitFailure) {
					onSubmitFailure(e);
				}

				toast.warning(`${item.validationMessage}: ${item.name}`);

				return;
			} else if (validate) {
				const checkValid = validate();
				if (!checkValid.success) {
					if (onSubmitFailure) {
						onSubmitFailure(e);
					}

					toast.error(checkValid.errorText || '');

					return;
				}
			}
		}

		if (onSubmit) {
			onSubmit(e);
		}
	};

	return (
		<form
			className={cx('form', className, noPadding && 'form-no-padding')}
			onSubmit={handleSubmit}
			noValidate
			{...restProps}
		>
			{children}
		</form>
	);
};
