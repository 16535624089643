import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { observer } from 'mobx-react-lite';

import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import { constants } from './constants';
import { bidsStore } from 'stores/bids';
import { BidApprove, BidRequest } from 'utils/api/api';
import { formatAddress } from 'utils/formatters/format-address';

import { Layout } from 'components/common';
import { Checkbox, Form } from 'components/forms';
import { Table } from 'components/table';
import { BidsWindow } from './bids-window';

import './styles.scss';
import { formatCurrency } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';

export const BidsComponent: FC = () => {
	const intl = useIntl();

	const { bidId } = useParams<{ bidId?: string }>();
	const navigate = useNavigate();

	const bid = useMemo(
		() => bidId && bidsStore.list.find((b) => b.id === bidId),
		[bidsStore.list, bidId],
	);
	const [listWhereState, setListWhereState] = useState<BidRequest>({});

	const closeBidsWindow = useCallback(() => navigate('/bids'), []);

	const data = useMemo(
		() =>
			bidsStore.list.map((bid) => ({
				...bid,
				createdAt: intl.formatDate(bid.createdAt),
				status: (
					<span
						className={cx({
							'bids__status-ok': bid.approved === 'Allowed',
						})}
					>
						{intl.formatMessage({ id: bid.approved })}
					</span>
				),
				sum: formatCurrency(bid.sum),
				period: (
					<span>
						<FormattedDate value={bid.dateWhen} />
						{' -> '}
						{bid.dateBefore ? (
							<FormattedDate value={bid.dateBefore} />
						) : (
							<FormattedMessage id="NoLimit" />
						)}
					</span>
				),
				monitor: <span className="bids__monitor">{bid.monitor.name}</span>,
				address: <span>{formatAddress(bid.monitor.address)}</span>,
				playlist: (
					<button
						className="bids__playlist"
						onClick={() => navigate(`/bids/${bid.id}`)}
					>
						<FormattedMessage id="View" defaultMessage="View" />
					</button>
				),
				playlistChange: (
					<FormattedMessage id={bid.playlistChange ? 'ForcePlay' : 'ToQueue'} />
				),
				actions: (
					<>
						<button
							className="bids__confirm"
							title={intl.formatMessage({
								id: 'Confirm',
								defaultMessage: 'Confirm',
							})}
							onClick={() => bidsStore.decide(bid, BidApprove.Allowed)}
							disabled={bid.approved !== BidApprove.NotProcessed}
						/>
						<button
							className="bids__deny"
							title={intl.formatMessage({
								id: 'Dismiss',
								defaultMessage: 'Dismiss',
							})}
							onClick={() => bidsStore.decide(bid, BidApprove.Denied)}
							disabled={bid.approved !== BidApprove.NotProcessed}
						/>
					</>
				),
			})),
		[intl, bidsStore.list, bidsStore.decide],
	);

	const handleFilterFormChange = useCallback(
		(e: ChangeEvent<HTMLFormElement>) => {
			setListWhereState((state) => {
				const { name, value, checked } = e.target;
				const newStateData: Partial<typeof state> = {};

				if (name === 'approved') {
					newStateData.approved = state.approved?.filter((v) => v !== value);

					if (checked) {
						newStateData.approved = (newStateData.approved || []).concat(value);
					}

					if (!newStateData.approved?.length) {
						delete state.approved;
						delete newStateData.approved;
					}
				}

				return {
					...state,
					...newStateData,
				};
			});
		},
		[setListWhereState],
	);

	useEffect(() => {
		void bidsStore.getList({ where: listWhereState });
	}, [bidsStore.order, listWhereState]);

	return (
		<Layout>
			<h1 className="main__title">
				<FormattedMessage id="app.route.Bids" defaultMessage="Заявки" />
			</h1>
			{bid && <BidsWindow bid={bid} onClose={closeBidsWindow} />}
			<Form
				onChange={handleFilterFormChange}
				name="bids-filter"
				className="bids__categories"
			>
				{[BidApprove.Allowed, BidApprove.NotProcessed, BidApprove.Denied].map(
					(value) => {
						return (
							<div key={value} className={cx('bids__categories-item', value)}>
								<Checkbox
									name="approved"
									colorModifier={
										BidApprove.Allowed === value ? 'green' : 'gray'
									}
									label={intl.formatMessage({
										id: value,
										defaultMessage: 'Archived',
									})}
									value={value}
									checked={listWhereState.approved?.includes(value)}
									onChange={() => null}
								/>
							</div>
						);
					},
				)}
			</Form>
			<Table
				className="bids__table scroll"
				columns={constants.columns}
				data={data}
				onSortClick={bidsStore.setOrder}
				order={bidsStore.order}
			/>
		</Layout>
	);
};

export const Bids = observer(BidsComponent);
