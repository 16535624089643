import { FC, Fragment, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { IMonitorItem } from '_types/stores';
import { categoryOptions } from './constants';

import { monitorsStore } from 'stores/monitors/monitor-list';

import IconConditionOff from 'assets/icons/item-status-off.svg';
import IconConditionOn from 'assets/icons/item-status-on.svg';
import IconMonitorDelete from 'assets/icons/monitor-delete.svg';
import IconMonitorEdit from 'assets/icons/monitor-edit.svg';
import FavoriteFill from 'assets/icons/favorite-fill.svg';
import FavoriteColor from 'assets/icons/favorite-color.svg';

import './styles.scss';
import { MonitorMultiple } from 'utils/api/api';
import { icons } from '../../assets/icons';
import { Icon } from '../../components/icon';
import cx from 'classnames';

/**
 * Todo: icon change logic
 */
export const NameWithIcon: FC<{
	name: string;
	enabled: boolean;
	type: MonitorMultiple | undefined;
}> = ({ name, enabled, type = MonitorMultiple.SINGLE }) => {
	let iconClassName: string, iconSize: number | undefined;

	switch (type) {
		case MonitorMultiple.MIRROR:
			{
				iconClassName = enabled
					? icons.MonitorsGroupSyncGreen
					: icons.MonitorsGroupSync;
				iconSize = 30;
			}
			break;

		case MonitorMultiple.SCALING:
			{
				iconClassName = enabled
					? icons.MonitorsGroupWallGreen
					: icons.MonitorsGroupWall;
			}
			break;

		default:
			{
				iconClassName = enabled ? icons.MonitorOn : icons.MonitorOff;
			}
			break;
	}

	return (
		<span>
			<Icon
				className={cx('table__icon table__icon-name', iconClassName)}
				size={iconSize}
			/>
			{name}
		</span>
	);
};

export const CategoryOption: FC<{
	id: string;
	category: IMonitorItem['category'];
}> = ({ id, category }) => {
	const cOption = useMemo(
		() => categoryOptions.find((cOption) => cOption === category),
		[category],
	);

	return cOption ? (
		<FormattedMessage
			id={cOption}
			defaultMessage={cOption}
			key={`table_row${id}_name_category`}
		/>
	) : (
		<span key={`table_row${id}_name_category`}>--</span>
	);
};

export const FavoriteIcon: FC<{ monitor: IMonitorItem }> = observer(
	({ monitor }) => {
		const handleClick = useCallback(() => {
			void monitorsStore.favoriteToggle(monitor.id, monitor.favorite);
		}, [monitor.id, monitor.favorite]);

		return monitor.favorite ? (
			<img
				src={FavoriteColor}
				alt="❤"
				className="table__icon table__icon-favorite"
				onClick={handleClick}
			/>
		) : (
			<img
				src={FavoriteFill}
				alt="❤"
				className="table__icon table__icon-favorite"
				onClick={handleClick}
			/>
		);
	},
);

export const StatusIcon: FC<{
	id: string;
	enabled: boolean;
	hideText?: boolean;
}> = ({ id, enabled, hideText }) => (
	<Fragment key={`table_row${id}_status_img`}>
		<img
			src={enabled ? IconConditionOn : IconConditionOff}
			alt="Monitor condition"
			className="table__icon table__icon-condition"
		/>
		{hideText || (enabled ? 'On' : 'Off')}
	</Fragment>
);

export const Coordinate: FC<Pick<IMonitorItem, 'id' | 'location'>> = ({
	location,
}) => {
	const coordsString = useMemo(
		() => (location ? location.coordinates.join(', ') : ''),
		[location],
	);

	return <>{coordsString}</>;
};

export const Actions: FC<Pick<IMonitorItem, 'id' | 'multiple'>> = ({
	id,
	multiple,
}) => {
	const monitorEditPath = useMemo(
		() =>
			monitorsStore.isGroup(multiple)
				? `/monitors/group/${id}`
				: `/monitors/edit/${id}`,
		[multiple, id],
	);

	return (
		<div className="row">
			<Link to={monitorEditPath}>
				<img
					src={IconMonitorEdit}
					alt="Monitor edit"
					className="table__icon table__icon__action"
				/>
			</Link>
			<Link to={`/monitors/delete/${id}`}>
				<img
					src={IconMonitorDelete}
					alt="Monitor delete"
					className="table__icon table__icon__action"
				/>
			</Link>
		</div>
	);
};
