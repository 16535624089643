import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';

const useDynamicHeight = <T>(state: boolean): [RefObject<T>, number] => {
	const ref = useRef<T>(null);
	const [value, setValue] = useState('auto');
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [initialValue, setInitialValue] = useState(0);

	useLayoutEffect(() => {
		if (ref && ref.current !== null) {
			if (initialValue === 0) {
				setInitialValue((ref.current as unknown as HTMLElement).offsetHeight);
			}
			setValue(
				`${(ref.current as unknown as HTMLElement).scrollHeight as number}px`,
			);
		}
	}, [ref, initialValue]);
	useEffect(() => {
		if (ref && ref.current !== null) {
			(ref.current as unknown as HTMLElement).style.maxHeight = state
				? value
				: `${initialValue}px`;
		}
	}, [ref, state, value, initialValue]);

	return [ref, initialValue];
};
export default useDynamicHeight;
