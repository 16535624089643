import { appStore } from 'stores/app';
import { toast } from '../toast';
import { IntlShape } from 'react-intl';

export type TConditionErrorMessage = string;
export type TRequestCondition<T, K extends keyof T = keyof T> =
	| K
	| {
			key: K;
			validate: (
				value: T[K],
				intl: IntlShape,
			) => boolean | TConditionErrorMessage;
	  };
export type TRequestErrorField<T, K extends keyof T = keyof T> =
	| K
	| { key: K; message: TConditionErrorMessage };
export type TRequestErrorFields<T = string> = TRequestErrorField<T>[];

export class RequestError<T> extends Error {
	public name = 'RequestError';
	fieldNames: Array<keyof T> = [];
	errorFields: TRequestErrorFields<T> = [];

	constructor(
		errorFields: TRequestErrorFields<T>,
		message = appStore.intl.formatMessage({
			id: 'RequiredFields',
			defaultMessage: 'Заполните обязательные поля',
		}),
	) {
		message = [
			message,
			errorFields
				.filter(
					(errorField): errorField is keyof T => typeof errorField === 'string',
				)
				.map((fieldName) => {
					return `"${appStore.intl.formatMessage({
						id: `app.field.${String(fieldName)}`,
					})}"`;
				})
				.join(', '),
		].join(': ');
		super(message);
		this.errorFields = errorFields;
		this.fieldNames = this.errorFields.map((f) =>
			typeof f === 'object' ? f.key : f,
		);
	}

	notify() {
		if (this.errorFields.filter((f) => typeof f === 'string').length) {
			toast.error(this.toString());
		}

		this.errorFields
			.reduce((acc, errorField) => {
				if (
					typeof errorField === 'object' &&
					!acc.includes(errorField.message)
				) {
					return acc.concat(errorField.message);
				}
				return acc;
			}, [] as string[])
			.forEach((m) => toast.error(m));
	}
}

export function checkRequestData<T>(
	data: T,
	conditions: Array<TRequestCondition<T>>,
) {
	const errorFields: TRequestErrorFields<T> = conditions
		.map((condition) => {
			const key = typeof condition === 'object' ? condition.key : condition;
			const value = data[key];

			if (typeof condition === 'object') {
				const isValid = condition.validate(value, appStore.intl);
				if (isValid !== true) {
					if (typeof isValid === 'string') {
						return {
							message: isValid,
							key,
						};
					}
					return key;
				}
			} else if (!value) {
				return key;
			}

			return null;
		})
		.filter((errorField): errorField is TRequestErrorField<T> =>
			Boolean(errorField),
		);

	if (errorFields.length) {
		throw new RequestError<T>(errorFields);
	}

	return data;
}
