import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { RouteLink } from 'modules/routing-module';

import { Button } from 'components/common';
import { Form, Input, InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import './styles.scss';

const ResetPasswordComponent: FC = () => (
	<AuthWrapper
		title="Reset Password"
		subtitle="Enter your email to reset your password"
	>
		<Form onSubmit={authStore.resetPassword}>
			<Input
				type="email"
				name="email"
				value={authStore.email}
				onChange={authStore.setField}
				placeholder={
					<FormattedMessage id="Email Address" defaultMessage="Email Address" />
				}
				fieldClass="auth__reset-field"
				required
			/>
			<InputField>
				<Button type="submit" primary className="auth__button">
					<FormattedMessage
						id="Reset Password"
						defaultMessage="Reset Password"
					/>
				</Button>
			</InputField>
			<InputField>
				<RouteLink routeKey="Login" className="link auth__link">
					<FormattedMessage id="Back to login" defaultMessage="Back to login" />
				</RouteLink>
			</InputField>
		</Form>
	</AuthWrapper>
);

export const ResetPassword = observer(ResetPasswordComponent);
