import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { IInputProps, Input } from './input';

import './styles.scss';

export interface ICheckboxProps
	extends Omit<IInputProps, 'type' | 'label' | 'placeholder'> {
	label?: ReactNode;
	colorModifier?: 'green' | 'dark' | 'gray';
	sizeModifier?: 'small' | 'big';
	className?: string;
}

export const Checkbox: FC<ICheckboxProps> = ({
	label = ' ',
	colorModifier = 'green',
	sizeModifier = 'big',
	className,
	...props
}) => (
	<Input
		{...props}
		type="checkbox"
		placeholder={
			<>
				<div
					className={cx(
						'form__checkbox-mask',
						`form__checkbox-mask--${colorModifier}`,
						`form__checkbox-mask--${sizeModifier}`,
						className,
					)}
				/>
				<span>{label}</span>
			</>
		}
	/>
);
