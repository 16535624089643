import { FC, useCallback } from 'react';
import {
	AccountantPlateField,
	PagePlate,
} from 'components/page-plate/page-plate';
import { icons } from 'assets/icons';
import { Form, InputMask } from 'components/forms';

import './styles.scss';
import { observer } from 'mobx-react-lite';
import {
	authStore,
	REQUIRED_DETAILS_NAMES,
	REQUIRED_NAMES,
	USER_DETAILS_NAMES,
	USER_INFO_NAMES,
} from 'stores/auth';
import { useForm } from 'hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError, showToast, throttle, toast } from 'utils';
import { invoicesStore } from 'stores/invoices';
import { RouteLink } from 'modules/routing-module';
import { useNavigate } from 'react-router-dom';
import { appStore } from 'stores/app';
import { Language } from '_types/constants';
import { UserPlan } from '../../utils/api/api';
import { IUser } from '../../_types/stores';
import { INPUT_MASK } from '../../_constants';

const formId = {
	USER_DETAILS: 'userDetails',
	USER_INFO: 'userInfo',
};
export const MIN_INVOICE_SUM = 100;

const UserInfoPlate: FC = observer(() => {
	const update = useCallback(
		throttle((data: Partial<IUser>) => authStore.updateUser(data), 1000),
		[],
	);
	const [{ values, formProps }] = useForm(
		{
			surname: authStore.user?.surname || '',
			name: authStore.user?.name || '',
			middleName: authStore.user?.middleName || '',
			email: authStore.user?.email || '',
		},
		{
			onChange: (newState, state, names) => {
				const data = Object.fromEntries(
					names.map((name) => [name, newState[name]]),
				);
				update(data);
			},
		},
	);

	return (
		<PagePlate
			iconClassName={icons.ProfileGray}
			name={
				<h1>
					<FormattedMessage
						id="About the user"
						defaultMessage="О пользователе"
					/>
				</h1>
			}
		>
			<Form {...formProps} className="page-plate__form" id={formId.USER_INFO}>
				<fieldset className="page-plate__fieldset">
					{USER_INFO_NAMES.map((name) => (
						<AccountantPlateField
							key={name}
							name={name}
							value={values[name]}
							required={REQUIRED_NAMES.includes(name)}
						/>
					))}
				</fieldset>
			</Form>
		</PagePlate>
	);
});

const UserDetailsPlate: FC = observer(() => {
	const update = useCallback(
		throttle((data: Partial<IUser>) => authStore.updateUserDetails(data), 1000),
		[],
	);
	const [{ values, formProps }] = useForm(
		{
			company: authStore.user?.company || '',
			companyPhone: authStore.user?.companyPhone || '',
			companyLegalAddress: authStore.user?.companyLegalAddress || '',
			companyActualAddress: authStore.user?.companyActualAddress || '',
			companyTIN: authStore.user?.companyTIN || '',
			companyRRC: authStore.user?.companyRRC || '',
			companyPSRN: authStore.user?.companyPSRN || '',
			companyEmail: authStore.user?.companyEmail || '',
			companyBank: authStore.user?.companyBank || '',
			companyBIC: authStore.user?.companyBIC || '',
			companyCorrespondentAccount:
				authStore.user?.companyCorrespondentAccount || '',
			companyPaymentAccount: authStore.user?.companyPaymentAccount || '',
		},
		{
			required: REQUIRED_DETAILS_NAMES,
			onChange: (newState, state, names) => {
				const data = Object.fromEntries(
					names.map((name) => [name, newState[name]]),
				);
				update(data);
			},
		},
	);

	return (
		<PagePlate
			iconClassName={icons.ProfileGray}
			name={<h1>Реквизиты</h1>}
			className="details"
		>
			<Form
				{...formProps}
				className="page-plate__form"
				id={formId.USER_DETAILS}
			>
				<fieldset className="page-plate__fieldset">
					{USER_DETAILS_NAMES.map((name) => (
						<AccountantPlateField
							key={name}
							name={name}
							value={values[name]}
							required={REQUIRED_DETAILS_NAMES.includes(name)}
						>
							{name === 'companyPhone' && (
								<InputMask
									key={name}
									name={name}
									value={values[name]}
									className="page-plate__input"
									mask={INPUT_MASK.PHONE_NUMBER}
									placeholder={INPUT_MASK.PHONE_NUMBER}
								/>
							)}
						</AccountantPlateField>
					))}
				</fieldset>
			</Form>
		</PagePlate>
	);
});

const InvoiceCreatePlate: FC = () => {
	const intl = useIntl();
	const navigate = useNavigate();

	const sayAboutMinSum = useCallback(
		() =>
			showToast(
				intl.formatMessage(
					{
						id: 'InputValidNumber',
						defaultMessage: 'Введите корректное число в поле "Сумма"',
					},
					{
						fieldName: appStore.intl.formatMessage({ id: 'app.field.sum' }),
					},
				),
			),
		[intl],
	);

	const [{ values, formProps, canSubmit }, { submit, setField }] = useForm(
		{
			sum: '',
		},
		{
			required: ['sum'],
			parsers: {
				sum: (value) => {
					const sum = value as string;
					if (Number(sum) > 0) {
						return { sum };
					}
					sayAboutMinSum();

					return { sum: String(MIN_INVOICE_SUM) };
				},
			},
			onSubmit(data) {
				const sum = Number(data.sum);

				if (MIN_INVOICE_SUM > sum) {
					toast.error(
						intl.formatMessage(
							{
								id: 'InputValidNumber',
								defaultMessage: 'Введите корректное число в поле "Сумма"',
							},
							{
								fieldName: appStore.intl.formatMessage({ id: 'app.field.sum' }),
							},
						),
					);
					return;
				}

				invoicesStore
					.create(sum)
					.then(() => navigate('/accountant/invoices'))
					.catch((err) => {
						toast.error(handleError(err));
					});
			},
		},
	);

	const handleSumBlur = useCallback(() => {
		if (MIN_INVOICE_SUM > +values.sum) {
			setTimeout(() => {
				sayAboutMinSum();
				setField('sum', String(MIN_INVOICE_SUM));
			}, 500);
		}
	}, [values.sum, setField]);

	return (
		<PagePlate name={<h1>Выставление счета</h1>}>
			<Form {...formProps} className="page-plate__form">
				<fieldset className="page-plate__fieldset">
					<AccountantPlateField
						type="number"
						name="sum"
						step="any"
						min={MIN_INVOICE_SUM}
						rawPlaceholder="0.00"
						desc={
							<FormattedMessage
								id="app.field.orderSum"
								values={{
									unit: intl.locale === Language.EN ? 'RUB' : 'руб.',
								}}
							/>
						}
						value={values.sum}
						onBlur={handleSumBlur}
					/>
				</fieldset>
				<fieldset className="page-plate__fieldset buttons">
					<button
						type="submit"
						className="button button-primary button-rounded page-plate__button"
						onClick={submit}
						disabled={!canSubmit}
					>
						<FormattedMessage
							id="app.button.invoiceCreate"
							defaultMessage="Выставить счет"
						/>
					</button>
					<RouteLink
						type="button"
						className="button button-text-primary"
						routeKey="AccountantInvoices"
					>
						<FormattedMessage
							id="app.button.seeInvoiceHistory"
							defaultMessage="Смотреть историю счетов"
						/>
					</RouteLink>
				</fieldset>
			</Form>
		</PagePlate>
	);
};

const PromoDescription = observer(() => {
	return (
		authStore.user?.plan === UserPlan.DEMO && (
			<p className="main__desc">
				Заполните реквизиты юридического лица и выставьте счет на оплату. После
				оплаты аккаунт автоматически перейдет на версию{' '}
				<b>
					<FormattedMessage id={UserPlan.FULL} defaultMessage="PRO" />
				</b>
				.
			</p>
		)
	);
});

export const Profile: FC = () => (
	<div>
		<h1 className="main__title">
			<FormattedMessage id="app.route.Profile" defaultMessage="Профиль" />
		</h1>
		<PromoDescription />
		<div className="profile" id="userProfile">
			<UserDetailsPlate />
			<UserInfoPlate />
			{appStore.isCreatedFor('default') && <InvoiceCreatePlate />}
		</div>
	</div>
);
