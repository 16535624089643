import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const Toast: FC<{ title: string; description: string }> = ({
	title,
	description,
}) => {
	return (
		title && (
			<div className="toast__text">
				<p className="toast__title">
					<FormattedMessage id={title} defaultMessage={title} />
				</p>
				{description && (
					<p className="toast__description">
						<FormattedMessage id={description} defaultMessage={description} />
					</p>
				)}
			</div>
		)
	);
};
