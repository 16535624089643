import { FC, HTMLAttributes, useMemo } from 'react';
import './styles.scss';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';
import { UserPlan } from 'utils/api/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatBytes } from 'utils';
import { RouteLink } from '../../modules/routing-module';
import { metricsStore } from '../../stores/metrics';

export interface IDemoBarProps extends HTMLAttributes<HTMLDivElement> {}

export const DemoBar: FC<IDemoBarProps> = observer(({ className }) => {
	const intl = useIntl();

	const storageSpacePercent = useMemo(
		() =>
			metricsStore.userMetrics &&
			(metricsStore.userMetrics.storageSpace.storage * 100) /
				metricsStore.userMetrics.storageSpace.total,
		[
			metricsStore.userMetrics?.storageSpace.storage,
			metricsStore.userMetrics?.storageSpace.total,
		],
	);

	return (
		authStore.user?.plan === UserPlan.DEMO && (
			<RouteLink
				routeKey="Profile"
				className={cx('demo-bar__layout', className)}
			>
				<div className="demo-bar__info">
					<FormattedMessage
						id="YouUseDemo"
						defaultMessage="Вы используете ДЕМО-версию"
					/>
					{' | '}
					<FormattedMessage
						id="DaysLeft"
						defaultMessage="Осталось дней: {days}"
						values={{
							days: authStore.user.planValidityPeriod,
						}}
					/>
					{' | '}
					<FormattedMessage
						id="StorageUsed"
						defaultMessage="Диск занят на {percent}%"
						values={{
							percent: storageSpacePercent
								? Math.floor(storageSpacePercent * 10) / 10
								: '"-"',
						}}
					/>
					<div
						className="demo-bar__progress-bar"
						style={
							storageSpacePercent
								? {
										// @ts-expect-error: no TypeScript support
										'--memory-taken': `${storageSpacePercent}%`,
									}
								: undefined
						}
					>
						{formatBytes(metricsStore.userMetrics?.storageSpace.storage)}
						<div className="demo-bar__serifs">
							{Array.from(Array(5)).map((_, i) => (
								<div className="demo-bar__serif" key={i} />
							))}
						</div>
					</div>
					<span className="demo-bar__memory">
						{formatBytes(metricsStore.userMetrics?.storageSpace.total)}
					</span>
				</div>
				<div className="button button-rounded demo-bar__button">
					<FormattedMessage
						id="app.button.getFull"
						defaultMessage="Перейти на {planTitle}"
						values={{
							planTitle: intl.formatMessage({
								id: UserPlan.FULL,
								defaultMessage: 'PRO',
							}),
						}}
					/>
				</div>
			</RouteLink>
		)
	);
});
