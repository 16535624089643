import { FC, HTMLAttributes, useEffect, useMemo, useRef } from 'react';
import { videoEditorStore } from '../store';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import './style.scss';
import { appStore } from 'stores/app';

export interface IEditorPreview extends HTMLAttributes<HTMLDivElement> {}

const EditorPreviewComponent: FC<IEditorPreview> = ({
	className,
	...restProps
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const canvasSizes = useMemo(
		() => ({
			width: videoEditorStore.project?.width,
			height: videoEditorStore.project?.height,
		}),
		[videoEditorStore.project?.width, videoEditorStore.project?.height],
	);

	useEffect(() => {
		const { current: canvas } = videoEditorStore.previewCanvasRef;
		const { current: container } = containerRef;
		if (canvas && container && canvasSizes.width && canvasSizes.height) {
			canvas.style.height = `${container.clientWidth / (canvasSizes.width / canvasSizes.height)}px`;
		}
	}, [appStore.layout.windowWidth, appStore.layout.windowHeight, canvasSizes]);

	return (
		<div
			{...restProps}
			className={cx('preview-canvas', className)}
			ref={containerRef}
		>
			<canvas
				ref={videoEditorStore.previewCanvasRef}
				className="preview-canvas__canvas"
				{...canvasSizes}
			/>
		</div>
	);
};

export const EditorPreview = observer(EditorPreviewComponent);
