import { ListOrder } from '../utils/api/components';
import {
	UserWallet,
	WalletOperationsGetRequest,
	WalletResponse,
} from '../utils/api/api';
import { action, makeObservable, observable } from 'mobx';
import { appStore } from './app';
import { handleError, swaggerApi, toast } from '../utils';

export interface IWalletStore {
	list: WalletResponse[];

	getList(data?: WalletOperationsGetRequest): Promise<IWalletStore['list']>;
}

class WalletStore extends ListOrder implements IWalletStore {
	@observable list: IWalletStore['list'] = [];
	@observable wallet: UserWallet = {};

	constructor() {
		super();
		makeObservable(this);
	}

	@action getList: IWalletStore['getList'] = async (data) => {
		try {
			appStore.isLoading = true;

			const { data: invoicesData } = await this.getSortedList(
				swaggerApi.api.walletOperationsGet,
				{
					...data,
				},
			);
			this.list = invoicesData.data;

			return this.list;
		} catch (error) {
			toast.error(handleError(error));

			return this.list;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action setWallet(wallet: UserWallet) {
		this.wallet = wallet;
	}
}

export const walletStore = new WalletStore();
