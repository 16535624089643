import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
	InvoiceResponse,
	InvoiceStatus,
	SpecificFormat,
	UserRole,
} from 'utils/api/api';
import { Button, Confirmation } from 'components/common';
import { InvoicesStore, invoicesStore } from 'stores/invoices';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';
import { PopupFullscreen } from 'components/popup';
import { UploadButton } from '../../components/upload-button/upload-button';
import { ACCEPT_DOCUMENT_EXTS } from '../../_constants';
import { explorerStore } from '../../stores/media';
import { Select } from '../../components/forms';
import cx from 'classnames';
import { Icon } from '../../components/icon';
import { icons } from '../../assets/icons';

const MAX_TABLE_FILE_NAME_LENGTH = 30;

const DownloadOriginButton: FC<{ invoice: InvoiceResponse; mini?: boolean }> =
	observer(({ invoice, mini }) => {
		const format = useMemo(
			() => invoicesStore.invoiceFormat[invoice.id] || SpecificFormat.Xlsx,
			[invoicesStore.invoiceFormat[invoice.id]],
		);

		const download = useCallback(
			(format: SpecificFormat) => {
				void invoicesStore.downloadOrigin(invoice, format);
			},
			[format],
		);

		const handleChange = useCallback(
			(e: ChangeEvent<HTMLSelectElement>) => {
				const format = e.target.value as SpecificFormat;
				invoicesStore.setInvoiceFormat(invoice.id, format);
				download(format);
			},
			[download],
		);

		const handleDownloadClick = useCallback(() => {
			download(format);
		}, [format]);

		return (
			<Button
				className={cx('invoice-actions__download-origin', format, { mini })}
				secondary
			>
				{!mini && (
					<div onClick={handleDownloadClick}>
						<FormattedMessage
							id="app.button.generate"
							defaultMessage="Сгенерировать"
						/>
					</div>
				)}
				<Select onChange={handleChange} value={format}>
					{[SpecificFormat.Pdf, SpecificFormat.Xlsx].map((format) => (
						<option key={format} value={format}>
							<FormattedMessage
								id={format}
								defaultMessage={format.toUpperCase()}
							/>
						</option>
					))}
				</Select>
			</Button>
		);
	});

export const InvoiceActions: FC<{ invoice: InvoiceResponse }> = observer(
	({ invoice }) => {
		const intl = useIntl();
		const fullAccess = useMemo(
			() => authStore.onlyFor([UserRole.Administrator, UserRole.Accountant]),
			[authStore.onlyFor],
		);

		const invoiceName = useMemo(() => {
			return invoice.file
				? fullAccess
					? invoice.file.name.length > MAX_TABLE_FILE_NAME_LENGTH
						? `${invoice.file.name.slice(0, MAX_TABLE_FILE_NAME_LENGTH).trim()}...`
						: invoice.file.name
					: `${InvoicesStore.readableNameFromInvoice(invoice)}.${invoice.file.extension}`
				: undefined;
		}, [fullAccess, invoice.file, invoice.sum, invoice.updatedAt]);

		const canDownload = useMemo(
			() =>
				Boolean(invoice.file) &&
				(invoice.status === InvoiceStatus.AWAITING_CONFIRMATION
					? fullAccess
					: true),
			[invoice.file, invoice.status, fullAccess],
		);

		const canDownloadOrigin = useMemo(
			() =>
				fullAccess && invoice.status === InvoiceStatus.AWAITING_CONFIRMATION,
			[fullAccess, invoice.status],
		);

		const canUpload = useMemo(
			() =>
				fullAccess && invoice.status === InvoiceStatus.AWAITING_CONFIRMATION,
			[fullAccess, invoice.status],
		);

		const handleDownloadClick = useCallback(() => {
			if (canDownload && invoice.file) {
				void explorerStore.download(invoice.file, invoiceName);
			}
		}, [canDownload, invoice.file, invoiceName]);

		const handleUpload = useCallback(
			(files: File[]) => {
				if (canUpload) {
					void invoicesStore.upload(invoice.id, files[0]);
				}
			},
			[canUpload, invoice.id],
		);

		const downloadButtonIsPrimary = useMemo(
			() =>
				![InvoiceStatus.CANCELLED, InvoiceStatus.PAID].includes(invoice.status),
			[invoice.status],
		);

		return (
			<div className="invoice-actions">
				{canDownloadOrigin && <DownloadOriginButton invoice={invoice} />}
				{canUpload && (
					<UploadButton accept={ACCEPT_DOCUMENT_EXTS} onUpload={handleUpload}>
						{invoice.file ? (
							<FormattedMessage
								id="app.button.invoiceReplace"
								defaultMessage="Заменить счет"
							/>
						) : (
							<FormattedMessage
								id="app.button.invoiceUpload"
								defaultMessage="Загрузить счет"
							/>
						)}
					</UploadButton>
				)}
				{canDownload &&
					(canUpload ? (
						<div
							className="invoice-actions__file as-button"
							title={intl.formatMessage({
								id: 'app.button.invoiceDownload',
								defaultMessage: 'Скачать счет',
							})}
							onClick={handleDownloadClick}
						>
							<span>{invoiceName}</span>
							<Icon className={icons.Disk} />
						</div>
					) : (
						<>
							<Button
								primary={downloadButtonIsPrimary}
								secondary={!downloadButtonIsPrimary}
								onClick={handleDownloadClick}
							>
								<FormattedMessage
									id="app.button.invoiceDownload"
									defaultMessage="Скачать счет"
								/>
							</Button>
							{fullAccess && invoiceName && (
								<div className="invoice-actions__file">
									<span>{invoiceName}</span>
								</div>
							)}
						</>
					))}
			</div>
		);
	},
);

export const InvoiceTableStatus: FC<{ invoice: InvoiceResponse }> = observer(
	({ invoice }) => {
		const intl = useIntl();
		const [message, setMessage] = useState('');

		const handleConfirmClick = useCallback(() => {
			setMessage(
				`Подтвердить правильность счета №${invoice.seqNo}?\nСчет будет отправлен пользователю на электронную почту.`,
			);
		}, [invoice]);

		const handlePaidClick = useCallback(() => {
			setMessage(
				`Подтвердить оплату счета №${
					invoice.seqNo
				} на сумму ${intl.formatNumber(invoice.sum, {
					style: 'currency',
					currency: 'RUB',
				})}?\nСредства будут начислены на баланс пользователя.`,
			);
		}, [invoice.seqNo, invoice.sum, intl]);

		const confirmInvoice = useCallback(async () => {
			await invoicesStore.confirm(invoice);
			setMessage('');
		}, [invoice]);

		const confirmPayment = useCallback(async () => {
			await invoicesStore.confirmPayment(invoice);
			setMessage('');
		}, [invoice]);

		const cancelConfirmation = useCallback(() => {
			setMessage('');
		}, []);

		return (
			<>
				<FormattedMessage id={invoice.status} />
				<div className="invoice-status">
					{message &&
						[
							InvoiceStatus.AWAITING_CONFIRMATION,
							InvoiceStatus.CONFIRMED_PENDING_PAYMENT,
						].includes(invoice.status) && (
							<PopupFullscreen onOutsideClick={cancelConfirmation}>
								<Confirmation
									message={message}
									onConfirm={
										invoice.status === InvoiceStatus.AWAITING_CONFIRMATION
											? confirmInvoice
											: confirmPayment
									}
									onCancel={cancelConfirmation}
								/>
							</PopupFullscreen>
						)}
					{authStore.onlyFor([UserRole.Administrator, UserRole.Accountant]) && (
						<>
							{invoice.status === InvoiceStatus.AWAITING_CONFIRMATION && (
								<Button primary onClick={handleConfirmClick}>
									<FormattedMessage
										id="app.button.confirm"
										defaultMessage="Подтвердить"
									/>
								</Button>
							)}
							{invoice.status === InvoiceStatus.CONFIRMED_PENDING_PAYMENT && (
								<Button primary onClick={handlePaidClick}>
									<FormattedMessage
										id="app.button.invoicePaymentConfirm"
										defaultMessage="Подтвердить приход"
									/>
								</Button>
							)}
						</>
					)}
				</div>
			</>
		);
	},
);
