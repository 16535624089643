import { forwardRef, useMemo } from 'react';
import { IMenu, IMenuItemProps, Menu } from '../menu';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { appStore } from 'stores/app';

const HeaderMenuItem = forwardRef<HTMLAnchorElement, IMenuItemProps>(
	(
		{
			activity,
			displayTitle,
			className,
			iconClassName,
			iconClassNameActive,
			...restProps
		},
		linkRef,
	) => (
		<NavLink ref={linkRef} {...restProps} className="header__menu-item">
			<div className={cx('header__menu-icon', iconClassName)} />
			<span>{displayTitle}</span>
		</NavLink>
	),
);

export function HeaderMenu() {
	const headerMenuSchema = useMemo<IMenu['routingSchema']>(
		() => (appStore.isCreatedFor('promo') ? ['Monitors'] : []),
		[],
	);

	return headerMenuSchema.length ? (
		<Menu
			className="header__menu"
			routingSchema={headerMenuSchema}
			ItemComponent={HeaderMenuItem}
		/>
	) : null;
}
