import { checkInputError } from '../../hocs';
import ReactInputMask, { Props as InputMaskProps } from 'react-input-mask';
import cx from 'classnames';

export const InputMask = checkInputError<InputMaskProps>(
	({ isError, className, ...restProps }) => {
		return (
			<ReactInputMask
				{...restProps}
				className={cx('form__input__text form__input', className, {
					error: isError,
				})}
				type="text"
			/>
		);
	},
);
