import { Component, ReactNode } from 'react';
import { toast } from 'utils';

import { Error } from 'components/error';

type Props = {
	children: ReactNode;
};
type State = { isError: boolean; error: Error | null };

export class Catcher extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = { isError: false, error: null };
	}

	static getDerivedStateFromError(error: Error): {
		isError: boolean;
		error: Error;
	} {
		// Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
		return { isError: true, error };
	}

	componentDidCatch(error: unknown, errorInfo: unknown): void {
		// Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок

		toast.error('MobX error?');
		// eslint-disable-next-line no-console
		console.warn('MobX error?', error, errorInfo);
	}

	render(): ReactNode {
		const { children } = this.props;
		const { isError, error } = this.state;

		return isError && error ? (
			<Error title={'Error'} message={error.message} />
		) : (
			children
		);
	}
}
