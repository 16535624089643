import { FC, useCallback, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IMonitorItem } from '_types/stores';
import { filterObject } from 'utils';
import { monitorsStore } from 'stores/monitors/monitor-list';
import { BigInput, Files } from 'components/add-edit-form';
import { Form } from 'components/forms';
import { AddEditWrapper } from 'components/wrapper';
import { Button, Layout } from 'components/common';
import {
	CategoryField,
	LocationField,
	MonitorInformationField,
	NameField,
	OrientationField,
	PriceFields,
} from './add-edit-fields';

import IconSettings from 'assets/icons/added-monitor.svg';
import ScreenshotImage from 'assets/images/screenshot.jpg';

import './styles.scss';
import {
	ADDRESS_TEXT_PARAMS,
	COORDS_NAMES,
	DEFAULT_MONITOR_CATEGORY,
	DEFAULT_MONITOR_ORIENTATION,
	DEFAULT_MONITOR_SOUND,
	MONITOR_DISPLAY_VALUE_SPECIFICATIONS,
} from './constants';
import { RequestError } from 'utils/api/check-request-data';
import { errorStore } from '../../stores/error-store';
import { parseValueAsNumber, useForm } from '../../hooks';

const initialMonitorData: Partial<IMonitorItem> &
	Pick<IMonitorItem, 'category' | 'sound' | 'orientation'> = {
	category: DEFAULT_MONITOR_CATEGORY,
	sound: DEFAULT_MONITOR_SOUND,
	orientation: DEFAULT_MONITOR_ORIENTATION,
	price1s: 0,
	minWarranty: 0,
	maxDuration: 0,
	angle: 0,
	brightness: 0,
	width: 0,
	height: 0,
};

const MonitorAddEdit: FC = () => {
	const { id, code } = useParams<{ id?: string; code?: string }>();

	const [{ values, formProps, canSubmit }, { submit, setValues }] = useForm(
		initialMonitorData,
		{
			disableCast: ['code'],
			parsers: {
				resolution: (value) => {
					if (typeof value !== 'string') return;
					const [width, height] = value
						.split('x')
						.map((str) => parseInt(str, 10));

					return {
						width,
						height,
					};
				},
				...Object.fromEntries(
					COORDS_NAMES.map((name) => [
						name,
						(value: string, { location }) => {
							const coordinates = (
								location?.coordinates || [0, 0]
							).concat() as [number, number];
							const number = +value;

							switch (name) {
								case 'latitude':
									{
										if (-90 > number || number > 90) {
											return;
										}

										coordinates[0] = number;
									}
									break;

								case 'longitude': {
									if (-180 > number || number > 180) {
										return;
									}

									coordinates[1] = number;
								}
							}

							return {
								location: {
									...location,
									coordinates,
								},
							};
						},
					]),
				),
				...Object.fromEntries(
					ADDRESS_TEXT_PARAMS.map((part) => [
						part,
						(value: string, { address = {} }: typeof values) => ({
							address: {
								...address,
								[part]: value,
							},
						}),
					]),
				),
			},
			onBlur: (values, names) => {
				const partialStateEntries = names
					.map((name) => {
						switch (name) {
							case 'angle':
							case 'brightness': {
								return [
									name,
									parseValueAsNumber(
										values[name],
										MONITOR_DISPLAY_VALUE_SPECIFICATIONS[name],
									),
								];
							}
						}
					})
					.filter((item): item is NonNullable<typeof item> => Boolean(item));

				if (partialStateEntries.length) {
					return Object.fromEntries(partialStateEntries);
				}
			},
			onSubmit: async (values) => {
				try {
					if (id) {
						await monitorsStore.updateMonitor(
							id,
							filterObject(values, { excludedKeys: ['createdAt'] }),
						);
					} else {
						await monitorsStore.createMonitor(values as IMonitorItem);
					}
					errorStore.reset();
				} catch (error) {
					if (error instanceof RequestError) {
						error.notify();
						errorStore.setErrors(
							error.fieldNames.map((name) => {
								switch (name) {
									case 'width':
									case 'height':
										return 'resolution';

									default:
										return name;
								}
							}),
						);
					}
				}
			},
		},
	);

	const loadMonitor = useCallback((id?: IMonitorItem['id']) => {
		if (id) {
			monitorsStore.loadMonitor(id).then((monitor) => {
				if (monitor) {
					setValues({ ...monitor });
				}
			});
		}
	}, []);

	useEffect(() => {
		if (id) {
			loadMonitor(id);
		} else if (code) {
			setValues((state) => ({ ...state, code }));
		} else {
			setValues(initialMonitorData);
		}
	}, [id, code, loadMonitor]);

	return (
		<Layout>
			{monitorsStore.isGroup(values.multiple) && (
				<Navigate to={`/monitors/group/${id}`} />
			)}
			<Form {...formProps} className="add__edit__form monitor__add-edit-layout">
				{!values.id && <BigInput name="code" value={values.code} />}
				<AddEditWrapper
					cancelRouteKey="MonitorsList"
					icon={IconSettings}
					title={values.id ? 'Edit the monitor' : 'Add a monitor'}
					submit={submit}
					disabled={!canSubmit}
				>
					<div className="monitor__add-edit-form">
						<div className="monitor__form-column">
							<NameField name={values.name} />
							<CategoryField
								category={values.category}
								className="form__input-category"
							/>
							<LocationField
								address={values.address}
								location={values.location}
								category={values.category}
							/>
						</div>
						<div className="monitor__form-column">
							<PriceFields monitor={values} />
							<OrientationField monitor={values} />
							<Files
								monitorId={values.id}
								photos={values.photos}
								documents={values.documents}
								addCallback={() => loadMonitor(values.id)}
								deleteCallback={() => loadMonitor(values.id)}
							/>
							<MonitorInformationField monitor={values} />
						</div>
					</div>
				</AddEditWrapper>
			</Form>
			<div className="screenshot__layout">
				<img
					src={ScreenshotImage}
					className="screenshot__image"
					alt="Скришнот"
				/>
				<Button className="screenshot__button" secondary rounded>
					Сделать скриншот
				</Button>
			</div>
		</Layout>
	);
};

export default observer(MonitorAddEdit);
