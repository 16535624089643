import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { RouteLink } from 'modules/routing-module';

import { Layout } from 'components/common';
import { InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import SendIcon from 'assets/icons/send-email.svg';
import './styles.scss';

export const SendResetPassword: FC = () => {
	const [searchParams] = useSearchParams();
	const domain = searchParams.get('domain');
	const domainUrl = useMemo(() => {
		if (domain) {
			return new URL(`https://${domain}`);
		}
		return null;
	}, [domain]);

	return (
		<AuthWrapper
			title="Email Delivered"
			subtitle="Check your email, you will now receive a recovery email"
		>
			<Layout className="p-4-em" flex>
				<InputField>
					<img src={SendIcon} className="page-image auth__icon" alt="Sent" />
				</InputField>
				<InputField>
					{domainUrl ? (
						<a
							href={domainUrl.toString()}
							target="_blank"
							className="button button-primary auth__button auth__button--margin"
						>
							<FormattedMessage id="Go to email" defaultMessage="Go to email" />
						</a>
					) : (
						<RouteLink
							routeKey="Login"
							className="button button-primary auth__button auth__button--margin"
						>
							<FormattedMessage id="Sign In" defaultMessage="Sign in" />
						</RouteLink>
					)}
				</InputField>
			</Layout>
		</AuthWrapper>
	);
};
