import { observer } from 'mobx-react-lite';

import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Actions, NameWithIcon } from './list-components';
import { tableConstants } from '_constants/index';
import { Layout } from 'components/common';
import { Table } from 'components/table';
import {
	DEFAULT_PAGINATION_LIMIT,
	Pagination,
} from 'modules/pagination-module';

import { userStore } from 'stores/users';
import { UserRoleMessageId } from '_types/constants';

import './styles.scss';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

const FIRST_PAGE = 1;

const UsersList: FC = observer(() => {
	const navigate = useNavigate();
	const intl = useIntl();

	const { currentPage = FIRST_PAGE } = useParams<{ currentPage?: string }>();
	const [page, setPage] = useState(Number(currentPage));

	useEffect(() => {
		void userStore.getList({
			scope: {
				limit: DEFAULT_PAGINATION_LIMIT,
				page,
			},
		});
	}, [page, userStore.order]);

	const handlePaginate = useCallback(
		(paginatePage: number) => {
			setPage((state) => {
				if (paginatePage === state) {
					return state;
				}
				navigate(`/users/list/${page}`);
				return paginatePage;
			});
		},
		[navigate, page],
	);

	const data = useMemo(
		() =>
			userStore.list.map((user, i) => ({
				...user,
				ID: <NameWithIcon id={user.id} fill={i % 2 ? '#32C832' : ' #888FA3'} />,
				role: (
					<FormattedMessage
						id={UserRoleMessageId[user.role]}
						defaultMessage={user.role}
					/>
				),
				createdAt: intl.formatDate(user.createdAt),
				updatedAt: user.updatedAt ? intl.formatDate(user.updatedAt) : null,
				actions: <Actions id={user.id} />,
			})),
		[userStore.list, intl],
	);

	return (
		<Layout>
			<h1 className="main__title">
				<FormattedMessage id="app.route.Users" defaultMessage="Пользователи" />
			</h1>
			<Table
				className="user__table scroll"
				columns={tableConstants.columns}
				data={data}
				onSortClick={userStore.setOrder}
				order={userStore.order}
			/>
			<Pagination
				totalItems={userStore.count}
				currentPage={+currentPage}
				onPageChange={handlePaginate}
			/>
		</Layout>
	);
});

export { UsersList };
