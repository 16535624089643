import { Checkbox, ICheckboxProps } from '../forms';
import { useMemo } from 'react';

export interface ITableCheckboxProps<T = string>
	extends Omit<ICheckboxProps, 'onChange'> {
	itemId: T;
	itemIds: T[];
	onChange: (checked: boolean, itemId: T) => void;
}

export const TableCheckbox = <T = string,>({
	itemId,
	itemIds,
	onChange,
	...restProps
}: ITableCheckboxProps<T>) => {
	const isChecked = useMemo(() => itemIds.includes(itemId), [itemId, itemIds]);

	return (
		<Checkbox
			data-id={itemId}
			readOnly={true}
			{...restProps}
			onChange={(e) => {
				e.stopPropagation();
				onChange(!isChecked, itemId);
			}}
			checked={isChecked}
		/>
	);
};
