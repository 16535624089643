import { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { RouteLink } from 'modules/routing-module';
import { UserPlan, UserRole } from 'utils/api/api';
import { IMenu, Menu } from '../menu';
import { SidebarMenuItem, SidebarSubmenuItem } from './sidebar-menu-item';

import './styles.scss';
import { appStore } from '../../stores/app';

export const sidebarMenuSchema: IMenu['routingSchema'] = [
	'Desktop',
	[
		'Monitors',
		appStore.isProd
			? ['MonitorsAdd', 'MonitorsList']
			: ['MonitorsAdd', 'MonitorsList', 'MonitorsGroup'],
	],
	'Library',
	['Editor', ['EditorCreate', 'EditorProjects']],
	['Playlists', ['PlaylistsAdd', 'PlaylistsList', 'PlaylistsMapMonitorList']],
	'Bids',
	'Users',
];

export interface ISidebar extends HTMLAttributes<HTMLDivElement> {}

const PlanTitle: FC<HTMLAttributes<HTMLDivElement>> = observer(
	({ className, ...restProps }) => {
		const title = useMemo(() => {
			switch (authStore.user?.plan) {
				case UserPlan.FULL:
					return 'Pro';
				case UserPlan.VIP:
					return 'VIP';
				default:
					return 'Demo';
			}
		}, [authStore.user?.plan]);

		return (
			<div
				{...restProps}
				className={cx('sidebar__version', className, {
					'sidebar__version--demo': authStore.user?.plan === UserPlan.DEMO,
				})}
			>
				{title}
			</div>
		);
	},
);

const SidebarComponent: FC<ISidebar> = ({ className }) => {
	const isOpen = useMemo(
		() => appStore.layout.sidebarOpen,
		[appStore.layout.sidebarOpen],
	);
	const burgerOnClick = useCallback(
		() =>
			appStore.setLayout({
				sidebarOpen: !isOpen,
			}),
		[isOpen],
	);

	const routingSchema = useMemo<IMenu['routingSchema']>(() => {
		const accountantMenuItem: IMenu['routingSchema'][number] = [
			'Accountant',
			['AccountantInvoices'],
		];

		switch (authStore.userRole) {
			case UserRole.Administrator: {
				return [...sidebarMenuSchema, accountantMenuItem];
			}
			case UserRole.Accountant: {
				return [accountantMenuItem, 'Logout'];
			}
			default:
				return sidebarMenuSchema;
		}
	}, [authStore.userRole]);

	return (
		<div className={cx('sidebar', className)}>
			<div className="sidebar__header">
				<button className="sidebar__burger" onClick={burgerOnClick} />
				<RouteLink routeKey="Home">
					<div className="sidebar__logo" />
				</RouteLink>
				<PlanTitle />
			</div>
			<Menu
				className="menu__wrapper"
				routingSchema={routingSchema}
				ItemComponent={SidebarMenuItem}
				SubItemComponent={SidebarSubmenuItem}
			/>
		</div>
	);
};

export const Sidebar = observer(SidebarComponent);
