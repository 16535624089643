import React, {
	ChangeEvent,
	FC,
	HTMLAttributes,
	useCallback,
	useMemo,
	useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { Feature } from 'geojson';

import { MonitorOrientation } from 'utils/api/api';
import { coordsToFeature, stringToCoords, TCoords } from 'utils/geojson';
import { simulateChange } from 'utils/helpers/simulate-change';
import {
	checkFeatureKind,
	geoSearch,
	parseFeatureToAddress,
	SEARCH_TEXT_SEPARATOR,
} from 'utils/yandex-api';
import { IMonitorItem } from '_types/stores';
import { ICONS_CLASSNAME, UI_TIMEOUT_MS } from '_constants';
import {
	ADDRESS_TEXT_PARAMS,
	categoryOptions,
	COORDS_NAMES,
	MONITOR_DISPLAY_VALUE_SPECIFICATIONS,
} from './constants';

import { FormField } from 'components/add-edit-form';
import { Layout } from 'components/common';
import {
	CustomMap,
	MIN_SEARCH_TEXT_LENGTH,
	Placemark,
} from 'components/custom-map';
import { Checkbox, Input, Select } from 'components/forms';

import './styles.scss';
import { DomEventHandler, LngLat } from '@yandex/ymaps3-types';
import { Icon } from '../../components/icon';
import { upFirstLetter } from '../../utils';
import { icons } from '../../assets/icons';

export interface ILocationFieldProps
	extends NonNullable<
		Pick<IMonitorItem, 'address' | 'location' | 'category'>
	> {}

export const NameField: FC<Partial<Pick<IMonitorItem, 'name'>>> = ({
	name = '',
	...restProps
}) => (
	<FormField title="Name">
		<Input
			{...restProps}
			name="name"
			type="text"
			placeholder="Device name"
			fieldClass="add__edit__form__field"
			value={name}
		/>
	</FormField>
);

export const LocationField: FC<ILocationFieldProps> = ({
	address = {},
	location,
	category,
}) => {
	const addressRef = useRef<HTMLInputElement>(null);
	const locationRef = useRef<HTMLInputElement>(null);

	const coordinates = useMemo(
		() => (location ? (location.coordinates as TCoords) : undefined),
		[location],
	);
	const lngLatCoordinates = useMemo(
		() => coordinates && ([coordinates[1], coordinates[0]] as LngLat),
		[coordinates],
	);

	const inputAddress = useCallback((f: Feature) => {
		simulateChange(addressRef, parseFeatureToAddress(f));
	}, []);

	const inputLocation = useCallback((g: Feature['geometry'] | null) => {
		simulateChange(locationRef, g);
	}, []);

	const inputLocationWithAddress = useCallback(
		(coords: TCoords) => {
			let feature = coordsToFeature(coords);
			let timeout: number | null = window.setTimeout(() => {
				inputLocation(feature.geometry);
				timeout = null;
			}, UI_TIMEOUT_MS);

			void geoSearch(
				{
					text: coords.toString(),
					results: 1,
				},
				({ features }) => {
					if (features.length) {
						const featureWithKind = features[0];

						if (
							checkFeatureKind(featureWithKind, [
								'locality',
								'province',
								'street',
								'house',
							])
						) {
							inputAddress(featureWithKind);
							if (timeout) {
								feature = featureWithKind;
							} else {
								inputLocation(featureWithKind.geometry);
							}
						}
					}
				},
			);
		},
		[inputAddress, inputLocation],
	);

	const handleMapClick = useCallback<DomEventHandler>(
		(obj, e) => {
			const [lng, lat] = e.coordinates;
			inputLocationWithAddress([lat, lng]);
		},
		[inputLocationWithAddress],
	);

	const handleAddressBlur = useCallback(() => {
		const text = ADDRESS_TEXT_PARAMS.map((key) => address[key])
			.filter((value): value is string => Boolean(value))
			.join(SEARCH_TEXT_SEPARATOR);

		if (text.length >= MIN_SEARCH_TEXT_LENGTH) {
			void geoSearch(
				{
					text,
					results: 1,
				},
				({ features }) => {
					if (features.length) {
						inputLocation(features[0].geometry);
					} else {
						inputLocation(null);
					}
				},
			);
		}
	}, [address, inputLocation]);

	const handleCoordsBlur = useCallback(() => {
		if (
			coordinates &&
			coordinates.length === 2 &&
			coordinates.every((val) => !isNaN(val))
		) {
			inputLocationWithAddress(coordinates);
		}
	}, [coordinates]);

	const handleCoordsPaste = useCallback(
		(e: React.ClipboardEvent) => {
			const clipboardString = e.clipboardData.getData('text/plain');
			const [latitude, longitude] = stringToCoords(clipboardString);

			if (latitude && longitude) {
				e.preventDefault();
				inputLocationWithAddress([latitude, longitude]);
			}
		},
		[inputLocationWithAddress],
	);

	const dynamicZoom = useMemo(() => {
		let zoom = 9;

		if (address.street) {
			zoom = 13;
		}

		if (address.house) {
			zoom = 16;
		}

		return zoom;
	}, [address.street, address.house]);

	const mapState = useMemo(() => {
		if (!lngLatCoordinates) return;

		return {
			center: lngLatCoordinates,
			zoom: dynamicZoom,
		};
	}, [dynamicZoom, lngLatCoordinates]);

	return (
		<FormField title="Address" fieldClass="add__edit__address">
			{ADDRESS_TEXT_PARAMS.map((part) => {
				const name = part;
				const value = address[part] || '';
				const placeholder = upFirstLetter(part);

				return (
					<Input
						key={name}
						name={name}
						type="text"
						placeholder={placeholder}
						fieldClass="add__edit__form__field"
						value={value}
						onBlur={handleAddressBlur}
					/>
				);
			})}
			<div className="add__edit__coords add__edit__form__field">
				{COORDS_NAMES.map((name, idx) => {
					const value = coordinates ? coordinates[idx] : '';
					const placeholder = name[0].toUpperCase() + name.substring(1);

					return (
						<Input
							key={name}
							name={name}
							type="number"
							step={'any'}
							placeholder={placeholder}
							fieldClass="add__edit__form__field monitor__field-coordinates"
							value={value}
							onPaste={handleCoordsPaste}
							onBlur={handleCoordsBlur}
						/>
					);
				})}
			</div>
			<div className="add__edit__address__map">
				<CustomMap location={mapState} onClick={handleMapClick}>
					{lngLatCoordinates && (
						<Placemark
							coordinates={lngLatCoordinates}
							label={<Icon className={ICONS_CLASSNAME[category]} />}
						/>
					)}
				</CustomMap>
			</div>
			<input ref={addressRef} type="hidden" name="address" />
			<input ref={locationRef} type="hidden" name="location" />
		</FormField>
	);
};

export const CategoryField: FC<
	Partial<Pick<IMonitorItem, 'category'>> & HTMLAttributes<HTMLSelectElement>
> = ({ category, className }) => {
	const intl = useIntl();

	return (
		<FormField title="Category">
			<Select
				name="category"
				fieldClass="add__edit__form__field"
				className={cx('form__input_select', className)}
				value={category}
			>
				{categoryOptions.map((value) => (
					<option key={value} value={value}>
						{intl.formatMessage({ id: value || 'Select a category' })}
					</option>
				))}
			</Select>
		</FormField>
	);
};

export const ONE_DAY_SECONDS = 24 * 3600;

export const PriceFields: FC<{
	monitor:
		| IMonitorItem
		| Pick<IMonitorItem, 'price1s' | 'minWarranty' | 'maxDuration'>;
}> = ({ monitor }) => {
	const { price1s = '', minWarranty = '', maxDuration = '' } = monitor;
	const warrantyInputRef = useRef<HTMLInputElement | null>(null);

	const checkNumber = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const number = +e.target.value;
		const isWrong =
			isNaN(number) || +number < 0 || e.target.value.includes('--');

		if (isWrong) {
			e.target.value = '';
		}

		return isWrong ? null : number;
	}, []);

	const handleMaxDurationChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const duration = checkNumber(e);

			setTimeout(() =>
				simulateChange(
					warrantyInputRef,
					duration ? String(Math.round(ONE_DAY_SECONDS / duration)) : '',
				),
			);
		},
		[checkNumber],
	);
	// TODO@nikshirobokov: Ограничивать поле minWarranty в зависимости от результатов метода monitorGetBids
	// const [calcMinWarranty, setCalcMinWarranty] =
	//   useState<IMonitorItem['minWarranty']>(minWarranty);
	//
	// useEffect(() => {
	//   setCalcMinWarranty((state) => {
	//     if (state === minWarranty) return state;
	//     return minWarranty;
	//   });
	// }, [minWarranty, setCalcMinWarranty]);

	return (
		<>
			<FormField title="Price of 1 second of impression">
				<Input
					name="price1s"
					type="text"
					rawPlaceholder="1000 (RUB)"
					fieldClass="add__edit__form__field"
					value={price1s}
					onChange={checkNumber}
				/>
			</FormField>
			<FormField title="Max playlist duration">
				<Input
					name="maxDuration"
					type="text"
					rawPlaceholder="0"
					fieldClass="add__edit__form__field"
					value={maxDuration}
					onChange={handleMaxDurationChange}
				/>
			</FormField>
			<FormField title="Min number of impressions per day">
				<input type="hidden" ref={warrantyInputRef} name="minWarranty" />
				<Input
					name="minWarranty"
					type="text"
					rawPlaceholder="0"
					fieldClass="add__edit__form__field"
					value={minWarranty}
					onChange={() => null}
					disabled
				/>
			</FormField>
		</>
	);
};

export const OrientationField: FC<{ monitor: Partial<IMonitorItem> }> = ({
	monitor,
}) => {
	return (
		<FormField
			title="Screen orientation"
			row
			layoutClass="monitor__orientation__layout"
		>
			{[MonitorOrientation.Horizontal, MonitorOrientation.Vertical].map(
				(orientation) => {
					const checked = monitor.orientation === orientation;
					let iconClassName: string;

					if (orientation === MonitorOrientation.Horizontal) {
						iconClassName = checked
							? icons.MonitorHorizontalGreen
							: icons.MonitorHorizontal;
					} else {
						iconClassName = checked
							? icons.MonitorVerticalGreen
							: icons.MonitorVertical;
					}

					return (
						<label key={orientation} className="monitor__orientation__icon">
							<Icon className={iconClassName} />
							<input
								name="orientation"
								type="radio"
								value={orientation}
								checked={checked}
								onChange={() => null}
							/>
						</label>
					);
				},
			)}
		</FormField>
	);
};

export const MonitorInformationField: FC<{
	monitor: Partial<IMonitorItem>;
}> = ({ monitor }) => (
	<Layout flex column className="monitor__monitor__layout">
		<Layout flex row className="align">
			<Input
				name="model"
				type="text"
				placeholder="Monitor Model"
				fieldClass="add__edit__form__field monitor__monitor__field monitor__monitor__field-model"
				value={monitor.model}
			/>
			<Checkbox
				name="sound"
				checked={monitor.sound}
				label={<FormattedMessage id="Sound" defaultMessage="Со звуком" />}
				onChange={() => null}
				colorModifier="dark"
				sizeModifier="small"
			/>
		</Layout>
		<Layout flex row className="monitor__monitor__field__wrapper">
			<Select
				name="resolution"
				fieldClass="add__edit__form__field monitor__monitor__field"
				className="form__input_select monitor__input-info"
				value={`${monitor.width} x ${monitor.height} px`}
				fieldElement={
					<span className="monitor__monitor__field-label">
						<FormattedMessage
							id="Resolution"
							defaultMessage="Разрешение экрана"
						/>
					</span>
				}
			>
				<option value="">
					<FormattedMessage id="Select" defaultMessage="Выбрать" />
				</option>
				{MONITOR_DISPLAY_VALUE_SPECIFICATIONS.resolution.options.map((r) => (
					<option key={r} value={r}>
						{r}
					</option>
				))}
			</Select>
			<Input
				name="angle"
				type="number"
				step={MONITOR_DISPLAY_VALUE_SPECIFICATIONS.angle.step}
				min={MONITOR_DISPLAY_VALUE_SPECIFICATIONS.angle.min}
				max={MONITOR_DISPLAY_VALUE_SPECIFICATIONS.angle.max}
				placeholder="Viewing angle"
				fieldClass="add__edit__form__field monitor__monitor__field"
				className="monitor__input-info monitor__input-info--angle"
				value={monitor.angle}
			/>
		</Layout>
		<Layout flex row className="monitor__monitor__field__wrapper">
			<Select
				name="matrix"
				fieldClass="add__edit__form__field monitor__monitor__field"
				className="form__input_select monitor__input-info"
				value={monitor.matrix}
				fieldElement={
					<span className="monitor__monitor__field-label">
						<FormattedMessage
							id="The type of matrix"
							defaultMessage="The type of matrix"
						/>
					</span>
				}
			>
				<option value="">
					<FormattedMessage id="Select" defaultMessage="Выбрать" />
				</option>
				{MONITOR_DISPLAY_VALUE_SPECIFICATIONS.matrix.options.map((m) => (
					<option key={m} value={m}>
						{m}
					</option>
				))}
			</Select>
			<Input
				name="brightness"
				type="number"
				step={MONITOR_DISPLAY_VALUE_SPECIFICATIONS.brightness.step}
				min={MONITOR_DISPLAY_VALUE_SPECIFICATIONS.brightness.min}
				max={MONITOR_DISPLAY_VALUE_SPECIFICATIONS.brightness.max}
				placeholder="Brightness"
				fieldClass="add__edit__form__field monitor__monitor__field"
				className="monitor__input-info monitor__input-info--brightness"
				value={monitor.brightness}
			/>
		</Layout>
	</Layout>
);
