import { action, makeObservable, observable, runInAction } from 'mobx';
import { LimitRequest, Order, RequestParams } from '../api';
import { AxiosResponse } from 'axios';

export type TOrderAccessor = string;

export enum RequestStatus {
	Failure = 'failure',
	Idle = 'idle',
	Success = 'success',
	Request = 'request',
}

export interface IListOrder {
	count: number;
	order: Record<TOrderAccessor, Order>;
	requestStatus: RequestStatus;

	setOrder(accessor: TOrderAccessor): void;

	setSearchText(text: string): void;

	getSortedList<
		T extends (
			data: Partial<{
				where: never;
				scope: LimitRequest;
				select: never[];
			}>,
			params?: RequestParams,
		) => ReturnType<T>,
	>(
		apiListGet: T,
		data?: Parameters<T>[0],
	): ReturnType<T>;
}

export const DEFAULT_COUNT = 0;

export class ListOrder implements IListOrder {
	@observable text = '';
	@observable count = DEFAULT_COUNT;
	@observable order: IListOrder['order'] = {
		createdAt: Order.DESC,
	}; /* DEFAULT ORDER */
	@observable requestStatus = RequestStatus.Idle;

	constructor() {
		makeObservable(this);
	}

	@action.bound setOrder: IListOrder['setOrder'] = (accessor) => {
		this.order = {
			[accessor]: this.order[accessor] === Order.DESC ? Order.ASC : Order.DESC,
		};
	};

	@action.bound setSearchText: IListOrder['setSearchText'] = (text) => {
		this.text = `%${text}%`;
	};

	@action.bound getSortedList: IListOrder['getSortedList'] = (
		apiListGet,
		data,
	) => {
		try {
			this.requestStatus = RequestStatus.Request;

			if (data) {
				if (data.scope) {
					data.scope.order = this.order;
				} else {
					data.scope = {
						order: this.order,
					};
				}
			} else {
				data = {
					scope: {
						order: this.order,
					},
				} as NonNullable<typeof data>;
			}

			const response = apiListGet(data as NonNullable<typeof data>);

			void runInAction(async () => {
				const { data } = (await response) as AxiosResponse<{
					count: IListOrder['count'];
				}>;
				this.count = data.count;
			});
			this.requestStatus = RequestStatus.Success;

			return response;
		} catch (e) {
			this.requestStatus = RequestStatus.Failure;
			this.count = DEFAULT_COUNT;

			throw e;
		}
	};
}
