import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { routingStore } from '../store';
import { TRouteKey } from '../routes';
import { PrivateRoute } from './private-route';
import { Main } from 'components/main';
import { IAppRouteProps } from '../types';

function toRoute(
	{ Component, isPublic, routeKey, outletFor, ...routeProps }: IAppRouteProps,
	isNested: boolean,
) {
	const element = isPublic ? (
		<Component />
	) : (
		<PrivateRoute>
			{isNested ? (
				<Component />
			) : (
				<Main>
					<Component data-route-key={routeKey} />
				</Main>
			)}
		</PrivateRoute>
	);

	return (
		<Route key={routeKey} {...routeProps} element={element}>
			{outletFor &&
				toRoutes(
					routingStore.routeObjects.filter(({ routeKey }) =>
						outletFor?.includes(routeKey),
					),
					true,
				)}
		</Route>
	);
}

function toRoutes(routes: IAppRouteProps[], isNested = false) {
	const outletFor = routes.reduce(
		(acc, r) => (r.outletFor ? acc.concat(r.outletFor) : acc),
		[] as TRouteKey[],
	);

	return routes
		.filter((r) => r.outletFor || !outletFor.includes(r.routeKey))
		.map((r) => toRoute(r, isNested));
}

/**
 * @desc
 * Takes apart the routes.
 * Apart e.g: authentication routes.
 */
export const AppRoutes: FC = observer(() => {
	return <Routes>{toRoutes(routingStore.routeObjects)}</Routes>;
});
