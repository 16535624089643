import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'components/table';
import { invoicesStore } from 'stores/invoices';
import { Pagination } from 'modules/pagination-module';
import { InvoiceActions, InvoiceTableStatus } from './list-components';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { checkPaginationPage, formatCurrency } from 'utils';
import { observer } from 'mobx-react-lite';

import './styles.scss';
import { authStore } from 'stores/auth';
import { UserRole } from 'utils/api/api';

const FIRST_PAGE = 1;
const INVOICES_GET_LIST_LIMIT = 20;

export const AccountantInvoices: FC = observer(() => {
	const navigate = useNavigate();
	const intl = useIntl();

	const { currentPage = FIRST_PAGE } = useParams<{ currentPage?: string }>();
	const [page, setPage] = useState(Number(currentPage));

	const handlePaginate = useCallback(
		(paginatePage: number) => {
			setPage((state) => {
				if (paginatePage === state) {
					return state;
				}
				navigate(`/accountant/invoices/${paginatePage}`);
				return paginatePage;
			});
		},
		[navigate],
	);

	useEffect(() => {
		void invoicesStore.getList({
			scope: {
				limit: INVOICES_GET_LIST_LIMIT,
				page,
			},
		});
	}, [page, invoicesStore.order]);

	useEffect(() => {
		const { requestStatus, count } = invoicesStore;

		checkPaginationPage(requestStatus, count, Number(currentPage));
	}, [invoicesStore.count, invoicesStore.requestStatus, currentPage]);

	const data = useMemo(
		() =>
			invoicesStore.list.map((invoice) => ({
				...invoice,
				company: invoice.description,
				sum: formatCurrency(invoice.sum),
				createdAt: intl.formatDate(invoice.createdAt),
				updatedAt: intl.formatDate(invoice.updatedAt),
				actions: <InvoiceActions invoice={invoice} />,
				status: <InvoiceTableStatus invoice={invoice} />,
			})),
		[invoicesStore.list, intl],
	);

	return (
		<div id="accountantInvoices">
			<h1 className="main__title">
				<FormattedMessage id="Invoices" defaultMessage="Счета" />
			</h1>
			<Table
				className="invoice__table scroll"
				columns={[
					{ accessor: 'seqNo', label: '№', withSort: true },
					{ accessor: 'company', withSort: true },
					{ accessor: 'createdAt', withSort: true },
					{ accessor: 'sum', withSort: true },
					{ accessor: 'status', withSort: true },
					{
						accessor: 'actions',
						messageId: authStore.restrictFrom([
							UserRole.MonitorOwner,
							UserRole.Advertiser,
						])
							? 'app.table.invoiceActions'
							: undefined,
					},
				]}
				data={data}
				onSortClick={invoicesStore.setOrder}
				order={invoicesStore.order}
			/>
			<Pagination
				limit={INVOICES_GET_LIST_LIMIT}
				totalItems={invoicesStore.count}
				currentPage={+currentPage}
				onPageChange={handlePaginate}
			/>
		</div>
	);
});
