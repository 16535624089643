import React, {
	ChangeEvent,
	ComponentType,
	FC,
	HTMLAttributes,
	MouseEvent,
	ReactNode,
	useCallback,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import OutsideClickHandler from 'react-outside-click-handler';
import TextareaAutosize from 'react-textarea-autosize';

import { Confirmation, Layout } from 'components/common';

import { formatBytes, formatSeconds } from 'utils';
import { FileResponse, FileType } from 'utils/api/api';
import { explorerStore } from 'stores/media';
import { FilePreview, IFilePreviewProps } from './file-preview';
import { FileStatus } from './file-status';
import './explorer.scss';

export interface IExplorerItemProps
	extends Pick<IFilePreviewProps, 'file'>,
		HTMLAttributes<HTMLDivElement> {
	ActionComponent?: ComponentType<{ item: FileResponse }>;
	actionElement?: ReactNode;
	customContextMenu?: boolean;
}

export const ExplorerItem: FC<IExplorerItemProps> = observer(
	({ file, ActionComponent, actionElement, customContextMenu = false }) => {
		const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

		const [state, setState] = useState({
			menuIsActive: false,
			renaming: {
				isActive: false,
				value: file.name,
			},
		});

		const handleNameBlur = useCallback(() => {
			if (state.renaming.value !== file.name) {
				void explorerStore.updateMedia(file.id, state.renaming.value);
			}
			explorerStore.rename(null);
		}, [state.renaming.value, file.name, file.id]);

		const turnMenuOn = (event: MouseEvent<HTMLDivElement>) => {
			if (!customContextMenu) {
				return;
			}

			event.preventDefault();
			setState({
				...state,
				menuIsActive: true,
			});
		};

		const turnMenuOff = () => {
			setState({
				...state,
				menuIsActive: false,
			});
		};

		const turnRenamingOn = () => {
			setState({
				...state,
				menuIsActive: false,
				renaming: {
					...state.renaming,
					isActive: true,
				},
			});
		};

		const turnRenamingOff = () => {
			setState({
				...state,
				renaming: {
					...state.renaming,
					isActive: false,
				},
			});
		};

		const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
			setState({
				...state,
				renaming: {
					...state.renaming,
					value: event.target.value,
				},
			});
		};

		const handleNameKeydown = (
			event: React.KeyboardEvent<HTMLTextAreaElement>,
		) => {
			if (event.code === 'Enter') {
				event.preventDefault();

				setState({
					...state,
					renaming: {
						...state.renaming,
						isActive: false,
					},
				});
				handleNameBlur();
			}
		};

		const handleDelete = useCallback(() => {
			setConfirmationIsOpen(true);
		}, []);

		const renderMenu = () => {
			if (state.menuIsActive) {
				return (
					<OutsideClickHandler onOutsideClick={turnMenuOff}>
						<div className="media-list__item-menu">
							<div className="media-list__item-action" onClick={turnRenamingOn}>
								<FormattedMessage id="Rename" defaultMessage="Rename" />
							</div>
							<div className="media-list__item-action" onClick={handleDelete}>
								<FormattedMessage id="Delete" defaultMessage="Delete" />
							</div>
						</div>
					</OutsideClickHandler>
				);
			}

			return null;
		};

		const renderItemName = () => {
			if (explorerStore.renameId === file.id || state.renaming.isActive) {
				return (
					<OutsideClickHandler onOutsideClick={turnRenamingOff}>
						<TextareaAutosize
							autoFocus
							className="media-list__item-textarea"
							value={state.renaming.value}
							onChange={handleNameChange}
							onKeyDown={handleNameKeydown}
							onBlur={handleNameBlur}
							maxRows={2}
						/>
					</OutsideClickHandler>
				);
			}

			return (
				<div className="media-list__item-filename" title={file.name}>
					{file.name}
				</div>
			);
		};

		const renderConfirmation = () => {
			if (!confirmationIsOpen) {
				return null;
			}

			const handleConfirm = () => {
				void explorerStore.deleteFile(file.id);

				setConfirmationIsOpen(false);
			};

			const handleCancel = () => {
				setConfirmationIsOpen(false);
			};

			return (
				<Confirmation
					message={'Are you sure to delete the selected one?'}
					onConfirm={handleConfirm}
					onCancel={handleCancel}
				/>
			);
		};

		return (
			<>
				{renderConfirmation()}
				<Layout
					flex
					column
					className="media-list__item"
					onContextMenu={turnMenuOn}
				>
					<Layout flex column className="media-list__item-preview">
						<FilePreview
							file={file}
							className="media-list__item-preview_image"
						/>
						<span className="media-list__item-preview_ext">
							{file.extension}
						</span>
					</Layout>
					{renderItemName()}
					<FileStatus className="media-list__item-status" file={file} />
					<div className="media-list__item-file-info">
						{file.type !== FileType.Image && (
							<span className="media-list__item-file-info_duration">
								{file.duration ? formatSeconds(file.duration) : null}
							</span>
						)}
						<span className="media-list__item-file-info_size">
							{file.filesize ? formatBytes(file.filesize) : null}
						</span>
					</div>
					{actionElement ||
						(ActionComponent && <ActionComponent item={file} />)}
					{renderMenu()}
				</Layout>
			</>
		);
	},
);
