import { action, makeObservable, observable, runInAction } from 'mobx';

export type TName = symbol | number | string;

export interface IErrorStore {
	errors: Record<TName, string | boolean>;
	isErrorName: (name: TName | undefined) => string | boolean;

	setError(name: TName, msg?: string): IErrorStore['errors'];

	setErrors(
		errors: IErrorStore['errors'] | Array<TName | { name: TName; msg: string }>,
	): IErrorStore['errors'];

	takeOff(names: TName | TName[]): IErrorStore['errors'];

	reset(): IErrorStore['errors'];
}

class ErrorStore implements IErrorStore {
	@observable errors: IErrorStore['errors'] = {};

	constructor() {
		makeObservable(this);
	}

	isErrorName: IErrorStore['isErrorName'] = (name) => {
		return Boolean(name && this.errors[name]);
	};

	@action setError: IErrorStore['setError'] = (name, msg) => {
		this.errors[name] = msg || true;
		return this.errors;
	};

	@action setErrors: IErrorStore['setErrors'] = (errors) => {
		if (Array.isArray(errors)) {
			this.errors = {
				...this.errors,
				...Object.fromEntries(
					errors.map((error) =>
						typeof error === 'object' ? [error.name, error.msg] : [error, true],
					),
				),
			};
		} else {
			this.errors = {
				...this.errors,
				...errors,
			};
		}

		return this.errors;
	};

	takeOff: IErrorStore['takeOff'] = (names) => {
		const errorNames = Array.isArray(names) ? names : [names];

		errorNames.forEach((name) => runInAction(() => delete this.errors[name]));

		return this.errors;
	};

	@action reset: IErrorStore['reset'] = () => {
		this.errors = {};
		return this.errors;
	};
}

export const errorStore = new ErrorStore();
