import menuIcons from './icons.module.scss';
import { TRouteKey } from 'modules/routing-module/routes';

/**
 * @example
 * {
 *   iconClassName: '{moduleName}_menu__item__icon-library__{hash}',
 *   iconClassNameActive: '{moduleName}_menu__item__icon-library-active__{hash}'
 * }
 */
export interface IMenuIconsClassList {
	iconClassName: string;
	iconClassNameActive?: string;
}

const MENU_ICONS_ROUTE_KEYS: TRouteKey[] = [
	'Desktop',
	'Bids',
	'Monitors',
	'Playlists',
	'Library',
	'NotFound',
	'Users',
	'Editor',
	'Profile',
	'Statistics',
	'Logout',
];

export const menuIconsByRouteKey = MENU_ICONS_ROUTE_KEYS.reduce(
	(acc, routeKey) => {
		const defaultStateName = `menu__item__icon${routeKey}`;
		const activeStateName = `${defaultStateName}--active`;
		const iconClassName = menuIcons[defaultStateName];

		return {
			...acc,
			[routeKey]: {
				iconClassName,
				iconClassNameActive: menuIcons[activeStateName],
			},
		};
	},
	{} as { [K in TRouteKey]: IMenuIconsClassList },
);
