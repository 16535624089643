import cx from 'classnames';
import {
	toast as ReactToastifyToast,
	ToastClassName,
	ToastOptions,
} from 'react-toastify';

import { Toast } from 'components/common';
import { throttle } from '../helpers';

export type TMethod = (description: string, options?: ToastOptions) => void;
export type TToast = {
	success: TMethod;
	info: TMethod;
	warning: TMethod;
	error: TMethod;
};

export const TOAST_SHOW_TIMEOUT_MS = 1500;

const toastClassNameToString = (tClassName?: ToastClassName) =>
	typeof tClassName === 'function' ? tClassName() : tClassName;

export const toast: TToast = {
	success: (description, options = {}) => {
		if (description) {
			ReactToastifyToast.success(
				<Toast title="Success" description={description} />,
				{
					...options,
					className: cx(
						'Toastify__toast-message',
						toastClassNameToString(options.className),
					),
				},
			);
		}
	},
	info: (description, options = {}) => {
		if (description) {
			ReactToastifyToast.info(
				<Toast title="Info" description={description} />,
				{
					...options,
					className: cx(
						'Toastify__toast-message',
						toastClassNameToString(options.className),
					),
				},
			);
		}
	},
	warning: (description, options = {}) => {
		if (description) {
			ReactToastifyToast.warning(
				<Toast title="Warning" description={description} />,
				{
					...options,
					className: cx(
						'Toastify__toast-message',
						toastClassNameToString(options.className),
					),
				},
			);
		}
	},
	error: (description, options = {}) => {
		if (description) {
			ReactToastifyToast.error(
				<Toast title="Error" description={description} />,
				{
					...options,
					autoClose: options.autoClose || 15000,
					className: cx(
						'Toastify__toast-message',
						toastClassNameToString(options.className),
					),
				},
			);
		}
	},
};

export const showToast = throttle(
	(message: Parameters<TMethod>[0], f: TMethod = toast.info) => f(message),
	TOAST_SHOW_TIMEOUT_MS,
);
