import GeoJSON, { FeatureCollection } from 'geojson';

export function arrayToCollection<T, K extends keyof T = keyof T>(
	arr: T[],
	key: K,
	include?: K[],
): FeatureCollection {
	// @ts-expect-error: No typings for GeoJSON.parse
	return GeoJSON.parse(
		arr.filter((item): item is T => Boolean(item)).filter((item) => item[key]),
		{ GeoJSON: key, include },
	);
}
