import { observer } from 'mobx-react-lite';
import { RouteLink } from 'modules/routing-module';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import cx from 'classnames';
import { formatCurrency } from 'utils';
import { walletStore } from 'stores/wallet';

import './wallet.scss';

export const Wallet = observer(() => {
	const intl = useIntl();

	return (
		<div className="wallet">
			<div className="wallet__balance">
				<span className="wallet__balance-label">Баланс:</span>
				<h1>{formatCurrency(walletStore.wallet.total, intl)}</h1>
			</div>
			<nav className="tab wallet__nav">
				<RouteLink
					routeKey="WalletHistory"
					className={({ isActive }) =>
						cx('tab__item', {
							active: isActive,
						})
					}
				>
					<FormattedMessage
						id="app.route.WalletHistory"
						defaultMessage="История"
					/>
				</RouteLink>
			</nav>
			<Outlet />
		</div>
	);
});
