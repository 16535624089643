import { IMapFilterData } from 'pages/monitors/monitors-map';
import { MonitorCategory, UserRole } from 'utils/api/api';
import { icons } from 'assets/icons';

export type TAbbreviationKeys = keyof IMapFilterData | MonitorCategory;

export const ABBREVIATIONS: Record<TAbbreviationKeys, string> = {
	address: 'adr',
	selectedCategories: 'sc',
	radius: 'r',
	latitude: 'lat',
	longitude: 'lng',
	price1s: 'p1s',
	minWarranty: 'mwrnt',
	maxDuration: 'mdur',
	noStrictSearch: 'nostrict',
	[MonitorCategory.GAS_STATION]: 'gas',
	[MonitorCategory.MALL]: 'mall',
	[MonitorCategory.FOOD]: 'food',
	[MonitorCategory.CAFES_AND_RESTAURANTS]: 'cafe',
	[MonitorCategory.PHARMACY]: 'pha',
	[MonitorCategory.ATM]: 'atm',
	[MonitorCategory.HOTEL]: 'hotl',
	[MonitorCategory.AUTO_REPAIR]: 'autrr',
	[MonitorCategory.CAR_WASHING]: 'carwsh',
	[MonitorCategory.HOSPITALS]: 'hsptl',
	[MonitorCategory.CINEMAS]: 'cnma',
	[MonitorCategory.SAUNAS]: 'sana',
	[MonitorCategory.BEAUTY_SALONS]: 'besalo',
};

export const ABBREVIATIONS_REVERSE = Object.fromEntries(
	Object.entries(ABBREVIATIONS).map(([key, abbr]) => [abbr, key]),
);
export const PARAMS_SEPARATOR = ',';
export const PLUS = '+';
export const MINUS = '-';

export const GRAY_COLOR = '#697185';
export const GREEN_COLOR = '#32c832';
export const GREEN_DARK_COLOR = '#00a800';

export const FIELD_NAME_SEPARATOR = '.';
export const ONE_GB_IN_BYTES = 1073741824;
export const TEN_GB_IN_BYTES = 10 * ONE_GB_IN_BYTES;
export const TRANSITION_MS = {
	LAYOUT_CHANGES: 300,
};

export const RESPONSE_CODE = {
	unauthorized: 'server-error.10001',
	forbidden: 'server-error.10002',
	bad_request: 'server-error.10004',
	server_error: 'server-error.10000',
};

export const AUTH_STORAGE = 'authStore';
export const AUTH_REMEMBER_ME = 'remember_me';
export const STORAGE_NAME = {
	SIDEBAR_COLLAPSE: 'SIDEBAR_COLLAPSE',
};

export const tableConstants = {
	columns: [
		'ID',
		{ accessor: 'name', withSort: true },
		{ accessor: 'surname', withSort: true },
		{ accessor: 'middleName', withSort: true },
		{ accessor: 'email', withSort: true },
		{ accessor: 'role', withSort: true },
		'createdAt',
		'updatedAt',
		'actions',
	],

	userRoles: [
		UserRole.Advertiser,
		UserRole.MonitorOwner,
		UserRole.Administrator,
	],
};

export const ICONS_CLASSNAME = {
	[MonitorCategory.ATM]: icons.Atm,
	[MonitorCategory.FOOD]: icons.Cart,
	[MonitorCategory.MALL]: icons.Malls,
	[MonitorCategory.SAUNAS]: icons.Sauna,
	[MonitorCategory.HOTEL]: icons.Hotel,
	[MonitorCategory.HOSPITALS]: icons.Hospital,
	[MonitorCategory.AUTO_REPAIR]: icons.CarRepair,
	[MonitorCategory.CAR_WASHING]: icons.CarWashing,
	[MonitorCategory.PHARMACY]: icons.Pharma,
	[MonitorCategory.BEAUTY_SALONS]: icons.BeautySalon,
	[MonitorCategory.CAFES_AND_RESTAURANTS]: icons.Cafe,
	[MonitorCategory.CINEMAS]: icons.Cinema,
	[MonitorCategory.GAS_STATION]: icons.GasStation,
};

export const ACCEPT_AUDIO_EXTS = ['.avi', '.mp3'].join(',');
export const ACCEPT_IMAGE_EXTS = ['.jpg', '.jpeg', '.bmp', '.png'].join(',');
export const ACCEPT_VIDEO_EXTS = ['.mpg', '.mp4', '.mov', '.wmv'].join(',');
export const ACCEPT_DOCUMENT_EXTS = [
	'.pdf',
	'.xls',
	'.xlsx',
	'.doc',
	'.docx',
	'.ods',
	'.odt',
].join(',');

export const ACCEPT_FILE_EXTS = [
	ACCEPT_AUDIO_EXTS,
	ACCEPT_VIDEO_EXTS,
	ACCEPT_IMAGE_EXTS,
].join(',');

export const NO_BREAK_SPACE = '\u00A0';
export const UI_TIMEOUT_MS = 144;
export const INPUT_MASK_SEPARATOR = '-';
export const INPUT_MASK = {
	COORDS: '00.00000',
	MONITOR_CODE: ['999', '999', '999'].join(INPUT_MASK_SEPARATOR),
	PHONE_NUMBER: '+7 (999) 999-9999',
};
