import React, { ChangeEvent, FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import TextareaAutosize from 'react-textarea-autosize';
import { observer } from 'mobx-react-lite';

import { explorerStore } from 'stores/media';

export const NewFolder: FC = observer(() => {
	const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		explorerStore.folderCreation.value = event.target.value;
	};

	const handleNameKeydown = (
		event: React.KeyboardEvent<HTMLTextAreaElement>,
	) => {
		if (event.code === 'Enter') {
			event.preventDefault();
			explorerStore.turnFolderCreationOff();
		}
	};

	return (
		<div className="folder__layout">
			<div className="folder__item library__item">
				<div className="folder__icon" />
				<OutsideClickHandler
					onOutsideClick={explorerStore.turnFolderCreationOff}
				>
					<TextareaAutosize
						className="folder__textarea"
						value={explorerStore.folderCreation.value}
						onChange={handleNameChange}
						onKeyDown={handleNameKeydown}
						maxRows={2}
						autoFocus
					/>
				</OutsideClickHandler>
			</div>
		</div>
	);
});
