import { ComponentType } from 'react';
import { observer } from 'mobx-react-lite';
import { errorStore } from '../stores/error-store';

export interface ICheckInputErrorProps {
	name?: string;
}

export interface ICheckInputErrorHOCProps {
	isError: boolean;
}

export const checkInputError = <
	T extends ICheckInputErrorProps,
	HOCProps extends T & ICheckInputErrorHOCProps = T & ICheckInputErrorHOCProps,
>(
	Component: ComponentType<HOCProps>,
) =>
	observer((hocProps: T) => {
		const props = {
			...hocProps,
			isError: errorStore.isErrorName(hocProps.name),
		} as HOCProps;

		return <Component {...props} />;
	});
