import { EditorLayerResponse, EditorResponse } from 'utils/api/api';
import { IEditorProject, IEditorTrack } from './types';

export function normalizeLayer(
	rawLayer: EditorLayerResponse | IEditorTrack,
): IEditorTrack {
	return {
		...rawLayer,
		mediaId: rawLayer.file.id,
		type: rawLayer.file.type,
	};
}

export function normalizeProject(
	rawProject: EditorResponse | IEditorProject,
): IEditorProject {
	const startToEnd = (l1: EditorLayerResponse, l2: EditorLayerResponse) =>
		l1.start > l2.start ? 1 : -1;
	const [
		{ layers: audioLayers, totalDuration: audioDuration },
		{ layers: videoLayers, totalDuration: videoDuration },
	] = (['audioLayers', 'videoLayers'] as const).map((key) => {
		const rawLayers = rawProject[key];

		if (rawLayers?.length) {
			const layers = rawLayers.sort(startToEnd).map(normalizeLayer);
			const lastLayer = layers[layers.length - 1];

			return {
				layers,
				totalDuration: lastLayer.start + lastLayer.duration,
			};
		}
		return { layers: [], totalDuration: 0 };
	});

	return {
		...rawProject,
		audioLayers,
		videoLayers,
		totalDuration:
			audioDuration > videoDuration ? audioDuration : videoDuration,
	};
}
