import { DateInput, Form } from 'components/forms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'hooks';
import { handleError, resetDateTime, swaggerApi, toast } from 'utils';
import { BidPrecalcPromoRequest, MonitorResponse } from 'utils/api/api';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { invoicesStore } from 'stores/invoices';
import { useNavigate } from 'react-router-dom';
import { DAY_IN_WEEK_NUMBER } from '../../utils/intl';

const PLAYLIST_DURATION_SEC = [5, 10, 15];

export interface IPrecalcForm {
	playlistDuration: BidPrecalcPromoRequest['playlistDuration'];
	dateRange: string[];
}

export interface IMonitorPrecalcFromProps {
	monitorIds: Array<MonitorResponse['id']>;
}

export function MonitorsPrecalcForm({ monitorIds }: IMonitorPrecalcFromProps) {
	const intl = useIntl();
	const navigate = useNavigate();
	const [sum, setSum] = useState<string>('');
	const [isCalc, setIsCalc] = useState(false);

	const today = useMemo(() => new Date(), []);

	const initialDateRange = useMemo(
		() => [
			today.toISOString(),
			new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() + DAY_IN_WEEK_NUMBER,
			).toISOString(),
		],
		[],
	);

	const precalc = useCallback(
		async (
			values: IPrecalcForm,
			monitorIds: BidPrecalcPromoRequest['monitorIds'],
		) => {
			let sum = '';

			if (monitorIds.length) {
				try {
					setIsCalc(true);

					const { data: precalcData } = await swaggerApi.api.bidPrecalcPromo({
						monitorIds,
						playlistDuration: values.playlistDuration,
						dateFrom: values.dateRange[0],
						dateTo: values.dateRange[1],
					});

					sum = precalcData.data.sum;
				} catch (e) {
					toast.error(handleError(e));
					sum = '';
				} finally {
					setIsCalc(false);
				}
			}

			setSum(sum);

			return sum;
		},
		[],
	);

	const [{ formProps, values, canSubmit }, { submit }] = useForm(
		{
			playlistDuration: 5,
			dateRange: initialDateRange,
		},
		{
			disableDeepEqual: true,
			required: ['playlistDuration', 'dateRange'],
			parsers: {
				dateRange: (value) => {
					let parsedValue = value as typeof initialDateRange;

					try {
						if (typeof value === 'string') {
							parsedValue = JSON.parse(value);
						}
					} catch (e) {
						console.error(e);
						parsedValue = initialDateRange;
					}

					return {
						dateRange: parsedValue,
					};
				},
			},
			onSubmit: async (data) => {
				if (isCalc) {
					return toast.warning('Подождите! Идёт расчёт стоимости...');
				}
				const sum = await precalc(data, monitorIds);

				invoicesStore
					.create(parseFloat(sum))
					.then(() => navigate('/accountant/invoices'))
					.catch((err) => {
						toast.error(handleError(err));
					});
			},
		},
	);

	const durationList = useMemo(
		() =>
			PLAYLIST_DURATION_SEC.map((seconds) => {
				const checked = seconds === values.playlistDuration;

				return (
					<label
						key={`seconds-${seconds}`}
						className={cx(['monitor__filter-radius-select', { checked }])}
					>
						<input
							name="playlistDuration"
							type="radio"
							value={seconds}
							checked={checked}
							onChange={() => {
								return;
							}}
						/>
						{seconds} <FormattedMessage id="sec" defaultMessage="сек" />
					</label>
				);
			}),
		[values.playlistDuration],
	);

	useEffect(() => {
		void precalc(values, monitorIds);
	}, [values, monitorIds, precalc]);

	return (
		<Form {...formProps} className="monitor-precalc-form">
			<div className="monitor-precalc-form__calendar">
				<span className="monitor-precalc-form__calendar-title">
					<FormattedMessage id="app.table.period" defaultMessage="Период" />
				</span>
				<DateInput
					min={resetDateTime(new Date())}
					className="inlined monitor-precalc-form__date-range"
					name="dateRange"
					value={values.dateRange.map((str) => new Date(str))}
					onChange={() => null}
				/>
			</div>
			<div className="monitor__filter-radius-select-layout">{durationList}</div>
			<div className="monitor-precalc-form__output">
				<FormattedMessage id="app.field.sum" />:
				<span style={{ marginLeft: '10px' }}>
					{intl.formatNumber(parseFloat(sum) || 0, {
						style: 'currency',
						currency: 'RUB',
					})}
				</span>
			</div>
			<button
				type="submit"
				className="button button-primary button-rounded monitor-precalc-form__button"
				disabled={!canSubmit || isCalc}
				onClick={submit}
			>
				<FormattedMessage
					id="app.button.invoiceCreate"
					defaultMessage="Выставить счет"
				/>
			</button>
		</Form>
	);
}
