import { FeatureCollection, Point } from 'geojson';

import { toast } from '../toast';
import { handleError } from '../api/handle-error';
import { throttle } from '../helpers';
import { MonitorCategory } from '../api/api';
import { IGeoSearchParams, IGeoSearchStorage } from './types';

export const YANDEX_SEARCH_LATENCY_MS = 1500;
export const MAX_YANDEX_SEARCH_RESULT_COUNT = 500;
export const MAX_YANDEX_SEARCH_SKIP = 1000;
export const yandexSearchTextByMonitorCategory: Record<
	MonitorCategory,
	IGeoSearchParams['text']
> = {
	[MonitorCategory.GAS_STATION]: 'АЗС',
	[MonitorCategory.MALL]: 'Торговые центры',
	[MonitorCategory.FOOD]: 'Продукты',
	[MonitorCategory.CAFES_AND_RESTAURANTS]: 'Кафе и рестораны',
	[MonitorCategory.PHARMACY]: 'Аптеки',
	[MonitorCategory.ATM]: 'Банкоматы',
	[MonitorCategory.HOTEL]: 'Отели',
	[MonitorCategory.AUTO_REPAIR]: 'Автосалоны',
	[MonitorCategory.CAR_WASHING]: 'Автомойки',
	[MonitorCategory.HOSPITALS]: 'Больницы',
	[MonitorCategory.CINEMAS]: 'Кинотеатры',
	[MonitorCategory.SAUNAS]: 'Сауны',
	[MonitorCategory.BEAUTY_SALONS]: 'Салоны красоты',
};

const geoSearchStorage: IGeoSearchStorage = {
	results: {},
};

export const geoSearch = async (
	params: IGeoSearchParams,
	callback: (collection: FeatureCollection) => void,
	withStorage = true,
) => {
	try {
		const searchParams = new URLSearchParams({
			type: 'geo',
			lang: 'ru_RU',
		});
		Object.entries(params).forEach(([k, v]) =>
			searchParams.append(k, String(v)),
		);
		const storageParams = searchParams.toString();
		let result;

		if (withStorage) {
			result = geoSearchStorage.results[storageParams];

			if (result) {
				return callback(result);
			}
		}

		searchParams.set('apikey', process.env.REACT_APP_YMAPS_SEARCH_KEY || '');
		const response = await fetch(
			`${process.env.REACT_APP_YMAPS_SEARCH_API_URL}?${searchParams}`,
		);
		result = (await response.json()) as FeatureCollection | Error;

		if (!('features' in result)) return;

		result.features = result.features.map((f) => {
			const geometry = f.geometry as Point;

			/* Normalizes coords ordering to the [latitude, longitude] format. */
			geometry.coordinates = [geometry.coordinates[1], geometry.coordinates[0]];

			return {
				...f,
				geometry,
			};
		});

		if (withStorage) {
			geoSearchStorage.results[storageParams] = result;
		}

		callback(result);
	} catch (e) {
		toast.error(handleError(e));
	}
};

export const throttledGeoSearch = throttle(geoSearch, YANDEX_SEARCH_LATENCY_MS);
