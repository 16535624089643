import {
	FC,
	HTMLAttributes,
	MouseEventHandler,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
} from 'react';
import { IPropsWithYandexMaps, withYandexMaps } from '../../utils/yandex-api';
import { YMapMarkerProps } from '@yandex/ymaps3-types';
import cx from 'classnames';
import { action, makeObservable, observable } from 'mobx';
import { random } from '../../utils';
import { observer } from 'mobx-react-lite';

export interface IPlacemarkProps
	extends IPropsWithYandexMaps,
		YMapMarkerProps,
		Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'children' | 'title'> {
	label?: ReactNode;
}

class PlacemarkStore {
	static zIndex = 1;
	static zIndexOpen = 2;
	@observable openPlacemarkKey: string | null = null;

	constructor() {
		makeObservable(this);
	}

	toggle = (key: string) => {
		if (this.openPlacemarkKey === key) {
			this.close();
		} else {
			this.open(key);
		}
	};

	@action open(key: string) {
		if (key) {
			this.openPlacemarkKey = key;
		}
	}

	@action close(key?: string | null) {
		this.openPlacemarkKey = key || null;
	}
}

export const placemarkStore = new PlacemarkStore();

const PlacemarkComponent: FC<IPlacemarkProps> = ({
	ymaps3,
	children,
	className,
	label,
	title,
	zIndex,
	...restProps
}) => {
	const key = useMemo(() => random(), []);

	const isOpen = useMemo(
		() => placemarkStore.openPlacemarkKey === key,
		[key, placemarkStore.openPlacemarkKey],
	);

	const zIdx = useMemo(
		() =>
			isOpen ? PlacemarkStore.zIndexOpen : zIndex || PlacemarkStore.zIndex,
		[zIndex, isOpen],
	);

	const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
		(e) => {
			e.stopPropagation();
			placemarkStore.toggle(key);
		},
		[key],
	);

	const handlePopupClick = useCallback<MouseEventHandler<HTMLDivElement>>(
		(e) => {
			e.stopPropagation();
		},
		[],
	);

	useEffect(() => {
		return () => {
			placemarkStore.close(key);
		};
	}, []);

	return (
		<ymaps3.YMapMarker {...restProps} zIndex={zIdx}>
			<div className={cx('placemark', className)}>
				<div className="placemark__icon" title={title} onClick={handleClick}>
					<div className="placemark__icon-label">{label}</div>
				</div>
				{isOpen && children && (
					<div className="placemark__popup" onClick={handlePopupClick}>
						<div className="placemark__popup-content">{children}</div>
					</div>
				)}
			</div>
		</ymaps3.YMapMarker>
	);
};

export const Placemark = withYandexMaps(observer(PlacemarkComponent));
