import {
	DragEvent,
	HTMLAttributes,
	ReactElement,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import cx from 'classnames';

import './styles.scss';
import { toast } from 'utils';
import { useIntl } from 'react-intl';

export interface IDndProps
	extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'onChange'> {
	children: ReactElement[];
	onChange: (children: ReactElement[]) => void;
}

export const CLASSNAMES = {
	DRAGGABLE: 'dnd__draggable',
	DROPPABLE: 'dnd__droppable',
	OVER: 'over',
};

export function Dnd({
	children,
	className,
	onChange,
	...restProps
}: IDndProps) {
	const intl = useIntl();
	const dndRef = useRef<HTMLDivElement>(null);
	const [childArray, setChildArray] = useState(children);
	const [submitData, setSubmitData] = useState<ReactElement[] | null>(null);

	const hideOverEffect = useCallback((elem: HTMLDivElement) => {
		elem.classList.remove(CLASSNAMES.OVER);
	}, []);

	const handleDragStart = (
		e: DragEvent<HTMLDivElement>,
		draggedChild: ReactElement,
	) => {
		const key = draggedChild.key;
		if (!key) {
			throw new Error(`Dragged element needs "key" prop: ${key}`);
		}
		e.dataTransfer.setData('text/plain', JSON.stringify({ key }));
	};

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.currentTarget.classList.add(CLASSNAMES.OVER);
	};

	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		hideOverEffect(e.currentTarget);
	};

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>, droppedChild: ReactElement) => {
			e.preventDefault();
			const droppedKey = droppedChild.key;
			if (!droppedKey) {
				throw new Error(`Dropped element needs "key" prop: ${droppedKey}`);
			}

			try {
				const { key: draggedKey } = JSON.parse(
					e.dataTransfer?.getData('text/plain'),
				) as {
					key: NonNullable<ReactElement['key']>;
				};

				setChildArray((children) => {
					const newState = [...children];
					const droppedIdx = children.findIndex((c) => c.key === droppedKey);
					const draggedIdx = children.findIndex((c) => c.key === draggedKey);

					newState[droppedIdx] = children[draggedIdx];
					newState[draggedIdx] = children[droppedIdx];

					setSubmitData(newState);

					return newState;
				});
				hideOverEffect(e.currentTarget);
			} catch (e) {
				const errorMessage = intl.formatMessage({
					id: 'DndError',
					defaultMessage:
						'Непредвиденная ошибка при перетаскивания елемента страницы.',
				});
				toast.error(errorMessage);
				console.error(errorMessage, e);
			}
		},
		[setChildArray, hideOverEffect, intl],
	);

	useEffect(() => {
		if (onChange && submitData) {
			onChange(submitData);
			setSubmitData(null);
		}
	}, [submitData, onChange]);

	return (
		<div {...restProps} ref={dndRef} className={cx('dnd', className)}>
			{childArray.map((child) => {
				const { draggable = true } = child.props;
				return (
					<div
						key={child.key}
						className={cx(CLASSNAMES.DROPPABLE)}
						onDragOver={handleDragOver}
						onDragLeave={handleDragLeave}
						onDrop={(el) => handleDrop(el, child)}
					>
						<div
							className={cx(CLASSNAMES.DRAGGABLE)}
							draggable={draggable}
							onDragStart={(el) => handleDragStart(el, child)}
						>
							{child}
						</div>
					</div>
				);
			})}
		</div>
	);
}
