export function filterObject<
	T,
	In extends Array<keyof T> = [],
	Ex extends Array<keyof T> = [],
>(
	data: T,
	options: Partial<{
		includedKeys: In;
		excludedKeys: Ex;
	}> = {},
) {
	if (!data || typeof data !== 'object') {
		throw TypeError(`Argument [0] requires "object" type: ${data}`);
	}
	const { includedKeys, excludedKeys } = options;
	const result = { ...data };

	if (includedKeys) {
		Object.keys(result).forEach((key) => {
			if (!includedKeys.includes(key)) {
				delete result[key];
			}
		});
	}

	if (excludedKeys) {
		Object.keys(result).forEach((key) => {
			if (excludedKeys.includes(key)) {
				delete result[key];
			}
		});
	}

	return result as In extends undefined
		? Ex extends undefined
			? T
			: Omit<T, Ex[number]>
		: Ex extends undefined
			? Pick<T, In[number]>
			: Pick<T, Exclude<In[number], Ex[number]>>;
}
