import { Api } from './api';
import { withJwt } from './with-jwt';

export const swaggerApi = new Api({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 10000,
	headers: {
		'content-type': 'application/json',
	},
	validateStatus(status) {
		return status >= 200 && status < 300;
	},
	maxContentLength: Infinity,
});
export const { cancelToken, setToken, refreshToken } = withJwt(swaggerApi);

export const api = {};
