import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import TextareaAutosize from 'react-textarea-autosize';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { explorerStore, IFolderItem } from 'stores/media';
import { Confirmation } from 'components/common';

import './styles.scss';

export const LibraryFolder: FC<{
	folder: IFolderItem;
	onClick: () => void;
	customContextMenu?: boolean;
	noActions?: boolean;
}> = observer(({ folder, onClick, customContextMenu = false, noActions }) => {
	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

	const [state, setState] = useState({
		menuIsActive: false,
		renaming: {
			isActive: false,
			value: folder.name,
		},
	});

	const handleNameBlur = useCallback(() => {
		if (state.renaming.value !== folder.name) {
			void explorerStore.updateFolder(folder.id, state.renaming.value);
		}
		explorerStore.rename(null);
	}, [state.renaming.value, folder.id, folder.name]);

	const turnMenuOn = (event: React.MouseEvent<HTMLDivElement>) => {
		if (noActions || !customContextMenu) {
			return;
		}

		event.preventDefault();
		setState({
			...state,
			menuIsActive: true,
		});
	};

	const turnMenuOff = () => {
		setState({
			...state,
			menuIsActive: false,
		});
	};

	const turnRenamingOn = () => {
		setState({
			...state,
			menuIsActive: false,
			renaming: {
				...state.renaming,
				isActive: true,
			},
		});
	};

	const turnRenamingOff = () => {
		setState({
			...state,
			renaming: {
				...state.renaming,
				isActive: false,
			},
		});
	};

	const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setState({
			...state,
			renaming: {
				...state.renaming,
				value: event.target.value,
			},
		});
	};

	const handleNameKeydown = (
		event: React.KeyboardEvent<HTMLTextAreaElement>,
	) => {
		if (event.code === 'Enter') {
			event.preventDefault();

			setState({
				...state,
				renaming: {
					...state.renaming,
					isActive: false,
				},
			});
			handleNameBlur();
		}
	};

	const handleClick = () => {
		if (
			explorerStore.renameId === folder.id ||
			state.menuIsActive ||
			state.renaming.isActive
		) {
			return;
		}

		onClick();
	};

	const handleDelete = useCallback(() => {
		setConfirmationIsOpen(true);
	}, []);

	const renderMenu = () => {
		if (state.menuIsActive) {
			return (
				<OutsideClickHandler onOutsideClick={turnMenuOff}>
					<div className="folder__menu">
						<div className="folder__action" onClick={turnRenamingOn}>
							<FormattedMessage id="Rename" defaultMessage="Rename" />
						</div>
						<div className="folder__action" onClick={handleDelete}>
							<FormattedMessage id="Delete" defaultMessage="Delete" />
						</div>
					</div>
				</OutsideClickHandler>
			);
		}

		return null;
	};

	const renderFolderName = () => {
		if (explorerStore.renameId === folder.id || state.renaming.isActive) {
			return (
				<OutsideClickHandler onOutsideClick={turnRenamingOff}>
					<TextareaAutosize
						className="folder__textarea"
						value={state.renaming.value}
						onChange={handleNameChange}
						onKeyDown={handleNameKeydown}
						onBlur={handleNameBlur}
						maxRows={2}
					/>
				</OutsideClickHandler>
			);
		}

		return (
			<div className="folder__name" title={folder.name}>
				{folder.name}
			</div>
		);
	};

	const renderConfirmation = () => {
		if (!confirmationIsOpen) {
			return null;
		}

		const message = folder.empty
			? 'Are you sure to delete the selected one?'
			: 'Among the deleted folders there are folders with nested elements that will be lost when deleted. Continue deleting?';

		const handleConfirm = () => {
			explorerStore.deleteFolder(folder.id);

			setConfirmationIsOpen(false);
		};

		const handleCancel = () => {
			setConfirmationIsOpen(false);
		};

		return (
			<Confirmation
				message={message}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
		);
	};

	const isChecked = explorerStore.selected.folderIds.includes(folder.id);

	const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
		e.stopPropagation();
		explorerStore.selectFolder(folder.id);
	};

	return (
		<>
			{renderConfirmation()}
			<div className="folder__layout" onClick={handleClick}>
				<div className="folder__item library__item" onContextMenu={turnMenuOn}>
					<div className="folder__icon" />
					{renderFolderName()}
					{!noActions && (
						<input
							className="folder__checkbox"
							type="checkbox"
							onClick={handleCheckboxClick}
							checked={isChecked}
						/>
					)}
				</div>
				{renderMenu()}
			</div>
		</>
	);
});
