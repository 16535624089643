import { FC, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { CSSTransition } from 'react-transition-group';

import { appStore } from 'stores/app';

import PreloaderImage from 'assets/images/loader.png';

import './styles.scss';

const PreloaderComponent: FC = () => {
	const ref = useRef(null);

	return (
		<CSSTransition
			nodeRef={ref}
			in={appStore.isLoading}
			classNames="preloader-transition"
			timeout={300}
			unmountOnExit
		>
			<div className="preloader__wrapper" ref={ref}>
				<img src={PreloaderImage} width={100} height={100} alt="Loading" />
			</div>
		</CSSTransition>
	);
};

export const Preloader = observer(PreloaderComponent);
