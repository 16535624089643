import { ITableProps } from '../../components/table';

export const playlistTimelineColumns: ITableProps['columns'] = [
	{
		accessor: 'order',
		label: ' ',
	},
	{
		accessor: 'preview',
		label: ' ',
	},
	{
		accessor: 'checkbox',
		label: ' ',
	},
	'extension',
	'name',
	'duration',
	'filesize',
];
