import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { RouteRedirect } from './route-redirect';

/**
 * @description
 * A wrapper for <Route> that redirects to the login screen
 * if not yet authenticated.
 */
export const PrivateRoute: FC<PropsWithChildren> = observer(({ children }) => {
	if (authStore.wasAuthenticated) {
		return authStore.user ? children : <RouteRedirect routeKey="Login" />;
	}
	return null;
});
