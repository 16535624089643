import {
	FC,
	ForwardedRef,
	forwardRef,
	PropsWithChildren,
	ReactNode,
	useEffect,
	useMemo,
} from 'react';
import { NavLink, NavLinkProps, useMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IAppRouteProps, TRouteKey } from '../types';
import { routingStore } from '../store';

export type TRouteLinkActivity = boolean;
export type TRouteLinkRef = HTMLAnchorElement;

export interface IRouteLinkComponentProps
	extends Omit<NavLinkProps, 'children'>,
		PropsWithChildren {
	activity: TRouteLinkActivity;
}

export interface IRouteLink extends Omit<NavLinkProps, 'children'> {
	defaultActivity?: TRouteLinkActivity;
	onActive?: (routeKey: TRouteKey) => void;
	children?: ReactNode | ((activity: TRouteLinkActivity) => ReactNode);
	renderComponent?: (
		props: IRouteLinkComponentProps,
		ref: ForwardedRef<TRouteLinkRef>,
	) => JSX.Element;
	to: IAppRouteProps['path'];
	routeKey: TRouteKey;
}

/**
 * @description
 * A wrapper for <NavLink>. Used to forwarding active status to children.
 */
const RouteLinkComponent: FC<IRouteLink> = ({
	children,
	renderComponent,
	defaultActivity,
	onActive,
	routeKey,
	...props
}) => {
	const match = useMatch(props.to);
	const activity = useMemo(() => {
		return defaultActivity !== undefined ? defaultActivity : Boolean(match);
	}, [defaultActivity, match]);

	const CustomComponent = useMemo(() => {
		return (
			renderComponent &&
			forwardRef<TRouteLinkRef, Omit<IRouteLinkComponentProps, 'activity'>>(
				(props, ref) => {
					return renderComponent({ ...props, activity }, ref);
				},
			)
		);
	}, [activity, renderComponent]);

	const childrenForRender = useMemo(() => {
		return typeof children === 'function' ? children(activity) : children;
	}, [activity, children]);

	useEffect(() => {
		if (!onActive) return;
		if (activity) {
			onActive(routeKey);
		}
	}, [activity, routeKey, onActive]);

	return CustomComponent ? (
		<CustomComponent {...props}>{childrenForRender}</CustomComponent>
	) : (
		<NavLink {...props}>{childrenForRender}</NavLink>
	);
};

export const RouteLink = observer<Omit<IRouteLink, 'to'>>((props) => {
	const path = useMemo<IRouteLink['to'] | undefined>(() => {
		const { path } = routingStore.routes[props.routeKey] || {};

		const parentRoute = routingStore.routeObjects.find((r) =>
			r.outletFor?.includes(props.routeKey),
		);

		return parentRoute ? [parentRoute.path, path].join('/') : path;
	}, [routingStore.routes, props.routeKey]);

	return path ? <RouteLinkComponent {...props} to={path} /> : null;
});
