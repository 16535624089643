import {
	Address,
	MonitorCategory,
	MonitorMultiple,
	MonitorOrientation,
	UserRole,
} from 'utils/api/api';

import { ITableProps } from 'components/table';
import { IMonitorItem } from '_types/stores';
import { IMapFilterData } from './monitors-map';

export const ADDRESS_TEXT_PARAMS: (keyof Address)[] = [
	'city',
	'street',
	'house',
];

export const MAP_RADIUS_WALKABLE_KM = 0.1;
export const MAP_RADIUS_METERS = [50000, 25000, 5000, 1000, 500];
export const MAP_RADIUS_KM = MAP_RADIUS_METERS.map((m) => m / 1000);

export const categoryOptions = [
	MonitorCategory.GAS_STATION,
	MonitorCategory.MALL,
	MonitorCategory.FOOD,
	MonitorCategory.CAFES_AND_RESTAURANTS,
	MonitorCategory.PHARMACY,
	MonitorCategory.ATM,
	MonitorCategory.HOTEL,
	MonitorCategory.AUTO_REPAIR,
	MonitorCategory.CAR_WASHING,
	MonitorCategory.HOSPITALS,
	MonitorCategory.CINEMAS,
	MonitorCategory.SAUNAS,
	MonitorCategory.BEAUTY_SALONS,
];

export const MONITOR_DISPLAY_VALUE_SPECIFICATIONS = {
	angle: {
		min: 10,
		max: 180,
		step: 10,
	},
	brightness: {
		min: 0,
		max: 500,
		step: 10,
	},
	matrix: {
		options: ['IPS', 'TN', 'ACE', 'VA', 'AHVA', 'MVA', 'PVA'],
	},
	resolution: {
		options: [
			'3840 x 2160 px',
			'2560 x 1440 px',
			'1920 x 1080 px',
			'1366 x 768 px',
		],
	},
};

export const MONITOR_TABLE_COLUMNS: ITableProps['columns'] = [
	{ accessor: 'favorite', label: ' ', withSort: true },
	{
		accessor: 'name',
		messageId: 'The name of the monitor',
		withSort: true,
	},
	{ accessor: 'category', withSort: true },
	{ accessor: 'price1s', withSort: true },
	{ accessor: 'minWarranty', withSort: true },
	{ accessor: 'maxDuration', withSort: true },
	{ accessor: 'status', withSort: true },
	{ accessor: 'address', withSort: true },
	'coordinate',
	{ accessor: 'actions', restrictedFrom: [UserRole.Advertiser] },
];

export const MONITOR_GROUP_TYPES = [
	MonitorMultiple.MIRROR,
	MonitorMultiple.SCALING,
];
export const DEFAULT_MONITOR_CATEGORY = MonitorCategory.GAS_STATION;
export const DEFAULT_MONITOR_MULTIPLE = MonitorMultiple.MIRROR;
export const DEFAULT_MONITOR_SOUND: IMonitorItem['sound'] = true;
export const DEFAULT_MONITOR_ORIENTATION = MonitorOrientation.Horizontal;
export const WALL_SCALING_SCHEMAS: Array<[number, number]> = [
	[2, 2],
	[3, 2],
	[3, 3],
	[4, 3],
];
export type TMonitorFilterName = Extract<
	keyof IMonitorItem,
	'minWarranty' | 'maxDuration' | 'price1s'
>;
export const MONITOR_FILTER_NAMES: Array<TMonitorFilterName> = [
	'price1s',
	'minWarranty',
	'maxDuration',
];
export const COORDS_NAMES: Array<
	keyof Pick<IMapFilterData, 'latitude' | 'longitude'>
> = ['latitude', 'longitude'];
export const COST_EXTERNALS = {
	minWarranty: [0, 20000],
	maxDuration: [0, 120],
	price1s: [0, 5000],
};
