import { AxiosError } from 'axios';
import { appStore } from 'stores/app';
import { ConflictData, ConflictError } from './api';
import { toast } from '../toast';

export interface IApiErrorData<T = unknown> {
	status: number;
	statusCode: number;
	message: string;
	errors: { body: Error[] };
	data: T;
}

export const handleError = (error?: AxiosError | unknown): string => {
	if (!error) {
		return 'Unknown Error';
	}
	const err = error as AxiosError<IApiErrorData>;
	const { response, message } = err;
	if (!response) {
		return message;
	}
	const errorData = (error as AxiosError<ConflictError>).response?.data;

	if (response.status >= 500) {
		return (
			errorData?.message ||
			message ||
			appStore.intl.formatMessage({
				id: 'ServerError',
				defaultMessage: 'Что-то пошло не так...',
			})
		);
	}

	if (errorData?.statusCode === 409) {
		(['audio', 'playlist', 'video'] as Array<keyof ConflictData>).forEach(
			(key) => {
				const arr = errorData.error[key];
				if (!arr?.length) return;
				const fileName = arr[0].file?.name;
				const names = arr.map(({ name }) => name).join(', ');
				let messageId = '';
				const values: Partial<Record<string, string>> = {
					fileName,
				};
				if (key === 'playlist') {
					messageId =
						'The {fileName} file is used in playlists: {playlistNames}';
					values.playlistNames = names;
				} else {
					messageId = 'The {fileName} file is used in projects: {projectNames}';
					values.projectNames = names;
				}
				const message = appStore.intl.formatMessage(
					{
						id: messageId,
					},
					values,
				);

				toast.error(message);
			},
		);
		return '';
	}

	if (response.data.errors) {
		const { body } = response.data.errors;
		return body
			.map((_error: Error) =>
				appStore.intl.formatMessage({
					id: _error.message,
					defaultMessage: _error.message,
				}),
			)
			.join('\n');
	}

	return response.data.message || message;
};
