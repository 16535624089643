import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { appStore } from 'stores/app';

import { AppRoutes } from 'modules/routing-module';

import { swaggerApi, throttle } from './utils';
import { ONE_SECOND_MS } from './modules/video-editor-module';

export const App: FC = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		appStore.setIntl(intl);
		appStore.setHistory(navigate, location);
	}, [intl, navigate, location]);

	useEffect(() => {
		const scroll = throttle(() => {
			appStore.setLayout({
				windowScrollX: window.scrollX,
				windowScrollY: window.scrollY,
			});
		}, ONE_SECOND_MS);
		const resize = throttle(() => {
			appStore.setLayout({
				windowHeight: window.innerHeight,
				windowWidth: window.innerWidth,
			});
		}, ONE_SECOND_MS);

		appStore.setLayout({
			windowHeight: window.innerHeight,
			windowWidth: window.innerWidth,
			windowScrollX: window.scrollX,
			windowScrollY: window.scrollY,
		});

		window.addEventListener('scroll', scroll);
		window.addEventListener('resize', resize);

		swaggerApi.api
			.constantsGet()
			.then(async (response) => {
				// @ts-expect-error: Window custom property
				window.CONSTANTS = {
					value: response.data.data,
				};
			})
			.catch(console.error);

		return () => {
			window.removeEventListener('scroll', scroll);
			window.removeEventListener('resize', resize);
		};
	}, []);

	return <AppRoutes />;
};
