import { FC, HTMLAttributes } from 'react';
import cx from 'classnames';

import { Layout } from 'components/common';

import { HeaderProfile } from './header-profile';
import { SearchBar } from './search-bar';
import LanguageSwitcher from './language-switcher';

import './styles.scss';
import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/auth';
import { UserRole } from 'utils/api/api';
import { appStore } from 'stores/app';
import { HeaderMenu } from './header-menu';
import { formatCurrency, formatUserName } from '../../utils';
import { RouteLink } from '../../modules/routing-module';
import AddMoneyIcon from '../../assets/icons/plus-add.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { walletStore } from '../../stores/wallet';
import { metricsStore } from '../../stores/metrics';

export interface IHeaderProps extends HTMLAttributes<HTMLDivElement> {}

const Balance: FC = observer(() => (
	<Layout flex row>
		<RouteLink routeKey="WalletHistory" className="header__balance">
			{formatCurrency(walletStore.wallet.total)}
		</RouteLink>
		<RouteLink
			routeKey="Profile"
			className="button button-primary button-rounded header__add-money"
		>
			<img className="header__add-icon" src={AddMoneyIcon} alt="+" />
			<FormattedMessage
				id="app.button.depositWallet"
				defaultMessage="Пополнить"
			/>
		</RouteLink>
	</Layout>
));

const MonitorMetrics: FC = observer(() => {
	if (!metricsStore.userMetrics) {
		return null;
	}
	const { online, offline, empty } = metricsStore.userMetrics.monitors;
	return (
		<Layout flex row>
			<div className="header__monitors-list">
				{[online, offline, empty].map((number, idx) => {
					const colorClassSuffix = ['green', 'red', 'gray'][idx];

					return (
						<div key={colorClassSuffix} className="header__monitors-group">
							<span className="header__monitors-number">{number}</span>
							<div
								className={`header__monitors-light header__monitors-light--${colorClassSuffix}`}
							/>
						</div>
					);
				})}
			</div>
		</Layout>
	);
});
const Email: FC = observer(() => {
	const intl = useIntl();

	return (
		authStore.user && (
			<Layout flex row className="header__email">
				<span className="header__email-name">
					{formatUserName(authStore.user, 'SHORT', intl)}
				</span>
				<span className="header__email-email">{authStore.user.email}</span>
			</Layout>
		)
	);
});

export const Header: FC<IHeaderProps> = observer(({ className }) => (
	<div className={cx('header', className)}>
		<div className="header__left">
			<HeaderMenu />
		</div>
		<div className="header__right">
			<SearchBar />
			<Email />
			{authStore.restrictFrom(UserRole.Accountant) && (
				<>
					<HeaderProfile />
					{appStore.isCreatedFor('default') && (
						<>
							<Balance />
							<MonitorMetrics />
						</>
					)}
				</>
			)}
			<LanguageSwitcher />
		</div>
	</div>
));
