import { ChangeEvent, FC, HTMLAttributes, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { explorerStore } from 'stores/media';

import { FileInput } from 'components/forms';
import { DocumentsList } from './add-edit-form-documents-list';
import { FormField } from './add-edit-form-field';
import PhotoThumnailSlider from './add-edit-form-photos-slider';
import { ACCEPT_DOCUMENT_EXTS, ACCEPT_IMAGE_EXTS } from '_constants';
import { FileResponse } from 'utils/api/api';
import { IMonitorItem } from '_types/stores';
import { MonitorFileCategory } from '../../stores/monitors/monitor-list';

export type TFileDeleteCallback = (fileId: FileResponse['id']) => void;

export interface IEditFilesListProps extends HTMLAttributes<HTMLDivElement> {
	files: FileResponse[];
	onDelete: TFileDeleteCallback;
}

export interface IFilesProps
	extends HTMLAttributes<HTMLDivElement>,
		Pick<IMonitorItem, 'photos' | 'documents'> {
	monitorId?: IMonitorItem['id'];
	addCallback?: (response: unknown) => void;
	deleteCallback?: (response: unknown) => void;
}

export const Files: FC<IFilesProps> = ({
	monitorId,
	photos,
	documents,
	addCallback,
	deleteCallback,
}) => {
	const uploadFiles = useCallback(
		(category: MonitorFileCategory, e: ChangeEvent<HTMLInputElement>) => {
			const { files } = e.target;

			if (monitorId && files?.length) {
				explorerStore
					.uploadFiles([...files], {
						monitorId,
						category,
					})
					.then(addCallback);
			}
			// Clears input, e.g.: https://stackoverflow.com/a/54633061
			e.target.value = '';
		},
		[monitorId, addCallback],
	);
	const uploadPhotos = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			uploadFiles(MonitorFileCategory.Photo, e);
		},
		[uploadFiles],
	);

	const uploadDocuments = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			uploadFiles(MonitorFileCategory.Documents, e);
		},
		[uploadFiles],
	);

	const handleDelete = useCallback<TFileDeleteCallback>(
		(fileId) => {
			explorerStore.deleteFile(fileId).then(deleteCallback);
		},
		[deleteCallback],
	);

	return (
		<>
			<FormField
				title="Photo"
				row
				fieldClass="form__padding__field files__field"
				layoutClass="add__edit__photos__layout"
			>
				{monitorId ? (
					<>
						<FileInput
							fieldClass="add__edit__form__field"
							name="photos"
							onChange={uploadPhotos}
							accept={ACCEPT_IMAGE_EXTS}
						/>
						{photos && (
							<PhotoThumnailSlider files={photos} onDelete={handleDelete} />
						)}
					</>
				) : (
					<FileUploadIsBlocked />
				)}
			</FormField>
			<FormField
				title="Ownership documents"
				row
				fieldClass="form__padding__field files__field"
				layoutClass="add__edit__documents__layout"
				labelClass="monitor__file-label"
			>
				{monitorId ? (
					<>
						<FileInput
							fieldClass="add__edit__form__field"
							name="documents"
							onChange={uploadDocuments}
							accept={ACCEPT_DOCUMENT_EXTS}
						/>
						{documents && (
							<DocumentsList files={documents} onDelete={handleDelete} />
						)}
					</>
				) : (
					<FileUploadIsBlocked />
				)}
			</FormField>
		</>
	);
};

const FileUploadIsBlocked = () => (
	<span className="add__edit__form__require_edit">
		<FormattedMessage
			id="To add media content fill in the required fields and save the monitor"
			defaultMessage="To add media content fill in the required fields and save the monitor"
		/>
	</span>
);
