import { observer } from 'mobx-react-lite';
import { formatCurrency } from 'utils';
import { walletStore } from 'stores/wallet';
import { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ITableProps, Table } from 'components/table';
import { WalletTransactionType } from 'utils/api/api';
import cx from 'classnames';
import { PLUS } from '_constants';

export const WALLET_HISTORY_COLUMNS: ITableProps['columns'] = [
	'createdAt',
	'sum',
	{ accessor: 'description', messageId: 'app.table.event' },
];

export const WalletHistory = observer(() => {
	const intl = useIntl();

	const data = useMemo(
		() =>
			walletStore.list.map((transaction) => ({
				...transaction,
				createdAt: (
					<span className="wallet-history__date">
						{intl.formatDate(transaction.createdAt, {
							dateStyle: 'full',
						})}
					</span>
				),
				sum: (
					<span
						className={cx([
							'wallet-history__sum',
							transaction.type.toLowerCase(),
						])}
					>
						{transaction.type === WalletTransactionType.DEBIT ? PLUS : null}
						{formatCurrency(transaction.sum, intl)}
					</span>
				),
			})),
		[walletStore.list, intl],
	);

	useEffect(() => {
		void walletStore.getList();
	}, []);

	return (
		<div className="wallet-history">
			<h2 className="main__title">
				<FormattedMessage
					id="app.route.WalletHistory"
					defaultMessage="История операций"
				/>
			</h2>
			<Table
				className="wallet-history__table"
				data={data}
				columns={WALLET_HISTORY_COLUMNS}
			/>
		</div>
	);
});
