import { InputHTMLAttributes, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { random } from 'utils';
import { InputField, InputMask } from '../forms';
import { checkInputError } from '../../hocs';

import IconMonitor from 'assets/icons/monitor.svg';
import { INPUT_MASK } from '../../_constants';

export interface IBigInputProps extends InputHTMLAttributes<HTMLInputElement> {
	noHint?: boolean;
}

export const BigInput = checkInputError<IBigInputProps>(
	({ isError, noHint = false, value, ...restProps }) => {
		const [inputId] = useMemo(() => random(), []);

		return (
			<InputField type="text" className="add__edit__form__big_field">
				<img
					src={IconMonitor}
					alt="Input icon"
					className="big-input__form__input-icon"
				/>
				<InputMask
					{...restProps}
					id={inputId}
					className={cx(
						'form__input',
						'form__input__text',
						'big-input__form__input',
					)}
					mask={INPUT_MASK.MONITOR_CODE}
					placeholder={INPUT_MASK.MONITOR_CODE}
					value={value || ''}
				/>
				{!noHint && (
					<span className={cx('big-input__form_hint', { error: isError })}>
						<FormattedMessage
							id="Enter the code from the device screen"
							defaultMessage="Введите код с экрана устройства"
						/>
					</span>
				)}
			</InputField>
		);
	},
);
