import { computed, makeObservable } from 'mobx';

import { authStore } from 'stores/auth';

import routes from './routes';
import { IRoutingStore } from './types';

class RoutingStore implements IRoutingStore {
	constructor() {
		makeObservable(this);
	}

	@computed get routes() {
		const { user } = authStore;

		return user
			? Object.fromEntries(
					Object.entries(routes).filter((arr) => {
						const r = arr[1];

						return !r.restrictedFrom || !r.restrictedFrom.includes(user.role);
					}),
				)
			: routes;
	}

	@computed get routeObjects() {
		return Object.values(this.routes);
	}
}

export const routingStore = new RoutingStore();
