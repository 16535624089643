import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { videoEditorStore } from 'modules/video-editor-module';
import { FC, HTMLAttributes, useMemo } from 'react';
import { formatToHhmmss } from '../../../../utils/formatters/format-to-hhmmss';

interface ITimelineScale extends HTMLAttributes<HTMLDivElement> {
	timeStamps: undefined[];
}

const TimelineScaleComponent: FC<ITimelineScale> = ({
	timeStamps,
	className,
	...restProps
}) => {
	const timeScale = useMemo(() => {
		return timeStamps.map((nothing, idx) => {
			const seconds = (idx + 1) * videoEditorStore.scaleUnitSeconds; // skipping 0 index
			const time = formatToHhmmss(seconds, { hour: undefined });

			return (
				<div key={`${time}Unit`} className="timeline__unit">
					<div className="timeline__unit-time">
						<span>{time}</span>
					</div>
					<div className="timeline__unit-scale">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			);
		});
	}, [timeStamps, videoEditorStore.scaleUnitSeconds]);

	return (
		<div {...restProps} className={cx('timeline__stamps', className)}>
			{timeScale}
			<div className="timeline__line" />
		</div>
	);
};

export const TimelineScale = observer(TimelineScaleComponent);
