import { FC, Fragment, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { IPlaylistItem } from '_types/stores';
import { MonitorStatus } from 'utils/api/api';

import IconPlaylistDelete from 'assets/icons/playlist-delete.svg';
import IcoPlaylistEdit from 'assets/icons/playlist-edit.svg';
import IconPlaylist from 'assets/icons/playlist-off.svg';

interface IPlaylistSubcomponent {
	playlist: IPlaylistItem;
}

const NameWithIcon: FC<IPlaylistSubcomponent> = ({ playlist }) => (
	<Fragment key={`table_row${playlist.id}_name_img`}>
		<img
			src={IconPlaylist}
			alt="Monitor icon"
			className="table__icon table__icon-name"
		/>
		{playlist.name}
	</Fragment>
);

const Actions: FC<IPlaylistSubcomponent> = ({ playlist }) => (
	<Fragment key={`table_row${playlist.id}_action`}>
		<Link to={`/playlists/edit/${playlist.id}`}>
			<img
				src={IcoPlaylistEdit}
				alt="Monitor edit"
				className="table__icon table__icon__action"
			/>
		</Link>
		<Link to={`/playlists/delete/${playlist.id}`}>
			<img
				src={IconPlaylistDelete}
				alt="Monitor delete"
				className="table__icon table__icon__action"
			/>
		</Link>
	</Fragment>
);

const StatusComponent: FC<{
	playlist: IPlaylistItem;
}> = ({ playlist }) => {
	const statusByMonitors = useMemo(() => {
		if (playlist.monitors && playlist.monitors.length) {
			return playlist.monitors.map((m) => {
				let messageId = '';

				if (m.status === MonitorStatus.Offline) {
					messageId = 'Offline';
				} else if (m.playlistPlayed) {
					messageId = 'On the broadcast';
				} else {
					messageId = 'No broadcast';
				}

				return (
					<li key={m.id}>
						{m.name} (<FormattedMessage id={messageId} />)
					</li>
				);
			});
		}

		return <FormattedMessage id="Is not used" />;
	}, [playlist.monitors]);

	return <ul>{statusByMonitors}</ul>;
};

const NameWithIconWrapped = observer(NameWithIcon);
const ActionsWrapped = observer(Actions);
export const Status = observer(StatusComponent);

export { NameWithIconWrapped as NameWithIcon, ActionsWrapped as Actions };
