import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
	DRAFT_PROJECT_ID,
	videoEditorStore,
} from 'modules/video-editor-module';
import { TProjectId } from 'modules/video-editor-module/types';

import { monitorsStore } from 'stores/monitors/monitor-list';
import { EditorLibrary } from './components/editor-library';
import { PlayerContainer } from './components/player/player-container';
import { Timeline } from './components/timeline';

import './styles.scss';
import { FC, useEffect, useRef } from 'react';

const EditorProjectComponent: FC = () => {
	const timelineRef = useRef<HTMLDivElement>(null);
	const { id } = useParams<{ id: TProjectId }>();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		videoEditorStore.initProject({ projectId: id || DRAFT_PROJECT_ID });
	}, [id]);

	useEffect(() => {
		return () => {
			// cleanup
			videoEditorStore.initProject({});
		};
	}, []);

	useEffect(() => {
		void monitorsStore.getList();
	}, [monitorsStore.order]);

	useEffect(() => {
		if (videoEditorStore.project?.id) {
			const path = `/editor/project/${videoEditorStore.project.id}`;

			if (location.pathname !== path) {
				navigate(path, { replace: true });
			}
		}
	}, [location.pathname, navigate, videoEditorStore.project?.id]);

	return (
		<div className="editor__layout">
			<EditorLibrary timelineRef={timelineRef} />
			<div className="player__layout">
				<PlayerContainer />
			</div>
			<Timeline innerRef={timelineRef} />
		</div>
	);
};

export const EditorProject = observer(EditorProjectComponent);
