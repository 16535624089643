import {
	action,
	computed,
	makeObservable,
	observable,
	runInAction,
} from 'mobx';
import { filterObject, handleError, swaggerApi, throttle, toast } from 'utils';

import { IMonitorItem, IMonitorsStore } from '_types/stores';
import { StoreViewMode } from '_types/constants';

import { appStore } from 'stores/app';
import { ListOrder } from 'utils/api/components';
import {
	MonitorCreateRequest,
	MonitorMultiple,
	MonitorResponse,
	MonitorUpdateRequest,
} from 'utils/api/api';
import {
	checkRequestData,
	RequestError,
	TRequestCondition,
} from 'utils/api/check-request-data';

export enum MonitorFileCategory {
	Photo = 'Photo',
	Documents = 'Documents',
}

const MONITOR_REQUEST_KEYS: Array<keyof MonitorCreateRequest> = [
	'name',
	'address',
	'category',
	'price1s',
	'minWarranty',
	'maxDuration',
	'orientation',
	'model',
	'angle',
	'matrix',
	'brightness',
	'width',
	'height',
	'sound',
	'multiple',
	'location',
	'code',
	'groupIds',
];
export const REQUIRED_MONITOR_REQUEST_CONDITIONS: Array<
	TRequestCondition<MonitorCreateRequest | MonitorUpdateRequest>
> = (
	['name', 'category', 'width', 'height'] as Array<keyof MonitorCreateRequest>
).map((key) => {
	switch (key) {
		case 'width':
		case 'height':
			return {
				key,
				validate: (number, intl) =>
					(typeof number === 'number' && number > 0) ||
					intl.formatMessage({
						id: 'Choice resolution',
						defaultMessage: 'Выберите разрешение экрана',
					}),
			};

		default:
			return key;
	}
});

export const REQUIRED_MONITOR_GROUP_REQUEST_CONDITIONS: Array<
	TRequestCondition<MonitorCreateRequest | MonitorUpdateRequest>
> = (['name', 'groupIds'] as Array<keyof MonitorCreateRequest>).map((key) => {
	switch (key) {
		case 'groupIds':
			return {
				key,
				validate: (arr, intl) =>
					(Array.isArray(arr) && arr.length > 0) ||
					intl.formatMessage({
						id: 'Select multiple monitors',
						defaultMessage: 'Выберите несколько мониторов для создания группы',
					}),
			};

		default:
			return key;
	}
});

class MonitorsStore extends ListOrder implements IMonitorsStore {
	@observable list: IMonitorItem[] = [];

	@observable mode: StoreViewMode = 'list';

	@observable error: string | null = null;
	search = throttle((text: string) => {
		this.setSearchText(text);
		this.getSortedList(swaggerApi.api.monitorsGet, {
			where: {
				name: this.text,
			},
		})
			.then(({ data: monitorsData }) => {
				runInAction(() => {
					this.count = monitorsData.count;
					this._setList(monitorsData.data);
				});
			})
			.catch((error) => toast.error(handleError(error)));
	}, 500);

	constructor() {
		super();
		makeObservable(this);
	}

	@computed get listNoSubordinate() {
		return this.list.filter((m) => m.multiple !== MonitorMultiple.SUBORDINATE);
	}

	@action getList: IMonitorsStore['getList'] = async (data = {}) => {
		appStore.isLoading = true;

		if (this.text && !data.where?.name) {
			if (data.where) {
				data.where.name = this.text;
			} else {
				data.where = {
					name: this.text,
				};
			}
		}

		try {
			const { data: monitorsData } = await this.getSortedList(
				swaggerApi.api.monitorsGet,
				data,
			);

			this.count = monitorsData.count;
			this._setList(monitorsData.data);
			return this.list;
		} catch (error) {
			toast.error(handleError(error));
			return this.list;
		} finally {
			appStore.isLoading = false;
		}
	};

	@action _setList(list: MonitorResponse[]) {
		if (appStore.isProd) {
			list = list.filter((m) => !this.isGroup(m.multiple));
		}
		this.list = list;
	}

	loadMonitor: IMonitorsStore['loadMonitor'] = async (id) => {
		try {
			const { data: monitorData } = await swaggerApi.api.monitorGet(id);
			const monitor = monitorData.data as IMonitorItem;

			this._setList(this.list.map((m) => (m.id === id ? monitor : m)));

			return monitor;
		} catch (e) {
			toast.error(handleError(e));
			return null;
		}
	};

	@action createMonitor: IMonitorsStore['createMonitor'] = async (data) => {
		appStore.isLoading = true;

		try {
			const { data: monitorData } = await swaggerApi.api.monitorCreate(
				checkRequestData(
					filterObject(data, {
						includedKeys: MONITOR_REQUEST_KEYS,
					}),
					this.isGroup(data.multiple)
						? REQUIRED_MONITOR_GROUP_REQUEST_CONDITIONS
						: REQUIRED_MONITOR_REQUEST_CONDITIONS.concat({
								key: 'code',
								validate: (code) =>
									typeof code === 'string' &&
									code
										.replaceAll('-', '')
										.split('')
										.every((str) => !isNaN(Number(str))),
							}),
				),
			);

			await this.getList();

			appStore.navigate(`/monitors/edit/${monitorData.data.id}`);
		} catch (e) {
			if (e instanceof RequestError) {
				throw e;
			}
			toast.error(handleError(e as Error));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action updateMonitor: IMonitorsStore['updateMonitor'] = async (
		id,
		monitor,
	) => {
		appStore.isLoading = true;

		try {
			await swaggerApi.api.monitorUpdate(
				id,
				checkRequestData(
					filterObject(monitor, {
						includedKeys: MONITOR_REQUEST_KEYS,
					}),
					this.isGroup(monitor.multiple)
						? REQUIRED_MONITOR_GROUP_REQUEST_CONDITIONS
						: REQUIRED_MONITOR_REQUEST_CONDITIONS,
				),
			);

			appStore.navigate('/monitors/list');
		} catch (e) {
			if (e instanceof RequestError) {
				throw e;
			}
			toast.error(handleError(e as Error));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action deleteMonitor: IMonitorsStore['deleteMonitor'] = async (id) => {
		appStore.isLoading = true;

		try {
			await swaggerApi.api.monitorDelete(id);
			await this.getList();
		} catch (error) {
			toast.error(handleError(error));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action linkMonitorPlaylistMap: IMonitorsStore['linkMonitorPlaylistMap'] =
		async (monitorIds, playlistId, bid) => {
			appStore.isLoading = true;

			try {
				const result = await swaggerApi.api.monitorPlaylistCreate({
					playlistId,
					monitorIds,
					bid,
				});

				return result.data.data;
			} catch (error) {
				toast.error(handleError(error));
				return null;
			} finally {
				appStore.isLoading = false;
			}
		};

	@action favoriteToggle: IMonitorsStore['favoriteToggle'] = async (
		monitorId,
		isFavorite,
	) => {
		try {
			const { data: monitorData } = await (isFavorite
				? swaggerApi.api.monitorFavoriteMinus(monitorId)
				: swaggerApi.api.monitorFavoritePlus(monitorId));

			const isFavoriteFromResponse = monitorData.data.favorite;

			this._setList(
				this.list.map((m) => {
					if (m.id === monitorId) {
						return { ...m, favorite: isFavoriteFromResponse };
					}
					return m;
				}),
			);

			return isFavoriteFromResponse;
		} catch (error) {
			toast.error(handleError(error));
			return isFavorite;
		}
	};

	isGroup(multiple: MonitorMultiple | undefined | null) {
		return Boolean(
			multiple &&
				[MonitorMultiple.SCALING, MonitorMultiple.MIRROR].includes(multiple),
		);
	}
}

const monitorsStore = new MonitorsStore();

export { monitorsStore };
