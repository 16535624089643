import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { errorHandlerStore } from 'stores/error-handler/error-handler';
import { Error } from 'components/error';

export const ErrorHandler: FC<PropsWithChildren> = observer(({ children }) => {
	return errorHandlerStore.error.isError ? (
		<Error title={<FormattedMessage id="Error" defaultMessage="Error" />} />
	) : (
		children
	);
});
