import {
	ComponentType,
	FC,
	HTMLAttributes,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
} from 'react';

import './explorer.scss';
import { explorerStore } from '../../stores/media';
import { LibraryFolder, NewFolder } from '../library-folder';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { ExplorerItem, IExplorerItemProps } from './explorer-item';
import { FileResponse, FileType } from '../../utils/api/api';

export interface IExplorerProps extends HTMLAttributes<HTMLDivElement> {
	acceptableFileTypes?: FileType[];
	fileList?: ReactNode;
	noActions?: boolean;
	ItemComponent?: ComponentType<Pick<IExplorerItemProps, 'file'>>;
	ItemActionComponent?: ComponentType<Pick<IExplorerItemProps, 'file'>>;
}

export const Explorer: FC<IExplorerProps> = observer(
	({
		noActions: noActions,
		ItemComponent,
		ItemActionComponent,
		className,
		fileList,
		acceptableFileTypes,
	}) => {
		const folderList = useMemo(
			() =>
				explorerStore.foldersList.map((folder) => {
					const handleFolderClick = () => {
						explorerStore.navigateForward(folder);
					};

					return (
						<LibraryFolder
							folder={folder}
							key={folder.id}
							onClick={handleFolderClick}
							noActions={noActions}
							customContextMenu
						/>
					);
				}),
			[explorerStore.foldersList],
		);

		const newFolder = useMemo(() => {
			if (explorerStore.folderCreation.isActive) {
				return <NewFolder />;
			}

			return null;
		}, [explorerStore.folderCreation.isActive]);

		const fileItems = useMemo(() => {
			if (fileList) {
				return fileList;
			}
			const files = acceptableFileTypes
				? explorerStore.fileList.filter((f) =>
						acceptableFileTypes.includes(f.type),
					)
				: explorerStore.fileList;

			return ItemComponent
				? files.map((m) => <ItemComponent key={m.id} file={m} />)
				: files.map((m) => (
						<ExplorerItem
							key={m.id}
							file={m}
							ActionComponent={noActions ? undefined : Actions}
							actionElement={
								ItemActionComponent && <ItemActionComponent file={m} />
							}
							customContextMenu
						/>
					));
		}, [
			fileList,
			acceptableFileTypes,
			explorerStore.fileList,
			ItemComponent,
			ItemActionComponent,
			noActions,
		]);

		const renderBreadcrumbs = useMemo(() => {
			if (!explorerStore.breadcrumbs) {
				return null;
			}

			return (
				<div className="library__folder-path">
					{explorerStore.breadcrumbs.map((breadcrumb, i, breadcrumbs) => {
						const handleClick = () => {
							if (i + 1 === breadcrumbs.length) {
								return;
							}

							explorerStore.navigateBackwardTo(i + 1);
						};

						if (breadcrumb.parentFolderId === null) {
							return (
								<button
									className="library__folder-path-item"
									key={breadcrumb.id}
									onClick={handleClick}
									type="button"
								>
									<FormattedMessage id="Library" defaultMessage="Library" />
								</button>
							);
						}

						return (
							<button
								className="library__folder-path-item"
								key={breadcrumb.id}
								onClick={handleClick}
								type="button"
							>
								&nbsp;/&nbsp;{breadcrumb.name}
							</button>
						);
					})}
				</div>
			);
		}, [explorerStore.breadcrumbs]);

		useEffect(() => {
			const source = explorerStore.getMediaList();

			return () => {
				source.cancel();
			};
		}, []);

		return (
			<div className={cx('explorer', className)}>
				<div className="library__folder-path-layout">
					<button
						className="library__path-circle"
						onClick={explorerStore.navigateBackward}
						type="button"
					/>
					{renderBreadcrumbs}
				</div>
				<div className="grid library__grid">
					{folderList}
					{newFolder}
					{fileItems}
				</div>
			</div>
		);
	},
);

const Actions: FC<{ item: FileResponse }> = observer(({ item }) => {
	const handleDownloadClick = useCallback(
		() => explorerStore.download(item),
		[item],
	);

	const isChecked = explorerStore.selected.fileIds.includes(item.id);

	const handleChange = useCallback(() => {
		explorerStore.selectFiles(item.id);
	}, [item.id]);

	return (
		<div className="library__item-actions">
			<span className="library__item-download" onClick={handleDownloadClick}>
				<FormattedMessage id="Download" defaultMessage="Download" />
			</span>
			<input
				className="library__item-checkbox"
				type="checkbox"
				onChange={handleChange}
				checked={isChecked}
			/>
		</div>
	);
});
