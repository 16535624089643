import { useCallback, useMemo, useState } from 'react';

export interface IUseSliderOptions {
	startIdx?: number;
}

const DEFAULT_CURRENT_IDX = 0;

export function useSlider<T>(
	slides: T[],
	options?: IUseSliderOptions,
): [
	{
		currentSlide?: T;
		currentIdx: number;
		slides: T[];
	},
	{
		nextSlide: () => void;
		prevSlide: () => void;
		setSlide: (idx: number) => void;
	},
] {
	const { startIdx = DEFAULT_CURRENT_IDX } = options || {};
	const [currentIdx, setCurrentIdx] = useState(startIdx);

	const nextSlide = useCallback(
		() =>
			setCurrentIdx((state) => {
				const newState = state + 1;

				return slides[newState] ? newState : DEFAULT_CURRENT_IDX;
			}),
		[slides, setCurrentIdx],
	);

	const prevSlide = useCallback(
		() =>
			setCurrentIdx((state) => {
				const newState = state - 1;

				return newState >= 0 ? newState : slides.length - 1;
			}),
		[slides, setCurrentIdx],
	);

	const setSlide = useCallback(
		(idx: number) => {
			if (slides[idx]) {
				setCurrentIdx(idx);
			}
		},
		[slides, setCurrentIdx],
	);

	const currentSlide = useMemo(() => slides[currentIdx], [slides, currentIdx]);

	return [
		{
			currentSlide,
			currentIdx,
			slides,
		},
		{
			nextSlide,
			prevSlide,
			setSlide,
		},
	];
}
