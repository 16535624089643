import {
	FC,
	ForwardRefExoticComponent,
	HTMLAttributes,
	MouseEventHandler,
	ReactNode,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import {
	IRouteLink,
	IRouteLinkComponentProps,
	routeKeyToMessageId,
	RouteLink,
	TRouteLinkRef,
} from 'modules/routing-module';
import { TRouteKey } from 'modules/routing-module/types';

import { Layout } from 'components/common';
import { Submenu } from './submenu';

import './styles.scss';
import { IMenuIconsClassList, menuIconsByRouteKey } from './menu-icons';

/**
 * @property displayTitle
 * used to display specific string instead `FormattedMessage` node:
 * @example
 * {
 *   routePath: '/current-user'
 *   displayTitle: <span>Nikita Shirobokov</span>
 * }
 */
export interface IMenuItem {
	routeKey: TRouteKey;
	displayTitle: ReactNode;
	iconClassName?: IMenuIconsClassList['iconClassName'];
	iconClassNameActive?: IMenuIconsClassList['iconClassNameActive'];
}

export interface IMenuItemProps
	extends Omit<IMenuItem, 'routeKey'>,
		IRouteLinkComponentProps {}

export type TRoutingSchemaItem = TRouteKey | IMenuItem;

/**
 * @property routingSchema
 * used to define menu structure and nesting based on {TRouteKey} type.
 * @example
 * {
 *   routingSchema: [
 *    TRouteKey, - single menu item.
 *    [TRouteKey,...] - nested menu item with submenu.
 *    ...
 *   ]
 * }
 */
export interface IMenu
	extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
	routingSchema: Array<
		TRoutingSchemaItem | [TRoutingSchemaItem, TRoutingSchemaItem[]]
	>;
	children?: (itemList: ReactNode) => ReactNode;
	ItemComponent: ForwardRefExoticComponent<
		IMenuItemProps & RefAttributes<TRouteLinkRef>
	>;
	SubItemComponent?: IMenu['ItemComponent'];
}

export const Menu: FC<IMenu> = ({
	className,
	routingSchema,
	children,
	ItemComponent,
	SubItemComponent,
}) => {
	const [appearRouteKey, setAppearRouteKey] = useState<TRouteKey | null>();

	const handleMenuLinkActive: IRouteLink['onActive'] = useCallback(
		(routeKey: TRouteKey) => setAppearRouteKey(routeKey),
		[],
	);

	const handleMenuLinkClick = useCallback<
		(rPath: TRouteKey) => MouseEventHandler<HTMLAnchorElement>
	>(
		(routeKey) => (e) => {
			e.preventDefault();
			setAppearRouteKey((state) => {
				if (state === routeKey) {
					return null;
				}
				return routeKey;
			});
		},
		[],
	);

	const schemaItemToMenuItem = useCallback(
		(schemaItem: TRoutingSchemaItem): IMenuItem => {
			const routeKey =
				typeof schemaItem === 'string' ? schemaItem : schemaItem.routeKey;

			return {
				...(menuIconsByRouteKey[routeKey] || {}),
				...(typeof schemaItem === 'string'
					? {
							routeKey,
							displayTitle: (
								<FormattedMessage
									id={routeKeyToMessageId(schemaItem)}
									defaultMessage={routeKeyToMessageId(schemaItem)}
								/>
							),
						}
					: schemaItem),
			};
		},
		[],
	);

	const menuList = useMemo(() => {
		return routingSchema.map((item) => {
			if (Array.isArray(item)) {
				// with submenu
				const [schemaItem, subSchemaItems] = item;
				const menuItems = subSchemaItems.map(schemaItemToMenuItem);
				const { routeKey, ...menuItem } = schemaItemToMenuItem(schemaItem);
				const appear = appearRouteKey === routeKey;

				return (
					<RouteLink
						key={routeKey}
						routeKey={routeKey}
						className={cx('menu__item menu__item-link', {
							'menu__item-appear': appear,
						})}
						renderComponent={(props) => (
							<ItemComponent {...props} {...menuItem} />
						)}
						onActive={handleMenuLinkActive}
						onClick={handleMenuLinkClick(routeKey)}
					>
						<Submenu
							appear={appear}
							menuItems={menuItems}
							ItemComponent={SubItemComponent || ItemComponent}
						/>
					</RouteLink>
				);
			}
			// no submenu
			const { routeKey, ...menuItem } = schemaItemToMenuItem(item);
			return (
				<RouteLink
					key={routeKey}
					routeKey={routeKey}
					className={cx('menu__item', 'menu__item-no-submenu')}
					renderComponent={(props) => (
						<ItemComponent {...props} {...menuItem} />
					)}
				/>
			);
		});
	}, [
		routingSchema,
		appearRouteKey,
		ItemComponent,
		SubItemComponent,
		handleMenuLinkActive,
		handleMenuLinkClick,
		schemaItemToMenuItem,
	]);

	const childrenForRender = useMemo(() => {
		return typeof children === 'function' ? children(menuList) : menuList;
	}, [menuList, children]);

	return <Layout className={className}>{childrenForRender}</Layout>;
};
