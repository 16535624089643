import { FC, TimeHTMLAttributes } from 'react';
import { formatToHhmmss } from '../../utils';

export interface IDurationProps
	extends Omit<TimeHTMLAttributes<HTMLTimeElement>, 'dateTime'> {
	seconds: number;
}

export const Duration: FC<IDurationProps> = ({ seconds, ...restProps }) => {
	return (
		<time {...restProps} dateTime={`P${Math.round(seconds)}S`}>
			{formatToHhmmss(seconds, { hour: undefined })}
		</time>
	);
};
