export interface IObjectWithOrder {
	order: number;
}

export type TObjectWithOrder<T> = IObjectWithOrder & T;

export interface IOrderObjectsOptions {
	min?: number;
	max?: number;
}

export function orderObjects<T>(
	objects: T[],
	options?: IOrderObjectsOptions,
): Array<TObjectWithOrder<T>> {
	const { min = 1, max = objects.length } = options || {};

	const [objectsWithOrder, objectsNoOrder] = objects.reduce(
		(acc, obj) => {
			if (!(typeof obj === 'object' && obj)) {
				throw new TypeError(
					`Argument (1) of the "orderObject" function requires that item of array to be "object" type: ${JSON.stringify(objects)}`,
				);
			}
			if (
				'order' in obj &&
				typeof obj.order === 'number' &&
				obj.order >= min &&
				obj.order <= max
			) {
				acc[0].push(obj as TObjectWithOrder<T>);
			} else {
				acc[1].push(obj);
			}
			return acc;
		},
		[[] as TObjectWithOrder<T>[], [] as T[]],
	);

	if (!objectsNoOrder.length) {
		return objectsWithOrder;
	}
	const usedOrderNumbers = objectsWithOrder.map((obj) => obj.order);
	let vacant = min;

	const checkOrder = (candidate = vacant): number => {
		if (usedOrderNumbers.includes(candidate)) {
			return checkOrder(candidate + 1);
		} else {
			vacant = candidate + 1;

			return candidate;
		}
	};
	return objectsWithOrder.concat(
		objectsNoOrder.map((obj) => ({
			...obj,
			order: checkOrder(),
		})),
	);
}
