import {
	FC,
	HTMLAttributes,
	RefObject,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { observer, useLocalObservable } from 'mobx-react-lite';
import cx from 'classnames';

import { explorerStore } from 'stores/media';

import { FileResponse, FileType } from 'utils/api/api';

import {
	DRAFT_PROJECT_ID,
	videoEditorStore,
} from 'modules/video-editor-module';

import { EditorLibraryItem } from './editor-library-item';
import { ChangeNameForm } from '../change-name-form';
import { upFirstLetter } from 'utils/formatters';
import { Explorer } from 'components/explorer';

export interface IEditorLibrary extends HTMLAttributes<HTMLDivElement> {
	timelineRef: RefObject<HTMLDivElement>;
}

const MEDIA_TYPES = [FileType.Video, FileType.Image, FileType.Audio];

const EditorLibraryComponent: FC<IEditorLibrary> = ({
	timelineRef,
	className,
	...restProps
}) => {
	const localStore = useLocalObservable(() => ({
		currentType: FileType.Video,
		changeType(type: FileResponse['type']) {
			this.currentType = type;
		},
		selectedMedia: [] as FileResponse[],
		selectMedia(mediaItem: FileResponse) {
			this.selectedMedia = this.selectedMedia
				.filter((m) => m.id !== mediaItem.id)
				.concat(mediaItem);
		},
		isSelectedMedia(mediaId: FileResponse['id']) {
			return this.selectedMedia.some((m) => m.id === mediaId);
		},
		unSelectMedia(mediaIds: Array<FileResponse['id']>) {
			this.selectedMedia = this.selectedMedia.filter(
				(m) => !mediaIds.includes(m.id),
			);
		},
		unSelectAllMedia() {
			this.selectedMedia = [];
		},
		selectAllMedia() {
			this.selectedMedia = explorerStore.fileList
				.filter((m) => m.type === this.currentType)
				.concat();
		},
	}));

	useEffect(() => {
		const source = explorerStore.getMediaList();

		return () => {
			source.cancel();
		};
	}, []);

	const selectAll = useCallback(() => {
		const currentTypeMediaList = explorerStore.fileList.filter(
			(m) => m.type === localStore.currentType,
		);

		if (localStore.selectedMedia.length === currentTypeMediaList.length) {
			localStore.unSelectAllMedia();
		} else {
			localStore.selectAllMedia();
		}
	}, [
		localStore.currentType,
		localStore.selectedMedia,
		localStore.selectedMedia,
		localStore.unSelectMedia,
		explorerStore.fileList,
	]);

	const [changeNameFormIsOpen, setChangeNameFormIsOpen] = useState(false);

	const handleChangeNameFormClose = useCallback(() => {
		setChangeNameFormIsOpen(false);
	}, [setChangeNameFormIsOpen]);

	const handleChangeNameFormSave = useCallback(
		(name: string) => {
			const mediaItems = localStore.selectedMedia.filter(
				(m) => m.type === localStore.currentType,
			);

			videoEditorStore.addMedia(name, mediaItems);
			localStore.unSelectMedia(mediaItems.map((m) => m.id));

			setChangeNameFormIsOpen(false);
		},
		[
			localStore.selectedMedia,
			localStore.currentType,
			localStore.unSelectMedia,
			setChangeNameFormIsOpen,
		],
	);

	const renderChangeNameForm = () => {
		if (!videoEditorStore.project) {
			return null;
		}

		return (
			<ChangeNameForm
				open={changeNameFormIsOpen}
				status="create"
				value={videoEditorStore.project.name}
				canBeClosed={false}
				onClose={handleChangeNameFormClose}
				onSave={handleChangeNameFormSave}
			/>
		);
	};

	const addMediaToTimeline = useCallback(() => {
		if (!videoEditorStore.project) {
			return;
		}

		const mediaItems = localStore.selectedMedia.filter(
			(m) => m.type === localStore.currentType,
		);

		if (mediaItems.length === 0) {
			return;
		}

		if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
			setChangeNameFormIsOpen(true);
			return;
		}

		videoEditorStore.addMedia(null, mediaItems);
		localStore.unSelectMedia(mediaItems.map((m) => m.id));
	}, [
		localStore.selectedMedia,
		localStore.currentType,
		localStore.unSelectMedia,
		videoEditorStore.project?.id,
	]);

	const handleMediaItemMouseDown = useCallback(
		(mediaItem: FileResponse) => {
			if (videoEditorStore.isReadOnly) return;
			const onMouseMove = (moveEvent: MouseEvent) => {
				moveEvent.preventDefault();

				if (!localStore.isSelectedMedia(mediaItem.id)) {
					localStore.selectMedia(mediaItem);
				}
			};

			const onMouseUp = (upEvent: MouseEvent) => {
				const mouseOnTimeline = timelineRef.current?.contains(
					document.elementFromPoint(upEvent.clientX, upEvent.clientY),
				);
				if (mouseOnTimeline) {
					addMediaToTimeline();
				}
				document.removeEventListener('mousemove', onMouseMove);
				document.removeEventListener('mouseup', onMouseUp);
			};

			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		},
		[
			addMediaToTimeline,
			timelineRef,
			localStore,
			videoEditorStore.isReadOnly,
			localStore.isSelectedMedia,
			localStore.selectMedia,
		],
	);

	const handleMediaItemClick = useCallback(
		(mediaItem: FileResponse) => {
			if (videoEditorStore.isReadOnly) return;
			if (localStore.isSelectedMedia(mediaItem.id)) {
				localStore.unSelectMedia([mediaItem.id]);
			} else {
				localStore.selectMedia(mediaItem);
			}
		},
		[
			localStore.isSelectedMedia,
			localStore.selectedMedia,
			localStore.unSelectMedia,
			videoEditorStore.isReadOnly,
		],
	);

	const mediaList = useMemo(
		() =>
			explorerStore.fileList
				.filter((f) => f.type === localStore.currentType)
				.map((f) => (
					<EditorLibraryItem
						key={f.id}
						file={f}
						selected={localStore.isSelectedMedia(f.id)}
						onMouseDown={() => handleMediaItemMouseDown(f)}
						onClick={() => handleMediaItemClick(f)}
					/>
				)),
		[
			explorerStore.fileList,
			localStore.currentType,
			localStore.isSelectedMedia,
			handleMediaItemMouseDown,
			handleMediaItemClick,
		],
	);

	return (
		<div
			{...restProps}
			className={cx('editor-content editor-content__layout', className)}
		>
			{renderChangeNameForm()}
			<div className="editor-content__bar">
				<button className="editor-content__upload" />
				{MEDIA_TYPES.map((type) => (
					<button
						key={type}
						className={cx('editor-content__item', {
							'editor-content__item--active': localStore.currentType === type,
						})}
						onClick={() => localStore.changeType(type)}
					>
						<FormattedMessage
							id={upFirstLetter(type)}
							defaultMessage={upFirstLetter(type)}
						/>
					</button>
				))}
			</div>
			<div className="video-library">
				<Explorer fileList={mediaList} />
				<div className="video-library__actions">
					<button
						disabled={videoEditorStore.isReadOnly}
						className="video-library__action"
						onClick={selectAll}
					>
						<FormattedMessage id="Select All" defaultMessage="Select All" />
					</button>
					<button
						disabled={videoEditorStore.isReadOnly}
						className="video-library__action"
						onClick={addMediaToTimeline}
					>
						<FormattedMessage
							id="On the timeline"
							defaultMessage="On the timeline"
						/>
					</button>
					{/* <button className="video-library__action">*/}
					{/*  <FormattedMessage id="Delete" defaultMessage="Delete" />*/}
					{/* </button>*/}
				</div>
			</div>
		</div>
	);
};

export const EditorLibrary = observer(EditorLibraryComponent);
