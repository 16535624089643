export function formatToHhmmss(
	seconds: number | Date,
	options?: Intl.DateTimeFormatOptions,
) {
	const { format } = Intl.DateTimeFormat('ru-RU', {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		...(options || {}),
	});

	return format(
		seconds instanceof Date ? seconds : new Date(0, 0, 0, 0, 0, seconds),
	);
}
