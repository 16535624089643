import React, {
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useEffectOnceWhen } from 'rooks';
import cx from 'classnames';
import { Feature, Point } from 'geojson';

import {
	GRAY_COLOR,
	ICONS_CLASSNAME,
	INPUT_MASK,
	TRANSITION_MS,
} from '_constants';
import {
	categoryOptions,
	COORDS_NAMES,
	COST_EXTERNALS,
	MAP_RADIUS_KM,
	MAP_RADIUS_WALKABLE_KM,
	MONITOR_FILTER_NAMES,
	TMonitorFilterName,
} from './constants';
import { IMonitorItem } from '_types/stores';
import { monitorsStore } from 'stores/monitors/monitor-list';
import {
	Address,
	MonitorCategory,
	MonitorStatus,
	Order,
	UserRole,
} from 'utils/api/api';
import {
	formatAddress,
	formatCurrency,
	handleError,
	swaggerApi,
	toast,
	upFirstLetter,
} from 'utils';
import { arrayToCollection, stringToCoords } from 'utils/geojson';
import {
	checkFeatureKind,
	circleToPolygonGeometry,
	createCircle,
	createGeoObjects,
	createMap2_1,
	geoSearch,
	MAX_YANDEX_SEARCH_RESULT_COUNT,
	MAX_YANDEX_SEARCH_SKIP,
	RUSSIA,
	searchInside,
	throttledGeoSearch,
	yandexSearchTextByMonitorCategory,
} from 'utils/yandex-api';
import { FeatureWithProps, IGeoSearchParams } from 'utils/yandex-api/types';

import {
	CustomMap,
	MapInitCallback,
	MIN_SEARCH_TEXT_LENGTH,
	Placemark,
} from 'components/custom-map';
import { Button, IButtonProps } from 'components/common';
import { Form, Input } from 'components/forms';
import {
	IUseFormReturnTypeGetter,
	IUseFormReturnTypeSetter,
	useForm,
} from '../../hooks';
import { appStore } from '../../stores/app';
import { DomEventHandler, LngLat, PolygonGeometry } from '@yandex/ymaps3-types';
import { Icon } from '../../components/icon';
import * as MonitorListComponents from './list-components';
import { Actions, NameWithIcon } from './list-components';

import { icons } from 'assets/icons';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../stores/auth';
import { YMapLocation } from '@yandex/ymaps3-types/imperative/YMap';
import { Feature as YMapFeature } from '@yandex/ymaps3-types/packages/clusterer';
import { Circle, IGeoObjectCollection } from 'yandex-maps';
import { SliderInput } from '../../components/slider-input';

export type TFilterStep = 'Location' | 'Radius' | 'Category' | 'Cost';

export interface IMapFilterData {
	selectedCategories: MonitorCategory[];
	minWarranty: number[];
	maxDuration: number[];
	price1s: number[];
	noStrictSearch: boolean;
	address?: string;
	radius?: number;
	latitude?: number;
	longitude?: number;
}

export interface IFilterState {
	isExpand: boolean;
	filterStep: TFilterStep | null;
}

export interface IMonitorsMapProps {
	filterState: [IFilterState, Dispatch<SetStateAction<IFilterState>>];
	onFilter: (monitorIds: Array<IMonitorItem['id']> | null) => void;
	onMonitorSelect: (id: string) => void;
}

const StepButton: FC<
	Omit<IButtonProps, 'form'> & {
		form: [
			IUseFormReturnTypeGetter<IMapFilterData>,
			IUseFormReturnTypeSetter<IMapFilterData>,
		];
		onlyCoords: boolean;
		selectedCategoriesCount: number;
		step: TFilterStep;
		selected: boolean;
		costExternals: typeof COST_EXTERNALS;
	}
> = ({
	step,
	selected,
	form,
	onlyCoords,
	selectedCategoriesCount,
	costExternals,
	...restProps
}) => {
	const intl = useIntl();
	const [{ values }] = form;
	let formattedMessage: ReactNode = (
		<FormattedMessage id={step} defaultMessage={upFirstLetter(step)} />
	);
	let filled = false;

	switch (step) {
		case 'Location':
			{
				if (onlyCoords) {
					formattedMessage = `${intl.formatMessage({
						id: 'W./L.',
						defaultMessage: 'Ш./Д.',
					})}: ${values.latitude}/${values.longitude}`.concat();
					filled = true;
				} else if (values.address) {
					formattedMessage = values.address;
					filled = true;
				}

				if (selected || !filled) {
					formattedMessage = (
						<FormattedMessage
							id="Locality (address)"
							defaultMessage="Населённый пункт"
						/>
					);
				}
			}
			break;

		case 'Category':
			{
				if (onlyCoords) {
					formattedMessage = (
						<FormattedMessage
							id="Category Blocked"
							defaultMessage="Категории недоступны"
						/>
					);
				} else {
					formattedMessage = (
						<FormattedMessage
							id="Multiple selected"
							values={{
								count: selectedCategoriesCount,
							}}
						/>
					);

					if (selectedCategoriesCount) {
						filled = true;
					}
				}
			}
			break;

		case 'Radius':
			{
				if (typeof values.radius === 'number') {
					filled = true;
					formattedMessage = `${intl.formatMessage({
						id: 'Search radius',
						defaultMessage: 'Радиус поиска',
					})}: ${values.radius}${intl.formatMessage({
						id: 'km',
						defaultMessage: 'км',
					})}`;
				}

				if (selected || !filled) {
					formattedMessage = <FormattedMessage id="Search radius (km)" />;
				}
			}
			break;

		case 'Cost': {
			filled = MONITOR_FILTER_NAMES.some((name) => {
				const numbers = values[name];

				return (
					numbers.length &&
					costExternals[name].some((n, idx) => n !== numbers[idx])
				);
			});

			formattedMessage = (
				<FormattedMessage id="The cost" defaultMessage="Стоимость" />
			);
		}
	}

	return (
		<Button
			{...restProps}
			className={cx('monitor__filter-step', {
				selected,
				filled,
			})}
			rounded
		>
			{formattedMessage}
		</Button>
	);
};

const LocationStep: FC<{
	form: [
		IUseFormReturnTypeGetter<IMapFilterData>,
		IUseFormReturnTypeSetter<IMapFilterData>,
	];
	geoLocations: Array<FeatureWithProps>;
	onlyCoords: boolean;
}> = ({ form, geoLocations, onlyCoords }) => {
	const intl = useIntl();
	const [{ values }, { setValues }] = form;

	const handleCoordsPaste = useCallback(
		(e: React.ClipboardEvent) => {
			const clipboardString = e.clipboardData.getData('text/plain');
			const [latitude, longitude] = stringToCoords(clipboardString);

			if (latitude && longitude) {
				e.preventDefault();
				setValues((state) => ({
					...state,
					latitude,
					longitude,
				}));
			}
		},
		[setValues],
	);

	const addressList = useMemo(
		() =>
			geoLocations
				.filter(
					(
						f,
					): f is typeof f & {
						properties: NonNullable<typeof f.properties>;
					} => Boolean(f.properties),
				)
				.map(({ properties, geometry }) => {
					const address = `${properties.name}, ${properties.description}`;
					const checked = address === values.address;

					return (
						<label
							key={JSON.stringify(geometry)}
							className={cx(['monitor__filter-address-option', { checked }])}
						>
							<input
								name="address"
								type="radio"
								value={address}
								checked={checked}
								onChange={() => null}
							/>
							{address}
						</label>
					);
				}),
		[geoLocations, values.address],
	);

	const resetCoords = useCallback(
		() =>
			setValues((state) => {
				const newState = { ...state };
				delete newState.latitude;
				delete newState.longitude;
				return newState;
			}),
		[setValues],
	);

	return (
		<>
			<div className="flex row monitor__filter-location">
				<Input
					autoComplete="off"
					fieldClass="monitor__filter-input"
					disabled={onlyCoords}
					name="address"
					rawPlaceholder={intl.formatMessage({
						id: 'Search',
						defaultMessage: 'Поиск',
					})}
					value={values.address || ''}
				/>
				<div className="monitor__filter-splitter" />
				{COORDS_NAMES.map((name) => (
					<Input
						key={name}
						fieldClass="monitor__filter-input"
						name={name}
						rawPlaceholder={INPUT_MASK.COORDS}
						type="number"
						step={'any'}
						value={values[name] || ''}
						onPaste={handleCoordsPaste}
						fieldElement={intl
							.formatMessage({
								id: upFirstLetter(name),
								defaultMessage: name === 'latitude' ? 'Широта' : 'Долгота',
							})
							.toLowerCase()}
					/>
				))}
				<button
					disabled={!values.latitude && !values.longitude}
					type="button"
					className="monitor__filter-clear-button"
					onClick={resetCoords}
					title={intl.formatMessage({
						id: 'Reset Coords',
						defaultMessage: 'Сбросить координаты',
					})}
				>
					<Icon className={icons.XClear} />
				</button>
			</div>
			<div className="list">{!onlyCoords ? addressList : null}</div>
		</>
	);
};

const RadiusStep: FC<{
	form: [
		IUseFormReturnTypeGetter<IMapFilterData>,
		IUseFormReturnTypeSetter<IMapFilterData>,
	];
}> = ({ form }) => {
	const [{ values }] = form;

	const radiusList = useMemo(
		() =>
			MAP_RADIUS_KM.map((km) => {
				const checked = km === values.radius;

				return (
					<label
						key={`radius-${km}`}
						className={cx(['monitor__filter-radius-select', { checked }])}
					>
						<input
							name="radius"
							type="radio"
							value={km}
							checked={checked}
							onChange={() => null}
						/>
						{km} <FormattedMessage id="km" defaultMessage="km" />
					</label>
				);
			}),
		[values.radius],
	);

	return (
		<div className="flex row monitor__filter-radius">
			<span className="monitor__filter-radius-label">
				<FormattedMessage id="Search radius" defaultMessage="Радиус поиска" />
			</span>
			<div className="monitor__filter-splitter" />
			<Input
				name="radius"
				fieldClass="monitor__filter-input"
				rawPlaceholder="000.00"
				type="number"
				step={'any'}
				value={values.radius || ''}
			/>
			<div className="monitor__filter-radius-select-layout">{radiusList}</div>
		</div>
	);
};

const CategoryStep: FC<{
	form: [
		IUseFormReturnTypeGetter<IMapFilterData>,
		IUseFormReturnTypeSetter<IMapFilterData>,
	];
	onlyCoords: boolean;
}> = ({ form, onlyCoords }) => {
	const [{ values }] = form;

	return onlyCoords ? (
		<FormattedMessage
			id="OnlyCoordsSearch"
			defaultMessage="Вы указали точные координаты. Поиск по категориям не доступен."
		/>
	) : (
		<>
			<div className="monitor__filter-category-grid">
				{categoryOptions.map((category) => {
					const checked = values.selectedCategories.includes(category);

					return (
						<label
							key={category}
							className={cx('monitor__filter-category', { checked })}
						>
							<input
								type="checkbox"
								name="category"
								checked={checked}
								value={category}
								onChange={() => null}
								className="monitor__filter-category-checkbox"
							/>
							<div className="monitor__filter-category-button">
								<Icon className={ICONS_CLASSNAME[category]} />
							</div>
							<span className="monitor__filter-category-name">
								<FormattedMessage id={category} defaultMessage={category} />
							</span>
						</label>
					);
				})}
			</div>
			<label
				className={cx('checkbox monitor__filter-category-strict', {
					checked: values.noStrictSearch,
				})}
			>
				<input
					type="checkbox"
					name="noStrictSearch"
					checked={values.noStrictSearch}
					onChange={() => null}
				/>
				<div className="checkbox__box">
					<Icon className={cx('checkbox__icon', icons.CheckHover)} />
				</div>
				<FormattedMessage
					id="StrictCategorySearch"
					defaultMessage="Показывать устройства в других категориях, если они находятся рядом"
				/>
			</label>
		</>
	);
};

const CostStep: FC<{
	form: [
		IUseFormReturnTypeGetter<IMapFilterData>,
		IUseFormReturnTypeSetter<IMapFilterData>,
	];
	externals: {
		[P in TMonitorFilterName]: number[];
	};
}> = ({ form, externals }) => {
	const [{ values }] = form;

	return (
		<div className="monitor__filter-cost">
			{MONITOR_FILTER_NAMES.map((name) => {
				const [min, max] = externals[name];

				return (
					<div key={name} className="monitor__filter-cost-item">
						<span className="monitor__filter-cost-title">
							<FormattedMessage id={`app.table.${name}`} />
						</span>
						<SliderInput name={name} value={values[name]} min={min} max={max} />
					</div>
				);
			})}
		</div>
	);
};

const PlacemarkMonitorPopup: FC<{ monitor: IMonitorItem }> = observer(
	({ monitor }) => {
		return (
			<div className="monitor__placemark-popup">
				<div className="monitor__placemark-popup-top">
					<NameWithIcon
						name={monitor.name}
						enabled={monitor.status === MonitorStatus.Online}
						type={monitor.multiple}
					/>
					<div className="monitor__placemark-popup-buttons">
						{authStore.userRole === UserRole.Advertiser ? (
							<>
								<Icon className={icons.Favorite} />
								<Icon className={icons.PlaylistOff} />
							</>
						) : (
							<Actions id={monitor.id} multiple={monitor.multiple} />
						)}
					</div>
				</div>
				<div className="monitor__placemark-popup-info">
					<table>
						<tbody>
							{(
								[
									'category',
									'price1s',
									'minWarranty',
									'maxDuration',
									'address',
								] as Array<keyof IMonitorItem>
							).map((accessor) => {
								let value = monitor[accessor] as ReactNode;

								switch (accessor) {
									case 'category':
										{
											value = (
												<FormattedMessage
													id={value as string}
													defaultMessage={value as string}
												/>
											);
										}
										break;
									case 'price1s':
										value = formatCurrency(value as number) || (
											<FormattedMessage
												id="PriceFree"
												defaultMessage="Бесплатно"
											/>
										);

										break;

									case 'minWarranty':
										value = value || (
											<FormattedMessage
												id="NoLimit"
												defaultMessage="Без ограничений"
											/>
										);

										break;

									case 'address':
										value = formatAddress(value as Address);
								}

								return (
									<tr key={accessor}>
										<td data-accessor={accessor}>
											{
												<FormattedMessage
													id={`app.table.${accessor}`}
													defaultMessage={upFirstLetter(accessor)}
												/>
											}
										</td>
										<td>{value}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	},
);

export const MonitorsMap: FC<IMonitorsMapProps> = ({
	filterState,
	onFilter,
}) => {
	const intl = useIntl();
	const [map, setMap] = useState<Parameters<MapInitCallback>[0] | null>(null);
	const [geoLocations, setGeoLocations] = useState<Array<FeatureWithProps>>([]);
	const [polygons, setPolygons] = useState<PolygonGeometry[]>([]);
	const [filteredIds, setFilteredIds] = useState<IMonitorItem['id'][] | null>(
		null,
	);
	const [mapLocation, setLocation] = useState<YMapLocation | undefined>();
	const [costExternals, setCostExternals] = useState(COST_EXTERNALS);

	const [{ isExpand, filterStep }, setState] = filterState;

	const steps = useMemo<TFilterStep[]>(
		() => ['Location', 'Radius', 'Category', 'Cost'],
		[],
	);

	const loadGeoLocations = useCallback(
		(text: IGeoSearchParams['text']) => {
			void throttledGeoSearch(
				{
					text,
					results: 15,
				},
				({ features }) => {
					const locations = features.filter((f): f is FeatureWithProps =>
						checkFeatureKind(f, ['locality', 'province']),
					);

					setGeoLocations(locations);
				},
			);
		},
		[setGeoLocations],
	);

	const expandCollapse = useCallback(() => {
		setState((state) => {
			const newState = {
				...state,
				isExpand: !state.isExpand,
			};

			if (newState.isExpand) {
				setTimeout(() =>
					appStore.setLayout({
						sidebarOpen: false,
					}),
				);
			} else {
				newState.filterStep = null;
			}

			return newState;
		});
	}, [setState, map]);

	const parseMonitorList = useCallback(
		async (values: IMapFilterData) => {
			if (monitorsStore.count !== monitorsStore.list.length) {
				await monitorsStore.getList();
			}

			let mapMonitors = monitorsStore.list.filter((m) =>
				MONITOR_FILTER_NAMES.every((name) => {
					const numbers = values[name];
					if (numbers.length) {
						const [min, max] = numbers;
						const val = m[name];

						if (typeof val === 'number') {
							return max >= val && val >= min;
						}
					}
					return true;
				}),
			);

			if (!values.noStrictSearch && values.selectedCategories.length) {
				mapMonitors = mapMonitors.filter((m) =>
					values.selectedCategories.includes(m.category),
				);
			}
			const monitorCollection = arrayToCollection(mapMonitors, 'location', [
				'id',
				'category',
			]);

			return {
				mapMonitors,
				monitorCollection,
				monitorGeoObjects: createGeoObjects({
					collection: monitorCollection,
				}),
			};
		},
		[monitorsStore.list, monitorsStore.count],
	);

	const showAllMonitors = useCallback(
		async (values: IMapFilterData) => {
			if (!map) return;
			try {
				const { monitorGeoObjects } = await parseMonitorList(values);
				const map2_1 = createMap2_1();
				// @ts-expect-error: no TypeScript support
				monitorGeoObjects.addToMap(map2_1);
				const bounds = map2_1.getBounds();
				map.setLocation({
					bounds: [
						[bounds[0][1], bounds[0][1]],
						[bounds[1][0], bounds[1][0]],
					],
				});
				setLocation({
					center: map.center as LngLat,
					bounds: map.bounds,
					zoom: map.zoom,
				});
				setFilteredIds(null);
				setPolygons([]);
			} catch (e) {
				toast.error(handleError(e));
				console.error(e);
			}
		},
		[map, parseMonitorList],
	);

	const form = useForm(
		{
			selectedCategories: [],
			minWarranty: [],
			maxDuration: [],
			price1s: [],
			noStrictSearch: true,
		},
		{
			disableDeepEqual: true,
			parsers: {
				// ...Object.fromEntries(
				// 	MONITOR_FILTER_NAMES.map((name) => [
				// 		name,
				// 		(v) => ({ [name]: typeof v === 'string' ? JSON.parse(v) : v }),
				// 	]),
				// ),
				category: (value, state) => {
					const category = value as MonitorCategory;

					const selectedCategories = state.selectedCategories.includes(category)
						? state.selectedCategories.filter((c) => category !== c)
						: state.selectedCategories.concat(category);

					return {
						selectedCategories,
					};
				},
				latitude: (val) => {
					const latitude = Number(val);
					if (latitude < -90 || 90 < latitude) return;
					return {
						latitude,
					};
				},
				longitude: (val) => {
					const longitude = Number(val);
					if (longitude < -180 || 180 < longitude) return;
					return {
						longitude,
					};
				},
				radius: (val) => {
					return { radius: Number(val) };
				},
			},
			onChange(newState, state, names, e) {
				if (names.includes('address')) {
					const type = e?.target.type;
					const value = newState.address;
					switch (type) {
						case 'text':
							{
								if (
									typeof value === 'string' &&
									value.length >= MIN_SEARCH_TEXT_LENGTH
								) {
									loadGeoLocations(value);
								} else {
									setGeoLocations([]);
								}
							}
							break;

						case 'radio': {
							setTimeout(() => {
								setGeoLocations([]);
							});
						}
					}
				}
			},
			onSubmit: async (data) => {
				setState((state) => ({ ...state, filterStep: null }));

				if (!map) return;
				const { address, selectedCategories, latitude, longitude, radius } =
					data;
				let filteredIds: Parameters<IMonitorsMapProps['onFilter']>[0] = [],
					circles: Circle[] = [];

				const getAndSetRadius = (defaultRadiusKm: number) => {
					let circleRadius = radius;

					if (!circleRadius) {
						circleRadius = defaultRadiusKm;
						form[1].setField('radius', circleRadius);
					}

					return circleRadius;
				};

				try {
					const map2_1 = createMap2_1();

					let { monitorGeoObjects } = await parseMonitorList(data);

					if (latitude && longitude && radius) {
						const circle = createCircle([latitude, longitude], radius * 1000);

						map2_1.geoObjects.add(circle);

						circles.push(circle);
						monitorGeoObjects = searchInside(monitorGeoObjects, circle);
					} else {
						let filteredObjects = createGeoObjects({
							objects: monitorGeoObjects,
						});

						if (selectedCategories.length) {
							const searchAddress = address || RUSSIA,
								searchCircleRadius = getAndSetRadius(MAP_RADIUS_WALKABLE_KM);
							let outsideObjects = createGeoObjects({
								objects: monitorGeoObjects,
							});

							const search = async (
								acc: IGeoObjectCollection[] = [],
								skip = 0,
							): Promise<IGeoObjectCollection[]> => {
								if (
									!outsideObjects.getLength() ||
									skip > MAX_YANDEX_SEARCH_SKIP
								) {
									return acc;
								}
								const entries = await Promise.all(
									selectedCategories.map(
										(category: MonitorCategory) =>
											new Promise<IGeoObjectCollection[]>((resolve) => {
												geoSearch(
													{
														text: `${yandexSearchTextByMonitorCategory[category]}, ${searchAddress}`,
														type: 'biz',
														results: skip + MAX_YANDEX_SEARCH_RESULT_COUNT,
														skip,
													},
													({ features }) => {
														const collections: IGeoObjectCollection[] = [];
														features
															.map((f) => {
																const geometry = f.geometry as Point;

																return createCircle(
																	geometry.coordinates,
																	searchCircleRadius * 1000,
																);
															})
															.forEach((circle) => {
																map2_1.geoObjects.add(circle);

																const objectsOnCircle = searchInside(
																	outsideObjects,
																	circle,
																);

																if (objectsOnCircle.getLength()) {
																	circles.push(circle);
																	collections.push(objectsOnCircle);
																} else {
																	map2_1.geoObjects.remove(circle);
																}
																outsideObjects =
																	// @ts-expect-error: no TypeScript support
																	outsideObjects.remove(objectsOnCircle);
															});

														resolve(collections);
													},
												);
											}),
									),
								);

								return search(
									[...acc, ...entries.flat()],
									skip + MAX_YANDEX_SEARCH_RESULT_COUNT,
								);
							};

							const collections = await search();

							filteredObjects =
								// @ts-expect-error: no TypeScript support
								filteredObjects.remove(filteredObjects);
							collections.flat().forEach((collection) => {
								// @ts-expect-error: no TypeScript support
								filteredObjects = filteredObjects.add(collection);
							});
						} else if (address) {
							const circleRadius = getAndSetRadius(MAP_RADIUS_KM[0]);
							let feature: FeatureWithProps | Feature | undefined =
								geoLocations.find(
									(f) =>
										`${f.properties.name}, ${f.properties.description}` ===
										address,
								);

							if (!feature) {
								await new Promise((resolve, reject) => {
									geoSearch({ text: address, results: 1 }, ({ features }) => {
										if (features.length) {
											feature = features[0];
											resolve(true);
										} else {
											reject('Address not found');
										}
									});
								});
							}
							if (feature) {
								const geometry = feature.geometry as Point;

								const circle = createCircle(
									geometry.coordinates,
									circleRadius * 1000,
								);

								map2_1.geoObjects.add(circle);

								filteredObjects = searchInside(monitorGeoObjects, circle);
								circles.push(circle);
							}
						}

						monitorGeoObjects = filteredObjects;
					}

					monitorGeoObjects.each((obj) => {
						const monitorId = obj.properties.get(
							'id',
							{},
						) as unknown as IMonitorItem['id'];

						filteredIds?.push(monitorId);
					});
					// @ts-expect-error: no TypeScript support
					monitorGeoObjects.addToMap(map2_1);

					const bounds = map2_1.geoObjects.getBounds();
					if (bounds) {
						map.setLocation({
							bounds: [
								[bounds[0][1], bounds[0][0]],
								[bounds[1][1], bounds[1][0]],
							],
						});

						setLocation({
							bounds: map.bounds,
							center: map.center as LngLat,
							zoom: map.zoom,
						});
					}
				} catch (e) {
					filteredIds = null;
					circles = [];
					toast.error(handleError(e));
					console.error(e);
				} finally {
					setFilteredIds(filteredIds);
					setPolygons(
						circles.map((c) =>
							circleToPolygonGeometry(
								// @ts-expect-error: no TypeScript support
								c.geometry._coordinates,
								// @ts-expect-error: no TypeScript support
								c.geometry._radius,
							),
						),
					);
					onFilter(filteredIds);
				}
			},
			onReset: async () => {
				onFilter(null);
				const newState: IMapFilterData = {
					selectedCategories: [],
					minWarranty: [],
					maxDuration: [],
					price1s: [],
					noStrictSearch: true,
				};
				await showAllMonitors(newState);

				return newState;
			},
		},
	);
	const [
		{ values, formProps, formStatus, canSubmit },
		{ setValues, setField, submit, reset },
	] = form;

	const onlyCoords = useMemo(
		() => Boolean(values.latitude && values.longitude),
		[values.latitude, values.longitude],
	);

	const selectedCategoriesCount = useMemo(
		() => values.selectedCategories.length,
		[values.selectedCategories],
	);

	const filterDisabled = useMemo(() => {
		if (!canSubmit) {
			return true;
		}
		let isDisabled = false;

		if (selectedCategoriesCount) {
			return false;
		}

		if (onlyCoords) {
			isDisabled = !values.radius; // центр круга без радиуса
		} else {
			const filledFields = Object.entries(values).filter(
				([k, v]) => !['selectedCategories', ...COORDS_NAMES].includes(k) && v,
			);
			isDisabled = !filledFields.length;

			if (filledFields.length === 1) {
				const nameToValue = Object.fromEntries(filledFields);

				if (nameToValue.radius) {
					// радиус без какого-либо центра
					isDisabled = true;
				}
			}
		}

		return isDisabled;
	}, [values, onlyCoords, selectedCategoriesCount, canSubmit]);

	useEffectOnceWhen(
		() => {
			void showAllMonitors(values);
		},
		Boolean(map && monitorsStore.list.length > 0),
	);

	const handleMapClick = useCallback<DomEventHandler>(
		(obj, e) => {
			const [lng, lat] = e.coordinates;

			setValues((state) => ({
				...state,
				latitude: lat,
				longitude: lng,
			}));
		},
		[setField],
	);

	const points = useMemo<YMapFeature[]>(
		() =>
			(filteredIds
				? monitorsStore.list.filter((m) => filteredIds.includes(m.id))
				: monitorsStore.list
			)
				.map((m) => {
					if (m.location) {
						const [lat, lng] = m.location.coordinates;

						return {
							type: 'Feature',
							id: m.id,
							geometry: {
								coordinates: [lng, lat],
							},
							properties: { ...m },
						};
					}
					return null as unknown as YMapFeature;
				})
				.filter((f): f is YMapFeature => Boolean(f)),
		[monitorsStore.list, filteredIds],
	);

	const marker = useCallback((f: YMapFeature) => {
		const monitor = f.properties as unknown as IMonitorItem;
		const count = Number(monitor.groupIds?.length);
		const withCount = count > 1;

		const label = (
			<div
				className={cx('monitor__placemark-label', {
					'with-count': withCount,
				})}
			>
				<Icon className={ICONS_CLASSNAME[monitor.category]} />
				{withCount && <span>{count}</span>}
			</div>
		);

		return (
			<Placemark
				coordinates={f.geometry.coordinates}
				label={label}
				className="monitor__placemark"
				title={monitor.name}
			>
				<PlacemarkMonitorPopup monitor={monitor} />
			</Placemark>
		);
	}, []);

	const cluster = useCallback(
		(coordinates: LngLat, features: YMapFeature[]) => {
			const monitors = features.map(
				(f) => f.properties as unknown as IMonitorItem,
			);
			const { category, address } = monitors[0];
			const formattedAddress = formatAddress(address);
			const categoryDiff = monitors.some((m) => m.category !== category);
			const addressDiff = monitors.some(
				(m) => formattedAddress !== formatAddress(m.address),
			);

			const label = (
				<div className={cx('monitor__placemark-label')}>
					{categoryDiff || <Icon className={ICONS_CLASSNAME[category]} />}
					<span>{features.length}</span>
				</div>
			);

			return (
				<Placemark
					coordinates={coordinates}
					label={label}
					className={cx('monitor__placemark', { cluster: categoryDiff })}
				>
					<div className="monitor__placemark-popup">
						{!categoryDiff && (
							<div className="flex row monitor__placemark-popup-top">
								<div className="monitor__filter-category">
									<div className="monitor__filter-category-button">
										<Icon className={ICONS_CLASSNAME[category]} />
									</div>
								</div>
								{!addressDiff && <span>{formattedAddress}</span>}
							</div>
						)}
						<div className="monitor__placemark-popup-table">
							<table>
								<tbody>
									{monitors.map((m) => {
										return (
											<tr key={m.id}>
												<td>
													<MonitorListComponents.StatusIcon
														id={m.id}
														enabled={m.status === MonitorStatus.Online}
														hideText
													/>
												</td>
												<td>
													<MonitorListComponents.NameWithIcon
														name={m.name}
														enabled={m.status === MonitorStatus.Online}
														type={m.multiple}
													/>
												</td>
												<td>
													{addressDiff ? formatAddress(m.address) : m.model}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</Placemark>
			);
		},
		[],
	);

	useEffect(() => {
		Promise.all(
			MONITOR_FILTER_NAMES.map(async (name) => {
				const numbers = await Promise.all(
					[Order.ASC, Order.DESC].map(async (order, idx) => {
						const { data: monitorsData } = await swaggerApi.api.monitorsGet({
							scope: {
								order: {
									[name]: order,
								},
								limit: 1,
							},
							select: ['id', name],
						});
						const monitor = monitorsData.data[0];

						return monitor && typeof monitor[name] === 'number'
							? monitor[name]
							: COST_EXTERNALS[name][idx];
					}),
				);

				return [name, numbers];
			}),
		).then((results) => setCostExternals(Object.fromEntries(results)));
	}, []);

	return (
		<div
			className={cx('monitor__map', { expand: isExpand })}
			style={{
				transition: `height ${TRANSITION_MS.LAYOUT_CHANGES}ms`,
			}}
		>
			<CustomMap
				location={mapLocation}
				onInit={setMap}
				onClick={handleMapClick}
			>
				{({ YMapClusterer, YMapFeature, clusterByGrid }) => {
					// @ts-expect-error: no TypeScript support
					const method = clusterByGrid({ gridSize: 56 });

					return (
						<>
							<YMapClusterer
								marker={marker}
								cluster={cluster}
								features={points}
								method={method}
							/>
							{polygons.map((p) => (
								<YMapFeature
									key={p.coordinates.join('')}
									geometry={p}
									style={{
										stroke: [
											{
												color: GRAY_COLOR,
												width: 1,
												dash: [3, 10],
											},
										],
										fill: 'rgba(0,0,0,.2)',
									}}
									properties={{
										geodesic: true,
										circleCompatibility: true,
									}}
								/>
							))}
						</>
					);
				}}
			</CustomMap>
			<div className={cx('monitor__filter', { open: Boolean(filterStep) })}>
				<div className="monitor__filter-steps">
					{steps.map((step) => (
						<StepButton
							key={step}
							costExternals={costExternals}
							onlyCoords={onlyCoords}
							form={form}
							selected={step === filterStep}
							selectedCategoriesCount={selectedCategoriesCount}
							step={step}
							onClick={() =>
								setState((state) => {
									const newState = {
										...state,
										filterStep: state.filterStep === step ? null : step,
									};

									if (newState.filterStep && !state.isExpand) {
										newState.isExpand = true;
									}

									return newState;
								})
							}
						/>
					))}
					<Button
						disabled={filterDisabled}
						type="submit"
						primary
						onClick={submit}
					>
						{formStatus === 'submitProcess' ? (
							<span className="monitor__filter-status">
								<FormattedMessage id="Searching" defaultMessage="Ищем..." />
							</span>
						) : (
							<FormattedMessage id="Apply" defaultMessage="Применить" />
						)}
					</Button>
					{formStatus === 'wasSubmit' ? (
						<button
							type="reset"
							className="monitor__filter-clean"
							onClick={reset}
							title={intl.formatMessage({
								id: 'Clear the search result',
								defaultMessage: 'Очистить результат поиска',
							})}
						/>
					) : null}
				</div>
				<Form {...formProps} className="monitor__filter-form">
					{filterStep === 'Location' && (
						<LocationStep
							form={form}
							onlyCoords={onlyCoords}
							geoLocations={geoLocations}
						/>
					)}
					{filterStep === 'Category' && (
						<CategoryStep form={form} onlyCoords={onlyCoords} />
					)}
					{filterStep === 'Radius' && <RadiusStep form={form} />}
					{filterStep === 'Cost' && (
						<CostStep form={form} externals={costExternals} />
					)}
				</Form>
			</div>
			<div
				className={cx('monitor__info', { empty: !points.length })}
				onClick={expandCollapse}
			>
				{isExpand && (
					<FormattedMessage
						id="OnlyCoordsSearch"
						defaultMessage="На карте {count} из {total}"
						values={{
							count: points.length,
							total: monitorsStore.list.length,
						}}
					/>
				)}
			</div>
			<button className="monitor__toggle" onClick={expandCollapse}>
				<Icon className={icons.ArrowMixed} />
			</button>
		</div>
	);
};
