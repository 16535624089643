import { DEFAULT_ROUTE } from './constants';
import { IAppRouteProps, TRouteKey } from './types';
import routes from './routes';

const ROUTE_MESSAGE_PARTS = ['app', 'route'];
const MESSAGE_ID_SEPARATOR = '.';

/**
 * @return {Boolean}
 * @example
 * 'CurrentUser' => true
 * 'InvalidKey' => false
 */
export function isRouteKey(draftKey: unknown): draftKey is TRouteKey {
	return typeof draftKey === 'string' && draftKey in routes;
}

/**
 * @returns {string} app.route.routeKey
 * @example
 * 'CurrentUser' => 'app.route.CurrentUser'
 */
export function routeKeyToMessageId(routeKey: TRouteKey): string {
	return ROUTE_MESSAGE_PARTS.concat(routeKey).join(MESSAGE_ID_SEPARATOR);
}

/**
 * @returns {TRouteKey | undefined}
 * @example
 * 'app.route.CurrentUser' => 'CurrentUser'
 * 'route.current-user' => undefined
 */
export function parseMessageIdToRouteKey(
	messageId: string,
): TRouteKey | undefined {
	const draftKey = messageId
		.split(MESSAGE_ID_SEPARATOR)
		.filter((text) => !ROUTE_MESSAGE_PARTS.includes(text))
		.join('');

	return isRouteKey(draftKey) ? draftKey : undefined;
}

/**
 * @desc
 * Adds default props to the routes (e.g: exact, routeKey)
 */
export function normalizeRoutes(
	draftRoutes: Record<string, Omit<IAppRouteProps, 'routeKey' | 'outletFor'>>,
) {
	return Object.fromEntries(
		Object.entries(draftRoutes).map(
			([routeKey, r]) =>
				[
					routeKey,
					{
						...DEFAULT_ROUTE,
						...r,
						routeKey,
					},
				] as [TRouteKey, IAppRouteProps],
		),
	);
}
