import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { videoEditorStore } from 'modules/video-editor-module';

import { PlayerTopLine } from '../top-line';
import { Player } from './index';
import { TimelineControls } from '../timeline-controls';
import { FC, HTMLAttributes, useMemo } from 'react';

export interface IPlayerContainer extends HTMLAttributes<HTMLDivElement> {}

const PlayerContainerComponent: FC<IPlayerContainer> = () => {
	const hideControls = useMemo(
		() => videoEditorStore.view === 'fullscreen',
		[videoEditorStore.view],
	);

	return (
		<div className={cx('player__container', videoEditorStore.view)}>
			{hideControls ? null : <PlayerTopLine />}
			<Player />
			{hideControls ? null : <TimelineControls />}
		</div>
	);
};

export const PlayerContainer = observer(PlayerContainerComponent);
