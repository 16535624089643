import { FC, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import {
	DAY_IN_WEEK_NUMBER,
	daysForLocale,
	DECADE_NUMBER,
	IntlLocale,
	monthsForLocale,
} from '../../utils/intl';
import './calendar.scss';
import { resetDateTime, upFirstLetter } from '../../utils';
import { Icon } from '../icon';
import { icons } from '../../assets/icons';

export type TCalendarView = 'years' | 'months' | 'days';
export type TDateValue = Date | null | undefined;

export interface ICalendarProps
	extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	value: TDateValue;
	rangeValue?: TDateValue[];
	onRangeChange?: (value: NonNullable<ICalendarProps['rangeValue']>) => void;
	onChange?: (value: ICalendarProps['value']) => void;
	min?: Date;
	max?: Date;
	edgeDate?: Date;
}

export const CALENDAR_DEFAULT_COLUMN_COUNT = 4;

export const Calendar: FC<ICalendarProps> = ({
	className,
	min: _min,
	max: _max,
	value,
	onChange,
	rangeValue,
	onRangeChange,
	edgeDate,
	...restProps
}) => {
	const intl = useIntl();
	const today = useMemo(() => resetDateTime(new Date()), []);
	const valueISOString = useMemo(() => value?.toISOString(), [value]);
	const rangeValueISOStrings = useMemo(
		() => rangeValue?.map((v) => v?.toISOString()),
		[rangeValue],
	);
	const min = useMemo(() => _min && resetDateTime(_min), [_min]);
	const max = useMemo(() => _max && resetDateTime(_max), [_max]);

	const isValidDate = useCallback(
		(date: Date) => {
			let isValid = true;
			if (min) {
				isValid = min <= date;
			}
			if (max) {
				isValid = isValid && date <= max;
			}

			return isValid;
		},
		[min, max],
	);

	const [edgeMin, edgeMax] = useMemo(() => {
		if (rangeValue) {
			return rangeValue;
		}
		if (edgeDate && value) {
			let min: TDateValue, max: TDateValue;
			if (edgeDate > value) {
				min = value;
				max = edgeDate;
			} else {
				min = edgeDate;
				max = value;
			}
			return [min, max];
		}
		return [null, null];
	}, [edgeDate, value, rangeValue]);

	const isBetweenDate = useCallback(
		(date: Date) => {
			return Boolean(edgeMin && edgeMax && edgeMin <= date && date <= edgeMax);
		},
		[edgeMin, edgeMax],
	);

	const [view, setView] = useState<TCalendarView>('days');

	const isSelected = useCallback(
		({
			date,
			year,
			monthIndex,
		}: Partial<{
			date: Date;
			monthIndex: number;
			year: number;
		}>) => {
			switch (view) {
				case 'days': {
					if (!date) {
						return false;
					}
					const dateISOString = date.toISOString();
					if (rangeValueISOStrings) {
						return rangeValueISOStrings.includes(date?.toISOString());
					}
					return dateISOString === valueISOString;
				}
				case 'months': {
					if (monthIndex === undefined) {
						return false;
					}
					if (rangeValue) {
						return rangeValue
							.filter((v): v is Date => Boolean(v))
							.some(
								(v) => v.getFullYear() === year && v.getMonth() === monthIndex,
							);
					}
					return (
						value?.getFullYear() === year && monthIndex === value?.getMonth()
					);
				}

				case 'years': {
					if (year === undefined) {
						return false;
					}
					if (rangeValue) {
						return rangeValue
							.filter((v): v is Date => Boolean(v))
							.some((v) => v.getFullYear() === year);
					}
					return value?.getFullYear() === year;
				}
			}
		},
		[view, rangeValueISOStrings, valueISOString, value, rangeValue],
	);

	const initialValue = useMemo(() => {
		if (rangeValue) {
			return rangeValue[1] || rangeValue[0] || today;
		}
		return value || today;
	}, [rangeValue, value, today]);
	const [decade, setDecade] = useState(() => {
		const str = String(initialValue.getFullYear());

		return Number(str.slice(0, 3));
	});
	const [year, setYear] = useState(() => initialValue.getFullYear());
	const [monthIndex, setMonthIndex] = useState(() => initialValue.getMonth());
	const [dateIndex, setDateIndex] = useState(() => initialValue.getDate());

	const lastDateIndex = useMemo(
		() => new Date(year, monthIndex + 1, 0).getDate(),
		[year, monthIndex],
	);

	const { dayShorts, monthShorts } = useMemo(() => {
		const monthShorts = monthsForLocale(intl.locale, 'short');
		const dayShorts = daysForLocale(intl.locale, 'short');

		return {
			monthShorts,
			dayShorts,
		};
	}, [intl.locale]);

	const prevNext = useCallback(
		(direction: 'prev' | 'next') => {
			const number = direction === 'next' ? 1 : -1;

			switch (view) {
				case 'years':
					setDecade((state) => state + number);
					break;
				case 'months':
					setYear((state) => state + number);
					break;

				case 'days':
					setMonthIndex((state) => state + number);
			}
		},
		[view, setYear, setMonthIndex],
	);

	const changeView = useCallback(() => {
		switch (view) {
			case 'days':
				setView('months');
				break;
			case 'months':
				setView('years');
		}
	}, [view, setView]);

	const dayToColumn = useCallback(
		(day: number) => {
			if (dayShorts.locale === IntlLocale.Ru) {
				switch (day) {
					case 0:
						return DAY_IN_WEEK_NUMBER;
					default:
						return day;
				}
			}

			return day + 1;
		},
		[dayShorts],
	);

	return (
		<div {...restProps} className={cx('calendar', className)}>
			<div className="calendar__head">
				<div className="calendar__title" onClick={changeView}>
					{view === 'years'
						? `${decade}0 - ${decade}9`
						: upFirstLetter(
								Intl.DateTimeFormat(intl.locale, {
									month: view === 'months' ? undefined : 'long',
									year: 'numeric',
								}).format(new Date(year, monthIndex, dateIndex)),
							)}
				</div>
				<div className="calendar__slider">
					{(['prev', 'next'] as const).map((direction) => (
						<div
							key={direction}
							className="calendar__slider-prev"
							onClick={() => prevNext(direction)}
						>
							<Icon
								className={
									direction === 'prev' ? icons.GrayArrowUp : icons.GrayArrow
								}
							></Icon>
						</div>
					))}
				</div>
			</div>
			<div className={cx('calendar__view', view)} data-view={view}>
				{view === 'days' && (
					<>
						<div className="calendar__view-head">
							{dayShorts.map((d, idx) => (
								<div
									key={d}
									className="calendar__item"
									style={{
										// @ts-expect-error: No Typescript support
										'--col': idx + 1,
									}}
								>
									{upFirstLetter(d)}
								</div>
							))}
						</div>
						<div className="calendar__view-content">
							{Array.from({ length: lastDateIndex }).map((...[, idx]) => {
								const dateIdx = idx + 1;
								const date = new Date(year, monthIndex, dateIdx);
								const disabled = !isValidDate(date);
								const selected = isSelected({ date });
								const between = isBetweenDate(date);

								return (
									<div
										key={idx}
										className={cx('calendar__item', {
											selected,
											disabled,
											between,
										})}
										onClick={() => {
											if (disabled) return;
											setDateIndex(dateIndex);
											if (onChange) {
												onChange(date);
											}
											if (onRangeChange && rangeValue) {
												const [start, end] = rangeValue;

												if (start && end) {
													if (date > end) {
														onRangeChange([start, date]);
													} else if (start > date) {
														onRangeChange([date, end]);
													} else {
														onRangeChange([date, undefined]);
													}
												} else if (start) {
													if (date > start) {
														onRangeChange([start, date]);
													}
													if (start > date) {
														onRangeChange([date, start]);
													}
												} else {
													onRangeChange([date, undefined]);
												}
											}
										}}
										style={{
											// @ts-expect-error: No Typescript support
											'--col': dayToColumn(date.getDay()),
										}}
									>
										{dateIdx}
									</div>
								);
							})}
						</div>
					</>
				)}
				{view === 'months' && (
					<div className="calendar__view-content">
						{monthShorts.map((m, idx) => {
							const selected = isSelected({ year, monthIndex: idx });

							return (
								<div
									key={m}
									className={cx('calendar__item', {
										selected,
									})}
									onClick={() => {
										setMonthIndex(idx);
										setView('days');
									}}
									style={{
										// @ts-expect-error: No Typescript support
										'--row': Math.floor(
											idx / CALENDAR_DEFAULT_COLUMN_COUNT + 1,
										),
									}}
								>
									{upFirstLetter(m.replace('.', ''))}
								</div>
							);
						})}
					</div>
				)}
				{view === 'years' && (
					<div className="calendar__view-content">
						{Array.from(new Array(DECADE_NUMBER).keys()).map(
							(zeroNine, idx) => {
								const year = Number(`${decade}${zeroNine}`);
								const selected = isSelected({ year });

								return (
									<div
										key={year}
										className={cx('calendar__item', {
											selected,
										})}
										onClick={() => {
											setView('months');
											setYear(year);
										}}
										style={{
											// @ts-expect-error: No Typescript support
											'--row': Math.floor(
												idx / CALENDAR_DEFAULT_COLUMN_COUNT + 1,
											),
										}}
									>
										{year}
									</div>
								);
							},
						)}
					</div>
				)}
			</div>
		</div>
	);
};
