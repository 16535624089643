import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { playlistsStore } from 'stores/playlists';
import { IPlaylistItem } from '_types/stores';
import {
	FileResponse,
	FileType,
	PlaylistCreateRequest,
	PlaylistUpdateRequest,
} from 'utils/api/api';

import { Layout } from 'components/common';
import { Checkbox, Form, Input } from 'components/forms';
import { AddEditWrapper } from 'components/wrapper';

import { useForm } from '../../hooks';
import { formatBytes, formatSeconds, sum } from '../../utils';
import { errorStore } from '../../stores/error-store';
import { RequestError } from '../../utils/api/check-request-data';
import { Table } from '../../components/table';
import { explorerStore } from '../../stores/media';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { playlistTimelineColumns } from './constants';
import {
	Explorer,
	FilePreview,
	IExplorerItemProps,
} from '../../components/explorer';
import './styles.scss';

const ACCEPTABLE_FILE_TYPES = [FileType.Video];

const LibraryAction: FC<
	Pick<IExplorerItemProps, 'file'> & { selectedFileIds: string[] }
> = observer(({ file, selectedFileIds }) => {
	const checked = useMemo(
		() => selectedFileIds.includes(file.id),
		[selectedFileIds, file.id],
	);

	return (
		<Layout flex row className="playlists__item__action">
			<label
				className={
					checked
						? 'media-list__item__button_remove'
						: 'media-list__item__button_add'
				}
			>
				<input
					type="checkbox"
					name="files"
					value={file.id}
					onChange={() => null}
					checked={checked}
				/>
			</label>
		</Layout>
	);
});

const initialPlaylistData: Partial<
	PlaylistCreateRequest | PlaylistUpdateRequest
> &
	Pick<PlaylistCreateRequest, 'files'> = {
	files: [],
};

const PlaylistAddEdit: FC = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const params = useParams<{ id?: string; name?: string }>();
	const { id, name } = params;

	const [checkedMediaIds, setCheckedMediaIds] = useState<
		Array<FileResponse['id']>
	>([]);
	const [selectedMediaItems, setSelectedMediaItems] = useState<FileResponse[]>(
		[],
	);

	const [{ values, formProps, canSubmit }, { setValues, setFields, submit }] =
		useForm(initialPlaylistData, {
			required: ['name'],
			parsers: {
				files: (value, values) => {
					const files = values.files.includes(value as string)
						? values.files.filter((id) => id === value)
						: values.files.concat(value as string);

					return {
						files,
					};
				},
				checkedMediaIds: (value) => {
					setCheckedMediaIds((state) => {
						return state.includes(value as string)
							? state.filter((id) => id !== value)
							: state.concat(value as string);
					});
				},
			},
			onChange: (newState, state, names) => {
				if (names.includes('files')) {
					setSelectedMediaItems((state) => {
						const { files: fileIds } = newState;
						const files = fileIds
							.map(
								(id) =>
									state.find((f) => f.id === id) ||
									explorerStore.fileList.find((m) => m.id === id),
							)
							.filter((m): m is NonNullable<typeof m> => Boolean(m));

						if (files.length !== fileIds.length) {
							throw new Error(
								intl.formatMessage({
									id: 'MemoryLeak',
									defaultMessage:
										'Выбранный файл недоступен. Пожалуйста, обновите страницу или обратитесь в поддержку.',
								}),
							);
						}

						return files;
					});
				}
			},
			onSubmit: async (values) => {
				try {
					if (id) {
						await playlistsStore.updatePlaylist(id, values);
					} else {
						const playlist = await playlistsStore.createPlaylist(
							values as PlaylistCreateRequest,
						);

						if (playlist) {
							navigate(`/playlists/edit/${playlist.id}`);
						}
					}
					errorStore.reset();
				} catch (error) {
					if (error instanceof RequestError) {
						error.notify();
						errorStore.setErrors(error.fieldNames);
					}
				}
			},
		});

	const loadPlaylist = useCallback((id?: IPlaylistItem['id']) => {
		if (id) {
			playlistsStore.loadPlaylist(id).then((playlist) => {
				if (playlist) {
					setValues({
						name: playlist.name,
						description: playlist.description,
						files: playlist.files.map((f) => f.id),
					});
					setSelectedMediaItems(playlist.files.concat());
				}
			});
		}
	}, []);

	useEffect(() => {
		if (id) {
			loadPlaylist(id);
		} else if (name) {
			setValues((state) => ({ ...state, name }));
		} else {
			setValues(initialPlaylistData);
		}
	}, [id, name, loadPlaylist]);

	const setPlaylistOrder = useCallback(
		(idx: number, direction: 'prev' | 'next') => {
			const targetIdx = direction === 'next' ? idx + 1 : idx - 1;
			const files = values.files.concat();
			const movedItems = files.splice(idx, 1);

			files.splice(targetIdx, 0, ...movedItems);

			setFields({
				files,
			});
		},
		[values, setFields],
	);

	const { totalDuration, totalFilesize } = useMemo(
		() => ({
			totalDuration: formatSeconds(
				sum(selectedMediaItems.map((m) => m.duration)),
			),
			totalFilesize: formatBytes(
				sum(selectedMediaItems.map((m) => m.filesize)),
			),
		}),
		[selectedMediaItems],
	);

	const buttons = useMemo(
		() =>
			values.files.length > 1 &&
			checkedMediaIds.length &&
			!values.files.every((id) =>
				checkedMediaIds.some((checkedId) => checkedId === id),
			) ? (
				<button
					type="button"
					className="add__edit__timeline__footer_item add__edit__timeline__button"
					onClick={() => {
						const files = values.files.filter(
							(id) => !checkedMediaIds.includes(id),
						);

						setFields({
							files,
						});
						setCheckedMediaIds([]);
					}}
				>
					<div className="add__edit__timeline__button-content">
						<FormattedMessage
							id="Delete selected"
							defaultMessage="Delete selected"
						/>
					</div>
				</button>
			) : null,
		[values.files, checkedMediaIds, setFields],
	);

	const tableData = useMemo(
		() =>
			selectedMediaItems.map((m, idx, arr) => {
				const checked = checkedMediaIds.includes(m.id);

				return {
					...m,
					checkbox: (
						<Checkbox
							name="checkedMediaIds"
							value={m.id}
							checked={checked}
							onChange={() => null}
							label=" "
							colorModifier="dark"
						/>
					),
					order: (
						<div className="add__edit__timeline__order">
							<button
								type="button"
								className="add__edit__timeline__order-button top"
								onClick={() => setPlaylistOrder(idx, 'prev')}
								disabled={idx === 0}
							>
								&nbsp;
							</button>
							{idx + 1}
							<button
								type="button"
								className="add__edit__timeline__order-button bottom"
								onClick={() => setPlaylistOrder(idx, 'next')}
								disabled={idx === arr.length - 1}
							>
								&nbsp;
							</button>
						</div>
					),
					preview: <FilePreview file={m} />,
					extension: (
						<div className="add__edit__timeline__content-wrapper">
							<span className="add__edit__timeline_ext">{m.extension}</span>
						</div>
					),
					name: (
						<div className="add__edit__timeline__content-wrapper">
							<span>{m.name}</span>
						</div>
					),
					duration: (
						<div className="add__edit__timeline__content-wrapper">
							<span>{formatSeconds(m.duration)}</span>
						</div>
					),
					filesize: (
						<div className="add__edit__timeline__content-wrapper">
							<span>{formatBytes(m.filesize)}</span>
						</div>
					),
				};
			}),
		[checkedMediaIds, selectedMediaItems, setPlaylistOrder],
	);

	return (
		<Layout>
			<Form {...formProps} noPadding>
				<Layout flex column className="add__edit__timeline">
					<Layout flex row className="add__edit__timeline__header">
						<span className="add__edit__timeline__header_item add__edit__timeline__header__playlist-name">
							<FormattedMessage
								id="The name of the playlist"
								defaultMessage="The name of the playlist"
							/>
							:
						</span>
						<Input
							rawPlaceholder="Playlist name"
							type="text"
							name="name"
							value={values.name}
							required
						/>
						<button
							type="submit"
							className={cx(
								'add__edit__timeline__header_item',
								'add__edit__timeline__button',
								{
									add__edit__timeline__button_disabled: !canSubmit,
								},
							)}
							disabled={!canSubmit}
							onClick={submit}
						>
							<div className="add__edit__timeline__button-content">
								<FormattedMessage
									id={id ? 'app.button.update' : 'app.button.confirm'}
									defaultMessage={id ? 'Обновить' : 'Сохранить'}
								/>
							</div>
						</button>
					</Layout>
					<Table columns={playlistTimelineColumns} data={tableData} />
					<Layout flex row className="add__edit__timeline__footer">
						{buttons}
						<span className="add__edit__timeline__footer_item ml-auto">
							<FormattedMessage id="The total time of the playlist" />:{' '}
							{totalDuration}
						</span>
						<span className="add__edit__timeline__footer_item">
							<FormattedMessage id="Overall size" />: {totalFilesize}
						</span>
					</Layout>
				</Layout>
				<AddEditWrapper
					cancelRouteKey="PlaylistsList"
					icon={null}
					className="playlists__wrapper"
					title="To manage files, select file"
					submit={submit}
					disabled={!canSubmit}
				>
					<Explorer
						acceptableFileTypes={ACCEPTABLE_FILE_TYPES}
						noActions
						ItemActionComponent={({ file }) => (
							<LibraryAction file={file} selectedFileIds={values.files} />
						)}
					/>
				</AddEditWrapper>
			</Form>
		</Layout>
	);
};

export default observer(PlaylistAddEdit);
