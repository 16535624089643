import React from 'react';

import { explorerStore } from 'stores/media';
import { FileResponse } from 'utils/api/api';

export function useFileLink(
	fileId: FileResponse['id'],
	name: FileResponse['name'],
) {
	const [fileLink, setFileLink] = React.useState('');

	React.useEffect(() => {
		explorerStore.getFileS3(fileId, name).then((blob) => {
			if (blob) {
				setFileLink(window.URL.createObjectURL(blob));
			}
		});
	}, [fileId, name]);

	return fileLink;
}
