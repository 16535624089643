import {
	ChangeEvent,
	FC,
	HTMLAttributes,
	InputHTMLAttributes,
	useCallback,
} from 'react';
import cx from 'classnames';
import { ACCEPT_FILE_EXTS } from '../../_constants';

import './upload-button.scss';
import { toast } from '../../utils';
import { useIntl } from 'react-intl';

export const UploadButton: FC<
	HTMLAttributes<HTMLLabelElement> &
		Pick<InputHTMLAttributes<HTMLInputElement>, 'accept' | 'multiple'> & {
			onUpload?: (file: File[]) => void;
		}
> = ({ accept, className, multiple, onUpload, children, ...restProps }) => {
	const intl = useIntl();
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.files) {
				const files = [...e.target.files];

				if (!files[0]) {
					return toast.error(
						intl.formatMessage({
							id: 'Select file to upload',
							defaultMessage: 'Выберите файл для загрузки!',
						}),
					);
				}

				if (onUpload) {
					onUpload(files);
				}

				e.target.value = '';
			}
		},
		[onUpload, intl],
	);

	return (
		<label
			{...restProps}
			className={cx('button button-primary upload-button', className)}
		>
			<input
				type="file"
				accept={accept || ACCEPT_FILE_EXTS}
				multiple={multiple}
				onChange={handleChange}
			/>
			{children}
		</label>
	);
};
