import { action, makeObservable, observable, runInAction } from 'mobx';
import { IntlShape } from 'react-intl';

import { IAppStore, IWebSocketData } from '_types/stores';
import { Language } from '_types/constants';
import { refreshToken, swaggerApi, toast } from 'utils';
import { getAuthStorage, isExpiredToken } from 'utils/api/with-jwt';
import {
	MonitorResponse,
	MonitorStatus,
	UserMetrics,
	UserWallet,
	WsEvent,
} from 'utils/api/api';

import { monitorsStore } from '../monitors/monitor-list';
import { STORAGE_NAME } from '../../_constants';
import { walletStore } from '../wallet';
import { metricsStore } from '../metrics';

export const WS_RESTART_MS = 5000;

class App implements IAppStore {
	@observable layout: IAppStore['layout'] = {
		windowWidth: 0,
		windowHeight: 0,
		windowScrollX: 0,
		windowScrollY: 0,
		sidebarOpen: !localStorage.getItem(STORAGE_NAME.SIDEBAR_COLLAPSE),
	};
	@observable windowWidth: string | number = 0;

	@observable windowHeight = 0;

	@observable pageActive = true;

	@observable isLoading = true;

	@observable language: Language =
		(localStorage.getItem('language') as Language) || Language.RU;

	@observable ws: IAppStore['ws'] = null;
	intl!: IntlShape;
	/**
	 * @todo remove globally
	 */
	navigate!: IAppStore['navigate'];
	location!: IAppStore['location'];

	constructor() {
		makeObservable(this);

		swaggerApi.instance.interceptors.request.use((config) => {
			config.headers.set('accept-language', this.language);

			return config;
		});
	}

	get isProd() {
		return process.env.REACT_APP_ENVIRONMENT === 'production';
	}

	isCreatedFor(target: 'promo' | 'default') {
		if (target === 'default' && process.env.REACT_APP_TARGET === undefined) {
			return true;
		}
		return process.env.REACT_APP_TARGET === target;
	}

	/**
	 * @todo remove globally
	 */
	@action.bound handleNavSearch = async (
		searchValue: string,
	): Promise<void> => {
		console.info('Search from navigation', searchValue);
	};

	setIntl = (intl: IntlShape): void => {
		this.intl = intl;
	};

	@action setLayout: IAppStore['setLayout'] = (partialLayout) => {
		if ('sidebarOpen' in partialLayout) {
			if (partialLayout.sidebarOpen) {
				localStorage.removeItem(STORAGE_NAME.SIDEBAR_COLLAPSE);
			} else {
				localStorage.setItem(
					STORAGE_NAME.SIDEBAR_COLLAPSE,
					STORAGE_NAME.SIDEBAR_COLLAPSE,
				);
			}
		}
		return (this.layout = {
			...this.layout,
			...partialLayout,
		});
	};

	setLanguage = (language: Language) => {
		this.language = language;
		localStorage.setItem('language', language);
	};

	/**
	 * @todo remove globally
	 */
	setHistory: IAppStore['setHistory'] = (navigate, location): void => {
		this.navigate = navigate;
		this.location = location;
	};

	initWebSocket = () => {
		if (this.ws) return;
		const ws = new WebSocket(process.env.REACT_APP_WS_URL as string);

		ws.onopen = async () => {
			const authStorage = getAuthStorage();
			if (authStorage && !isExpiredToken(authStorage.token)) {
				const data: IWebSocketData = {
					event: WsEvent.AuthToken,
					data: { token: authStorage.token, date: new Date() },
				};

				ws.send(JSON.stringify(data));
			} else {
				await refreshToken();
				ws.close();
			}
		};

		ws.onmessage = (msg) => {
			if (!msg.data) {
				throw new Error('Invalid message data for the WebSocket');
			}
			const parsedMessageData = JSON.parse(msg.data) as IWebSocketData[];
			// TODO@nikshirobokov: Убрать console.log после тестов Ахмета
			console.log('WEBSOCKET MESSAGE: ', parsedMessageData);
			if (Array.isArray(parsedMessageData)) {
				parsedMessageData.forEach(this._handleWebSocketData);
			} else {
				this._handleWebSocketData(msg.data);
			}
		};

		ws.onclose = () => {
			this.ws = null;
			setTimeout(() => {
				this.initWebSocket();
			}, WS_RESTART_MS);
		};

		ws.onerror = (e) => {
			console.error('WS ERROR', e);
		};

		this.ws = ws;
	};

	_handleWebSocketData: IAppStore['_handleWebSocketData'] = (wsData) => {
		if (!this.ws) return;

		switch (wsData.event) {
			case WsEvent.Monitor:
				{
					if (wsData.error) {
						return console.error(wsData.error);
					}
					const monitor = wsData.data as MonitorResponse;
					if (monitor.status === MonitorStatus.Online) {
						let messageId, renderToast;

						if (monitor.playlistPlayed) {
							messageId = 'Playlist is played';
							renderToast = toast.success;
						} else {
							messageId = 'Playlist was stopped';
							renderToast = toast.error;
						}

						renderToast(
							this.intl.formatMessage(
								{
									id: messageId,
								},
								{
									monitorName: monitor.name,
									playlistName: monitor.playlist?.name,
								},
							),
							{
								autoClose: false,
								position: 'bottom-right',
							},
						);
					}
				}
				break;

			case WsEvent.MonitorStatus:
				{
					if (wsData.error) {
						return console.error(wsData.error);
					}
					const monitorDataArray = wsData.data as Array<{
						id: MonitorResponse['id'];
						status: MonitorStatus;
					}>;

					const monitorIdToStatus = Object.fromEntries(
						monitorDataArray.map(({ id, status }) => [id, status]),
					);

					runInAction(() => {
						monitorsStore.list = monitorsStore.list.map((monitor) =>
							monitor.id in monitorIdToStatus
								? {
										...monitor,
										status: monitorIdToStatus[monitor.id],
									}
								: monitor,
						);
					});
				}
				break;

			case WsEvent.AuthToken:
				{
					if (wsData.error) {
						refreshToken();
						this.ws.close();
						console.error(wsData.error);
					}
				}
				break;

			case WsEvent.Wallet:
				{
					if (wsData.error) {
						return console.error(wsData.error);
					}
					walletStore.setWallet(wsData.data as UserWallet);
				}
				break;

			case WsEvent.Metrics: {
				if (wsData.error) {
					return console.error(wsData.error);
				}
				metricsStore.setMetrics(wsData.data as UserMetrics);
			}
		}
	};
}

const appStore = new App();

export { appStore };
