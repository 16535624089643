import {
	DragEventHandler,
	FC,
	HTMLAttributes,
	MouseEventHandler,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import cx from 'classnames';

import './slider-input.scss';
import { simulateChange } from '../../utils';

export interface ISliderInputProps extends HTMLAttributes<HTMLDivElement> {
	name: string;
	value: number[] | number | null | undefined;
	max: number;
	min?: number;
	type?: 'int' | 'float' | 'px';
	format?: (value: Exclude<ISliderInputProps['value'], number[]>) => ReactNode;
}

export const SLIDER_DEFAULT_MIN = 0;
export const SLIDER_DEFAULT_TYPE: ISliderInputProps['type'] = 'int';

export const SliderInput: FC<ISliderInputProps> = ({
	name,
	value,
	min = SLIDER_DEFAULT_MIN,
	max,
	className,
	type = SLIDER_DEFAULT_TYPE,
	format,
	...restProps
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const trackRef = useRef<HTMLDivElement>(null);
	const circleMinRef = useRef<HTMLDivElement>(null);
	const circleMaxRef = useRef<HTMLDivElement>(null);

	const factor = useCallback(() => {
		const { current: track } = trackRef;
		const { current: cMin } = circleMinRef;
		const { current: cMax } = circleMaxRef;
		let factor = track && cMax && (track.clientWidth - cMax.clientWidth) / max;

		if (factor && cMin) {
			factor = factor - cMin.clientWidth;
		}

		return factor;
	}, [max]);

	const toPx = useCallback(
		(n: number | null | undefined) => {
			const x = factor();

			return n && x ? `${n * x}px` : '0px';
		},
		[factor],
	);

	const toValue = useCallback(
		(px: string | number) => {
			const pixels = typeof px === 'string' ? parseFloat(px) : px;
			if (type === 'px') {
				return pixels;
			}
			const x = factor();
			if (!isNaN(pixels) && x) {
				const float = pixels / x;
				return type === 'int' ? Math.floor(float) : float;
			}

			return pixels;
		},
		[max, factor, type],
	);

	const handleDragStart = useCallback<DragEventHandler<HTMLDivElement>>(() => {
		return false;
	}, []);

	const isMultiple = useMemo(() => Array.isArray(value), [value]);
	const [vMin, vMax] = useMemo(() => {
		if (Array.isArray(value)) {
			const [vMin, vMax] = value;
			return [vMin || min, vMax || max];
		}

		if (typeof value === 'number') {
			if (min >= value) {
				return [null, min];
			} else if (max > value) {
				return [null, value];
			}
		}
		return [null, max];
	}, [value, min, max]);

	const handleMouseDown = useCallback(
		(e: Parameters<MouseEventHandler>[0]) => {
			e.preventDefault();
			e.stopPropagation();
			const track = trackRef.current;
			if (!track) return;
			const circle = e.currentTarget;
			const { left } = track.getBoundingClientRect();
			// @ts-expect-error: no TypeScript support
			const { name } = circle.dataset;
			const isMinCircle = name === 'vmin';
			let value = isMinCircle ? vMin : vMax;

			if (isMinCircle && value === null) return;

			const simulate = () => {
				simulateChange(
					inputRef,
					vMin === null ? value : isMinCircle ? [value, vMax] : [vMin, value],
				);
			};

			const checkValue = (v: typeof value) => {
				if (typeof v === 'number') {
					if (isMinCircle) {
						if (vMax && v > vMax) {
							return vMax;
						}
					} else {
						if (vMin && vMin > v) {
							return vMin;
						}
					}

					if (v >= max) {
						return max;
					} else if (typeof min === 'number' && min >= v) {
						return min;
					} else if (max >= v && (typeof min !== 'number' || v >= min)) {
						return v;
					}
				}
				return value;
			};

			const onMouseMove = (moveEvent: MouseEvent) => {
				const candidateValue = checkValue(
					toValue(moveEvent.clientX - circle.clientWidth / 2 - left),
				);

				if (candidateValue !== value) {
					value = candidateValue;
					simulate();
				}
			};

			const onMouseUp = (upEvent: MouseEvent) => {
				onMouseMove(upEvent);
				simulate();
				document.removeEventListener('mouseup', onMouseUp);
				document.removeEventListener('mousemove', onMouseMove);
			};

			document.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		},
		[max, min, vMin, vMax, toValue, toPx],
	);

	const handleTrackClick = useCallback(
		(e: Parameters<MouseEventHandler>[0]) => {
			const { current: track } = trackRef;
			const { current: circle } = circleMaxRef;
			if (!track || !circle) return;
			const { left } = track.getBoundingClientRect();
			const value = toValue(e.clientX - circle.clientWidth / 2 - left);

			simulateChange(
				inputRef,
				isMultiple
					? value >= (vMin as number)
						? [vMin, value]
						: [value, vMax]
					: value,
			);
		},
		[vMin, vMax, toValue],
	);

	useEffect(() => {
		const { current: div } = ref;
		const { current: track } = trackRef;
		if (div && track) {
			div.style.setProperty('--track-width', `${track.clientWidth}px`);
			div.style.setProperty('--vmin', toPx(vMin));
			div.style.setProperty('--vmax', toPx(vMax));
		}
	}, []);

	return (
		<div
			{...restProps}
			ref={ref}
			className={cx('slider-input', className)}
			style={{
				// @ts-expect-error: no TypeScript support
				'--vmin': toPx(vMin),
				'--vmax': toPx(vMax),
			}}
		>
			<input ref={inputRef} name={name} type="hidden" />
			<div
				className={cx('slider-input__min', {
					active: vMin === min || vMax === min,
				})}
			>
				{min}
			</div>
			<div
				ref={trackRef}
				className="slider-input__track"
				onMouseDown={handleTrackClick}
			>
				<div className="slider-input__active" />
				{vMin !== null && (
					<div
						ref={circleMaxRef}
						onDragStart={handleDragStart}
						onMouseDown={handleMouseDown}
						className={cx('slider-input__circle')}
						data-name="vmin"
					>
						<div className="slider-input__value">
							{vMin > min && (format ? format(vMin) : vMin)}
						</div>
					</div>
				)}
				<div
					ref={circleMaxRef}
					onDragStart={handleDragStart}
					onMouseDown={handleMouseDown}
					className="slider-input__circle"
					data-name="vmax"
				>
					<div className="slider-input__value">
						{vMax < max && (format ? format(vMax) : vMax)}
					</div>
				</div>
			</div>
			<div className={cx('slider-input__max', { active: vMax === max })}>
				{max}
			</div>
		</div>
	);
};
