import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { BidCreateMonitorRequest, UserRole } from 'utils/api/api';
import { resetDateTime } from 'utils/formatters/reset-date-time';
import { toast } from 'utils';
import { IMonitorItem, IPlaylistItem } from '_types/stores';

import { authStore } from 'stores/auth';
import { monitorsStore } from 'stores/monitors/monitor-list';

import { IPopupFullscreenProps, PopupFullscreen } from 'components/popup';
import { DateInput, Form, Radio } from 'components/forms';

import './styles.scss';
import { useForm } from 'hooks';
import { Button } from '../../components/common';

export interface IBidData
	extends Pick<BidCreateMonitorRequest, 'dateWhen' | 'dateBefore'> {
	playQueue: 'ForcePlay' | 'ToQueue';
	periodType: 'both' | 'onlyWhen' | 'always';
	periodDates: string;
}

export interface IBidFormProps
	extends Omit<IPopupFullscreenProps, 'onSubmit' | 'onSubmitCapture'> {
	monitorIds: Array<IMonitorItem['id']>;
	playlistId?: IPlaylistItem['id'] | null;
	onSubmit?: (bid: BidCreateMonitorRequest) => void;
}

const BidSendFormComponent: FC<IBidFormProps> = ({
	className,
	monitorIds,
	playlistId,
	onSubmit,
	...restProps
}) => {
	const intl = useIntl();
	const today = useMemo(() => resetDateTime(new Date()), []);

	const [{ values, formProps, canSubmit }, { submit }] = useForm<IBidData>(
		{
			dateWhen: today.toISOString(),
			periodType:
				authStore.userRole === UserRole.Advertiser ? 'both' : 'always',
			periodDates: '',
			playQueue: 'ToQueue',
		},
		{
			disableDeepEqual: true,
			parsers: {
				periodDates: (value) => {
					if (typeof value === 'string') {
						const [dateWhen, dateBefore] = JSON.parse(value);

						return {
							dateWhen,
							dateBefore,
						};
					}
				},
			},
			onSubmit: (values) => {
				let { dateWhen, dateBefore } = values;

				switch (values.periodType) {
					case 'always':
						{
							dateWhen = today.toISOString();
							dateBefore = null;
						}
						break;
					case 'onlyWhen': {
						if (today > new Date(dateWhen)) {
							return toast.error(intl.formatMessage({ id: 'InvalidDateWhen' }));
						}
						dateBefore = null;
					}
				}

				[dateWhen, dateBefore = null] = [dateWhen]
					.concat(dateBefore || [])
					.map((v) => v && resetDateTime(v).toISOString());

				const bid: BidCreateMonitorRequest = {
					dateWhen,
					dateBefore,
					playlistChange: values.playQueue === 'ForcePlay',
				};

				if (onSubmit) {
					onSubmit(bid);
				} else if (monitorIds.length && playlistId) {
					if (!monitorIds.length) {
						return toast.error(
							intl.formatMessage({
								id: 'Monitors no selected',
								defaultMessage: 'Устройства не выбраны!',
							}),
						);
					}
					if (!playlistId) {
						return toast.error(
							intl.formatMessage({
								id: 'Playlist no selected',
								defaultMessage:
									'Плейлист не указан! Проверьте отправляемый плейлист',
							}),
						);
					}

					monitorsStore
						.linkMonitorPlaylistMap(monitorIds, playlistId, bid)
						.then((result) => {
							if (result) {
								toast.success(intl.formatMessage({ id: 'BidCreated' }));
							}
						});
				}
			},
		},
	);

	const calendar = useMemo(() => {
		switch (values.periodType) {
			case 'always':
				return null;
			case 'onlyWhen': {
				return (
					<DateInput
						min={today}
						name="dateWhen"
						value={new Date(values.dateWhen)}
					/>
				);
			}
			case 'both': {
				return (
					<DateInput
						min={today}
						name="periodDates"
						value={[values.dateWhen, values.dateBefore].map((str) =>
							str ? new Date(str) : undefined,
						)}
					/>
				);
			}
		}
	}, [values.periodType, values.dateWhen, values.dateBefore, today]);

	const periodsList = useMemo(
		() =>
			authStore.userRole === UserRole.Advertiser
				? ['both']
				: ['always', 'onlyWhen', 'both'],
		[authStore.userRole],
	);

	return (
		<PopupFullscreen {...restProps} className={cx('bid-form-popup', className)}>
			<Form {...formProps} className="bid-form">
				{authStore.userRole &&
					[UserRole.Administrator, UserRole.MonitorOwner].includes(
						authStore.userRole,
					) && (
						<section>
							<h3>
								<FormattedMessage id="BidQueue" />
							</h3>
							<fieldset name="queue">
								{(['ForcePlay', 'ToQueue'] as Array<IBidData['playQueue']>).map(
									(v) => (
										<label key={v} className="bid-form__field">
											<Radio
												name="playQueue"
												value={v}
												checked={values.playQueue === v}
												label={
													<div className="form__radio-mask form__radio-mask--gray" />
												}
											/>
											<FormattedMessage id={v} />
										</label>
									),
								)}
							</fieldset>
						</section>
					)}
				<section>
					<h3>
						<FormattedMessage id="BidStart" />
					</h3>
					<fieldset name="periods">
						{periodsList.map((v) => {
							let label;
							switch (v) {
								case 'always':
									{
										label = (
											<FormattedMessage
												id="BidNonStop"
												defaultMessage="Сразу"
											/>
										);
									}
									break;

								case 'onlyWhen':
									{
										label = (
											<span>
												{values.dateWhen
													? intl.formatDate(values.dateWhen)
													: intl.formatMessage({
															id: 'app.field.selectDate',
															defaultMessage: 'Выбрать дату',
														})}
											</span>
										);
									}
									break;

								case 'both':
									{
										label = (
											<span>
												{[values.dateWhen, values.dateBefore]
													.map((isoString, idx) => {
														if (!isoString) {
															return intl.formatMessage({
																id:
																	idx === 0
																		? 'app.field.dateWhen'
																		: 'app.field.dateBefore',
																defaultMessage:
																	idx === 0
																		? 'Начало периода'
																		: 'Конец периода',
															});
														}

														return intl.formatDate(isoString);
													})
													.join(' -> ')}
											</span>
										);
									}
									break;
							}
							const checked = values.periodType === v;

							return (
								<label
									key={v}
									className={cx('bid-form__field', {
										checked,
									})}
									data-name={v}
								>
									<Radio
										name="periodType"
										value={v}
										checked={checked}
										label={
											<div className="form__radio-mask form__radio-mask--gray" />
										}
									/>
									{label}
								</label>
							);
						})}
					</fieldset>
					<fieldset name="calendar">{calendar}</fieldset>
				</section>
				<Button primary onClick={submit} disabled={!canSubmit}>
					<FormattedMessage
						id="BidSend"
						defaultMessage="Send selected to device"
					/>
				</Button>
			</Form>
		</PopupFullscreen>
	);
};

export const BidSendForm = observer(BidSendFormComponent);
