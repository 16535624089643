import { InputHTMLAttributes, ReactNode, useState } from 'react';
import cx from 'classnames';
import { random } from 'utils';
import { FormattedMessage } from 'react-intl';
import { InputField } from './field';
import './styles.scss';
import { checkInputError } from '../../hocs';

export interface IInputProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'placeholder'> {
	placeholder?: ReactNode;
	rawPlaceholder?: string;
	fieldClass?: string;
	fieldElement?: ReactNode;
	fieldHint?: ReactNode;
	noIntl?: boolean;
	value?: HTMLInputElement['value'] | number | null;
}

const headlessInput: Array<IInputProps['type']> = ['checkbox', 'radio', 'file'];

export const Input = checkInputError<IInputProps>(
	({
		isError,
		id,
		type,
		className,
		placeholder,
		rawPlaceholder,
		fieldClass,
		fieldElement,
		fieldHint,
		noIntl,
		value,
		...props
	}) => {
		const [inputId] = useState(() => id || random());
		const placeholderElement =
			typeof placeholder === 'string' && !noIntl ? (
				<FormattedMessage id={placeholder} defaultMessage={placeholder} />
			) : (
				placeholder
			);

		return (
			<InputField
				type={type}
				className={cx('input', fieldClass)}
				data-name={props.name}
			>
				{fieldElement}
				<input
					onChange={() => null}
					{...props}
					value={value || ''}
					className={cx(
						`form__input__${type}`,
						{
							form__input: !headlessInput.includes(type),
							error: isError,
						},
						className,
					)}
					type={type}
					id={inputId}
					{...(headlessInput.includes(type)
						? {}
						: {
								type,
								placeholder: rawPlaceholder || ' ',
							})}
				/>
				{placeholder && !rawPlaceholder && (
					<label htmlFor={inputId}>{placeholderElement}</label>
				)}
				{fieldHint}
			</InputField>
		);
	},
);
