import cx from 'classnames';
import { FC } from 'react';

const NumberButton: FC<{
	className?: string;
	page: number | '-1' | '+1' | 'first' | 'last';
	onClick: () => void;
	tabIndex?: number;
	active?: boolean;
	disabled?: boolean;
}> = ({ className, page, onClick, tabIndex, active, disabled }) => {
	return (
		<button
			type="button"
			onClick={onClick}
			className={cx('pagination__btn', className, {
				'pagination__btn-first--disabled':
					page === 'first' && disabled && !active,
				'pagination__btn-last--disabled':
					page === 'last' && disabled && !active,
				'pagination__btn--active': typeof page === 'number' && active,
				'pagination__btn-next--disabled': page === '+1' && disabled && !active,
				'pagination__btn-prev--disabled': page === '-1' && disabled && !active,
			})}
			disabled={disabled && !active}
			tabIndex={tabIndex}
		>
			{page}
		</button>
	);
};

const NumberItem: FC<{
	page: number | '...';
	currentPage: number;
	isDisabled: number;
	onClick: () => void;
}> = ({ page, currentPage, onClick, isDisabled }) => {
	return typeof page === 'number' ? (
		<li className="pagination__item">
			<NumberButton
				page={page}
				onClick={onClick}
				active={page === currentPage}
				disabled={page > isDisabled}
			/>
		</li>
	) : (
		<li className="pagination__item" key={`pageIndex${page}`}>
			...
		</li>
	);
};

export { NumberButton, NumberItem };
