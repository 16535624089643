import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { FileResponse } from '../../utils/api/api';
import { Icon } from '../icon';
import { icons } from '../../assets/icons';

import './explorer.scss';

export interface IFileStatusProps extends HTMLAttributes<HTMLSpanElement> {
	file: FileResponse;
}

export const FileStatus: FC<IFileStatusProps> = ({
	file,
	className,
	...restProps
}) => {
	return (
		<span {...restProps} className={cx('file-status', className)}>
			{file.used ? (
				<>
					<Icon className={icons.FileEnable} />
					<FormattedMessage id="Active" defaultMessage="Активно" />
				</>
			) : (
				<>
					<Icon className={icons.FileDisable} />
					<FormattedMessage id="Inactive" defaultMessage="Неактивно" />
				</>
			)}
		</span>
	);
};
