import { FC, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ToastContentProps } from 'react-toastify';

import {
	IEditorProject,
	IVideoEditorStore,
	TProjectId,
} from 'modules/video-editor-module/types';

export interface IDownload extends Omit<ToastContentProps, 'toastProps'> {
	editorProjectId: TProjectId;
	renderingStatus: IVideoEditorStore['renderingStatus'];
	renderingPercent: IEditorProject['renderingPercent'];
}

export const ToastDownload: FC<IDownload> = ({
	editorProjectId,
	renderingStatus,
	renderingPercent,
	closeToast,
}) => {
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);

	const [toProjectLink, toProjectLinkText] = useMemo(
		() => [
			`/editor/project/${editorProjectId}`,
			<>
				<FormattedMessage
					id="Go to project {projectId}"
					values={{ projectId: editorProjectId.split('-')[0] }}
				/>
			</>,
		],
		[editorProjectId],
	);

	const title = useMemo(() => {
		let messageId = 'Rendering in progress';

		switch (renderingStatus) {
			case 'ready':
				messageId = 'Success';
				break;
			case 'error':
				messageId = 'Error';
		}

		return <FormattedMessage id={messageId} />;
	}, [renderingStatus]);

	const toggleConfirmBox = useCallback(
		() => setConfirmIsOpen((state) => !state),
		[setConfirmIsOpen],
	);

	const confirmBox = useMemo(() => {
		if (!confirmIsOpen) return null;

		return (
			<div className="toast-download__confirm-delete">
				<div className="toast-download__confirm-message">
					<FormattedMessage id="Sure to delete?" />
				</div>
				<div className="toast-download__confirm-buttons">
					<button
						className="toast-download__confirm-cancel"
						onClick={toggleConfirmBox}
					>
						<FormattedMessage id="Cancel" />
					</button>
					<button className="toast-download__confirm-ok" onClick={closeToast}>
						<FormattedMessage id="OK" />
					</button>
				</div>
			</div>
		);
	}, [confirmIsOpen, toggleConfirmBox, closeToast]);

	const closeButton = useMemo(() => {
		if (['ready', 'error'].includes(renderingStatus)) {
			return (
				<button
					className={cx('toast-download__close', {
						'toast-download__close--success': renderingStatus === 'ready',
					})}
					onClick={toggleConfirmBox}
				/>
			);
		}
		return null;
	}, [renderingStatus, toggleConfirmBox]);

	const downloadIconClassName = useMemo(() => {
		switch (renderingStatus) {
			case 'ready':
				return 'toast-download__icon--success';
			case 'error':
				return 'toast-download__icon--error';
			default:
				return undefined;
		}
	}, [renderingStatus]);

	const percent = useMemo(
		() => `${renderingPercent || 0}%`,
		[renderingPercent],
	);

	return (
		<div className="toast-download__body">
			<div className={cx('toast-download__icon', downloadIconClassName)}>
				<div className="toast-download__percent">{percent}</div>
			</div>
			<div className="toast-download__content">
				<div className="toast-download__info">
					<div className="toast-download__info-text">
						<span className="toast-download__title">{title}</span>
						<Link to={toProjectLink} className="toast-download__description">
							{toProjectLinkText}
						</Link>
					</div>
					<div className="toast-download__actions">
						<div className="toast-download__button-wrapper">
							{closeButton}
							{confirmBox}
						</div>
					</div>
				</div>
				<div className="toast-download__progress-bar">
					<div
						className={cx('toast-download__progress', {
							'toast-download__progress--paused': renderingStatus === 'error',
							'toast-download__progress--success': renderingStatus === 'ready',
						})}
						style={{
							// @ts-expect-error: Custom CSS property
							'--width-percent': percent,
						}}
					/>
				</div>
			</div>
		</div>
	);
};
