import { ChangeEvent, FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { NO_BREAK_SPACE } from '_constants';

import { Button } from 'components/common';
import { Form, Input, InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import './styles.scss';
import { Icon } from '../../components/icon';
import { icons } from '../../assets/icons';

export const PasswordInput = observer(
	({ fieldClass }: { fieldClass?: string }) => {
		const intl = useIntl();
		const [hint, setHint] = useState('');
		const [type, setType] = useState<'text' | 'password'>('password');
		const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
			authStore.setField(e);

			if (
				e.target.value.length >= 8 &&
				e.target.value.length <= 30 &&
				/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
					e.target.value,
				)
			) {
				setHint('');
			} else {
				setHint(
					intl.formatMessage({
						id: 'PasswordNoMatch',
						defaultMessage: `Используйте латиницу. Пароль должен быть от${NO_BREAK_SPACE}8${NO_BREAK_SPACE}до${NO_BREAK_SPACE}30 символов и содержать как${NO_BREAK_SPACE}минимум 1${NO_BREAK_SPACE}цифру, 1${NO_BREAK_SPACE}заглавную и 1${NO_BREAK_SPACE}строчную буквы.`,
					}),
				);
			}
		}, []);

		const toggleType = useCallback(() => {
			setType((state) => (state === 'text' ? 'password' : 'text'));
		}, [setType]);

		return (
			<Input
				type={type}
				name="password"
				value={authStore.password}
				onChange={handleChange}
				placeholder={
					<FormattedMessage id="Password" defaultMessage="Password" />
				}
				minLength={8}
				maxLength={30}
				fieldClass={fieldClass}
				required
				fieldHint={hint && <p className="input__hint">{hint}</p>}
				fieldElement={<Icon className={icons.Lock} onClick={toggleType} />}
			/>
		);
	},
);

const ResetPasswordComponent: FC = () => (
	<AuthWrapper
		title="Update Password"
		subtitle="Enter your new password to reset"
	>
		<Form onSubmit={authStore.updatePassword}>
			<PasswordInput fieldClass="auth__reset-field" />
			<InputField>
				<Button
					type="submit"
					primary
					className="auth__button auth__button--margin"
				>
					<FormattedMessage
						id="Confirm Password"
						defaultMessage="Confirm Password"
					/>
				</Button>
			</InputField>
		</Form>
	</AuthWrapper>
);

export const UpdateResetPassword = observer(ResetPasswordComponent);
