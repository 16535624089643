import { UserMetrics } from '../utils/api/api';
import { action, makeObservable, observable } from 'mobx';

class MetricsStore {
	@observable userMetrics: UserMetrics | null = null;

	constructor() {
		makeObservable(this);
	}

	@action setMetrics(userMetrics: UserMetrics | null) {
		this.userMetrics = userMetrics;
	}
}

export const metricsStore = new MetricsStore();
