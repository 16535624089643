import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { checkPaginationPage } from 'utils';
import { Layout } from 'components/common';
import { Pagination } from 'modules/pagination-module';
import { AddPlaylist } from 'components/register-forms';
import { Table } from 'components/table';
import { IAddPlaylistForm } from 'components/register-forms/add-playlist';
import { Actions, NameWithIcon, Status } from './list-components';

import { playlistsStore } from 'stores/playlists';

const FIRST_PAGE = 1;

const PlaylistsList: FC = () => {
	const navigate = useNavigate();
	const intl = useIntl();
	const { currentPage = FIRST_PAGE } = useParams<{ currentPage?: string }>();

	const [redirectPath, setRedirectPath] = useState<string>('');
	const [page, setPage] = useState(Number(currentPage));

	const handlePaginate = useCallback(
		(paginatePage: number) => {
			setPage((state) => {
				if (paginatePage === state) {
					return state;
				}
				navigate(`/playlists/list/${paginatePage}`);
				return paginatePage;
			});
		},
		[navigate],
	);

	useEffect(() => {
		playlistsStore.getList(10, page);
	}, [page, playlistsStore.order]);

	useEffect(() => {
		const { requestStatus, count } = playlistsStore;

		checkPaginationPage(requestStatus, count, Number(currentPage));
	}, [playlistsStore.count, playlistsStore.requestStatus, currentPage]);

	const data = useMemo(
		() =>
			playlistsStore.list.map((playlist) => ({
				...playlist,
				createdAt: intl.formatDate(playlist.createdAt),
				updatedAt: intl.formatDate(playlist.updatedAt),
				name: <NameWithIcon playlist={playlist} />,
				actions: <Actions playlist={playlist} />,
				status: <Status playlist={playlist} />,
			})),
		[playlistsStore.list, intl],
	);

	const handleAddPlaylistSubmit = useCallback<IAddPlaylistForm['onSubmit']>(
		({ name }) => {
			setRedirectPath(`/playlists/add/${name}`);
		},
		[],
	);

	return (
		<Layout>
			{redirectPath && <Navigate to={redirectPath} />}
			<h1 className="main__title">
				<FormattedMessage id="app.route.Playlists" defaultMessage="Плейлисты" />
			</h1>
			<AddPlaylist onSubmit={handleAddPlaylistSubmit} />
			<Table
				className="playlists__table scroll"
				columns={playlistsStore.columns}
				data={data}
				onSortClick={playlistsStore.setOrder}
				order={playlistsStore.order}
			/>
			<Pagination
				limit={10}
				totalItems={playlistsStore.count}
				currentPage={+currentPage}
				onPageChange={handlePaginate}
			/>
		</Layout>
	);
};

export default observer(PlaylistsList);
