import { FC } from 'react';
import ReactSelect from 'react-select';
import { observer } from 'mobx-react-lite';
import { appStore } from 'stores/app';
import { Layout } from 'components/common';
import { Language } from '_types/constants';

import './styles.scss';

type Option = { value: Language; label: Language };

const customStyles = {
	option: () => ({}),
	menuList: () => ({}),
	menu: () => ({}),
	control: () => ({}),
	valueContainer: () => ({}),
	singleValue: () => ({}),
};

const LanguageSwitcher: FC = () => {
	const options = Object.values(Language).map((lang) => {
		return { value: lang, label: lang };
	});

	const handleChange = (selectedOption: Option | null) => {
		if (selectedOption) {
			appStore.setLanguage(selectedOption.value);
		}
	};

	const DropdownIndicator = () => {
		return null;
	};

	const currentValue = options.find(
		(option) => option.value === appStore.language,
	);

	return (
		<Layout flex row className="header__languages">
			<form>
				<ReactSelect
					options={options}
					value={currentValue}
					onChange={handleChange}
					styles={customStyles}
					components={{ DropdownIndicator }}
					className="react-select__container"
					classNamePrefix="react-select"
					isMulti={false}
					isSearchable={false}
					hideSelectedOptions
				/>
			</form>
		</Layout>
	);
};

export default observer(LanguageSwitcher);
