import { FC } from 'react';
import cx from 'classnames';

import { formatBytes, formatSeconds } from 'utils';

import { Checkbox } from 'components/forms';
import {
	FilePreview,
	FileStatus,
	IExplorerItemProps,
} from 'components/explorer';
import { FileType } from 'utils/api/api';

export interface IEditorLibraryItem extends IExplorerItemProps {
	selected: boolean;
}

export const EditorLibraryItem: FC<IEditorLibraryItem> = ({
	file,
	className,
	selected,
	...restProps
}) => {
	return (
		<div {...restProps} className={cx('video-library__item', className)}>
			<div className="video-library__format">{file.extension}</div>
			<Checkbox
				name="video_checkbox"
				label=""
				fieldClass="video-library__checkbox"
				sizeModifier="small"
				colorModifier="dark"
				onClick={restProps.onClick}
				checked={selected}
				readOnly={true}
			/>
			<div className="video-library__preview">
				<FilePreview file={file} className="video-library__preview-image" />
			</div>
			<div className="video-library__info">
				<span className="video-library__name">{file.name}</span>
				<FileStatus className="video-library__status" file={file} />
				<div className="video-library__info-container">
					{file.type !== FileType.Image && (
						<span className="video-library__time">
							{file.duration ? formatSeconds(file.duration) : null}
						</span>
					)}
					<span className="video-library__file-size">
						{file.filesize ? formatBytes(+file.filesize) : null}
					</span>
				</div>
				{file.type !== FileType.Audio && (
					<span className="video-library__size">
						{file.width && file.height
							? `${file.width}x${file.height} px`
							: null}
					</span>
				)}
			</div>
		</div>
	);
};
