/* eslint-disable */
/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BadRequestError {
	/** @example 400 */
	statusCode: number;
	/** @example "server-error.10004" */
	code: string;
	/** @example "Bad request" */
	message: string;
}

export interface UnauthorizedError {
	/** @example 401 */
	statusCode: number;
	/** @example "server-error.10001" */
	code: string;
	/** @example "Unauthorized request" */
	message: string;
}

export interface ForbiddenError {
	/** @example 403 */
	statusCode: number;
	/** @example "server-error.10002" */
	code: string;
	/** @example "Forbidden" */
	message: string;
}

export interface NotFoundError {
	/** @example 404 */
	statusCode: number;
	/** @example "server-error.10005" */
	code: string;
	/** @example "Not Found" */
	message: string;
}

export interface NotAcceptableError {
	/** @example 406 */
	statusCode: number;
	/** @example "server-error.10000" */
	code: string;
	/** @example "Not Acceptable" */
	message: string;
}

export interface FileIDResponse {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id: string;
	/**
	 * Имя файла
	 * @example "foo.mp4"
	 */
	name: string;
}

export interface ConflictDataFile {
	/**
	 * ID ссылки
	 * @format uuid
	 */
	id?: string;
	/**
	 * Имя ссылки
	 * @example "Имя ссылки"
	 */
	name?: string;
	/** Файл */
	file?: FileIDResponse;
}

export interface ConflictData {
	/** Редакторы (видео) */
	video?: ConflictDataFile[];
	/** Редакторы (аудио) */
	audio?: ConflictDataFile[];
	/** Плэйлисты */
	playlist?: ConflictDataFile[];
	/** Мониторы */
	monitor?: ConflictDataFile[];
	/** Файлы */
	files?: ConflictDataFile[];
}

export interface ConflictError {
	/** @example 409 */
	statusCode: number;
	/** @example "server-error.10000" */
	code: string;
	/** @example "Conflict exists" */
	message: string;
	error: ConflictData;
}

export interface PreconditionFailedError {
	/** @example 412 */
	statusCode: number;
	/** @example "server-error.10002" */
	code: string;
	/** @example "User exists" */
	message: string;
}

export interface InternalServerError {
	/** @example 500 */
	statusCode: number;
	/** @example "server-error.10000" */
	code: string;
	/** @example "Server error" */
	message: string;
}

export interface NotImplementedError {
	/** @example 501 */
	statusCode: number;
	/** @example "server-error.10000" */
	code: string;
	/** @example "Server error" */
	message: string;
}

/** Статус операции */
export enum Status {
	Success = 'success',
	Error = 'error',
}

/** Роль пользователя */
export enum UserRole {
	Monitor = 'monitor',
	MonitorOwner = 'monitor-owner',
	Advertiser = 'advertiser',
	Administrator = 'administrator',
	Accountant = 'accountant',
}

/** План пользователя */
export enum UserPlan {
	DEMO = 'DEMO',
	FULL = 'FULL',
	VIP = 'VIP',
}

export interface UserWallet {
	/**
	 * Баланс
	 * @example 0
	 */
	total?: number;
}

export interface UserMetricsMonitors {
	/** Кол-во устройств с заявками на трансляции */
	online: number;
	/** Кол-во устройств с заявками, но выключенные */
	offline: number;
	/** Кол-во устройств без заявок */
	empty: number;
	/** Кол-во моих мониторов */
	user: number;
}

export interface UserMetricsStorage {
	/** Занятое место */
	storage: number;
	/** Максимальное место */
	total: number;
}

export interface UserMetricsPlaylists {
	/** Добавленные */
	added: number;
	/** Запущенные */
	played: number;
}

export interface UserMetrics {
	/** Статистика мониторов */
	monitors: UserMetricsMonitors;
	/** Дисковое пространство */
	storageSpace: UserMetricsStorage;
	/** Плейлисты */
	playlists: UserMetricsPlaylists;
}

export interface UserLastEntry {
	/**
	 * С какого устройства был выполнен последний вход
	 * @example "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36"
	 */
	userAgent?: string;
	/**
	 * Когда был выполнен последний вход
	 * @example "2020-01-01T00:00:00"
	 */
	at?: string;
}

export interface UserResponse {
	/**
	 * Идентификатор пользователя
	 * @format uuid
	 */
	id: string;
	/**
	 * EMail пользователя
	 * @format email
	 * @minLength 6
	 * @maxLength 254
	 * @example "foo@bar.baz"
	 */
	email: string;
	/**
	 * Фамилия
	 * @maxLength 50
	 * @example "Steve"
	 */
	surname?: string | null;
	/**
	 * Имя
	 * @maxLength 50
	 * @example "John"
	 */
	name?: string | null;
	/**
	 * Отчество
	 * @maxLength 50
	 * @example "Doe"
	 */
	middleName?: string | null;
	/**
	 * Телефон пользователя
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	phoneNumber?: string | null;
	/**
	 * Город
	 * @maxLength 100
	 * @example "Krasnodar"
	 */
	city?: string;
	/**
	 * Страна
	 * @maxLength 2
	 * @example "RU"
	 */
	country?: string;
	/**
	 * Предпочитаемый язык
	 * @maxLength 6
	 * @example "ru"
	 */
	preferredLanguage?: string;
	/**
	 * Настройки даты
	 * @maxLength 6
	 * @example "ru_RU"
	 */
	locale?: string;
	/**
	 * Дисковое пространство
	 * @example 20000000
	 */
	storageSpace?: number;
	/**
	 * Роль пользователя
	 * @example "advertiser"
	 */
	role: UserRole;
	/**
	 * EMail подтвержден
	 * @example true
	 */
	verified?: boolean;
	/**
	 * План пользователя
	 * @example "FULL"
	 */
	plan?: UserPlan;
	/**
	 * Сколько раз отправлялось напоминание о неуплате
	 * @example 0
	 */
	nonPayment: number;
	/**
	 * Компания
	 * @maxLength 100
	 * @example "ACME corporation"
	 */
	company?: string | null;
	/**
	 * Юридический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyLegalAddress?: string;
	/**
	 * Фактический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyActualAddress?: string;
	/**
	 * Идентификационный номер налогоплательщика (ИНН)
	 * @maxLength 12
	 * @example "012345678901"
	 */
	companyTIN?: string;
	/**
	 * Код Причины Постановки на учет (КПП)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyRRC?: string;
	/**
	 * Основной Государственный Регистрационный Номер (ОГРН)
	 * @maxLength 15
	 * @example "012345678901234"
	 */
	companyPSRN?: string;
	/**
	 * Телефон организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyPhone?: string;
	/**
	 * Email организации
	 * @maxLength 254
	 * @example "we@are.the.best"
	 */
	companyEmail?: string;
	/**
	 * Наименование банка
	 * @maxLength 254
	 * @example "Банк"
	 */
	companyBank?: string;
	/**
	 * Банковский идентификационный код (БИК)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyBIC?: string;
	/**
	 * Корреспондентский счет
	 * @maxLength 20
	 * @example "30101810400000000000"
	 */
	companyCorrespondentAccount?: string;
	/**
	 * Расчетный счет
	 * @maxLength 20
	 * @example "40802810064580000000"
	 */
	companyPaymentAccount?: string;
	/**
	 * Факс организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyFax?: string;
	/**
	 * Представитель организации
	 * @maxLength 254
	 * @example "Тухбатуллина Юлия Евгеньевна"
	 */
	companyRepresentative?: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
	/** Оставшийся срок оплаты */
	planValidityPeriod?: number;
	/** Баланс */
	wallet?: UserWallet;
	/** Метрика */
	metrics?: UserMetrics;
	/** Полное имя */
	fullName?: string;
	/** Полное имя и email */
	fullNameEmail?: string;
	/** Последний вход */
	lastEntry?: UserLastEntry;
}

export interface UserGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Пользователь */
	data: UserResponse;
}

export interface UserUpdateRequest {
	/**
	 * EMail пользователя
	 * @format email
	 * @minLength 6
	 * @maxLength 254
	 * @example "foo@bar.baz"
	 */
	email?: string;
	/**
	 * Фамилия
	 * @maxLength 50
	 * @example "Steve"
	 */
	surname?: string | null;
	/**
	 * Имя
	 * @maxLength 50
	 * @example "John"
	 */
	name?: string | null;
	/**
	 * Отчество
	 * @maxLength 50
	 * @example "Doe"
	 */
	middleName?: string | null;
	/**
	 * Телефон пользователя
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	phoneNumber?: string | null;
	/**
	 * Город
	 * @maxLength 100
	 * @example "Krasnodar"
	 */
	city?: string;
	/**
	 * Страна
	 * @maxLength 2
	 * @example "RU"
	 */
	country?: string;
	/**
	 * Предпочитаемый язык
	 * @maxLength 6
	 * @example "ru"
	 */
	preferredLanguage?: string;
	/**
	 * Настройки даты
	 * @maxLength 6
	 * @example "ru_RU"
	 */
	locale?: string;
	/**
	 * EMail подтвержден
	 * @example true
	 */
	verified?: boolean;
	/**
	 * Компания
	 * @maxLength 100
	 * @example "ACME corporation"
	 */
	company?: string | null;
	/**
	 * Юридический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyLegalAddress?: string;
	/**
	 * Фактический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyActualAddress?: string;
	/**
	 * Идентификационный номер налогоплательщика (ИНН)
	 * @maxLength 12
	 * @example "012345678901"
	 */
	companyTIN?: string;
	/**
	 * Код Причины Постановки на учет (КПП)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyRRC?: string;
	/**
	 * Основной Государственный Регистрационный Номер (ОГРН)
	 * @maxLength 15
	 * @example "012345678901234"
	 */
	companyPSRN?: string;
	/**
	 * Телефон организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyPhone?: string;
	/**
	 * Email организации
	 * @maxLength 254
	 * @example "we@are.the.best"
	 */
	companyEmail?: string;
	/**
	 * Наименование банка
	 * @maxLength 254
	 * @example "Банк"
	 */
	companyBank?: string;
	/**
	 * Банковский идентификационный код (БИК)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyBIC?: string;
	/**
	 * Корреспондентский счет
	 * @maxLength 20
	 * @example "30101810400000000000"
	 */
	companyCorrespondentAccount?: string;
	/**
	 * Расчетный счет
	 * @maxLength 20
	 * @example "40802810064580000000"
	 */
	companyPaymentAccount?: string;
	/**
	 * Факс организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyFax?: string;
	/**
	 * Представитель организации
	 * @maxLength 254
	 * @example "Тухбатуллина Юлия Евгеньевна"
	 */
	companyRepresentative?: string;
	/**
	 * Роль пользователя
	 * @example "advertiser"
	 */
	role?: UserRole;
	/**
	 * План пользователя
	 * @example "FULL"
	 */
	plan?: UserPlan;
}

export interface LoginRequest {
	/**
	 * EMail пользователя
	 * @format email
	 * @minLength 6
	 * @maxLength 254
	 * @example "foo@bar.baz"
	 */
	email: string;
	/**
	 * Пароля пользователя (должен удовлетворять минимальным требованиям)
	 * @format password
	 * @minLength 8
	 * @maxLength 30
	 * @pattern /((?=.*\d)|(?=.*\W+))(?![.
	 * ])(?=.*[A-Z])(?=.*[a-z]).*$/
	 * @example "Secret~12345678"
	 */
	password: string;
}

export interface AuthenticationPayload {
	/**
	 * Тип: Bearer
	 * @example "bearer"
	 */
	type: string;
	/**
	 * Токен, используемый в Authorization: Bearer
	 * @example "eyJcbGciOcJIUcI1xxxxxxxxxxxxxxxx"
	 */
	token: string;
	/**
	 * Refresh токен, используемый для запросов /api/v2/refresh
	 * @example "exJxcGxiOxJIxzIxNixsIxR5cxxxxxxxxxxx.E9jKilfGxxxxxxxxxxxxx"
	 */
	refreshToken: string;
}

export interface AuthResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Возвращаемый токен */
	payload: AuthenticationPayload;
	/** Пользователь */
	data: UserResponse;
}

export interface RegisterRequest {
	/**
	 * EMail пользователя
	 * @format email
	 * @minLength 6
	 * @maxLength 254
	 * @example "foo@bar.baz"
	 */
	email?: string;
	/**
	 * Фамилия
	 * @maxLength 50
	 * @example "Steve"
	 */
	surname?: string | null;
	/**
	 * Имя
	 * @maxLength 50
	 * @example "John"
	 */
	name?: string | null;
	/**
	 * Отчество
	 * @maxLength 50
	 * @example "Doe"
	 */
	middleName?: string | null;
	/**
	 * Телефон пользователя
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	phoneNumber?: string | null;
	/**
	 * Город
	 * @maxLength 100
	 * @example "Krasnodar"
	 */
	city?: string;
	/**
	 * Страна
	 * @maxLength 2
	 * @example "RU"
	 */
	country?: string;
	/**
	 * Предпочитаемый язык
	 * @maxLength 6
	 * @example "ru"
	 */
	preferredLanguage?: string;
	/**
	 * Роль пользователя
	 * @example "advertiser"
	 */
	role: UserRole;
	/**
	 * Компания
	 * @maxLength 100
	 * @example "ACME corporation"
	 */
	company?: string | null;
	/**
	 * Юридический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyLegalAddress?: string;
	/**
	 * Фактический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyActualAddress?: string;
	/**
	 * Идентификационный номер налогоплательщика (ИНН)
	 * @maxLength 12
	 * @example "012345678901"
	 */
	companyTIN?: string;
	/**
	 * Код Причины Постановки на учет (КПП)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyRRC?: string;
	/**
	 * Основной Государственный Регистрационный Номер (ОГРН)
	 * @maxLength 15
	 * @example "012345678901234"
	 */
	companyPSRN?: string;
	/**
	 * Телефон организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyPhone?: string;
	/**
	 * Email организации
	 * @maxLength 254
	 * @example "we@are.the.best"
	 */
	companyEmail?: string;
	/**
	 * Наименование банка
	 * @maxLength 254
	 * @example "Банк"
	 */
	companyBank?: string;
	/**
	 * Банковский идентификационный код (БИК)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyBIC?: string;
	/**
	 * Корреспондентский счет
	 * @maxLength 20
	 * @example "30101810400000000000"
	 */
	companyCorrespondentAccount?: string;
	/**
	 * Расчетный счет
	 * @maxLength 20
	 * @example "40802810064580000000"
	 */
	companyPaymentAccount?: string;
	/**
	 * Факс организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyFax?: string;
	/**
	 * Представитель организации
	 * @maxLength 254
	 * @example "Тухбатуллина Юлия Евгеньевна"
	 */
	companyRepresentative?: string;
	/**
	 * Пароля пользователя (должен удовлетворять минимальным требованиям)
	 * @minLength 8
	 * @maxLength 32
	 * @pattern /((?=.*d)|(?=.*W+))(?![.
	 * ])(?=.*[A-Z])(?=.*[a-z]).*$/
	 * @example "Secret~12345678"
	 */
	password: string;
}

export interface AuthRefreshRequest {
	/**
	 * Refresh токен, используемый для запросов /api/v2/refresh
	 * @example "exJxcGxiOxJIxzIxNixsIxR5cxxxxxxxxxxx.E9jKilfGxxxxxxxxxxxxx"
	 */
	refreshToken: string;
}

export interface AuthRefreshResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Возвращаемый токен */
	payload: AuthenticationPayload;
}

export interface VerifyEmailRequest {
	/** @example "j481y1b" */
	verify: string;
}

export interface SuccessResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
}

export interface ResetPasswordInvitationRequest {
	/**
	 * EMail пользователя
	 * @format email
	 * @minLength 6
	 * @maxLength 254
	 * @example "foo@bar.baz"
	 */
	email: string;
}

export interface ResetPasswordVerifyRequest {
	/** @example "j481y1b" */
	verify: string;
	/** @example "Secret~12345678" */
	password: string;
}

export interface AuthMonitorRequest {
	/**
	 * Используется для обозначения монитора
	 * @pattern ^[0-9]{3}-[0-9]{3}-[0-9]{3}$
	 * @example "123-456-789"
	 */
	code: string;
}

export interface Address {
	/**
	 * Страна
	 * @example "Россия"
	 */
	country?: string;
	/**
	 * Город
	 * @example "Краснодар"
	 */
	city?: string;
	/**
	 * Улица
	 * @example "Красная"
	 */
	street?: string;
	/**
	 * Дом
	 * @example "1"
	 */
	house?: string;
	/**
	 * Комната
	 * @example "1"
	 */
	room?: string;
}

/** Категория */
export enum MonitorCategory {
	FOOD = 'FOOD',
	CAFES_AND_RESTAURANTS = 'CAFES_AND_RESTAURANTS',
	PHARMACY = 'PHARMACY',
	ATM = 'ATM',
	HOTEL = 'HOTEL',
	AUTO_REPAIR = 'AUTO_REPAIR',
	CAR_WASHING = 'CAR_WASHING',
	HOSPITALS = 'HOSPITALS',
	CINEMAS = 'CINEMAS',
	MALL = 'MALL',
	SAUNAS = 'SAUNAS',
	BEAUTY_SALONS = 'BEAUTY_SALONS',
	GAS_STATION = 'GAS_STATION',
}

/** Ориентация экрана */
export enum MonitorOrientation {
	Horizontal = 'Horizontal',
	Vertical = 'Vertical',
}

/** Подключен */
export enum MonitorStatus {
	Offline = 'offline',
	Online = 'online',
}

/**
 * Обычный монитор, много мониторов с режимом масштабирования или зеркалирования
 * @default "SINGLE"
 */
export enum MonitorMultiple {
	SINGLE = 'SINGLE',
	SUBORDINATE = 'SUBORDINATE',
	SCALING = 'SCALING',
	MIRROR = 'MIRROR',
}

export interface PointClass {
	/**
	 * Point
	 * @example "Point"
	 */
	type: string;
	/**
	 * [ Долгота, Широта ]
	 * @example [38.97603,45.04484]
	 */
	coordinates: number[];
}

export interface MonitorRequest {
	/**
	 * Идентификатор монитора
	 * @format uuid
	 */
	id?: string;
	/**
	 * Имя
	 * @example "имя монитора"
	 */
	name?: string;
	/**
	 * Адрес монитора
	 * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
	 */
	address?: Address;
	/**
	 * Категория
	 * @example "GAS_STATION"
	 */
	category?: MonitorCategory;
	/**
	 * Ориентация экрана
	 * @example "Horizontal"
	 */
	orientation?: MonitorOrientation;
	/**
	 * Модель
	 * @example "Samsung"
	 */
	model?: string;
	/**
	 * Угол обзора
	 * @example 0
	 */
	angle?: number;
	/**
	 * Тип матрицы
	 * @example "IPS"
	 */
	matrix?: string;
	/**
	 * Яркость
	 * @example 100
	 */
	brightness?: number;
	/**
	 * Ширина
	 * @example 1920
	 */
	width?: number;
	/**
	 * Высота
	 * @example 1080
	 */
	height?: number;
	/**
	 * Присоединен
	 * @example false
	 */
	attached?: boolean;
	/**
	 * Есть звук: true/false
	 * @example true
	 */
	sound?: boolean;
	/**
	 * Подключен
	 * @example "offline"
	 */
	status?: MonitorStatus;
	/**
	 * Количество подключенных мониторов в группе
	 * @example 0
	 */
	groupOnlineMonitors?: number;
	/**
	 * Обычный монитор, много мониторов с режимом масштабирования или зеркалирования
	 * @example "SINGLE"
	 */
	multiple?: MonitorMultiple;
	/**
	 * Проигрывается плэйлист
	 * @example false
	 */
	playlistPlayed?: boolean;
	/**
	 * Идентификатор устройства
	 * @example "111-111-111"
	 */
	code?: string | null;
	/** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
	location?: PointClass;
	/**
	 * Избранный монитор
	 * @example false
	 */
	favorite?: boolean;
	/** Стоимость показа 1 секунды в рублях */
	'price1s'?: number | number[];
	/** Гарантированное минимальное количество показов в день */
	minWarranty?: number | number[];
	/** Максимальная длительность плэйлиста в секундах */
	maxDuration?: number | number[];
	/**
	 * Время начала проигрывания
	 * @format date-time
	 */
	dateWhenApp?: string | string[];
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export enum Order {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface LimitOrderRequest {
	id?: Order;
	name?: Order;
	description?: Order;
	extension?: Order;
	width?: Order;
	height?: Order;
	folder?: Order;
	folderId?: Order;
	monitor?: Order;
	monitorId?: Order;
	videoType?: Order;
	type?: Order;
	filesize?: Order;
	category?: Order;
	duration?: Order;
	files?: Order;
	monitors?: Order;
	playlists?: Order;
	parentFolder?: Order;
	parentFolderId?: Order;
	email?: Order;
	surname?: Order;
	middleName?: Order;
	phoneNumber?: Order;
	city?: Order;
	country?: Order;
	company?: Order;
	role?: Order;
	verified?: Order;
	isDemoUser?: Order;
	countUsedSpace?: Order;
	address?: Order;
	price?: Order;
	orientation?: Order;
	monitorInfo?: Order;
	attached?: Order;
	code?: Order;
	status?: Order;
	longitude?: Order;
	latitude?: Order;
	renderingStatus?: Order;
	fps?: Order;
	renderingPercent?: Order;
	renderingError?: Order;
	renderedFile?: Order;
	renderedFileId?: Order;
	keepSourceAudio?: Order;
	totalDuration?: Order;
	videoLayers?: Order;
	audioLayers?: Order;
	createdAt?: Order;
	updatedAt?: Order;
	seller?: Order;
	buyer?: Order;
	playlist?: Order;
	favorite?: Order;
}

export interface LimitRequest {
	/**
	 * Лимит строк результатов
	 * @example 20
	 */
	limit?: number;
	/**
	 * Страница результатов
	 * @example 1
	 */
	page?: number;
	/**
	 * Порядок результатов
	 * @example {"createdAt":"DESC"}
	 */
	order?: LimitOrderRequest;
}

export interface MonitorsGetRequest {
	/** Запрос */
	where?: MonitorRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: (
		| 'id'
		| 'name'
		| 'address'
		| 'category'
		| 'price1s'
		| 'minWarranty'
		| 'maxDuration'
		| 'orientation'
		| 'model'
		| 'angle'
		| 'matrix'
		| 'brightness'
		| 'width'
		| 'height'
		| 'attached'
		| 'sound'
		| 'status'
		| 'groupOnlineMonitors'
		| 'multiple'
		| 'playlistPlayed'
		| 'code'
		| 'lastSeen'
		| 'location'
		| 'favorite'
		| 'userId'
		| 'createdAt'
		| 'updatedAt'
		)[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface MonitorGroup {
	/**
	 * Подчиненный монитор в группе мониторов
	 * @format uuid
	 */
	monitorId: string;
	/** Подчиненный номер монитора в группе мониторов (строка) */
	row: number;
	/** Подчиненный номер монитора в группе мониторов (колонка) */
	col: number;
}

/** Статус */
export enum PlaylistStatus {
	Offline = 'Offline',
	Broadcast = 'Broadcast',
	NoBroadcast = 'NoBroadcast',
}

/** Тип файла */
export enum FileType {
	Video = 'video',
	Image = 'image',
	Audio = 'audio',
	Other = 'other',
}

export interface FolderResponse {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id: string;
	/**
	 * Наименование папки
	 * @minLength 1
	 * @maxLength 100
	 * @example "bar"
	 */
	name: string;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId: string;
	/**
	 * Родительская папка ID
	 * @format uuid
	 */
	parentFolderId?: string | null;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
	/** Системная папка */
	system: boolean;
	/**
	 * Подчиненные в этой папке
	 * @example true
	 */
	empty?: boolean;
}

export interface FileResponse {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id: string;
	/**
	 * Идентификатор папки
	 * @format uuid
	 */
	folderId: string;
	/**
	 * Имя файла
	 * @example "foo.mp4"
	 */
	name: string;
	/**
	 * Расширение файла
	 * @example "mp4"
	 */
	extension: string;
	/**
	 * Hash файла
	 * @example "2b0439011a3a215ae1756bfc342e5bbc"
	 */
	hash: string;
	/**
	 * Тип файла
	 * @example "video"
	 */
	type: FileType;
	/**
	 * Размер файла
	 * @example 210000
	 */
	filesize: number;
	/**
	 * Продолжительность видео
	 * @example 10
	 */
	duration: number;
	/**
	 * Размер по горизонтали
	 * @example 1024
	 */
	width: number;
	/**
	 * Размер по вертикали
	 * @example 1024
	 */
	height: number;
	/**
	 * Параметры видео, картинки или аудио, используется FfprobeData
	 * @example "{ format: { size: "100000", filename: "foo.mp4" }, streams: [{ codec_name: "h264", width: 1024, height: 1024 }] }"
	 */
	info?: object;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
	/**
	 * Подписанный URL на файл
	 * @example "https://storage.yandex.ru/file.mp4"
	 */
	signedUrl: string;
	/** Используется */
	used?: boolean;
	/** Папка */
	folder: FolderResponse;
}

export interface PlaylistResponse {
	/**
	 * Идентификатор плэйлиста
	 * @format uuid
	 */
	id: string;
	/**
	 * Имя плэйлиста
	 * @example "имя плэйлиста"
	 */
	name: string;
	/**
	 * Описание плейлиста
	 * @example "описание плейлиста"
	 */
	description?: string | null;
	/**
	 * Статус
	 * @example "Offline"
	 */
	status: PlaylistStatus;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId: string;
	/**
	 * Скрытый
	 * @default false
	 * @example false
	 */
	hide?: boolean;
	/** Подчиненные редакторы */
	editors?: EditorResponse[];
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
	/** Мониторы */
	monitors: MonitorResponse[];
	/** Файлы */
	files: FileResponse[];
}

export interface MonitorResponse {
	/**
	 * Идентификатор монитора
	 * @format uuid
	 */
	id: string;
	/**
	 * Имя
	 * @example "имя монитора"
	 */
	name: string;
	/**
	 * Адрес монитора
	 * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
	 */
	address?: Address;
	/**
	 * Категория
	 * @example "GAS_STATION"
	 */
	category: MonitorCategory;
	/**
	 * Стоимость показа 1 секунды в рублях
	 * @default "0"
	 * @example 1
	 */
	'price1s'?: number;
	/**
	 * Гарантированное минимальное количество показов в день
	 * @example 1
	 */
	minWarranty?: number;
	/**
	 * Максимальная длительность плэйлиста в секундах
	 * @example 1
	 */
	maxDuration?: number;
	/**
	 * Ориентация экрана
	 * @example "Horizontal"
	 */
	orientation?: MonitorOrientation;
	/**
	 * Модель
	 * @example "Samsung"
	 */
	model?: string;
	/**
	 * Угол обзора
	 * @example 0
	 */
	angle?: number;
	/**
	 * Тип матрицы
	 * @example "IPS"
	 */
	matrix?: string;
	/**
	 * Яркость
	 * @example 100
	 */
	brightness?: number;
	/**
	 * Ширина
	 * @example 1920
	 */
	width: number;
	/**
	 * Высота
	 * @example 1080
	 */
	height: number;
	/**
	 * Присоединен
	 * @example false
	 */
	attached: boolean;
	/**
	 * Есть звук: true/false
	 * @example true
	 */
	sound: boolean;
	/**
	 * Подключен
	 * @example "offline"
	 */
	status: MonitorStatus;
	/**
	 * Количество подключенных мониторов в группе
	 * @example 0
	 */
	groupOnlineMonitors?: number;
	/**
	 * Обычный монитор, много мониторов с режимом масштабирования или зеркалирования
	 * @example "SINGLE"
	 */
	multiple?: MonitorMultiple;
	/** Подчиненные мониторы в группе мониторов */
	groupIds?: MonitorGroup[];
	/**
	 * Проигрывается плэйлист
	 * @example false
	 */
	playlistPlayed: boolean;
	/**
	 * Последний раз виден
	 * @format date-time
	 * @example "2021-10-01T10:00:00.147Z"
	 */
	lastSeen?: string | null;
	/** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
	location?: PointClass;
	/**
	 * Избранный монитор
	 * @example false
	 */
	favorite: boolean;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
	/** Плэйлист привязанный к монитору */
	playlist?: PlaylistResponse;
	/** Фото монитора. Документы на право владения. */
	files?: FileResponse[];
	/**
	 * Идентификатор устройства
	 * @example "111-111-111"
	 */
	code: string;
}

export interface MonitorsGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество мониторов */
	count: number;
	/** Мониторы */
	data: MonitorResponse[];
}

export interface MonitorCreateRequest {
	/**
	 * Имя
	 * @example "имя монитора"
	 */
	name: string;
	/**
	 * Адрес монитора
	 * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
	 */
	address?: Address;
	/**
	 * Категория
	 * @example "GAS_STATION"
	 */
	category: MonitorCategory;
	/**
	 * Стоимость показа 1 секунды в рублях
	 * @default "0"
	 * @example 1
	 */
	'price1s'?: number;
	/**
	 * Гарантированное минимальное количество показов в день
	 * @example 1
	 */
	minWarranty?: number;
	/**
	 * Максимальная длительность плэйлиста в секундах
	 * @example 1
	 */
	maxDuration?: number;
	/**
	 * Ориентация экрана
	 * @example "Horizontal"
	 */
	orientation?: MonitorOrientation;
	/**
	 * Модель
	 * @example "Samsung"
	 */
	model?: string;
	/**
	 * Угол обзора
	 * @example 0
	 */
	angle?: number;
	/**
	 * Тип матрицы
	 * @example "IPS"
	 */
	matrix?: string;
	/**
	 * Яркость
	 * @example 100
	 */
	brightness?: number;
	/**
	 * Ширина
	 * @default 1920
	 * @example 1920
	 */
	width?: number;
	/**
	 * Высота
	 * @default 1080
	 * @example 1080
	 */
	height?: number;
	/**
	 * Есть звук: true/false
	 * @example true
	 */
	sound: boolean;
	/**
	 * Обычный монитор, много мониторов с режимом масштабирования или зеркалирования
	 * @example "SINGLE"
	 */
	multiple?: MonitorMultiple;
	/** Подчиненные мониторы в группе мониторов */
	groupIds?: MonitorGroup[];
	/** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
	location?: PointClass;
	/**
	 * Идентификатор устройства
	 * @example "111-111-111"
	 */
	code?: string;
}

export interface MonitorGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Монитор */
	data: MonitorResponse;
}

export interface BidCreateMonitorRequest {
	/**
	 * Время когда
	 * @format date
	 * @example "2021-01-01"
	 */
	dateWhen: string;
	/**
	 * Время до
	 * @format date
	 * @example "2021-10-01"
	 */
	dateBefore?: string | null;
	/**
	 * Смена текущего плэйлиста: сразу/когда закончится
	 * @example false
	 */
	playlistChange: boolean;
}

export interface MonitorsPlaylistAttachRequest {
	/**
	 * Плэйлист
	 * @format uuid
	 */
	playlistId: string;
	/** Мониторы */
	monitorIds: string[];
	/** Создание заявки */
	bid: BidCreateMonitorRequest;
}

/** Ок / Подождите */
export enum BidStatus {
	OK = 'OK',
	WAITING = 'WAITING',
}

/** Не обработан / Разрешен / Запрещен */
export enum BidApprove {
	NotProcessed = 'NotProcessed',
	Allowed = 'Allowed',
	Denied = 'Denied',
}

export interface BidResponse {
	/**
	 * Идентификатор взаимодействия
	 * @format uuid
	 */
	id: string;
	/** Номер заявки */
	seqNo: number;
	/** Покупатель */
	buyer: UserResponse;
	/** Продавец */
	seller: UserResponse;
	/** Монитор */
	monitor: MonitorResponse;
	/**
	 * Ок / Подождите
	 * @example "OK"
	 */
	status?: BidStatus;
	/**
	 * Скрытый
	 * @default false
	 * @example false
	 */
	hide?: boolean;
	/** Плэйлист */
	playlist: PlaylistResponse;
	/**
	 * Не обработан / Разрешен / Запрещен
	 * @example "NotProcessed"
	 */
	approved: BidApprove;
	/**
	 * Время когда
	 * @format date
	 * @example "2021-01-01"
	 */
	dateWhen: string;
	/**
	 * Время до
	 * @format date
	 * @example "2021-10-01"
	 */
	dateBefore?: string | null;
	/**
	 * Смена текущего плэйлиста: сразу/когда закончится
	 * @example false
	 */
	playlistChange: boolean;
	/**
	 * Сумма списания
	 * @example 10
	 */
	sum: number;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
}

export interface BidsGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество взаимодействий */
	count: number;
	/** Взаимодействия покупателей и продавца */
	data: BidResponse[];
}

export interface MonitorUpdateRequest {
	/**
	 * Идентификатор монитора
	 * @format uuid
	 */
	id?: string;
	/**
	 * Имя
	 * @example "имя монитора"
	 */
	name?: string;
	/**
	 * Адрес монитора
	 * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
	 */
	address?: Address;
	/**
	 * Категория
	 * @example "GAS_STATION"
	 */
	category?: MonitorCategory;
	/**
	 * Стоимость показа 1 секунды в рублях
	 * @default "0"
	 * @example 1
	 */
	'price1s'?: number;
	/**
	 * Гарантированное минимальное количество показов в день
	 * @example 1
	 */
	minWarranty?: number;
	/**
	 * Максимальная длительность плэйлиста в секундах
	 * @example 1
	 */
	maxDuration?: number;
	/**
	 * Ориентация экрана
	 * @example "Horizontal"
	 */
	orientation?: MonitorOrientation;
	/**
	 * Модель
	 * @example "Samsung"
	 */
	model?: string;
	/**
	 * Угол обзора
	 * @example 0
	 */
	angle?: number;
	/**
	 * Тип матрицы
	 * @example "IPS"
	 */
	matrix?: string;
	/**
	 * Яркость
	 * @example 100
	 */
	brightness?: number;
	/**
	 * Ширина
	 * @example 1920
	 */
	width?: number;
	/**
	 * Высота
	 * @example 1080
	 */
	height?: number;
	/**
	 * Присоединен
	 * @example false
	 */
	attached?: boolean;
	/**
	 * Есть звук: true/false
	 * @example true
	 */
	sound?: boolean;
	/**
	 * Подключен
	 * @example "offline"
	 */
	status?: MonitorStatus;
	/**
	 * Обычный монитор, много мониторов с режимом масштабирования или зеркалирования
	 * @example "SINGLE"
	 */
	multiple?: MonitorMultiple;
	/** Подчиненные мониторы в группе мониторов */
	groupIds?: MonitorGroup[];
	/**
	 * Проигрывается плэйлист
	 * @example false
	 */
	playlistPlayed?: boolean;
	/**
	 * Идентификатор устройства
	 * @example "111-111-111"
	 */
	code?: string | null;
	/** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
	location?: PointClass;
}

/** Статус рендеринга */
export enum RenderingStatus {
	Initial = 'initial',
	Ready = 'ready',
	Pending = 'pending',
	Error = 'error',
}

export interface EditorRequest {
	/**
	 * Идентификатор редактора
	 * @format uuid
	 */
	id?: string;
	/**
	 * Имя редактора
	 * @example "имя редактора"
	 */
	name?: string;
	/**
	 * Статус рендеринга
	 * @example "initial"
	 */
	renderingStatus?: RenderingStatus;
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface EditorsGetRequest {
	/** Запрос */
	where?: EditorRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: (
		| 'id'
		| 'name'
		| 'width'
		| 'height'
		| 'fps'
		| 'renderingStatus'
		| 'renderingPercent'
		| 'renderingError'
		| 'renderedFile'
		| 'keepSourceAudio'
		| 'totalDuration'
		| 'videoLayers'
		| 'audioLayers'
		| 'userId'
		| 'createdAt'
		| 'updatedAt'
		)[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface EditorLayerResponse {
	/**
	 * Идентификатор слоя
	 * @example "12345678"
	 */
	id: string;
	/**
	 * Индекс файла
	 * @default 1
	 * @example 1
	 */
	index: number;
	/**
	 * Длительность
	 * @default 10
	 * @example 10
	 */
	duration: number;
	/**
	 * С какой секунды начать вырезать клип
	 * @default 0
	 * @example 0
	 */
	cutFrom: number;
	/**
	 * До какой секунды вырезать клип
	 * @default 10
	 * @example 10
	 */
	cutTo: number;
	/**
	 * С какой секунды начинать воспроизводить клип
	 * @default 0
	 * @example 0
	 */
	start: number;
	/** Обрезать слева */
	cropX?: number;
	/** Обрезать сверху */
	cropY?: number;
	/** Ширина обрезки */
	cropW?: number;
	/** Высота обрезки */
	cropH?: number;
	/**
	 * Аудио дорожка из видео, 0-выключен, 1-включен
	 * @default 1
	 * @example 1
	 */
	mixVolume: number;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
	/** Файл */
	file: FileResponse;
}

export interface EditorResponse {
	/**
	 * Идентификатор редактора
	 * @format uuid
	 */
	id: string;
	/**
	 * Имя редактора
	 * @example "имя редактора"
	 */
	name: string;
	/**
	 * Ширина редактора
	 * @example 1920
	 */
	width: number;
	/**
	 * Высота редактора
	 * @example 1080
	 */
	height: number;
	/**
	 * Фреймрейт
	 * @example 24
	 */
	fps: number;
	/**
	 * Статус рендеринга
	 * @example "initial"
	 */
	renderingStatus: RenderingStatus;
	/**
	 * Процент рендеринга
	 * @example 0
	 */
	renderingPercent: number | null;
	/** Ошибка рендеринга */
	renderingError: string | null;
	/** Обработанный файл */
	renderedFile?: FileResponse | null;
	/**
	 * Воспроизводить музыку с видео
	 * @example true
	 */
	keepSourceAudio: boolean;
	/**
	 * Общее время
	 * @example 0
	 */
	totalDuration: number;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
	/** Видео слой */
	videoLayers: EditorLayerResponse[];
	/** Аудио слой */
	audioLayers: EditorLayerResponse[];
}

export interface EditorsGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество редакторов */
	count: number;
	/** Оплаты */
	data: EditorResponse[];
}

export interface EditorCreateRequest {
	/**
	 * Имя редактора
	 * @example "имя редактора"
	 */
	name: string;
	/**
	 * Ширина редактора
	 * @example 1920
	 */
	width: number;
	/**
	 * Высота редактора
	 * @example 1080
	 */
	height: number;
	/**
	 * Фреймрейт
	 * @example 24
	 */
	fps: number;
	/**
	 * Воспроизводить музыку с видео
	 * @example true
	 */
	keepSourceAudio: boolean;
}

export interface EditorGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Редактор */
	data: EditorResponse;
}

export interface EditorUpdateRequest {
	/**
	 * Имя редактора
	 * @example "имя редактора"
	 */
	name?: string;
	/**
	 * Ширина редактора
	 * @example 1920
	 */
	width?: number;
	/**
	 * Высота редактора
	 * @example 1080
	 */
	height?: number;
	/**
	 * Фреймрейт
	 * @example 24
	 */
	fps?: number;
	/**
	 * Воспроизводить музыку с видео
	 * @example true
	 */
	keepSourceAudio?: boolean;
}

export interface EditorLayerCreateRequest {
	/**
	 * Индекс файла
	 * @default 1
	 * @example 1
	 */
	index?: number;
	/**
	 * Длительность
	 * @default 10
	 * @example 10
	 */
	duration?: number;
	/**
	 * С какой секунды начать вырезать клип
	 * @default 0
	 * @example 0
	 */
	cutFrom?: number;
	/**
	 * До какой секунды вырезать клип
	 * @default 10
	 * @example 10
	 */
	cutTo?: number;
	/**
	 * С какой секунды начинать воспроизводить клип
	 * @default 0
	 * @example 0
	 */
	start?: number;
	/** Обрезать слева */
	cropX?: number;
	/** Обрезать сверху */
	cropY?: number;
	/** Ширина обрезки */
	cropW?: number;
	/** Высота обрезки */
	cropH?: number;
	/**
	 * Аудио дорожка из видео, 0-выключен, 1-включен
	 * @default 1
	 * @example 1
	 */
	mixVolume?: number;
	/**
	 * Файл
	 * @format uuid
	 */
	file: string;
}

export interface EditorLayerGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Слой редактора */
	data: EditorLayerResponse;
}

export interface EditorLayerUpdateRequest {
	/**
	 * Индекс файла
	 * @default 1
	 * @example 1
	 */
	index?: number;
	/**
	 * Длительность
	 * @default 10
	 * @example 10
	 */
	duration?: number;
	/**
	 * С какой секунды начать вырезать клип
	 * @default 0
	 * @example 0
	 */
	cutFrom?: number;
	/**
	 * До какой секунды вырезать клип
	 * @default 10
	 * @example 10
	 */
	cutTo?: number;
	/**
	 * С какой секунды начинать воспроизводить клип
	 * @default 0
	 * @example 0
	 */
	start?: number;
	/** Обрезать слева */
	cropX?: number;
	/** Обрезать сверху */
	cropY?: number;
	/** Ширина обрезки */
	cropW?: number;
	/** Высота обрезки */
	cropH?: number;
	/**
	 * Аудио дорожка из видео, 0-выключен, 1-включен
	 * @default 1
	 * @example 1
	 */
	mixVolume?: number;
}

export interface EditorRenderingStatusResponse {
	/**
	 * Идентификатор редактора
	 * @format uuid
	 */
	id: string;
	/**
	 * Статус рендеринга
	 * @example "initial"
	 */
	renderingStatus: RenderingStatus;
	/**
	 * Процент рендеринга
	 * @example 0
	 */
	renderingPercent: number | null;
	/** Ошибка рендеринга */
	renderingError: string | null;
	/** Обработанный файл */
	renderedFile?: FileResponse | null;
}

export interface EditorGetRenderingStatusResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Редактор */
	data: EditorRenderingStatusResponse;
}

export interface EditorExportRequest {
	/**
	 * Перезапустить рендеринг
	 * @default false
	 * @example false
	 */
	rerender?: boolean;
}

export interface FileRequest {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id?: string;
	/**
	 * Идентификатор папки
	 * @format uuid
	 */
	folderId: string;
	/**
	 * Тип файла
	 * @example "video"
	 */
	type?: FileType;
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface FilesGetRequest {
	/** Запрос */
	where?: FileRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: (
		| 'id'
		| 'folderId'
		| 'name'
		| 'extension'
		| 'hash'
		| 'type'
		| 'filesize'
		| 'duration'
		| 'width'
		| 'height'
		| 'info'
		| 'userId'
		| 'createdAt'
		| 'updatedAt'
		)[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface FilesGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество файлов */
	count: number;
	/** Файлы */
	data: FileResponse[];
}

export interface FilesUploadResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество файлов */
	count: number;
	/** Файлы */
	data: FileResponse[];
}

export interface FileUpdatesRequest {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id: string;
	/**
	 * Имя файла
	 * @example "foo.mp4"
	 */
	name?: string;
	/**
	 * Идентификатор папки
	 * @format uuid
	 */
	folderId?: string;
}

export interface FilesUpdateRequest {
	/** Файлы */
	files: FileUpdatesRequest[];
}

export interface FileGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Файл */
	data: FileResponse;
}

export interface FileIdRequest {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id: string;
}

export interface FilesCopyRequest {
	/**
	 * Папка, куда копировать
	 * @format uuid
	 */
	toFolder: string;
	/** Файлы */
	files: FileIdRequest[];
}

export interface FileUpdateRequest {
	/**
	 * Идентификатор папки
	 * @format uuid
	 */
	folderId?: string;
	/**
	 * Имя файла
	 * @example "foo.mp4"
	 */
	name?: string;
}

export interface FilesDeleteRequest {
	/** Файлы для удаления */
	filesId: string[];
}

export interface FolderRequest {
	/**
	 * Наименование папки
	 * @minLength 1
	 * @maxLength 100
	 * @example "bar"
	 */
	name?: string;
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id?: string;
	/**
	 * Родительская папка
	 * @format uuid
	 */
	parentFolderId?: string | null;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId?: string;
	/** Время создания */
	createdAt?: (string | string[])[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface FoldersGetRequest {
	/** Запрос */
	where?: FolderRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: ('id' | 'name' | 'userId' | 'parentFolderId' | 'createdAt' | 'updatedAt' | 'system')[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface FoldersGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/**
	 * Количество папок
	 * @example 1
	 */
	count: number;
	/** Папки */
	data: FolderResponse[];
}

export interface FolderCreateRequest {
	/**
	 * Наименование папки
	 * @minLength 1
	 * @maxLength 100
	 * @example "bar"
	 */
	name: string;
	/**
	 * Родительская папка ID
	 * @format uuid
	 */
	parentFolderId?: string | null;
}

export interface FolderGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Папки */
	data: FolderResponse;
}

export interface FolderUpdateRequest {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id: string;
	/**
	 * Наименование папки
	 * @example "bar"
	 */
	name?: string;
	/**
	 * Родительская папка
	 * @format uuid
	 */
	parentFolderId?: string;
}

export interface FoldersUpdateRequest {
	/** Папки */
	folders: FolderUpdateRequest[];
}

export interface FolderCopyRequest {
	/**
	 * Идентификатор файла
	 * @format uuid
	 */
	id: string;
}

export interface FoldersCopyRequest {
	/**
	 * Папка, куда копировать
	 * @format uuid
	 */
	toFolder: string;
	/** Папки */
	folders: FolderCopyRequest[];
}

export interface FoldersDeleteRequest {
	/** Папки для удаления */
	foldersId: string[];
}

export interface FolderIdUpdateRequest {
	/**
	 * Наименование папки
	 * @example "bar"
	 */
	name?: string;
	/**
	 * Родительская папка
	 * @format uuid
	 */
	parentFolderId?: string;
}

export interface UserRequest {
	/**
	 * Идентификатор пользователя
	 * @format uuid
	 */
	id?: string;
	/**
	 * EMail пользователя
	 * @format email
	 * @minLength 6
	 * @maxLength 254
	 * @example "foo@bar.baz"
	 */
	email?: string;
	/**
	 * Фамилия
	 * @maxLength 50
	 * @example "Steve"
	 */
	surname?: string | null;
	/**
	 * Имя
	 * @maxLength 50
	 * @example "John"
	 */
	name?: string | null;
	/**
	 * Отчество
	 * @maxLength 50
	 * @example "Doe"
	 */
	middleName?: string | null;
	/**
	 * Телефон пользователя
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	phoneNumber?: string | null;
	/**
	 * Город
	 * @maxLength 100
	 * @example "Krasnodar"
	 */
	city?: string;
	/**
	 * Страна
	 * @maxLength 2
	 * @example "RU"
	 */
	country?: string;
	/**
	 * Предпочитаемый язык
	 * @maxLength 6
	 * @example "ru"
	 */
	preferredLanguage?: string;
	/**
	 * Настройки даты
	 * @maxLength 6
	 * @example "ru_RU"
	 */
	locale?: string;
	/**
	 * Дисковое пространство
	 * @example 20000000
	 */
	storageSpace?: number;
	/**
	 * Роль пользователя
	 * @example "advertiser"
	 */
	role?: UserRole;
	/**
	 * EMail подтвержден
	 * @example true
	 */
	verified?: boolean;
	/**
	 * План пользователя
	 * @example "FULL"
	 */
	plan?: UserPlan;
	/**
	 * Сколько раз отправлялось напоминание о неуплате
	 * @example 0
	 */
	nonPayment?: number;
	/**
	 * Компания
	 * @maxLength 100
	 * @example "ACME corporation"
	 */
	company?: string | null;
	/**
	 * Юридический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyLegalAddress?: string;
	/**
	 * Фактический адрес
	 * @maxLength 254
	 * @example "г. Краснодар, ул. Красная, д. 1"
	 */
	companyActualAddress?: string;
	/**
	 * Идентификационный номер налогоплательщика (ИНН)
	 * @maxLength 12
	 * @example "012345678901"
	 */
	companyTIN?: string;
	/**
	 * Код Причины Постановки на учет (КПП)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyRRC?: string;
	/**
	 * Основной Государственный Регистрационный Номер (ОГРН)
	 * @maxLength 15
	 * @example "012345678901234"
	 */
	companyPSRN?: string;
	/**
	 * Телефон организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyPhone?: string;
	/**
	 * Email организации
	 * @maxLength 254
	 * @example "we@are.the.best"
	 */
	companyEmail?: string;
	/**
	 * Наименование банка
	 * @maxLength 254
	 * @example "Банк"
	 */
	companyBank?: string;
	/**
	 * Банковский идентификационный код (БИК)
	 * @maxLength 9
	 * @example "012345678"
	 */
	companyBIC?: string;
	/**
	 * Корреспондентский счет
	 * @maxLength 20
	 * @example "30101810400000000000"
	 */
	companyCorrespondentAccount?: string;
	/**
	 * Расчетный счет
	 * @maxLength 20
	 * @example "40802810064580000000"
	 */
	companyPaymentAccount?: string;
	/**
	 * Факс организации
	 * @maxLength 14
	 * @example "+78002000000"
	 */
	companyFax?: string;
	/**
	 * Представитель организации
	 * @maxLength 254
	 * @example "Тухбатуллина Юлия Евгеньевна"
	 */
	companyRepresentative?: string;
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface UsersGetRequest {
	/** Запрос */
	where?: UserRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: (
		| 'id'
		| 'email'
		| 'surname'
		| 'name'
		| 'middleName'
		| 'phoneNumber'
		| 'city'
		| 'country'
		| 'preferredLanguage'
		| 'locale'
		| 'storageSpace'
		| 'role'
		| 'verified'
		| 'plan'
		| 'nonPayment'
		| 'company'
		| 'companyLegalAddress'
		| 'companyActualAddress'
		| 'companyTIN'
		| 'companyRRC'
		| 'companyPSRN'
		| 'companyPhone'
		| 'companyEmail'
		| 'companyBank'
		| 'companyBIC'
		| 'companyCorrespondentAccount'
		| 'companyPaymentAccount'
		| 'companyFax'
		| 'companyRepresentative'
		| 'createdAt'
		| 'updatedAt'
		)[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface UsersGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество пользователей */
	count: number;
	/** Пользователи */
	data: UserResponse[];
}

/** Подтверждение/отклонение счёта */
export enum InvoiceStatus {
	AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
	CONFIRMED_PENDING_PAYMENT = 'CONFIRMED_PENDING_PAYMENT',
	PAID = 'PAID',
	CANCELLED = 'CANCELLED',
}

export interface InvoiceRequest {
	/**
	 * Идентификатор счёта
	 * @format uuid
	 */
	id?: string;
	/** Номер счета */
	seqNo?: number;
	/**
	 * Описание заказа
	 * @example "описание заказа"
	 */
	description?: string;
	/** Сумма счета */
	sum?: number | number[];
	/** @example {"range":["AWAITING_CONFIRMATION","CANCELLED"]} */
	status?: InvoiceStatus[];
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface InvoicesGetRequest {
	/** Запрос */
	where?: InvoiceRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: ('id' | 'seqNo' | 'description' | 'status' | 'sum' | 'file' | 'createdAt' | 'updatedAt')[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface InvoiceResponse {
	/**
	 * Идентификатор счёта
	 * @format uuid
	 */
	id: string;
	/** Номер счета */
	seqNo: number;
	/**
	 * Описание заказа
	 * @example "описание заказа"
	 */
	description: string;
	/**
	 * Подтверждение/отклонение счёта
	 * @example "AWAITING_CONFIRMATION"
	 */
	status: InvoiceStatus;
	/**
	 * Сумма счета
	 * @example 1000
	 */
	sum: number;
	/** Файл */
	file: FileResponse | null;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
}

export interface InvoicesGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество заказов */
	count: number;
	/** Заказы */
	data: InvoiceResponse[];
}

export interface InvoiceCreateRequest {
	/**
	 * Сумма счета
	 * @example 1000
	 */
	sum: number;
	/**
	 * Описание заказа
	 * @example "описание заказа"
	 */
	description?: string;
}

export interface InvoiceGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Счёт */
	data: InvoiceResponse;
}

export interface PlaylistRequest {
	/**
	 * Идентификатор плэйлиста
	 * @format uuid
	 */
	id?: string;
	/**
	 * Имя плэйлиста
	 * @example "имя плэйлиста"
	 */
	name?: string;
	/**
	 * Описание плейлиста
	 * @example "описание плейлиста"
	 */
	description?: string | null;
	/** @example {"range":["Offline","Broadcast"]} */
	status?: PlaylistStatus[];
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface PlaylistsGetRequest {
	/** Запрос */
	where?: PlaylistRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: (
		| 'id'
		| 'name'
		| 'description'
		| 'status'
		| 'userId'
		| 'hide'
		| 'files'
		| 'monitors'
		| 'editors'
		| 'createdAt'
		| 'updatedAt'
		)[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface PlaylistsGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество плэйлистов */
	count: number;
	/** Плэйлисты */
	data: PlaylistResponse[];
}

export interface PlaylistCreateRequest {
	/**
	 * Имя плэйлиста
	 * @example "имя плэйлиста"
	 */
	name: string;
	/**
	 * Описание плейлиста
	 * @example "описание плейлиста"
	 */
	description?: string | null;
	/** Файлы */
	files: string[];
}

export interface PlaylistGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Плэйлист */
	data: PlaylistResponse;
}

export interface PlaylistUpdateRequest {
	/**
	 * Имя плэйлиста
	 * @example "имя плэйлиста"
	 */
	name?: string;
	/**
	 * Описание плейлиста
	 * @example "описание плейлиста"
	 */
	description?: string | null;
	/**
	 * Файлы
	 * @example ["1234-5678-9123-4567","7654-3219-8765-4321"]
	 */
	files?: string[];
}

/** Формат получаемого файла */
export enum SpecificFormat {
	Xlsx = 'xlsx',
	Pdf = 'pdf',
}

export interface ReportDeviceStatusRequest {
	/**
	 * Формат получаемого файла
	 * @example "xlsx"
	 */
	format: SpecificFormat;
	/**
	 * Мониторы ID (если не указан, то все мониторы)
	 * @example ["1234-5678-9123-4567","7654-3219-8765-4321"]
	 */
	monitorIds?: string[];
	/**
	 * Начальная дата
	 * @format date
	 * @example "2021-01-01"
	 */
	dateFrom: string;
	/**
	 * Конечная дата
	 * @format date
	 * @example "2021-01-01"
	 */
	dateTo: string;
}

export interface ReportViewsRequest {
	/**
	 * Формат получаемого файла
	 * @example "xlsx"
	 */
	format: SpecificFormat;
	/**
	 * Мониторы ID (если не указан, то все мониторы)
	 * @example ["1234-5678-9123-4567","7654-3219-8765-4321"]
	 */
	monitorIds?: string[];
	/**
	 * Начальная дата
	 * @format date
	 * @example "2021-01-01"
	 */
	dateFrom: string;
	/**
	 * Конечная дата
	 * @format date
	 * @example "2021-01-01"
	 */
	dateTo: string;
}

export interface BidRequest {
	/**
	 * Идентификатор взаимодействия
	 * @format uuid
	 */
	id?: string;
	/** Номер заявки */
	seqNo?: number;
	/**
	 * Покупатель ID
	 * @format uuid
	 */
	buyerId?: object | null;
	/**
	 * Продавец ID
	 * @format uuid
	 */
	sellerId?: string;
	/**
	 * Монитор ID
	 * @format uuid
	 */
	monitorId?: string;
	/**
	 * Плэйлист ID
	 * @format uuid
	 */
	playlistId?: string;
	/**
	 * Смена текущего плэйлиста: сразу/когда закончится
	 * @example false
	 */
	playlistChange?: boolean;
	/**
	 * Сумма списания
	 * @example 10
	 */
	sum?: number;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId?: string;
	/** @example {"range":["NotProcessed","Allowed"]} */
	approved?: BidApprove[];
	/** @example {"range":["OK","WAITING"]} */
	status?: BidStatus[];
	/**
	 * Время когда
	 * @format date-time
	 */
	dateWhen?: string | string[];
	/**
	 * Время до
	 * @format date-time
	 */
	dateBefore?: string | string[] | null;
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface BidsGetRequest {
	/** Запрос */
	where?: BidRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: (
		| 'id'
		| 'seqNo'
		| 'buyer'
		| 'buyerId'
		| 'seller'
		| 'sellerId'
		| 'monitor'
		| 'monitorId'
		| 'status'
		| 'hide'
		| 'playlist'
		| 'playlistId'
		| 'approved'
		| 'dateWhen'
		| 'dateBefore'
		| 'playlistChange'
		| 'sum'
		| 'userId'
		| 'createdAt'
		| 'updatedAt'
		)[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface BidGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Взаимодействие покупателей и продавца */
	data: BidResponse;
}

export interface BidUpdateRequest {
	/**
	 * Не обработан / Разрешен / Запрещен
	 * @example "NotProcessed"
	 */
	approved: BidApprove;
}

export interface BidPrecalcPromoRequest {
	/** Мониторы для расчета */
	monitorIds: string[];
	/**
	 * Длительность плейлиста (в секундах)
	 * @example 60
	 */
	playlistDuration: number;
	/**
	 * Дата и время начала
	 * @format date
	 * @example "2021-01-01"
	 */
	dateFrom: string;
	/**
	 * Дата и время окончания
	 * @format date
	 * @example "2021-12-31"
	 */
	dateTo: string;
}

export interface BidPrecalcDataResponse {
	/** Итоговая сумма */
	sum: string;
}

export interface BidPrecalcResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Возвращаемое значение */
	data: BidPrecalcDataResponse;
}

export interface BidPrecalcSumRequest {
	/**
	 * Плэйлист ID
	 * @format uuid
	 */
	playlistId: string;
	/**
	 * Гарантированное минимальное количество показов в день
	 * @example 1
	 */
	minWarranty: number;
	/**
	 * Стоимость показа 1 секунды в рублях
	 * @example 1
	 */
	'price1s': number;
	/**
	 * Дата и время начала
	 * @format date
	 * @example "2021-01-01"
	 */
	dateBefore: string;
	/**
	 * Дата и время окончания
	 * @format date
	 * @example "2021-12-31"
	 */
	dateWhen: string;
}

export interface CrontabCreateRequest {
	/**
	 * Crontab: "Second Minute Hour DayOfMonth Month DayOfWeek"
	 * @example "0 0 0 * * *"
	 */
	crontab?: string;
}

export interface ConstantsResponse {
	/** Версия бэкенд */
	VERSION_BACKEND: string;
	/** Стоимость подписки */
	SUBSCRIPTION_FEE: number;
	/** Минимальная сумма счета */
	MIN_INVOICE_SUM: number;
	/** Процент комиссии */
	COMMISSION_PERCENT: number;
}

export interface ConstantsGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Константы */
	data: ConstantsResponse;
}

/** Тип транзакции */
export enum WalletTransactionType {
	DEBIT = 'DEBIT',
	CREDIT = 'CREDIT',
}

export interface WalletRequest {
	/**
	 * Идентификатор баланса
	 * @format uuid
	 */
	id?: string;
	/** Описание транзакции */
	description?: string;
	/**
	 * Баланс
	 * @example 0
	 */
	sum?: number;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId?: string;
	/** @example {"range":["DEBIT","CREDIT"]} */
	type?: WalletTransactionType[];
	/**
	 * Время создания
	 * @format date-time
	 */
	createdAt?: string | string[];
	/**
	 * Время изменения
	 * @format date-time
	 */
	updatedAt?: string | string[];
}

export interface WalletOperationsGetRequest {
	/** Запрос */
	where?: WalletRequest;
	/**
	 * Выбрать поля
	 * @example []
	 */
	select?: ('id' | 'type' | 'description' | 'sum' | 'userId' | 'createdAt' | 'updatedAt')[];
	/** Рамки для запроса */
	scope?: LimitRequest;
}

export interface WalletResponse {
	/**
	 * Идентификатор баланса
	 * @format uuid
	 */
	id: string;
	/**
	 * Тип транзакции
	 * @example "DEBIT"
	 */
	type: WalletTransactionType;
	/** Описание транзакции */
	description: string;
	/**
	 * Баланс
	 * @example 0
	 */
	sum: number;
	/**
	 * Пользователь ID
	 * @format uuid
	 */
	userId: string;
	/**
	 * Время создания
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	createdAt?: string;
	/**
	 * Время изменения
	 * @format date-time
	 * @example "2021-01-01T00:00:00.000Z"
	 */
	updatedAt?: string;
}

export interface WalletOperationsGetResponse {
	/**
	 * Статус операции
	 * @example "success"
	 */
	status: Status;
	/** Количество операций с кошельком */
	count: number;
	/** Операции с кошельком */
	data: WalletResponse[];
}

export enum WsEvent {
	AuthToken = 'auth/token',
	Bids = 'bids',
	Bid = 'bid',
	Monitor = 'monitor',
	MonitorStatus = 'monitor/status',
	MonitorDelete = 'monitor/delete',
	Wallet = 'wallet',
	Metrics = 'metrics',
}

export interface WsEventClass {
	WsEvent?: WsEvent;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	public request = async <T = any, _E = any>({
																							 secure,
																							 path,
																							 type,
																							 query,
																							 format,
																							 body,
																							 ...params
																						 }: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}
}

/**
 * @title MyScreen backend API
 * @version 2.57.1
 * @externalDocs https://api.myscreen.ru
 * @contact Stanislav V Lanskoy <stanislav@wisekaa.dev> (https://wisekaa.dev)
 *
 * MyScreen backend API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	api = {
		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthGet
		 * @summary Проверяет, авторизован ли пользователь и выдает о пользователе полную информацию
		 * @request GET:/api/v2/auth
		 * @secure
		 */
		authGet: (params: RequestParams = {}) =>
			this.request<
				UserGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthUpdate
		 * @summary Изменение аккаунта пользователя
		 * @request PATCH:/api/v2/auth
		 * @secure
		 */
		authUpdate: (data: UserUpdateRequest, params: RequestParams = {}) =>
			this.request<
				UserGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthLogin
		 * @summary Авторизация пользователя
		 * @request POST:/api/v2/auth/login
		 */
		authLogin: (data: LoginRequest, params: RequestParams = {}) =>
			this.request<
				AuthResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/login`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthRegister
		 * @summary Регистрация пользователя
		 * @request POST:/api/v2/auth/register
		 */
		authRegister: (data: RegisterRequest, params: RequestParams = {}) =>
			this.request<
				UserGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/register`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name Refresh
		 * @summary Обновление токена
		 * @request POST:/api/v2/auth/refresh
		 */
		refresh: (data: AuthRefreshRequest, params: RequestParams = {}) =>
			this.request<
				AuthRefreshResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/refresh`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthEmailVerify
		 * @summary Подтвердить email пользователя
		 * @request POST:/api/v2/auth/email-verify
		 */
		authEmailVerify: (data: VerifyEmailRequest, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/email-verify`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthResetPassword
		 * @summary Отправить на почту пользователю разрешение на смену пароля
		 * @request POST:/api/v2/auth/reset-password
		 */
		authResetPassword: (data: ResetPasswordInvitationRequest, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/reset-password`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthResetPasswordVerify
		 * @summary Меняет пароль пользователя по приглашению из почты
		 * @request POST:/api/v2/auth/reset-password-verify
		 */
		authResetPasswordVerify: (data: ResetPasswordVerifyRequest, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/reset-password-verify`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthDisable
		 * @summary Скрытие аккаунта пользователя
		 * @request PATCH:/api/v2/auth/disable
		 * @secure
		 */
		authDisable: (params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/disable`,
				method: 'PATCH',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags auth
		 * @name AuthMonitor
		 * @summary Авторизация монитора
		 * @request POST:/api/v2/auth/monitor
		 */
		authMonitor: (data: AuthMonitorRequest, params: RequestParams = {}) =>
			this.request<
				AuthRefreshResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/auth/monitor`,
				method: 'POST',
				body: data,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorsGet
		 * @summary Получение списка мониторов
		 * @request POST:/api/v2/monitor
		 * @secure
		 */
		monitorsGet: (data: MonitorsGetRequest, params: RequestParams = {}) =>
			this.request<
				MonitorsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorCreate
		 * @summary Создание монитора
		 * @request PUT:/api/v2/monitor
		 * @secure
		 */
		monitorCreate: (data: MonitorCreateRequest, params: RequestParams = {}) =>
			this.request<
				MonitorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorPlaylistCreate
		 * @summary Создание связки плэйлиста и монитора
		 * @request PATCH:/api/v2/monitor/playlist
		 * @secure
		 */
		monitorPlaylistCreate: (data: MonitorsPlaylistAttachRequest, params: RequestParams = {}) =>
			this.request<
				MonitorsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/playlist`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorPlaylistDelete
		 * @summary Удаление связки плэйлиста и монитора
		 * @request DELETE:/api/v2/monitor/playlist
		 * @secure
		 */
		monitorPlaylistDelete: (data: MonitorsPlaylistAttachRequest, params: RequestParams = {}) =>
			this.request<
				MonitorsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/playlist`,
				method: 'DELETE',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorGet
		 * @summary Получение монитора
		 * @request GET:/api/v2/monitor/{monitorId}
		 * @secure
		 */
		monitorGet: (monitorId: string, params: RequestParams = {}) =>
			this.request<
				MonitorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorUpdate
		 * @summary Изменение монитора
		 * @request PATCH:/api/v2/monitor/{monitorId}
		 * @secure
		 */
		monitorUpdate: (monitorId: string, data: MonitorUpdateRequest, params: RequestParams = {}) =>
			this.request<
				MonitorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorDelete
		 * @summary Удаление монитора
		 * @request DELETE:/api/v2/monitor/{monitorId}
		 * @secure
		 */
		monitorDelete: (monitorId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorUploadPhotos
		 * @summary Загрузка файлов картинок монитора
		 * @request PUT:/api/v2/monitor/{monitorId}/upload-photos
		 * @secure
		 */
		monitorUploadPhotos: (
			monitorId: string,
			data: {
				/** Файл(ы) */
				files: File[];
			},
			params: RequestParams = {},
		) =>
			this.request<
				MonitorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}/upload-photos`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorUploadDocuments
		 * @summary Загрузка документов монитора
		 * @request PUT:/api/v2/monitor/{monitorId}/upload-documents
		 * @secure
		 */
		monitorUploadDocuments: (
			monitorId: string,
			data: {
				/** Файл(ы) */
				files: File[];
			},
			params: RequestParams = {},
		) =>
			this.request<
				MonitorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}/upload-documents`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorFavoritePlus
		 * @summary Избранное '+'
		 * @request GET:/api/v2/monitor/{monitorId}/favoritePlus
		 * @secure
		 */
		monitorFavoritePlus: (monitorId: string, params: RequestParams = {}) =>
			this.request<
				MonitorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}/favoritePlus`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorFavoriteMinus
		 * @summary Избранное '-'
		 * @request GET:/api/v2/monitor/{monitorId}/favoriteMinus
		 * @secure
		 */
		monitorFavoriteMinus: (monitorId: string, params: RequestParams = {}) =>
			this.request<
				MonitorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}/favoriteMinus`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags monitor
		 * @name MonitorGetBid
		 * @summary Получение плэйлиста монитора
		 * @request GET:/api/v2/monitor/{monitorId}/applications
		 * @secure
		 */
		monitorGetBid: (monitorId: string, params: RequestParams = {}) =>
			this.request<
				BidsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/monitor/${monitorId}/applications`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorsGet
		 * @summary Получение списка редакторов
		 * @request POST:/api/v2/editor
		 * @secure
		 */
		editorsGet: (data: EditorsGetRequest, params: RequestParams = {}) =>
			this.request<
				EditorsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorCreate
		 * @summary Создание редактора
		 * @request PUT:/api/v2/editor
		 * @secure
		 */
		editorCreate: (data: EditorCreateRequest, params: RequestParams = {}) =>
			this.request<
				EditorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorGet
		 * @summary Получение редактора
		 * @request GET:/api/v2/editor/{editorId}
		 * @secure
		 */
		editorGet: (editorId: string, params: RequestParams = {}) =>
			this.request<
				EditorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/${editorId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorUpdate
		 * @summary Изменить редактор
		 * @request PATCH:/api/v2/editor/{editorId}
		 * @secure
		 */
		editorUpdate: (editorId: string, data: EditorUpdateRequest, params: RequestParams = {}) =>
			this.request<
				EditorGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/${editorId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorDelete
		 * @summary Удаление редактора
		 * @request DELETE:/api/v2/editor/{editorId}
		 * @secure
		 */
		editorDelete: (editorId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/${editorId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorLayerCreate
		 * @summary Создание слоя редактора
		 * @request PUT:/api/v2/editor/layer/{editorId}
		 * @secure
		 */
		editorLayerCreate: (editorId: string, data: EditorLayerCreateRequest, params: RequestParams = {}) =>
			this.request<
				EditorLayerGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/layer/${editorId}`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorLayerGet
		 * @summary Получение слоя редактора
		 * @request GET:/api/v2/editor/layer/{editorId}/{layerId}
		 * @secure
		 */
		editorLayerGet: (editorId: string, layerId: string, params: RequestParams = {}) =>
			this.request<
				EditorLayerGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/layer/${editorId}/${layerId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorLayerUpdate
		 * @summary Изменить слой редактора
		 * @request PATCH:/api/v2/editor/layer/{editorId}/{layerId}
		 * @secure
		 */
		editorLayerUpdate: (
			editorId: string,
			layerId: string,
			data: EditorLayerUpdateRequest,
			params: RequestParams = {},
		) =>
			this.request<
				EditorLayerGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/layer/${editorId}/${layerId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorLayerDelete
		 * @summary Удаление слоя редактора
		 * @request DELETE:/api/v2/editor/layer/{editorId}/{layerId}
		 * @secure
		 */
		editorLayerDelete: (editorId: string, layerId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/layer/${editorId}/${layerId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorLayerMove
		 * @summary Изменить очередь слоя редактора
		 * @request PUT:/api/v2/editor/layer/{editorId}/{layerId}/{moveIndex}
		 * @secure
		 */
		editorLayerMove: (editorId: string, layerId: string, moveIndex: number, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/layer/${editorId}/${layerId}/${moveIndex}`,
				method: 'PUT',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorExportStatus
		 * @summary Узнать статус экспорта видео из редактора
		 * @request GET:/api/v2/editor/export/{editorId}
		 * @secure
		 */
		editorExportStatus: (editorId: string, params: RequestParams = {}) =>
			this.request<
				EditorGetRenderingStatusResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/export/${editorId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags editor
		 * @name EditorExport
		 * @summary Экспорт видео из редактора
		 * @request POST:/api/v2/editor/export/{editorId}
		 * @secure
		 */
		editorExport: (editorId: string, data: EditorExportRequest, params: RequestParams = {}) =>
			this.request<
				EditorGetRenderingStatusResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/editor/export/${editorId}`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FilesGet
		 * @summary Получение списка файлов
		 * @request POST:/api/v2/file
		 * @secure
		 */
		filesGet: (data: FilesGetRequest, params: RequestParams = {}) =>
			this.request<
				FilesGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FileUpload
		 * @summary Загрузка файлов
		 * @request PUT:/api/v2/file
		 * @secure
		 */
		fileUpload: (
			data: {
				/** Файл(ы) */
				files: File[];
				/**
				 * Папка куда загружать
				 * @format uuid
				 */
				folderId?: string | null;
			},
			params: RequestParams = {},
		) =>
			this.request<
				FilesUploadResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FilesUpdate
		 * @summary Изменить файлы
		 * @request PATCH:/api/v2/file
		 * @secure
		 */
		filesUpdate: (data: FilesUpdateRequest, params: RequestParams = {}) =>
			this.request<
				FileGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FilesDelete
		 * @summary Удаление файла
		 * @request DELETE:/api/v2/file
		 * @secure
		 */
		filesDelete: (data: FilesDeleteRequest, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file`,
				method: 'DELETE',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FilesCopy
		 * @summary Скопировать файлы
		 * @request PATCH:/api/v2/file/copy
		 * @secure
		 */
		filesCopy: (data: FilesCopyRequest, params: RequestParams = {}) =>
			this.request<
				FileGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file/copy`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FileDownloadPreview
		 * @summary Получить файл превью
		 * @request GET:/api/v2/file/preview/{fileId}
		 * @secure
		 */
		fileDownloadPreview: (fileId: string, params: RequestParams = {}) =>
			this.request<
				void,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file/preview/${fileId}`,
				method: 'GET',
				secure: true,
				format: 'blob',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FileDownload
		 * @summary Скачивание файла
		 * @request GET:/api/v2/file/download/{fileId}
		 * @secure
		 */
		fileDownload: (fileId: string, params: RequestParams = {}) =>
			this.request<
				void,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file/download/${fileId}`,
				method: 'GET',
				secure: true,
				format: 'blob',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FileGet
		 * @summary Получить файл
		 * @request POST:/api/v2/file/{fileId}
		 * @secure
		 */
		fileGet: (fileId: string, params: RequestParams = {}) =>
			this.request<
				FileGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file/${fileId}`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FileUpdate
		 * @summary Изменить файл
		 * @request PATCH:/api/v2/file/{fileId}
		 * @secure
		 */
		fileUpdate: (fileId: string, data: FileUpdateRequest, params: RequestParams = {}) =>
			this.request<
				FileGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file/${fileId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags file
		 * @name FileDelete
		 * @summary Удаление файла
		 * @request DELETE:/api/v2/file/{fileId}
		 * @secure
		 */
		fileDelete: (fileId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/file/${fileId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FoldersGet
		 * @summary Получение списка папок
		 * @request POST:/api/v2/folder
		 * @secure
		 */
		foldersGet: (data: FoldersGetRequest, params: RequestParams = {}) =>
			this.request<
				FoldersGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FolderCreate
		 * @summary Создание новой папки
		 * @request PUT:/api/v2/folder
		 * @secure
		 */
		folderCreate: (data: FolderCreateRequest, params: RequestParams = {}) =>
			this.request<
				FolderGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FoldersUpdate
		 * @summary Изменение информации о папках
		 * @request PATCH:/api/v2/folder
		 * @secure
		 */
		foldersUpdate: (data: FoldersUpdateRequest, params: RequestParams = {}) =>
			this.request<
				FolderGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FoldersDelete
		 * @summary Удаление папок
		 * @request DELETE:/api/v2/folder
		 * @secure
		 */
		foldersDelete: (data: FoldersDeleteRequest, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder`,
				method: 'DELETE',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FoldersCopy
		 * @summary Копирование папок
		 * @request PATCH:/api/v2/folder/copy
		 * @secure
		 */
		foldersCopy: (data: FoldersCopyRequest, params: RequestParams = {}) =>
			this.request<
				FolderGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder/copy`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FolderGet
		 * @summary Получение информации о папке
		 * @request GET:/api/v2/folder/{folderId}
		 * @secure
		 */
		folderGet: (folderId: string, params: RequestParams = {}) =>
			this.request<
				FolderGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder/${folderId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FolderUpdate
		 * @summary Изменение информации о папке
		 * @request PATCH:/api/v2/folder/{folderId}
		 * @secure
		 */
		folderUpdate: (folderId: string, data: FolderIdUpdateRequest, params: RequestParams = {}) =>
			this.request<
				FolderGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder/${folderId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags folder
		 * @name FolderDelete
		 * @summary Удаление папки
		 * @request DELETE:/api/v2/folder/{folderId}
		 * @secure
		 */
		folderDelete: (folderId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/folder/${folderId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UsersGet
		 * @summary Получение информации о пользователях (только администратор)
		 * @request POST:/api/v2/user
		 * @secure
		 */
		usersGet: (data: UsersGetRequest, params: RequestParams = {}) =>
			this.request<
				UsersGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/user`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserDisable
		 * @summary Скрытие аккаунта пользователя (только администратор)
		 * @request PATCH:/api/v2/user/disable/{userId}
		 * @secure
		 */
		userDisable: (userId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/user/disable/${userId}`,
				method: 'PATCH',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserEnable
		 * @summary Открытие аккаунта пользователя (только администратор)
		 * @request PATCH:/api/v2/user/enable/{userId}
		 * @secure
		 */
		userEnable: (userId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/user/enable/${userId}`,
				method: 'PATCH',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserGet
		 * @summary Получение информации о пользователе (только администратор)
		 * @request GET:/api/v2/user/{userId}
		 * @secure
		 */
		userGet: (userId: string, params: RequestParams = {}) =>
			this.request<
				UserGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/user/${userId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserUpdate
		 * @summary Изменение информации о пользователе (только администратор)
		 * @request PATCH:/api/v2/user/{userId}
		 * @secure
		 */
		userUpdate: (userId: string, data: UserUpdateRequest, params: RequestParams = {}) =>
			this.request<
				UserGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/user/${userId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags user
		 * @name UserDelete
		 * @summary Удаление аккаунта пользователя (только администратор)
		 * @request DELETE:/api/v2/user/{userId}
		 * @secure
		 */
		userDelete: (userId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/user/${userId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags invoice
		 * @name InvoicesGet
		 * @summary Получение списка счётов
		 * @request POST:/api/v2/invoice
		 * @secure
		 */
		invoicesGet: (data: InvoicesGetRequest, params: RequestParams = {}) =>
			this.request<
				InvoicesGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/invoice`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags invoice
		 * @name InvoiceCreate
		 * @summary Выставление счета
		 * @request PUT:/api/v2/invoice
		 * @secure
		 */
		invoiceCreate: (data: InvoiceCreateRequest, params: RequestParams = {}) =>
			this.request<
				InvoiceGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/invoice`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags invoice
		 * @name InvoiceUpload
		 * @summary Загрузка счёта (только бухгалтер)
		 * @request POST:/api/v2/invoice/upload/{invoiceId}
		 * @secure
		 */
		invoiceUpload: (
			invoiceId: string,
			data: {
				/**
				 * Файл
				 * @format binary
				 */
				file: File;
			},
			params: RequestParams = {},
		) =>
			this.request<
				InvoiceGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/invoice/upload/${invoiceId}`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.FormData,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags invoice
		 * @name InvoiceConfirmed
		 * @summary Подтверждение счёта (только бухгалтер)
		 * @request GET:/api/v2/invoice/confirmed/{invoiceId}
		 * @secure
		 */
		invoiceConfirmed: (invoiceId: string, params: RequestParams = {}) =>
			this.request<
				InvoiceGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/invoice/confirmed/${invoiceId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags invoice
		 * @name InvoicePayed
		 * @summary Оплата по счету (только бухгалтер)
		 * @request GET:/api/v2/invoice/payed/{invoiceId}
		 * @secure
		 */
		invoicePayed: (invoiceId: string, params: RequestParams = {}) =>
			this.request<
				void,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/invoice/payed/${invoiceId}`,
				method: 'GET',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags invoice
		 * @name InvoiceDownload
		 * @summary Метод для скачивания файла excel/pdf/etc
		 * @request GET:/api/v2/invoice/download/{invoiceId}/{format}
		 * @secure
		 */
		invoiceDownload: (invoiceId: string, format: string, params: RequestParams = {}) =>
			this.request<
				void,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/invoice/download/${invoiceId}/${format}`,
				method: 'GET',
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags playlist
		 * @name PlaylistsGet
		 * @summary Получение списка плэйлистов
		 * @request POST:/api/v2/playlist
		 * @secure
		 */
		playlistsGet: (data: PlaylistsGetRequest, params: RequestParams = {}) =>
			this.request<
				PlaylistsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/playlist`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags playlist
		 * @name PlaylistCreate
		 * @summary Создание плэйлиста
		 * @request PUT:/api/v2/playlist
		 * @secure
		 */
		playlistCreate: (data: PlaylistCreateRequest, params: RequestParams = {}) =>
			this.request<
				PlaylistGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/playlist`,
				method: 'PUT',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags playlist
		 * @name PlaylistGet
		 * @summary Получение плэйлиста
		 * @request GET:/api/v2/playlist/{playlistId}
		 * @secure
		 */
		playlistGet: (playlistId: string, params: RequestParams = {}) =>
			this.request<
				PlaylistGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/playlist/${playlistId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags playlist
		 * @name PlaylistUpdate
		 * @summary Обновление плэйлиста
		 * @request PATCH:/api/v2/playlist/{playlistId}
		 * @secure
		 */
		playlistUpdate: (playlistId: string, data: PlaylistUpdateRequest, params: RequestParams = {}) =>
			this.request<
				PlaylistGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/playlist/${playlistId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags playlist
		 * @name PlaylistDelete
		 * @summary Удаление плэйлиста
		 * @request DELETE:/api/v2/playlist/{playlistId}
		 * @secure
		 */
		playlistDelete: (playlistId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/playlist/${playlistId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags statistics
		 * @name DeviceStatus
		 * @summary Отчёт по статусу устройства
		 * @request POST:/api/v2/statistics/device-status
		 * @secure
		 */
		deviceStatus: (data: ReportDeviceStatusRequest, params: RequestParams = {}) =>
			this.request<
				void,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/statistics/device-status`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags statistics
		 * @name ReportViews
		 * @summary Отчёт по показам
		 * @request POST:/api/v2/statistics/report-views
		 * @secure
		 */
		reportViews: (data: ReportViewsRequest, params: RequestParams = {}) =>
			this.request<
				void,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/statistics/report-views`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bid
		 * @name BidsGet
		 * @summary Получение списка заявок
		 * @request POST:/api/v2/bid
		 * @secure
		 */
		bidsGet: (data: BidsGetRequest, params: RequestParams = {}) =>
			this.request<
				BidsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/bid`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bid
		 * @name BidGet
		 * @summary Получение заявки
		 * @request GET:/api/v2/bid/{bidId}
		 * @secure
		 */
		bidGet: (bidId: string, params: RequestParams = {}) =>
			this.request<
				BidGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/bid/${bidId}`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bid
		 * @name BidUpdate
		 * @summary Изменить заявку
		 * @request PATCH:/api/v2/bid/{bidId}
		 * @secure
		 */
		bidUpdate: (bidId: string, data: BidUpdateRequest, params: RequestParams = {}) =>
			this.request<
				BidGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/bid/${bidId}`,
				method: 'PATCH',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bid
		 * @name BidDelete
		 * @summary Удаление заявки
		 * @request DELETE:/api/v2/bid/{bidId}
		 * @secure
		 */
		bidDelete: (bidId: string, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/bid/${bidId}`,
				method: 'DELETE',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bid
		 * @name BidPrecalcPromo
		 * @summary Возвращает предрасчет мониторов (для promo)
		 * @request POST:/api/v2/bid/precalc-promo
		 * @secure
		 */
		bidPrecalcPromo: (data: BidPrecalcPromoRequest, params: RequestParams = {}) =>
			this.request<
				BidPrecalcResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/bid/precalc-promo`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags bid
		 * @name BidPrecalcSum
		 * @summary Возвращает предрасчет мониторов (для суммы списания)
		 * @request POST:/api/v2/bid/precalc-sum
		 * @secure
		 */
		bidPrecalcSum: (data: BidPrecalcSumRequest, params: RequestParams = {}) =>
			this.request<
				BidPrecalcResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/bid/precalc-sum`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags crontab
		 * @name CrontabCreateUsers
		 * @summary Включение CronTab для пользователей (только администратор)
		 * @request POST:/api/v2/crontab/create/users
		 * @secure
		 */
		crontabCreateUsers: (data: CrontabCreateRequest, params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/crontab/create/users`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags crontab
		 * @name CrontabDeleteUsers
		 * @summary Выключение CronTab для пользователей (только администратор)
		 * @request POST:/api/v2/crontab/delete/users
		 * @secure
		 */
		crontabDeleteUsers: (params: RequestParams = {}) =>
			this.request<
				SuccessResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/crontab/delete/users`,
				method: 'POST',
				secure: true,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags constants
		 * @name ConstantsGet
		 * @summary Получение списка констант
		 * @request GET:/api/v2/constants
		 */
		constantsGet: (params: RequestParams = {}) =>
			this.request<
				ConstantsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/constants`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags wallet
		 * @name WalletOperationsGet
		 * @summary Получение списка операций
		 * @request POST:/api/v2/wallet
		 * @secure
		 */
		walletOperationsGet: (data: WalletOperationsGetRequest, params: RequestParams = {}) =>
			this.request<
				WalletOperationsGetResponse,
				| BadRequestError
				| UnauthorizedError
				| ForbiddenError
				| NotFoundError
				| NotAcceptableError
				| ConflictError
				| PreconditionFailedError
				| InternalServerError
				| NotImplementedError
			>({
				path: `/api/v2/wallet`,
				method: 'POST',
				body: data,
				secure: true,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
}
