import React, { ComponentType } from 'react';
import ReactDOM from 'react-dom';
import { ReactifiedModule } from '@yandex/ymaps3-types/reactify';
import ClustererModule from '@yandex/ymaps3-types/packages/clusterer';

export interface IPropsWithYandexMaps {
	ymaps3: ReactifiedModule<typeof ymaps3> &
		ReactifiedModule<typeof ClustererModule>;
}

export function withYandexMaps<T extends IPropsWithYandexMaps>(
	Component: ComponentType<T>,
) {
	let ymaps3ReactModule: IPropsWithYandexMaps['ymaps3'] | null = null;

	Promise.all([
		ymaps3.import('@yandex/ymaps3-reactify'),
		ymaps3.import('@yandex/ymaps3-clusterer@0.0.1'),
		ymaps3.ready,
	]).then(([ymaps3React, clusterer]) => {
		const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);

		ymaps3ReactModule = {
			...reactify.module(ymaps3),
			...reactify.module(clusterer),
		};
	});

	return (props: Omit<T, keyof IPropsWithYandexMaps>) => {
		if (ymaps3ReactModule) {
			const propsWithYmaps = {
				...props,
				ymaps3: { ...ymaps3ReactModule },
			} as T;

			return <Component {...propsWithYmaps} />;
		}
		return null;
	};
}
