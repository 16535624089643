import { action, makeObservable, observable } from 'mobx';
import { Task } from './task';

export class TaskStore {
	@observable tasks: Task[] = [];

	constructor() {
		makeObservable(this);
	}

	@action createTask(...args: ConstructorParameters<typeof Task>) {
		const task = new Task(...args);
		this.tasks.push(task);

		return task;
	}

	find(id: Task['id']) {
		return this.tasks.find((t) => t.id === id);
	}

	@action.bound clear(ids: Task['id'] | Array<Task['id']>) {
		if (ids) {
			ids = Array.isArray(ids) ? ids : [ids];

			this.tasks = this.tasks.filter((t) => !ids.includes(t.id));
		} else {
			this.tasks = [];
		}
	}
}

export const taskStore = new TaskStore();
