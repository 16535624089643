import cx from 'classnames';
import { FC, HTMLAttributes } from 'react';
import './styles.scss';

export interface IInputFieldProps extends HTMLAttributes<HTMLLabelElement> {
	type?: HTMLInputElement['type'];
}

export const InputField: FC<IInputFieldProps> = ({
	className,
	children,
	type,
	...restProps
}) => (
	<label
		{...restProps}
		className={cx(
			'form__field',
			type === 'checkbox' && 'form__checkbox',
			type === 'radio' && 'form__radio',
			type === 'file' && 'form__file',
			(type === 'text' ||
				type === 'number' ||
				type === 'email' ||
				type === 'password') &&
				'form__native',
			className,
		)}
	>
		{children}
	</label>
);
