import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { authStore } from 'stores/auth';
import { RouteLink } from 'modules/routing-module';

import { Button } from 'components/common';
import { Checkbox, Form, Input, InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import './styles.scss';

const LoginRegister: FC = () => (
	<AuthWrapper
		title="Welcome to myscreen"
		subtitle="Sign in to your account to continue"
	>
		<Form onSubmit={authStore.login}>
			<Input
				type="email"
				name="email"
				value={authStore.email}
				onChange={authStore.setField}
				placeholder={
					<FormattedMessage id="Email Address" defaultMessage="Email Address" />
				}
				required
			/>
			<Input
				type="password"
				name="password"
				value={authStore.password}
				onChange={authStore.setField}
				placeholder={
					<FormattedMessage id="Password" defaultMessage="Password" />
				}
				minLength={8}
				required
			/>
			<Checkbox
				name="remember_me"
				label={
					<FormattedMessage id="Remember me" defaultMessage="Remember me" />
				}
				checked={authStore.remember_me}
				onChange={authStore.setField}
			/>
			<InputField>
				<Button type="submit" primary>
					<FormattedMessage id="Sign in" defaultMessage="Sign in" />
				</Button>
			</InputField>
			<InputField>
				<RouteLink routeKey="ResetPassword" className="link">
					<FormattedMessage id="Forgot password" />
				</RouteLink>
			</InputField>
		</Form>
	</AuthWrapper>
);

export default observer(LoginRegister);
