import { FC, forwardRef, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { authStore } from 'stores/auth';

import { Layout } from 'components/common';
import { IMenu, IMenuItemProps, Menu } from '../menu';

import './styles.scss';
import { UserRole } from 'utils/api/api';

const HeaderProfileMenuItem = forwardRef<HTMLAnchorElement, IMenuItemProps>(
	(
		{
			activity,
			displayTitle,
			className,
			iconClassName,
			iconClassNameActive,
			...restProps
		},
		linkRef,
	) => (
		<NavLink
			ref={linkRef}
			{...restProps}
			className={cx('profile-menu__item', iconClassName)}
		>
			{displayTitle}
		</NavLink>
	),
);

export const HeaderProfile: FC = observer(() => {
	const [popupIsOpen, setPopupIsOpen] = useState(false);

	const profileMenuSchema = useMemo<IMenu['routingSchema']>(
		() =>
			authStore.userRole === UserRole.Accountant
				? []
				: [
						{ routeKey: 'CurrentUser', displayTitle: authStore.userFullName },
						'Profile',
						'Statistics',
						'AccountantInvoices',
						'Logout',
					],
		[authStore.userFullName, authStore.userRole],
	);

	return (
		<div className="header__profile-block">
			<OutsideClickHandler onOutsideClick={() => setPopupIsOpen(false)}>
				<Layout flex row className="header__profile">
					<button
						className="header__profile-button"
						onClick={() => setPopupIsOpen((popupIsOpen) => !popupIsOpen)}
					/>
				</Layout>
				{popupIsOpen && (
					<Menu
						className="header__profile-menu"
						routingSchema={profileMenuSchema}
						ItemComponent={HeaderProfileMenuItem}
					/>
				)}
			</OutsideClickHandler>
		</div>
	);
});
