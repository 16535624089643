import {
	CSSProperties,
	FC,
	HTMLAttributes,
	MouseEvent,
	RefObject,
	useCallback,
	useMemo,
} from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import cx from 'classnames';
import { videoEditorStore } from 'modules/video-editor-module';

import { TimelinePointer } from './timeline-pointer';
import { TimelineScale } from './timeline-scale';
import { FileTrack } from '../file-track';

type TTimelineElement = HTMLDivElement;

export interface ITimelineComponent extends HTMLAttributes<TTimelineElement> {
	innerRef: RefObject<TTimelineElement>;
}

const TIMELINE_LENGTH_FACTOR = 2;
const NON_CLICK_TIMELINE_CHILDREN = [
	'.file-track__layout',
	'.timeline__pointer:not(.timeline__pointer--preview)',
];

const TimelineComponent: FC<ITimelineComponent> = ({
	className,
	style,
	innerRef,
	...restProps
}) => {
	const localStore = useLocalObservable(() => ({
		pointerPreviewStyle: {},
		setPointerPreviewStyle(style: CSSProperties) {
			this.pointerPreviewStyle = style;
		},
	}));

	const minScaleLength = useMemo(
		() =>
			`${
				videoEditorStore.scaleUnitSeconds *
				videoEditorStore.secondsToPixelFactor
			}px`,
		[videoEditorStore.secondsToPixelFactor, videoEditorStore.scaleUnitSeconds],
	);

	const videoTrackList = useMemo(
		() =>
			videoEditorStore.layerTracks.map((layer) => (
				<FileTrack
					key={`${layer.id}:${layer.start}`}
					name={layer.file.name}
					track={layer}
				/>
			)),
		[videoEditorStore.layerTracks],
	);

	const audioTrackList = useMemo(
		() =>
			videoEditorStore.audioTracks.map((layer) => (
				<FileTrack
					key={`${layer.id}:${layer.start}`}
					name={layer.file.name}
					track={layer}
				/>
			)),
		[videoEditorStore.audioTracks],
	);

	const { timeStamps, bodyWidth } = useMemo(() => {
		const minTimelineDuration =
			window.innerWidth / videoEditorStore.secondsToPixelFactor;
		const totalTimelineDuration =
			videoEditorStore.totalDuration * TIMELINE_LENGTH_FACTOR;

		const timelineDuration =
			totalTimelineDuration > minTimelineDuration
				? totalTimelineDuration
				: minTimelineDuration;

		const stampsNumber = Math.round(
			timelineDuration / videoEditorStore.scaleUnitSeconds,
		);

		return {
			timeStamps: Array.from(new Array(stampsNumber)),
			bodyWidth: `${
				timelineDuration * videoEditorStore.secondsToPixelFactor
			}px`,
		};
	}, [
		videoEditorStore.totalDuration,
		videoEditorStore.secondsToPixelFactor,
		videoEditorStore.scaleUnitSeconds,
	]);

	const calcEventCursorX = useCallback(
		(e: MouseEvent<HTMLDivElement>) =>
			e.clientX -
			e.currentTarget.getBoundingClientRect().left +
			e.currentTarget.scrollLeft,
		[],
	);

	const handleTimelineBodyClick = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			if (
				NON_CLICK_TIMELINE_CHILDREN.some((css) =>
					// @ts-expect-error: no TypeScript support
					e.target.closest(css),
				)
			)
				return;
			const x = calcEventCursorX(e);
			videoEditorStore.moveTimelinePointer({ x });
		},
		[calcEventCursorX],
	);

	const handleTimelineBodyMove = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			if (
				NON_CLICK_TIMELINE_CHILDREN.some((css) =>
					// @ts-expect-error: no TypeScript support
					e.target.closest(css),
				) ||
				videoEditorStore.tracksDragging
			) {
				localStore.setPointerPreviewStyle({});
				return;
			}
			const x = calcEventCursorX(e);
			localStore.setPointerPreviewStyle({
				transform: `translateX(calc(${x}px - 50%))`,
				visibility: 'visible',
			});
		},
		[localStore, calcEventCursorX, videoEditorStore.tracksDragging],
	);

	const handleTimelineBodyLeave = useCallback(() => {
		if (Object.keys(localStore.pointerPreviewStyle).length) {
			localStore.setPointerPreviewStyle({});
		}
	}, [localStore]);

	return (
		<div
			ref={innerRef}
			{...restProps}
			className={cx('timeline timeline__layout', className)}
			style={{
				...style,
				// @ts-expect-error: no TypeScript support
				'--min-scale-length': minScaleLength,
				'--timeline-body-width': bodyWidth,
			}}
		>
			<div className="timeline__aside">
				<div className="timeline__track-layout">
					<div className="timeline__track-icon timeline__track-icon--video" />
				</div>
				<div className="timeline__track-layout">
					<div className="timeline__track-icon timeline__track-icon--audio" />
				</div>
			</div>
			<div
				className={cx('timeline__body')}
				onClick={handleTimelineBodyClick}
				onMouseMove={handleTimelineBodyMove}
				onMouseLeave={handleTimelineBodyLeave}
			>
				<div
					className="timeline__pointer timeline__pointer--preview"
					style={{ ...localStore.pointerPreviewStyle }}
				/>
				<TimelinePointer />
				<TimelineScale timeStamps={timeStamps} />
				<div className="timeline__track-layout">
					<div className="timeline__track-body">{videoTrackList}</div>
				</div>
				<div className="timeline__track-layout">
					<div className="timeline__track-body">{audioTrackList}</div>
				</div>
			</div>
		</div>
	);
};

export const Timeline = observer(TimelineComponent);
