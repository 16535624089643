import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import {
	BehaviorEvents,
	DomEvents,
	MapEvents,
	YMap,
	YMapProps,
} from '@yandex/ymaps3-types';
import { FeatureCollection } from 'geojson';

import {
	DEFAULT_LOCATION,
	DEFAULT_ZOOM_RANGE,
	IPropsWithYandexMaps,
	throttledGeoSearch,
	withYandexMaps,
} from 'utils/yandex-api';
import { IGeoSearchParams } from 'utils/yandex-api/types';

import './custom-map.scss';

export type MapSearchCallback = (
	features: FeatureCollection['features'],
) => void;
export type MapInitCallback = (map: YMap) => void;

export interface IMapListenerProps
	extends DomEvents,
		MapEvents,
		BehaviorEvents {}

export interface ICustomMapProps
	extends Omit<YMapProps, 'children' | 'location'>,
		Partial<IMapListenerProps>,
		IPropsWithYandexMaps {
	location?: YMapProps['location'];
	onSearch?: MapSearchCallback;
	onInit?: MapInitCallback;
	geoSearchParams?: IGeoSearchParams;
	children?:
		| ReactNode
		| ((ymaps3: IPropsWithYandexMaps['ymaps3']) => ReactNode | undefined);
}

export const MAP_COPYRIGHT = 'MyScreen |';
export const MIN_SEARCH_TEXT_LENGTH = 3;

const CustomMapComponent: FC<ICustomMapProps> = ({
	ymaps3,
	children,
	location,
	onTouchStart,
	onTouchMove,
	onTouchEnd,
	onTouchCancel,
	onPointerDown,
	onPointerMove,
	onPointerUp,
	onPointerCancel,
	onFastClick,
	onClick,
	onDblClick,
	onMouseUp,
	onMouseDown,
	onMouseEnter,
	onMouseLeave,
	onMouseMove,
	onContextMenu,
	onRightDblClick,
	onUpdate,
	onResize,
	onStateChanged,
	onActionStart,
	onActionEnd,
	onSearch,
	onInit,
	geoSearchParams,
	zoomRange,
	...restProps
}) => {
	const mapRef = useRef<YMap | null>(null);

	const initializeMap = useCallback(
		(map: YMap | null) => {
			if (mapRef.current || !map) return;

			// if (events) {
			// 	events.forEach(([types, callback]) => {
			// 		map.events.add(types, callback);
			// 	});
			// }
			//
			// // Adds name of the organization.
			// map.copyrights.get().then((arr) => {
			// 	if (arr.includes(MAP_COPYRIGHT)) return;
			// 	map.copyrights.add(MAP_COPYRIGHT);
			// });

			// // @ts-expect-error: no TypeScript support
			// map.copyrights.togglePromo(); // Deletes the "Как добраться?" button.

			mapRef.current = map;

			if (onInit) {
				onInit(mapRef.current);
			}
		},
		[onInit],
	);

	useEffect(() => {
		if (
			onSearch &&
			geoSearchParams &&
			geoSearchParams.text.length >= MIN_SEARCH_TEXT_LENGTH
		) {
			throttledGeoSearch(
				geoSearchParams,
				(result) => {
					onSearch(result.features);
				},
				false,
			);
		}
	}, [geoSearchParams, onSearch]);

	return (
		<ymaps3.YMap
			ref={initializeMap}
			location={location || DEFAULT_LOCATION}
			zoomRange={zoomRange || DEFAULT_ZOOM_RANGE}
			zoomStrategy="zoomToPointer"
			{...restProps}
		>
			{/* Add a map scheme layer */}
			<ymaps3.YMapDefaultSchemeLayer />
			{/* Add a layer of geo objects to display the markers */}
			<ymaps3.YMapDefaultFeaturesLayer />
			{typeof children === 'function' ? children(ymaps3) : children}
			<ymaps3.YMapListener
				{...{
					onTouchStart,
					onTouchMove,
					onTouchEnd,
					onTouchCancel,
					onPointerDown,
					onPointerMove,
					onPointerUp,
					onPointerCancel,
					onFastClick,
					onClick,
					onDblClick,
					onMouseUp,
					onMouseDown,
					onMouseEnter,
					onMouseLeave,
					onMouseMove,
					onContextMenu,
					onRightDblClick,
					onUpdate,
					onResize,
					onStateChanged,
					onActionStart,
					onActionEnd,
				}}
			/>
		</ymaps3.YMap>
	);
};

export const CustomMap = withYandexMaps(CustomMapComponent);
