import { ChangeEvent, FormEvent } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import queryString from 'query-string';

import {
	AUTH_REMEMBER_ME,
	AUTH_STORAGE,
	ONE_GB_IN_BYTES,
	TEN_GB_IN_BYTES,
} from '_constants/index';
import { REGISTER_USER_ROLES } from '_types/constants';
import { IAuthStore, IUser } from '_types/stores';

import {
	cancelToken,
	crossTabSessionStorage,
	filterObject,
	handleError,
	swaggerApi,
	toast,
} from 'utils';
import { UserPlan, UserRole } from 'utils/api/api';

import { appStore } from '../app';
import {
	checkRequestData,
	RequestError,
	TRequestCondition,
} from '../../utils/api/check-request-data';
import { errorStore } from '../error-store';

export type TUserName = keyof Pick<
	IUser,
	'surname' | 'name' | 'middleName' | 'email'
>;
export type TUserDetailsName = keyof Pick<
	IUser,
	| 'company'
	| 'companyPhone'
	| 'companyLegalAddress'
	| 'companyActualAddress'
	| 'companyTIN'
	| 'companyRRC'
	| 'companyPSRN'
	| 'companyEmail'
	| 'companyBank'
	| 'companyBIC'
	| 'companyCorrespondentAccount'
	| 'companyPaymentAccount'
>;

export const USER_INFO_NAMES: Array<TUserName> = [
	'surname',
	'name',
	'middleName',
	'email',
];
export const USER_DETAILS_NAMES: Array<TUserDetailsName> = [
	'company',
	'companyPhone',
	'companyLegalAddress',
	'companyActualAddress',
	'companyTIN',
	'companyRRC',
	'companyPSRN',
	'companyEmail',
	'companyBank',
	'companyBIC',
	'companyCorrespondentAccount',
	'companyPaymentAccount',
];
export const REQUIRED_NAMES: Array<TUserName> = ['surname', 'name', 'email'];
export const REQUIRED_DETAILS_NAMES: Array<TUserDetailsName> = [
	'company',
	'companyPhone',
];

export class AuthStore implements IAuthStore {
	@observable wasAuthenticated = false;

	@observable isNotFound = false;

	@observable user: IAuthStore['user'] = null;

	@observable email = '';

	@observable password = '';

	@observable name = '';

	@observable surname = '';

	@observable middleName = '';

	@observable role: IUser['role'] | null = null;

	@observable remember_me = true;

	@observable error: string | null = null;

	constructor() {
		makeObservable(this);

		crossTabSessionStorage.setListener(AUTH_STORAGE, (value) => {
			if (value) {
				this.initAuth();
			} else {
				this.logout();
			}
		});
	}

	@computed get userMetrics() {
		return (
			this.user?.metrics || {
				monitors: { online: 0, offline: 0, empty: 0, user: 0 },
				storageSpace: {
					storage: 0,
					total:
						this.user?.plan === UserPlan.DEMO
							? ONE_GB_IN_BYTES
							: TEN_GB_IN_BYTES,
				},
				playlists: {
					added: 0,
					played: 0,
				},
			}
		);
	}

	@computed get emailDomain(): string {
		return this.email.split('@')[1];
	}

	@computed get emailDomainWeb(): string | undefined {
		const map: Record<string, string> = {
			'yandex.ru': 'https://mail.yandex.ru/',
			'ya.ru': 'https://mail.yandex.ru/',
			'ya.com': 'https://mail.yandex.ru/',
			'gmail.com': 'https://mail.google.com',
		};
		return map[this.emailDomain];
	}

	@computed get userRole() {
		return this.user?.role;
	}

	@computed get userFullName() {
		return this.user ? `${this.user.name} ${this.user.surname}` : '';
	}

	@action.bound setField = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, type } = event.target;
		const IS_CHECKBOX = type === 'checkbox';
		const IS_SELECT = type === 'select';
		const value = IS_CHECKBOX ? event.target.checked : event.target.value;

		if (IS_CHECKBOX) {
			this.remember_me = value as boolean;
		} else if (IS_SELECT) {
			this.setRole(value as UserRole);
		} else {
			this[name as 'email' | 'password' | 'name' | 'surname' | 'middleName'] =
				value as string;
		}
	};

	@action setRole = (role: IUser['role']) => {
		this.role = role;
	};

	@action resetFields = () => {
		this.email = '';
		this.password = '';
		this.name = '';
		this.surname = '';
		this.middleName = '';
		this.role = null;
		this.remember_me = true;
		this.error = null;
	};

	@action checkFields = (): boolean => {
		if (this.password.length < 8) {
			this.error = appStore.intl.formatMessage({
				id: 'server-error.10007',
				defaultMessage: 'Пароль должен содержать 8 и более символов',
			});
			return false;
		}
		return true;
	};

	@action.bound login = async (
		event: FormEvent<HTMLFormElement>,
	): Promise<void> => {
		event.preventDefault();
		if (!this.checkFields()) return;

		appStore.isLoading = true;

		try {
			const { data: loginData } = await swaggerApi.api.authLogin({
				email: this.email,
				password: this.password,
			});

			this.user = loginData.data;

			crossTabSessionStorage.setItem(
				AUTH_STORAGE,
				JSON.stringify(loginData.payload),
			);
			if (this.remember_me) {
				localStorage.setItem(AUTH_REMEMBER_ME, AUTH_REMEMBER_ME);
				localStorage.setItem(AUTH_STORAGE, JSON.stringify(loginData.payload));
			} else {
				localStorage.removeItem(AUTH_REMEMBER_ME);
				localStorage.removeItem(AUTH_STORAGE);
			}

			this.resetFields();

			appStore.initWebSocket();
		} catch (error) {
			this.error = handleError(error);
		} finally {
			appStore.isLoading = false;
		}
	};

	@action initAuth = async (): Promise<void> => {
		try {
			if (
				this.user ||
				[sessionStorage, localStorage].every((s) => !s.getItem(AUTH_STORAGE))
			) {
				// No authentication token in storage.;
				return;
			}

			if (this.wasAuthenticated) {
				this.wasAuthenticated = false;
			}
			appStore.isLoading = true;

			const { data: authData } = await swaggerApi.api.authGet();

			this.user = authData.data;
			this.error = null;

			appStore.initWebSocket();
		} catch (e) {
			this.error = handleError(e);
		} finally {
			appStore.isLoading = false;
			this.wasAuthenticated = true;
		}
	};

	@action.bound register = async (
		event: FormEvent<HTMLFormElement>,
	): Promise<void> => {
		event.preventDefault();
		let role = this.role;

		if (appStore.isCreatedFor('promo')) {
			role = UserRole.Advertiser; // Default for promo app
		}

		if (!this.checkFields()) return;
		if (!role || REGISTER_USER_ROLES.every((r) => r !== role)) return;

		appStore.isLoading = true;

		try {
			const { status: REGISTER_STATUS } = await swaggerApi.api.authRegister({
				email: this.email,
				password: this.password,
				role,
				name: this.name,
				surname: this.surname,
				middleName: this.middleName,
			});
			if (REGISTER_STATUS !== 409) {
				appStore.navigate(`/send-register-email?domain=${this.emailDomain}`);
			}
		} catch (error) {
			this.error = handleError(error);
		} finally {
			appStore.isLoading = false;
		}
	};

	@action confirmEmail = async (key: string): Promise<void> => {
		appStore.isLoading = true;

		try {
			await swaggerApi.api.authEmailVerify({
				verify: key,
			});

			this.resetFields();
		} catch (error) {
			this.error = handleError(error);
		} finally {
			appStore.isLoading = false;
		}
	};

	@action resetPassword = async (
		event: FormEvent<HTMLFormElement>,
	): Promise<void> => {
		event.preventDefault();

		appStore.isLoading = true;

		try {
			await swaggerApi.api.authResetPassword({
				email: this.email,
			});

			appStore.navigate(`/send-reset-password?domain=${this.emailDomain}`);

			this.resetFields();
		} catch (error) {
			this.error = handleError(error);
		} finally {
			appStore.isLoading = false;
		}
	};

	@action.bound updatePassword = async (
		event: FormEvent<HTMLFormElement>,
	): Promise<void> => {
		event.preventDefault();

		appStore.isLoading = true;

		try {
			const { key } = queryString.parse(appStore.location.search);
			if (!key) {
				return toast.error(
					'В адресной строке сайта отсутствует ключ для сброса пароля.',
				);
			}

			await swaggerApi.api.authResetPasswordVerify({
				password: this.password,
				verify: key.toString(),
			});

			this.resetFields();

			appStore.navigate('/login');
		} catch (e) {
			toast.error(handleError(e));
		} finally {
			appStore.isLoading = false;
		}
	};

	@action logout: IAuthStore['logout'] = () => {
		if (this.wasAuthenticated) {
			this.wasAuthenticated = false;
			this.user = null;
			this.role = null;

			cancelToken();
			appStore.ws?.close();
			setTimeout(() => {
				appStore.navigate('/login');
			});
		}
	};

	@action updateUser: IAuthStore['updateUser'] = async (data) => {
		try {
			if (
				checkRequestData(
					data,
					REQUIRED_NAMES.filter((n) => n in data).map((n) => {
						switch (n) {
							case 'email':
								return {
									key: n,
									validate: (value: string) =>
										/^[\w\W]+@[\w\W]+\.[\w\W]{2,10}$/.test(value),
								};

							default:
								return n;
						}
					}) as Array<TRequestCondition<Partial<IUser>>>,
				)
			) {
				const { data: userData } = await swaggerApi.api.authUpdate(
					filterObject(data, {
						includedKeys: USER_INFO_NAMES,
					}),
				);
				this.user = userData.data;
			}

			return this.user;
		} catch (e) {
			if (e instanceof RequestError) {
				errorStore.setErrors(e.fieldNames);
			} else {
				toast.error(handleError(e));
			}
			return this.user;
		}
	};

	@action updateUserDetails: IAuthStore['updateUserDetails'] = async (data) => {
		try {
			if (
				checkRequestData(
					data,
					REQUIRED_DETAILS_NAMES.filter((n) => n in data).map((n) => {
						switch (n) {
							case 'companyPhone':
								return {
									key: n,
									validate: (value: string) =>
										/^\+7\s?\(?\d{3}?\)?\s?\d{3}[-\s]?\d{4}$/.test(value),
								};

							default:
								return n;
						}
					}) as Array<TRequestCondition<Partial<IUser>>>,
				)
			) {
				const { data: userData } = await swaggerApi.api.authUpdate(
					filterObject(data, {
						includedKeys: USER_DETAILS_NAMES,
					}),
				);
				this.user = userData.data;
			}

			return this.user;
		} catch (e) {
			if (e instanceof RequestError) {
				errorStore.setErrors(e.fieldNames);
			} else {
				toast.error(handleError(e));
			}
			return this.user;
		}
	};

	restrictFrom(roles: UserRole | UserRole[]) {
		roles = Array.isArray(roles) ? roles : [roles];

		return Boolean(this.userRole && !roles.includes(this.userRole));
	}

	onlyFor(roles: UserRole | UserRole[]) {
		roles = Array.isArray(roles) ? roles : [roles];

		return Boolean(this.userRole && roles.includes(this.userRole));
	}
}

const authStore = new AuthStore();

export { authStore };
