import {
	ChangeEventHandler,
	FC,
	FocusEventHandler,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PopupFullscreen } from 'components/popup';
import { Form } from 'components/forms';

export const ChangeNameForm: FC<{
	open: boolean;
	status: null | 'create' | 'edit';
	value: string;
	canBeClosed?: boolean;
	onClose: () => void;
	onSave: (name: string) => void;
}> = (props) => {
	const { open, status, value, canBeClosed = true, onClose, onSave } = props;
	const intl = useIntl();

	const [draftValue, setDraftValue] = useState(value);
	const [error] = useState({
		open: false,
		value: 'The file name already exists',
	});

	useEffect(() => {
		if (open) {
			setDraftValue(value);
		}
	}, [open, value]);

	const renderError = () => {
		if (!error.open) return null;

		return (
			<div className="project-name__error-container">
				<p className="project-name__error-title">
					<FormattedMessage id="Error" />!
				</p>
				<p className="project-name__error-value">
					<FormattedMessage id={error.value || ''} />
				</p>
			</div>
		);
	};

	const handleOutsideClick = useCallback(() => {
		if (!canBeClosed) {
			return;
		}

		onClose();
	}, [canBeClosed, onClose]);

	const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		(event) => {
			setDraftValue(event.target.value);
		},
		[],
	);

	const handleSave = useCallback(() => {
		onSave(draftValue);
	}, [onSave, draftValue]);

	const handleNameInputFocus: FocusEventHandler<HTMLInputElement> = (event) => {
		event.target.select();
	};

	return status && open ? (
		<PopupFullscreen
			onOutsideClick={handleOutsideClick}
			withCloseButton={false}
		>
			<div className="project-name__icon" />
			<p className="project-name__title">
				<FormattedMessage
					id={
						status === 'create' ? 'Create a new project' : 'Rename the project'
					}
				/>
			</p>
			<Form onSubmit={handleSave} className="project-name__form">
				<input
					className="project-name__input"
					placeholder={intl.formatMessage({ id: 'File name' })}
					value={draftValue}
					onChange={handleChange}
					autoFocus
					onFocus={handleNameInputFocus}
				/>
				<button className="project-name__create-button">
					<FormattedMessage id={status === 'create' ? 'Create' : 'Rename'} />
				</button>
			</Form>
			{renderError()}
		</PopupFullscreen>
	) : null;
};
